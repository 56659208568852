// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateComplaint from "../../components/CreateComplaint";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";   import Grid from "@mui/material/Grid";
import StatBoxnumber from "../../components/statboxnumber";

import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';


const Myforms = () => {
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const [totalDeclarados, setTotalDeclarados] = useState(0);
  const [totalcam, setTotalcam] = useState(0);
  const [totalEvaluados, setTotalEvaluados] = useState(0);
  const [totalCampaign, setTotalCam] = useState(0);
  const [totalAutorizados, setTotalAutorizados] = useState(0);
  const [totalConConflicto, setTotalConConflicto] = useState(0);
  const [totalConMitigacion, setTotalConMitigacion] = useState(0);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const cmf = useSelector((state) => state.client.cmf);

  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const loggedInUserprof = useSelector((state) => state.user.userprofile); 
  const [formTotalDays, setformTotalDays] = useState();
  const [loading, setLoading] = useState(false);


  let filteredData = data;


  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
  filteredData = data
  .filter((row) => {
    // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
    const createdAt = parseInt(row.createdAt);
    return createdAt >= dateRange[0] && createdAt <= dateRange[1];
  })
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });


  // Calculate the count of each area type from filtered data
  const areaCounts = filteredData.reduce((counts, item) => {
    const area = item.area || 'Unknown';
    counts[area] = (counts[area] || 0) + 1;
    return counts;
  }, {});

// Calculate the count of each conflict type from filtered data
const conflictCounts = filteredData.reduce((counts, item) => {
  const conflicto = item.tipoconflicto || 'Unknown';
  counts[conflicto] = (counts[conflicto] || 0) + 1;
  return counts;
}, {});

 useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);

    fetchData();

  }, [isFormSubmitted, dateRange]);


const fetchData = async () => {
  try {
    setIsLoading(true); // Set loading to true before fetching data

    let endpoint = `${REACT_APP_API_ENDPOINT}formepd/user/${loggedInUserId}`;
 
    // Check the user profile and update the endpoint accordingly
 //   if (loggedInUserprof === "admin" || loggedInUserprof === "coadmin" || loggedInUserprof === "superadmin"  ) {
 //     endpoint = `${REACT_APP_API_ENDPOINT}formepd/contract/${loggedidcontrato}`;
 //   }
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    delete formData.password;

    // Add unique id and format createdAt
    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      id: row._id,
      createdAt: row.createdAt
        ? new Date(row.createdAt).toLocaleDateString("en-EN", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        : "",
    }));
    setData(formData);
    // Fetch posts(campaign) data for logged-in user
    const postsResponse = await fetch(`${REACT_APP_API_ENDPOINT}posts/${loggedInUserId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const postsData = await postsResponse.json();
    const totalcam = postsData.reduce((total, post) => total + post.description.length, 0);
    setTotalcam(totalcam);
    setIsLoading(false); // Set loading to false after processing data

    // Calculate formTotalDays for each row based on corresponding campaignId
    const dataWithFormTotalDays = dataWithFormattedDate.map((row) => {
      const matchingPost = postsData.find((post) =>
      post.description.includes(row.email)
    );
    
    const campaignCreatedAt = matchingPost
      ? new Date(matchingPost.createdAt).toISOString() // Convert to ISO 8601 format
      : null;
    
    const formCreatedAt = new Date(row.createdAt).toISOString(); // Convert to ISO 8601 format
    
    const timeDifference =
      campaignCreatedAt && formCreatedAt
        ? new Date(campaignCreatedAt) - new Date(formCreatedAt) // Calculate the time difference in milliseconds
        : 0;
    
    const formTotalDays = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
      
      return {
        ...row,
        postId: matchingPost ? matchingPost._id : "",
        campaignName: matchingPost ? matchingPost.comments : "",
        formTotalDays: formTotalDays,
      };
      setformTotalDays(formTotalDays);
      setIsLoading(false);
      setData(dataWithFormattedDate);
    });

     
    // Calculate other data and update state with the calculated data
    const totalDeclarados = dataWithFormTotalDays.length;
    setTotalDeclarados(totalDeclarados);

    const evaluadosData = dataWithFormTotalDays.filter((row) => row.estado === "Evaluada");
    const totalEvaluados = evaluadosData.length;
    setTotalEvaluados(totalEvaluados);

    const authData = dataWithFormTotalDays.filter((row) => row.opauth === "Si");
    const totalAutorizados = authData.length;
    setTotalAutorizados(totalAutorizados);

    const formsConConflicto = dataWithFormTotalDays.filter((row) => row.opconflicto === "Si");
    const totalConConflicto = formsConConflicto.length;
    setTotalConConflicto(totalConConflicto);

    const formsConMitigacion = dataWithFormTotalDays.filter((row) => row.opmitigacion === "Si");
    const totalConMitigacion = formsConMitigacion.length;
    setTotalConMitigacion(totalConMitigacion);
    setIsLoading(false); // Set loading to false after processing data

    setData(dataWithFormTotalDays);
    setForm(formData);
  } catch (error) {
    console.error("Error fetching data:", error);
    setIsLoading(false); // Set loading to false in case of an error

  }
};

const totalNoDeclarados = ((totalcam-totalDeclarados ) * 1).toFixed(0) ;
const progdeclarados = ((totalDeclarados / totalcam) / 100).toFixed(1) ; 
const progNodeclarados = ((totalNoDeclarados / totalcam) * 1).toFixed(1) ; 
const progNodeclaradosper = ((totalNoDeclarados / totalcam) * 100).toFixed(0)+ "%" ;
const progevaluados = ((totalEvaluados / totalcam) * 1).toFixed(1) ;
const progevaluadosdedeclarados = ((totalEvaluados / totalDeclarados) * 1).toFixed(1) ;
const progevaluadosdeclper = ((totalEvaluados / totalDeclarados) * 100).toFixed(0)+ "%" ;
const progconflictodecl = ((totalConConflicto / totalDeclarados) * 1).toFixed(1) ;
const progautorizadosdedeclarados = ((totalAutorizados / totalDeclarados) * 1).toFixed(1) ;
const progmitigacionaut = ((totalConMitigacion / totalAutorizados) * 1).toFixed(1) ;
const progdeclaradosper = ((totalDeclarados / totalcam) * 100).toFixed(0)+ "%" ;

const progconflictoperdecl = ((totalConConflicto / totalDeclarados) * 100).toFixed(0)+ "%" ;
const progautorizadospersin = ((totalAutorizados / totalEvaluados) );
const progmitigacionautpersin = ((totalConMitigacion / totalAutorizados)) ;
const datax = Object.entries(areaCounts).map(([label, count]) => ({ label, count }));
const dataxconflicto = Object.entries(conflictCounts).map(([label, count]) => ({ label, count }));


  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row
      navigate(`/formev/${rowId}`, {
        state: {
          formData: params.row, // Pass the row data
          formTotalDays: params.row.formTotalDays // Pass the formTotalDays value
      
        }
      });   
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };





  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('MisDeclaraciones');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'MisDeclaraciones.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

  const createdAtValueFormatter = (params) => {
    if (params.value) {
      const date = new Date(params.value);
      return date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    }}


    
  const columns = [
    {
      field: "createdAt",
      headerName: <FormattedMessage id="Declarado"/>,
      flex: 1,
      valueFormatter: createdAtValueFormatter,  headerClassName: "bold-header" ,
    },
    { field: "location", headerName: <FormattedMessage id="Organización"/>, flex: 1,  headerClassName: "bold-header" },
    { field: "opconflicto", headerName: <FormattedMessage id="Conflicto"/>, flex: 1, headerClassName: "bold-header",},
    { field: "tipoconflicto", headerName:<FormattedMessage id="Tipo Conflicto"/>, flex: 1,   headerClassName: "bold-header" },
    { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1,   headerClassName: "bold-header"},
    { field: "opauth", headerName: <FormattedMessage id="Autorizado"/>, flex: 1,  headerClassName: "bold-header"},
         
        {
  field: "formTotalDays",
  headerName: <FormattedMessage id="Demora (Días)"/>,
  flex: 1, headerClassName: "bold-header" ,
  renderCell: (params) => (
    <Typography variant="body2">
      {params.row.formTotalDays}
    </Typography>
  ),
},
];



// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  function generateRandomString(length) {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomString += charset.charAt(randomIndex);
    }
  
    return randomString;
  }
  
  // Generate a random 24-character string
  const   campaignidgen = generateRandomString(24);
  const handleCreateCampaignClick = async () => {
    try {
      setLoading(true); // Start loading

      const campaignid = Math.floor(Math.random() * 100000000) + 1;

        navigate(`/myformepd/campaign/${campaignid},${loggedidcontrato},${loggedInUserId},${email},${cmf}`);



    } catch (error) {
      console.log("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Stop loading, whether the request was successful or not
    }
  };
  

  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
//****************************************************
return (
  <Box m="10px">

<Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>

<Box display="flex" justifyContent="space-between" alignItems="center">
   
     <Header title={<FormattedMessage id="Mis Declaraciones" />} subtitle={<FormattedMessage id="Enviadas para evaluación" />} />

     </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>

    <Button
          onClick={handleCreateCampaignClick}
          sx={{ borderRadius: "20px",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[900],
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '10px 20px',
          }}
        >
          <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
          
          <FormattedMessage id="Nueva Declaración"/>

        </Button>

{/* GRID & CHARTS */}
<Box
display="grid"
gridTemplateColumns="repeat(12, 1fr)"
gridAutoRows="140px"
gap="20px"
>


{/* ROW 1 */}

        <Box
        gridColumn={{ xs: 'span 12', md: 'span 3' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >

        <div>
     
         </div>
      </Box>

      <Box
          gridColumn={{ xs: 'span 12', md: 'span 3' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          >

          <div onClick={() => navigate("/Forms")}>

              <StatBoxnom
                title= {totalDeclarados.toString()} // Convert totalEvaluados to string   
                subtitle={<FormattedMessage id="Declaradas" />}

                progress={progdeclarados.toString()} 
                increase={progdeclaradosper.toString()} 
                icon={
                  <EmailIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />}
                      
                    />
          </div>
      </Box>
  

            <Box
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >

            <div onClick={() => navigate("/Forms")}>

              <StatBox
                title= {totalConConflicto.toString()} // Convert totalEvaluados to string   
                subtitle={<FormattedMessage id="Conflicto" />}

                progress={progconflictodecl.toString()} 
                increase={progconflictoperdecl.toString()} 
                icon={
                  <ReportProblemOutlinedIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />
                }
              />
                </div>
            </Box>

      
            <Box
            gridColumn={{ xs: 'span 12', md: 'span 3' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >

            <div onClick={() => navigate("/Forms")}>

              <StatBox
                title={totalEvaluados.toString()} 
                subtitle={<FormattedMessage id="Evaluadas" />}

                progress={progevaluadosdedeclarados.toString()} 
                increase={progevaluadosdeclper.toString()} 
                icon={
                  <GradingOutlinedIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />
                }
              />
              </div>
            </Box>

  </Box>


    <Box>
    

<p></p>



   </Box>

    

            






{/* DATAGRID */}    
    <Box
      m="40px 0 0 0"
      height="45vh"
      sx={{ borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
     
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",

        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      
      <DataGrid
        rowHeight={40}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        columns={columns}
        rows={filteredData.map((row) => ({
          ...row,
          id: row._id, 
        }))}
        components={{ Toolbar: GridToolbar }}
      
        rowsPerPageOptions={[5, 10, 25]} // Include 25 as an option

        onRowClick={handleRowClick}
      />
    </Box>
    
<p></p>

    <Box>
              <Button
                  onClick={handleDownloadXLSX}
                  sx={{ borderRadius: "20px",
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[900],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                  {<FormattedMessage id="Descargar Reporte"/>}

            </Button>
            </Box>
            <p></p>



            <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >
{/*circle  autorizadas de mis recibidas*/}




   

  </Box>
</Box>
);
};


export default Myforms;
import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { Box, Typography, Divider, useTheme, TextField } from "@mui/material";
import UserImage from "./UserImage";
import FlexBetween from "./FlexBetween";
import WidgetWrapper from "./WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../src/theme";
import Paper from '@mui/material/Paper'; 
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormattedMessage } from 'react-intl';


const countries = ['Chile',
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
    'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
    'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
    'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica',
    'Cote d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czechia (Czech Republic)', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti',
    'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini (fmr. "Swaziland")',
    'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea',
    'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
    'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
    'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands',
    'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar (formerly Burma)',
    'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia (formerly Macedonia)',
    'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
    'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
    'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
    'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates',
    'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',
  ];


const { REACT_APP_API_ENDPOINT } = process.env;

const UserWidget = ({ userId, picturePath }) => {
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;
  const colors = tokens(theme.palette.mode);
  const greenText = { color: 'green' }; // Define the CSS style for green text
  const [editedFirstName, setEditedFirstName] = useState("");
  const [editedLastName, setEditedLastName] = useState("");
  const [editedOccupation, setEditedOccupation] = useState("");
  const [editedCelular, setEditedCelular] = useState("");
  const [editedNationality, setEditedNationality] = useState("");
  const [editedRut, setEditedRut] = useState("");

  const [editedLocation, setEditedLocation] = useState("");
  const [editedArea, setEditedArea] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  // Create a state object to hold the updated values
  const [updatedValues, setUpdatedValues] = useState({
    firstName: "",
    lastName: "",
    occupation: "",
    celular: "",
    location: "",
    area: "",
    nationality: "",
    // Add more fields as needed
  });

// Add a function to handle form submission
const handleFormSubmit = async () => {
  try {
    const { REACT_APP_API_ENDPOINT } = process.env;
    const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        firstName: editedFirstName, // Use editedFirstName
        lastName: editedLastName, // Use editedLastName
        occupation: editedOccupation, // Use editedOccupation
        celular: editedCelular, // Use editedCelular
        location: editedLocation, // Use editedLocation
        area: editedArea, // Use editedArea
        nationality: editedNationality, // Use editedArea
        rut: editedRut,

        // Add more fields as needed
      }),
    });
    // Continue with the navigation logic
    //navigate("/form");
    setIsFormSubmitted(true);
    
    // Check if the response status is ok (2xx status codes)
    if (response.ok) {
      // Show the success message
      setIsSuccessMessageVisible(true);

      // Hide the success message after 5 seconds
      const timeoutId = setTimeout(() => {
        setIsSuccessMessageVisible(false);
      },2000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    } else {
      // Handle non-successful response (e.g., display an error message)
      console.error(`PUT request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error(error);
    // Handle error
  }
};


const getUser = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    
    // Initialize the edited state variables with the user data
    setEditedFirstName(data.firstName);
    setEditedLastName(data.lastName);
    setEditedOccupation(data.occupation);
    setEditedCelular(data.celular);
    setEditedLocation(data.location);
    setEditedArea(data.area);
    setEditedNationality(data.nationality);
    setEditedRut(data.rut); // Set the edited Rut

    setUser(data);
  } catch (error) {
    console.error(error);
    // Handle error
  }
};





  useEffect(() => {
    getUser();
    }, [token]);



  
  if (!user) {
    return null;
  }

  const {
    
    firstName,
    lastName,
    location,
    rut,
    celular,
    nationality,
    email,
    occupation,
    userprofile,
    idcontrato,
    area,
  } = user;


  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };
// Add functions to handle changes in the editable text fields
const handleFirstNameChange = (event) => {
  const updatedValue = event.target.value;
  setEditedFirstName(updatedValue); // Update editedFirstName
};

const handleRutChange = (event) => {
  const updatedValue = event.target.value;
  setEditedRut(updatedValue); // Update editedRut
};

const handleLastNameChange = (event) => {
  const updatedValue = event.target.value;
  setEditedLastName(updatedValue); // Update editedLastName
};

const handleCargoChange = (event) => {
  const updatedValue = event.target.value;
  setEditedOccupation(updatedValue); // Update editedOccupation
};

const handleCelularChange = (event) => {
  const updatedValue = event.target.value;
  setEditedCelular(updatedValue); // Update editedCelular
};

const handleNationalityChange = (event) => {
  const updatedValue = event.target.value;
  setEditedNationality(updatedValue); // Update editedCelular
};

const handleEmpresaChange = (event) => {
  const updatedValue = event.target.value;
  setEditedLocation(updatedValue); // Update editedLocation
};

const handleAreaChange = (event) => {
  const updatedValue = event.target.value;
  setEditedArea(updatedValue); // Update editedArea
};


  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
      >

        
        <FlexBetween gap="1rem">
      
          <Box>
            <Typography
              variant="h3"
              color={dark}
              fontWeight="1000"
             
            >
              {firstName} {lastName}
            </Typography>
          </Box>
        </FlexBetween>
      </FlexBetween>

  
      {/* SECOND ROW */}
      <Box width="100%" display="flex" flexDirection="column">
     
      <Paper elevation={3} style={widgetStyle}>
  
      <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
  <TextField
    variant="outlined"
    label={<FormattedMessage id="Rut"/>}
    value={editedRut}
    onChange={handleRutChange} // Add onChange handler
    InputProps={{
      style: {
        color: 'black', // Set text color to black
      },
    }}
    fullWidth
    InputLabelProps={{ shrink: true }}
    sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
  />
</Box>


  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Email"/>}
      value={email}
      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
      disabled
    />
  </Box>

  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Nombres"/>}
      value={editedFirstName} // Use editedFirstName for value
      onChange={handleFirstNameChange} // Use handleFirstNameChange for onChange
     

      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>

  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Apellidos"/>}
      value={editedLastName}
      onChange={handleLastNameChange} // Add onChange handler

      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>

  

  {/* Make editable fields */}
  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Cargo"/>}
      onChange={handleCargoChange} // Add onChange handler

      value={editedOccupation}
      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>

  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Area"/>}
      value={editedArea}
      onChange={handleAreaChange} // Add onChange handler

      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>
  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Organización"/>}
      value={editedLocation}
      onChange={handleEmpresaChange} // Add onChange handler

      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>
  <InputLabel htmlFor="nationality"><FormattedMessage id="Nacionalidad"/></InputLabel>

  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
  <Select
    id="nationality"
    value={editedNationality}
    onChange={handleNationalityChange}
    label="Nacionalidad"
    
    fullWidth
    sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
  >
    {countries.map((country) => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ))}
  </Select>
</Box>


  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Celular"/>}
      value={editedCelular}
      onChange={handleCelularChange} // Add onChange handler

      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
    />
  </Box>
  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Perfil"/>}
      value={userprofile}
      
      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
      disabled // Make it non-editable

    />
  </Box>

  {/* Make these fields non-editable */}
  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="Contrato"/>}
      value={idcontrato}
      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
      disabled // Make it non-editable
    />
  </Box>

  <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
    <TextField
      variant="outlined"
      label={<FormattedMessage id="ID"/>}
      value={userId}
      InputProps={{
        style: {
          color: 'black', // Set text color to black
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ borderRadius: "20px", wordWrap: 'break-word' }}
      disabled // Make it non-editable
    />
  </Box>

</Paper>

     

      </Box>
   
       
  {/* Add a button to save changes */}
  <Box display="flex" alignItems="center" justifyContent="space-between" mt="1rem">
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={() => handleFormSubmit({ /* Pass the updated values here */ })}
          sx={{
            borderRadius: "20px",
            backgroundColor: colors.blueAccent[700],
            color: 'white', // Change text color to white
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              backgroundColor: colors.blueAccent[900], // Change background color on hover
            },
          }}
        >
          <FormattedMessage id="Guardar Cambios"/>
         
        </Button>

     
      </Box>
      <p></p>

      
 {/* Button for resetting password */}
 <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={() => navigate("/reset-password")}
          sx={{
            borderRadius: "20px",
            backgroundColor: colors.blueAccent[700],
            color: 'white', // Change text color to white
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              backgroundColor: colors.blueAccent[900], // Change background color on hover
            },
          }}
        >
                    <FormattedMessage id="Cambiar Contraseña"/>

        </Button>
      <Divider />

{/* Add a success message box */}
{isSuccessMessageVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: colors.greenAccent[400], // Set the background color for success message
            color: 'white', // Set text color to white
            padding: '1rem',
            borderRadius: '15px',
            textAlign: 'center',
            zIndex: 9999, // Set a high z-index to ensure it appears above other elements
          }}
        >
          ¡Cambios Guardados Exitosamente!
        </Box>
      )}
      {/* FOURTH ROW */}

    </WidgetWrapper>
  );
};

export default UserWidget;

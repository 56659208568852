import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import UserWidget from "../../components/UserWidget";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';


const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const loggedInUserId = useSelector((state) => state.user._id);
  const handleFormSubmit = (values) => {
  };

  return (
    <Box m="20px">
      <Header title={<FormattedMessage id="MI PERFIL" />} subtitle={<FormattedMessage id="Datos Principales" />} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
             <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
              sx={{ borderRadius: "30px",
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
             <UserWidget userId={loggedInUserId} picturePath={picturePath} />

          
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
 
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",

};

export default Form;

const regSession = async ({ token, userprofile, loggedInUserId, idcontrato, type }) => {
  if (userprofile !== "superadmin") {
   

  console.log('Calling regsession for user profile:', userprofile, 'with type:', type);

  try {
    const response24 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}session/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: loggedInUserId,
        type: type,
        idcontrato: idcontrato,
      }),
    });

    const responseData24 = await response24.json();
    console.log('Response from session POST:', responseData24);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
};

export default regSession;

//aqui esta el widget del form de Nueva camapaña que incluye enviar mails y guardar en posts separados por coma

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isValid, parse } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
//import dayjs, { Dayjs } from 'dayjs';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const { REACT_APP_API_ENDPOINT } = process.env;


AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });
  
  // Create an S3 object
  const s3 = new AWS.S3();

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds

const CreateContab = ({ _id }) => {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [clients, setClients] = useState([]);
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const loggedinusercontrato = useSelector((state) => state.user.idcontrato);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}clients`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClients(data);
        } else {
          console.error("Failed to fetch clients");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchClients();
  }, [token]);
  const campaignSchema = yup.object().shape({
    rutBranch: yup.string().trim().notRequired(),
    nombreBranchCPS: yup.string().trim().notRequired(),
    product: yup.string().trim().notRequired(),
    monto: yup.string().trim().notRequired(),
    comisionPer: yup.string().trim().notRequired(),
    contraparte: yup.string().trim().notRequired(),
    estado: yup.string().trim().notRequired(),
    concepto: yup.string().trim().notRequired(),
    entrega: yup.string().trim().notRequired(),
    pagoA: yup.string().trim().notRequired(),
    comments: yup.string().trim().notRequired(),
    ocurrencia: yup.string().trim().notRequired(),
    numeroDoc: yup.string().trim().notRequired(),
    inicioplan: yup
      .mixed()
      
  });

  const initialValuesEditCampaign = {
    rutBranch: "",
    nombreBranchCPS: "",
    product: "",
    idClient: "",
    monto: "",
    comisionPer: "",
    contraparte: "",
    estado: "Pendiente",
    concepto: "",
    entrega: null,
    entregado: null,
    pagoA: "",
    comments: "",
    ocurrencia: "",
    inicioplan: null,
    pagoRec: "",
    numeroDoc: "",
    idcontrato: loggedinusercontrato,

  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      const startDate = values.inicioplan;
      values.idcontrato = loggedinusercontrato;

      const generateMonthlyDocuments = (startDate, values) => {
        const documents = [];
        for (let i = 0; i < 72; i++) {
          const date = new Date(startDate);
          date.setMonth(date.getMonth() + i);
          let estado = "";
          if (values.concepto === "Venta" && values.tipo === "Factura") {
              estado = "Envio Pendiente";
          } else { estado = "Pendiente"; }

          documents.push({
              ...values,
              entrega: date,
              estado: estado
          });
        }
        return documents;
      };

      const generateQuarterlyDocuments = (startDate, values) => {
        const documents = [];
        for (let i = 0; i < 72; i += 3) {
          const date = new Date(startDate);
          date.setMonth(date.getMonth() + i);
          if (values.concepto === "Venta" && values.tipo === "Factura") {
            estado = "Envio Pendiente";
        }
        documents.push({
            ...values,
            entrega: date,
            estado: estado
        });
        }
        return documents;
      };
      
      const generateAnnualDocuments = (startDate, values) => {
        const documents = [];
        for (let i = 0; i < 72; i += 12) {
          const date = new Date(startDate);
          date.setFullYear(date.getFullYear() + i);
          let estado = "";
          if (values.concepto === "Venta" && values.tipo === "Factura") {
            estado = "Envio Pendiente";
          }
          documents.push({
              ...values,
              entrega: date,
              estado: estado
          });
        }
        return documents;
      };
      

      if (values.ocurrencia === "Mensual") {
        const documents = generateMonthlyDocuments(startDate, values);
console.log("generando item mensual 72 meses");
        const responses = await Promise.all(
          documents.map((document) =>
            fetch(`${REACT_APP_API_ENDPOINT}contab`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(document),
            })
          )
        );

        const failedResponses = responses.filter(response => !response.ok);
        if (failedResponses.length === 0) {
          navigate("/contab");
          setIsFormSubmitted(true);
        } else {
          throw new Error("Some requests failed");
        }
      } else 
      if (values.ocurrencia === "Anual") {
        const documents = generateAnnualDocuments(startDate, values);

        const responses = await Promise.all(
          documents.map((document) =>
            fetch(`${REACT_APP_API_ENDPOINT}contab`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(document),
            })
          )
        );

        const failedResponses = responses.filter(response => !response.ok);
        if (failedResponses.length === 0) {
          navigate("/contab");
          setIsFormSubmitted(true);
        } else {
          throw new Error("Some requests failed");
        }
      } else 

      if (values.ocurrencia === "Trimestral") {
        const documents = generateQuarterlyDocuments(startDate, values);

        const responses = await Promise.all(
          documents.map((document) =>
            fetch(`${REACT_APP_API_ENDPOINT}contab`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(document),
            })
          )
        );

        const failedResponses = responses.filter(response => !response.ok);
        if (failedResponses.length === 0) {
          navigate("/contab");
          setIsFormSubmitted(true);
        } else {
          throw new Error("Some requests failed");
        }
      }
      else
      {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}contab`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          navigate("/contab");
          setIsFormSubmitted(true);
        } else {
          throw new Error("Request failed");
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  if (isFormSubmitted) {
    return null;
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesEditCampaign}
      validationSchema={campaignSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            style={{ backgroundColor: "white", borderRadius: "20px" }}
          >
            <TextField
              label="Rut Branch"
              onBlur={handleBlur}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^0-9K]/gi, '');
                handleChange({ target: { name: 'rutBranch', value: newValue } });
              }}
              value={values.rutBranch}
              name="rutBranch"
              error={Boolean(touched.rutBranch) && Boolean(errors.rutBranch)}
              helperText={touched.rutBranch && errors.rutBranch}
              multiline
              fullWidth
              sx={{
                borderRadius: "20px",
                gridColumn: "span 4",
                '& input': {
                  background: 'white',
                },
              }}
            />

            <TextField
              label="Nombre Branch"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.nombreBranch}
              name="nombreBranch"
              error={Boolean(touched.nombreBranch) && Boolean(errors.nombreBranchCPS)}
              helperText={touched.nombreBranch && errors.nombreBranch}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

            <TextField
              label="Servicio"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.product}
              name="product"
              error={Boolean(touched.product) && Boolean(errors.product)}
              helperText={touched.product && errors.product}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />



<Select

  label={<FormattedMessage id="Cliente Raiz (HQ)"/>}
  value={values.idClient}
  name="idClient"
  onBlur={handleBlur}
  onChange={(e) => {
    handleChange(e);
    const selectedClient = clients.find((client) => client.nombreempresa === e.target.value);
    if (selectedClient) {
      setIdcontrato(selectedClient._id); // Use the client's _id
    }
  }}
  error={Boolean(touched.nombreempresa) && Boolean(errors.nombreempresa)}
>
  <MenuItem value="">
    <em>{<FormattedMessage id="Seleccionar Cliente Raíz"/>}</em>
  </MenuItem>
  {clients.map((client) => (
    <MenuItem key={client._id} value={client._id}>
      {client.nombreempresa}
    </MenuItem>
  ))}
</Select>




            <TextField
              label="Monto"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.monto}
              name="monto"
              error={Boolean(touched.monto) && Boolean(errors.monto)}
              helperText={touched.monto && errors.monto}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

            <TextField
              label="Comision Venta (%)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.comisionPer}
              name="comisionPer"
              error={Boolean(touched.comisionPer) && Boolean(errors.comisionPer)}
              helperText={touched.comisionPer && errors.comisionPer}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<FormControl
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
              error={Boolean(touched.contraparte) && Boolean(errors.contraparte)}
            >
              <InputLabel>Contraparte</InputLabel>
              <Select
                label="contraparte"
                name="contraparte"
                value={values.contraparte}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="Proveedor">Proveedor</MenuItem>
                <MenuItem value="Cliente">Cliente</MenuItem>
                <MenuItem value="Asociado">Asociado</MenuItem>
                <MenuItem value="Obligacion">Obligación</MenuItem>
                <MenuItem value="Empleado">Empleado</MenuItem>
                <MenuItem value="Rendicion">Rendicion</MenuItem>

                <MenuItem value="Otro">Otro</MenuItem>
              </Select>
              {touched.contraparte && <Typography color="error">{errors.contraparte}</Typography>}
            </FormControl>

          
            <FormControl
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
              error={Boolean(touched.concepto) && Boolean(errors.concepto)}
            >
              <InputLabel>Concepto</InputLabel>
              <Select
                label="Concepto"
                name="concepto"
                value={values.concepto}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="Venta">Venta</MenuItem>
                <MenuItem value="Gasto">Gasto</MenuItem>
                <MenuItem value="Gasto GG">Gasto GG</MenuItem>
                <MenuItem value="Gasto CH">Gasto CH</MenuItem>

                <MenuItem value="Otro">Otro</MenuItem>
              </Select>
              {touched.concepto && <Typography color="error">{errors.concepto}</Typography>}
            </FormControl>
            
            <div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de entrega</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>

   
<FormControl
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
              error={Boolean(touched.tipo) && Boolean(errors.tipo)}
            >
              <InputLabel>Tipo</InputLabel>
              <Select
                label="Tipo"
                name="tipo"
                value={values.tipo}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="OC">OC</MenuItem>
                <MenuItem value="Factura">Factura</MenuItem>
                <MenuItem value="Pago">Pago</MenuItem>
                <MenuItem value="Otro">Otro</MenuItem>

              </Select>
              {touched.tipo && <Typography color="error">{errors.tipo}</Typography>}
            </FormControl>


            

            <TextField
              label="Pago A"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pagoA}
              name="pagoA"
              error={Boolean(touched.pagoA) && Boolean(errors.pagoA)}
              helperText={touched.pagoA && errors.pagoA}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

            <TextField
              label="numeroDoc"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.numeroDoc}
              name="numeroDoc"
              error={Boolean(touched.numeroDoc) && Boolean(errors.numeroDoc)}
              helperText={touched.numeroDoc && errors.numeroDoc}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
  <TextField
              label="comments"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.comments}
              name="comments"
              error={Boolean(touched.comments) && Boolean(errors.comments)}
              helperText={touched.comments && errors.comments}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de Inicio</div>
      
      <DatePicker
        selected={values.inicioplan}
        onChange={(date) => handleChange({ target: { name: 'inicioplan', value: date } })}
        onBlur={handleBlur}
        name="inicioplan"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>

            <FormControl
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              error={Boolean(touched.ocurrencia) && Boolean(errors.ocurrencia)}
            >
              <InputLabel>Ocurrencia</InputLabel>
              <Select
                label="Ocurrencia"
                name="ocurrencia"
                value={values.ocurrencia}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="Una vez">Una vez</MenuItem>
                <MenuItem value="Mensual">Mensual</MenuItem>
                <MenuItem value="Trimestral">Trimestral</MenuItem>
                <MenuItem value="Anual">Anual</MenuItem>
                <MenuItem value="Indefinido">Indefinido</MenuItem>
              </Select>
              {touched.ocurrencia && <Typography color="error">{errors.ocurrencia}</Typography>}
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="primary" variant="contained">
              Guardar Item
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateContab;

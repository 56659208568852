import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokens } from "../../src/theme";
import { FormattedMessage } from 'react-intl';

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateUser = ({ _id }) => {
  const theme = useTheme();
  const token = useSelector((state) => state.token);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const loggedIncontract = useSelector((state) => state.user.idcontrato);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const campaignSchema = yup.object().shape({

  });

  const initialValuesEditCampaign = {
   
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
   

      // Split the maildes field by comma and trim whitespace
      const emails = values.maildes.split(',').map(email => email.trim());

      // Iterate over the emails array and send a PATCH request for each email
      for (const email of emails) {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${email}`, {
            method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email, userprofile: "externo" }),
        });

        if (!response.ok) {
          console.error(`PATCH request failed with status ${response.status}`);
          return;
        }
      }

      setIsFormSubmitted(true);
      setIsSuccessMessageVisible(true);

      const timeoutId = setTimeout(() => {
        setIsSuccessMessageVisible(false);
      }, 3000);

      return () => clearTimeout(timeoutId);

    } catch (error) {
      console.log(error);
    }
  };

  if (isFormSubmitted) {
    return null;
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesEditCampaign}
      validationSchema={campaignSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
<Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
            <TextField
              label={<FormattedMessage id="mails de personas a desenrolar(mover perfil a externo)"/>}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maildes}
              name="maildes"
              multiline
              rows={8}
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 4" }}
            />
          </Box>

          <Box>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              Desenrolar
            </Button>
          </Box>
          
          {isSuccessMessageVisible && (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: colors.greenAccent[400],
                color: 'white',
                padding: '1rem',
                borderRadius: '15px',
                textAlign: 'center',
                zIndex: 9999,
              }}
            >
              ¡Cambios Guardados Exitosamente!
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
};

export default CreateUser;

// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateComplaint from "../../components/CreateComplaint";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";
import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";  
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import Chip, { ChipProps } from "@mui/material/Chip";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Pause from "@mui/icons-material/Build";
import { green, red, blue } from '@mui/material/colors';



const Complaints = () => {
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedinUserEmail = useSelector((state) => state.user.email);

  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [complaintTotalDays, setcompaintTotalDays] = useState();
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  
  let filteredData = data;


  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
  
  filteredData = data
    .filter((row) => {
      // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
      const createdAt = parseInt(row.createdAt);
      return createdAt >= dateRange[0] && createdAt <= dateRange[1];
    })
    .filter((row) => {
      // Apply the search filter based on all fields in the row, including user object properties
      const searchInputLower = searchInput.toLowerCase();
      for (const key in row) {
        if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
          if (row[key].toLowerCase().includes(searchInputLower)) {
            return true; // Return true if any field contains the search input
          }
        }
      }
      // Also check user object properties
      for (const key in row.user) {
        if (row.user.hasOwnProperty(key) && typeof row.user[key] === 'string') {
          if (row.user[key].toLowerCase().includes(searchInputLower)) {
            return true; // Return true if any user object property contains the search input
          }
        }
      }
      return false; // Return false if no field contains the search input
    });

const filterDataByDate = (range) => {
  const startDateIndex = Math.floor((range[0] / 100) * data.length);
  const endDateIndex = Math.floor((range[1] / 100) * data.length);
  const filteredByDate = data.slice(startDateIndex, endDateIndex + 1);
  setData(filteredByDate);
};

  // Calculate the count of each area type from filtered data
  const areaCounts = filteredData.reduce((counts, item) => {
    const area = item.tipoconflicto || 'Unknown';
    counts[area] = (counts[area] || 0) + 1;
    return counts;
  }, {});


// Calculate the count of each conflict type from filtered data
const rutCounts = filteredData.reduce((counts, item) => {
  const rut = item.location || 'Unknown';
  counts[rut] = (counts[rut] || 0) + 1;
  return counts;
}, {});

  
// Parse the "2023-08-20" format
const parseDatevalDate = (dateString) => {
  return new Date(dateString);
};

// Parse the "15/08/23" format
const parseCreatedAtDate = (dateString) => {
  const parts = dateString.split("/");
  const year = parseInt(parts[2], 10) + 2000;
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);
  return new Date(year, month, day);
};

function getChipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "URGENTE" || params.value === "En Investigacion" ) {
    return {
      icon: <WarningIcon style={{ fill: red[500] }} />,
      label: params.value,
      style: {
        borderColor: red[500]
      }
    };
  }
  
  if (params.value === "En Investigacionx") {
    return {
      icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };}
    if (params.value === "Por Evaluar") {
      return {
        icon: <Pause style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: blue[500]
        }
      };
} 
    else {
      return {
        icon: <Pause style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: blue[500]
        }
      };
  }
}
const columns = [
  { field: "id", headerName: <FormattedMessage id="ID" />, flex: 0.5, valueGetter: (params) => params.value ,headerClassName: "bold-header",
},
  { field: "location", headerName: <FormattedMessage id="Organización Denunciada"/>, flex: 1 ,headerClassName: "bold-header",
},

  { field: "tipoconflicto", headerName: <FormattedMessage id="Tipo"/>, flex: 1 ,headerClassName: "bold-header",
},


{
  field: "flag",
  headerName: "Prioridad",
  flex: 1,
  headerClassName: "bold-header",
  renderCell: (params) => {
    // Check if 'prioridad' is 'Urgente'
    if (params.row.flag === "URGENTE") {
      return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
    }
    // If not 'Urgente', you can return null or an alternative component
    return null; // or return <Chip variant="outlined" size="small" label="Normal" />;
  }
},

  { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1 ,headerClassName: "bold-header",
},

  {
    field: "delay",headerClassName: "bold-header",
    headerName: <FormattedMessage id="Demora (Días)"/>,
      flex: 1,
      valueGetter: (params) => {
        const createdAtDate = params.row.createdAt;
        const datevalDate = params.row.dateval;
    
        if (datevalDate) {
          // Parse datevalDate and calculate absolute delay using "es-ES" locale
          const parsedCreatedAt = new Date(params.row.createdAt);
          const parsedDateval = new Date(params.row.dateval);
          const diffInMilliseconds = Math.abs(parsedDateval - parsedCreatedAt);
          const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
          return diffInDays;
        } else if (createdAtDate) {
          // Parse createdAtDate and calculate absolute delay using "es-ES" locale
          const currentDate = new Date();
          const parsedCreatedAt = new Date(params.row.createdAt);
          const diffInMilliseconds = Math.abs(currentDate - parsedCreatedAt);
          const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
          return diffInDays;
        } else {
          return ""; // No date information available
        }
      },
    },
    
    
  ];
  

  useEffect(() => {
    fetchData();
  }, [isFormSubmitted, dateRange, searchInput]);

 

  
  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}complaints/user/${loggedInUserId}`;

      // Check the user profile and update the endpoint accordingly
      if (userProfile === "superadmin" || userProfile === "comitetica" || userProfile === "adminetica" || userProfile === "admin" || userProfile === "aprobador" || userProfile === "coadmin"  ) {
        endpoint = `${REACT_APP_API_ENDPOINT}complaints/contract/${idcontrato}`;
      }
      if (userProfile === "investigador" ) {
        endpoint = `${REACT_APP_API_ENDPOINT}complaints/email/${loggedinUserEmail}`;
      }
      const response = await fetch(endpoint, {

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
      
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-EN", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
      

      setIsLoading(false);
      setData(dataWithFormattedDate);

    

    // Count occurrences of each tipoconflicto
const tipoconflictoCounts = {};
dataWithFormattedDate.forEach((row) => {
  if (tipoconflictoCounts[row.tipoconflicto]) {
    tipoconflictoCounts[row.tipoconflicto]++;
  } else {
    tipoconflictoCounts[row.tipoconflicto] = 1;
  }
});

// Create an array of objects with label, Categoria, and count properties
const dataxprog1 = Object.keys(tipoconflictoCounts).map((tipoconflicto) => ({
  label: tipoconflicto,
  Categoria: tipoconflicto,
  count: tipoconflictoCounts[tipoconflicto],
}));

     // Count occurrences of each location
const locationCounts = {};
dataWithFormattedDate.forEach((row) => {
  if (locationCounts[row.location]) {
    locationCounts[row.location]++;
  } else {
    locationCounts[row.location] = 1;
  }
});

// Create an array of objects with label and count properties
const datax1 = Object.keys(locationCounts).map((location) => ({
  label: location,
  count: locationCounts[location],
}));

     

    
      
      setDataxprog1(dataxprog1); // Set the datax state with the generated data
      setDatax1(datax1); // Set the datax state with the generated data





    } catch (error) {
      console.error("Error fetching user data:", error);
    }      setIsLoading(false); // Set isLoading to false in case of an error

  };
  

  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';
  
    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row

      navigate(`/complaintev/${rowId}`); // Replace "/campaigndetail" with the actual URL of your CampaignDetailPage

    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };




  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };

  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Denuncias');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'Denuncias.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };
  
  const datax = Object.entries(rutCounts).map(([label, count]) => ({ label, count }));
const dataxconflicto = Object.entries(areaCounts).map(([label, count]) => ({ label, count }));





// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue); // Ensure this is updating correctly
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "60%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };


  return (
    <Box m="20px">


<Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>

<Box display="flex" justifyContent="space-between" alignItems="center">

<Header title={<FormattedMessage id="Denuncias" />}  />

      </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>
</Grid>



  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
    {/* HEADER */}


    <Box>
  {userProfile === 'superadmin1' && (
    <Button
      onClick={handleCreateCampaignClick}
      sx={{ borderRadius: "20px",
      backgroundColor: 'red', // You can use a color code like '#FF0000' if needed
      color: colors.grey[900],
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 20px',
      }}
    >
      <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
      <FormattedMessage id="Nueva Denuncia"/>
    </Button>
  )}
       {showCreateCampaign && <CreateComplaint _id={_id} />}
       </Box>
<p></p>

<Grid container spacing={0}> {/* Set spacing to 0 to remove gaps */}
  {/* StatBox */}
  <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
    <StatBoxnom
      title={filteredData.length.toString()}
      subtitle={<FormattedMessage id="Denuncias"/>}
      icon={
        <PersonAddIcon
          sx={{
            borderRadius: "20px",
            color: colors.greenAccent[600],
            fontSize: "35px",
          }}
        />
      }
      sx={{
        height: "100%", // Set the StatBox height to 100%
      }}
    />
  </Grid>

</Grid>


          <Box
            m="40px 0 0 0"
            height="40vh"
            sx={{ borderRadius: "20px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
            >
            {/* Display loading spinner if isLoading is true */}
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={data.map((row) => ({
                ...row,
                id: row._id, 
              }))}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowClick={handleRowClick}
              rowsPerPageOptions={[10, 20]}
              headerClassName="bold-header"

              />
              )}


            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              message="Copiado al portapapeles"
            />
          </Box>
<p></p>
          <Box>
                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                    {<FormattedMessage id="Descargar Reporte"/>}
              </Button>
              </Box>
   {/*barras*/}

   <Box
      sx={{ borderRadius: "20px", marginTop: '20px' }}
  display="grid"
  gridTemplateColumns="repeat(12, 1fr)"
  gridAutoRows="140px"
  gap="20px"
>
  {userProfile === "comitetica" || userProfile === "adminetica" || userProfile === "superadmin" ? (
    /* Render the first widget only for "perfilteico" and "superadmin" */
    <Box
      gridColumn={{ xs: 'span 12', md: 'span 6' }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <Typography
        variant="h5"
        fontWeight="600"
        sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
      >
          {<FormattedMessage id="Empresas Denunciadas"/>}

        
      </Typography>
      <Box height="250px" mt="-20px">
        <BarChart data={datax1} />
      </Box>
    </Box>
  ) : null}

  {userProfile === "comitetica" || userProfile === "adminetica" || userProfile === "superadmin" ? (
    /* Render the second widget only for "perfilteico" and "superadmin" */
    <Box
      gridColumn={{ xs: 'span 12', md: 'span 6' }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <Typography
        variant="h5"
        fontWeight="600"
        sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
      >
                  {<FormattedMessage id="Tipo de Denuncia"/>}

      </Typography>
      <Box height="250px" mt="-20px">
        <BarChartor data={dataxprog1} />
      </Box>
    </Box>
  ) : null}
</Box>


 
    </Box>
  );
};

export default Complaints;
import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import {
  Modal,
  Box,
  Button,
  TextField,
  InputLabel, // ImporonDrop={(acceptedFiles)t the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';
import RegSession from "./RegSession";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles


const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const { REACT_APP_API_ENDPOINT } = process.env;



AWS.config.update({
accessKeyId: REACT_APP_ACCESS_KEY_ID,
secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
region: "sa-east-1", // Set your AWS region
});

// Create an S3 object
const s3 = new AWS.S3();

const currentDate = new Date();

const CreateTtvalue = ({ _id, data, values, touched, errors, handleBlur, handleChange }) => {
  const location = useLocation();
  const userId = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUserContract = useSelector((state) => state.user.idcontrato);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const loggedInUsermail = useSelector((state) => state.user.email);
  const userprofile = useSelector((state) => state.user.userprofile); 
  const { palette } = useTheme();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const greenText = { color: 'green' }; // Define the CSS style for green text
  const [uploadedFiles1, setUploadedFiles1] = useState([]);
  const [uploadedFiles2, setUploadedFiles2] = useState([]);
  const [uploadedFiles3, setUploadedFiles3] = useState([]);
  const [uploadedFiles4, setUploadedFiles4] = useState([]);
  const loggedclient = useSelector((state) => state.client);
  const [wagon1users, setWagon1Users] = useState([]);


    const campaignSchema = yup.object().shape({
      // Define your validation schema here
      tipott: yup.string().required("Este campo es obligatorio"),
      entrega: yup.string().required("Este campo es obligatorio"),

      razonauspicio: yup.string().notRequired(""),
      tipoauspicio: yup.string().notRequired(""),
      propositott: yup.string().notRequired(""),
      descripciontt: yup.string().required("Este campo es obligatorio"),
      valortt: yup.string().notRequired(""),
      oppreciojusto: yup.string().notRequired(""),
      costoevento: yup.string().notRequired(""),

      comunidad: yup.string().notRequired(""),
      ubicacion: yup.string().notRequired(""),
      nombreproyecto: yup.string().notRequired(""),
      nombreorganizacion: yup.string().notRequired(""),
      oplucro: yup.string().notRequired(""),
      replegal: yup.string().notRequired(""),
      domiciliolegal: yup.string().notRequired(""),
      mailreplegal: yup.string().notRequired(""),
      link: yup.string().notRequired(""),

      nombrecontacto: yup.string().notRequired(""),
      telefonocontacto: yup.string().notRequired(""),
      emailcontacto: yup.string().notRequired(""),
      opdd: yup.string().notRequired(""),
      ddrespaldo: yup.string().notRequired(""),

      oppagos: yup.string().notRequired(""),
      exenciontributaria:yup.string().notRequired(""),
      cartasolicitud: yup.string().notRequired(""),
      valoranticipado: yup.string().notRequired(""),
      acuerdo: yup.string().notRequired(""),

      duraciontt: yup.string().notRequired(""),



      orgpermiso: yup.string().notRequired(""),
      nombreb1: yup.string().notRequired(""),
      cargob1: yup.string().notRequired(""),
      organizacionb1: yup.string().notRequired(""),
      funcionariob1: yup.string().notRequired(""),
      nombreb2: yup.string().notRequired(""),
      cargob2: yup.string().notRequired(""),
      organizacionb2: yup.string().notRequired(""),
      funcionariob2: yup.string().notRequired(""),
      nombreb3: yup.string().notRequired(""),
      cargob3: yup.string().notRequired(""), 
      organizacionb3: yup.string().notRequired(""),
      funcionariob3: yup.string().notRequired(""),


      modoviaje: yup.string().notRequired(""), 
      clasesviaje: yup.string().notRequired(""),
      descripcionviaje: yup.string().notRequired(""),
      opalojamiento: yup.string().notRequired(""),
      costoporpersona: yup.string().notRequired(""),
      costoporpersonatransporte: yup.string().notRequired(""),
      costoporpersonainvitacion: yup.string().notRequired(""),
      costoporpersonacomida: yup.string().notRequired(""),
      costoporpersonaotros: yup.string().notRequired(""),
      otrosgastos: yup.string().notRequired(""),

      nombrerepresentantes1: yup.string().notRequired(""),
      cargosrepresentantes1: yup.string().notRequired(""),
      nombrerepresentantes2: yup.string().notRequired(""),
      cargosrepresentantes2: yup.string().notRequired(""),
      nombrerepresentantes3: yup.string().notRequired(""),
      cargosrepresentantes3: yup.string().notRequired(""),
      nombrerepresentantes4: yup.string().notRequired(""),
      cargosrepresentantes4: yup.string().notRequired(""),
      numeroasistentes: yup.string().notRequired(""),
      

      oporganizadoreventoestatal: yup.string().notRequired(""),
      txtorganizadoreventoestatal: yup.string().notRequired(""),

      opotrosauspicios: yup.string().notRequired(""),
      txtotrosauspicios: yup.string().notRequired(""),
      opmencionauspicio: yup.string().notRequired(""),
      opmkt: yup.string().notRequired(""),
      txtmkt: yup.string().notRequired(""),
      formadeaporte: yup.string().notRequired(""),
      opfuncionario: yup.string().notRequired(""),



    });
 
    const initialValuesEditCampaign = {
      userId: loggedInUserId,
      tipott: data?.tipott || "",
      estado: "Por Evaluar",
      idcontrato:  idcontrato, // Update the initial value
      tipoauspicio: data?.tipoauspicio || "",
      propositott: data?.propositott || "",
      descripciontt: data?.descripciontt || "",
      valortt: data?.valortt || "",
      oppreciojusto: data?.oppreciojusto || "",
      costoevento: data?.costoevento || "",
      comunidad: data?.comunidad || "",
      ubicacion: data?.ubicacion || "",
      nombreproyecto: data?.nombreproyecto || "",
      nombreorganizacion: data?.nombreorganizacion || "",
      oplucro: data?.oplucro || "",
      replegal: data?.replegal || "",
      domiciliolegal: data?.domiciliolegal || "",
      mailreplegal: data?.mailreplegal || "",
      link: data?.link || "",
      nombrecontacto: data?.nombrecontacto || "",
      telefonocontacto: data?.telefonocontacto || "",
      emailcontacto: data?.emailcontacto || "",
      opdd: data?.opdd || "",
      ddrespaldo: data?.ddrespaldo || "",
      oppagos: data?.oppagos || "",
      exenciontributaria: data?.exenciontributaria || "",
      cartasolicitud: data?.cartasolicitud || "",
      valoranticipado: data?.valoranticipado || "",
      acuerdo: data?.acuerdo || "",
      duraciontt: data?.duraciontt || "",
      entrega:  "",
      orgpermiso: data?.orgpermiso || "",
      nombreb1: data?.nombreb1 || "",
      cargob1: data?.cargob1 || "",
      organizacionb1: data?.organizacionb1 || "",
      funcionariob1: data?.funcionariob1 || "",
      nombreb2: data?.nombreb2 || "",
      cargob2: data?.cargob2 || "",
      organizacionb2: data?.organizacionb2 || "",
      funcionariob2: data?.funcionariob2 || "",
      nombreb3: data?.nombreb3 || "",
      cargob3: data?.cargob3 || "",
      organizacionb3: data?.organizacionb3 || "",
      funcionariob3: data?.funcionariob3 || "",
      modoviaje: data?.modoviaje || "",
      clasesviaje: data?.clasesviaje || "",
      descripcionviaje: data?.descripcionviaje || "",
      opalojamiento: data?.opalojamiento || "",
      costoporpersona: data?.costoporpersona || "",
      costoporpersonatransporte: data?.costoporpersonatransporte || "",
      costoporpersonainvitacion: data?.costoporpersonainvitacion || "",
      costoporpersonacomida: data?.costoporpersonacomida || "",
      costoporpersonaotros: data?.costoporpersonaotros || "",
      otrosgastos: data?.otrosgastos || "",
      nombrerepresentantes1: data?.nombrerepresentantes1 || "",
      cargosrepresentantes1: data?.cargosrepresentantes1 || "",
      nombrerepresentantes2: data?.nombrerepresentantes2 || "",
      cargosrepresentantes2: data?.cargosrepresentantes2 || "",
      nombrerepresentantes3: data?.nombrerepresentantes3 || "",
      cargosrepresentantes3: data?.cargosrepresentantes3 || "",
      nombrerepresentantes4: data?.nombrerepresentantes4 || "",
      cargosrepresentantes4: data?.cargosrepresentantes4 || "",
      numeroasistentes: data?.numeroasistentes || "",
      estadoCierre: "Pendiente",
      oporganizadoreventoestatal: data?.oporganizadoreventoestatal || "",
      txtorganizadoreventoestatal: data?.txtorganizadoreventoestatal || "",
      opotrosauspicios: data?.opotrosauspicios || "",
      txtotrosauspicios: data?.txtotrosauspicios || "",
      opmencionauspicio: data?.opmencionauspicio || "",
      opmkt: data?.opmkt || "",
      txtmkt: data?.txtmkt || "",
      formadeaporte: data?.formadeaporte || "",
      opfuncionario: data?.opfuncionario || "",
      opauth: data?.opauth || "",
      commentcierre: data?.commentcierre || "",
      pictures1: data?.pictures1 || [],
      opauths: data?.opauths || [],
      authtxts: data?.authtxts || [],
      datevals: data?.datevals || [],
      estados: data?.estados || [],
  };
  
  useEffect(() => {
    // Fetch or define the wagon1users dynamically (as per your previous logic)
    const fetchAprobadores = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });
        
        const aprobadores = await response.json();
        
        const aprobadorDetails = aprobadores
          .filter(user => user.wagon === "1")
          .map(user => user.email);
        console.log("aprobadorDetails", aprobadorDetails);
        setWagon1Users(aprobadorDetails);
      } catch (error) {
        console.error('Error fetching aprobadores:', error);
      }
    };

    fetchAprobadores();
  }, []);

   const handleFormSubmit = async (values, onSubmitProps) => {
    const formData = new FormData();

    try {
      for (const key in values) {
        formData.append(key, values[key]);
        
      }
      const uploadToS3 = async (files) => {
        const bucketName = 'repoeticpro';
        const uniqueSuffix = Date.now() + "-";
        const s3Urls = [];
      
        AWS.config.update({
          accessKeyId: REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
          region: "sa-east-1", // Set your AWS region
        });
        const s3 = new AWS.S3();
      
        for (const file of files) {
          const key = uniqueSuffix + file.name;
      
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
          };
      
          try {
            await s3.upload(params).promise();
            console.log(`File uploaded to S3: ${key}`);
            s3Urls.push(key);
          } catch (error) {
            console.error(`Error uploading file to S3: ${error.message}`);
            throw error;
          }
        }
      
        return s3Urls;
      };
      
      if (uploadedFiles1.length > 0) {
        const s3Urls1 = await uploadToS3(uploadedFiles1);
        formData.append('pictures1', s3Urls1);
      }
      
      if (uploadedFiles2.length > 0) {
        const s3Urls2 = await uploadToS3(uploadedFiles2);
        formData.append('pictures2', s3Urls2);
      }
      
      if (uploadedFiles3.length > 0) {
        const s3Urls3 = await uploadToS3(uploadedFiles3);
        formData.append('pictures3', s3Urls3);
      }
      

      const responseaprobadores = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }); 
      
      const aprobadores = await responseaprobadores.json();
      
      const aprobadorEmails = aprobadores
        .filter(user => user.wagon) // Ensure rangoAprobacion is not empty
        .sort((a, b) => a.wagon - b.wagon) // Sort by rangoAprobacion from minor to mayor
        .map(user => user.email)
        .filter(email => email); // Exclude empty emails
      
      console.log("aprobadores", aprobadores);
      console.log("aprobadorEmails", aprobadorEmails);
      
      // Counter to track how many emails are appended
      let appendedEmailCount = 0;
      
      // Append each email to formData
      aprobadorEmails.forEach(email => {
        if (
          email !== loggedInUsermail && // Skip if the email is the same as loggedInUsermail
          !(loggedInUsermail === "gbravo@bago.cl" && email === "bago.jefeproducto@eticpro.com") // Skip if the specific condition is met
        ) {
          formData.append('aprobadores[]', email);
          appendedEmailCount++; // Increment the counter only when an email is appended
        }
      });
      
      // Now compare appendedEmailCount to aprobadorEmails.length
      if (appendedEmailCount !== aprobadorEmails.length) {
        formData.set('autoEvaluacion', "Si");  // Set "Si" if the lengths are not equal
        console.log("appendedEmailCount", appendedEmailCount);
        console.log("aprobadorEmails.length", aprobadorEmails.length);
      } else {
        formData.set('autoEvaluacion', "No");  // Set "No" if they are equal
      }
      
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      
      });
      
      if (userResponse.ok) {
        console.log("User data stored successfully");
      
      
        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "TV" });


  const emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        position: relative;
      }
      .logo-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .logo-box {
        text-align: center;
        margin-bottom: 20px;
      }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
  </head>
  <body>
    <div class="frame">
      <div class="logo-container">
        <img src=${LogoImage} alt="Logo" style="width: 100%;" />
      </div>
      <img src=${loggedclient.logo} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
      
      <p>Estimado(a) Usuario,</p>
      <p>Le informamos que  hemos recibido la transferencia de valor.  Le invitamos ingresar a Eticpro en nuestro link de abajo, módulo Transferencias de Valor para revisar el estado y acción.</p>
      <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
      
      <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
      <p>Gracias por su atención y cooperación.</p>
      <p>Equipo Encargado de Prevención de Delitos</p>
      <p>Eticpro.</p>
      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    </div>
  </body>
  </html>
`;
  const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      emails: aprobadorEmails, // Use the aprobadorEmails array
      emailTemplate,
      mailserver: process.env.MAILSERVER, 
      subject : "Transferencia de Valor Recibida",

    }),
  });


  if (emailResponse.ok) {
    // Email sent successfully
    console.log("Email sent successfully");
  } else {
    // Error sending email
    console.log("Failed to send email");
  }


  setIsFormSubmitted(true);
  setShowSuccessPopup(true);
  onSubmitProps.resetForm();
  navigate("/successPage", {
    state: { message: "Transferencia de Valor creada con éxito" }
});             

} else {
  console.error(
    "Error storing user data:",
    userResponse.status,
    userResponse.statusText,
  );
}


   


    } catch (err) {
  console.log("Error creating Ttvalue:", err);

  if (err.response) {
    console.log("Response status:", err.response.status);

    // Use the .text() method to get the response body as text
    err.response.text().then(responseText => {
      console.log("Response data:", responseText);
    });
  }
}
  };

  
  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 

          <Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Transferencia de Valor</InputLabel>

              <Select
                label="Transferencia de Valor"
                value={values.tipott}
                name="tipott"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.tipott) && Boolean(errors.tipott)}
              >
                <MenuItem value="">
                  <em>Seleccionar Transferencia de Valor</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="donacion">Donación</MenuItem>
                <MenuItem value="comida">Comida</MenuItem>
                <MenuItem value="regalo">Regalo</MenuItem>
                <MenuItem value="viaje">Viaje</MenuItem>
                <MenuItem value="auspicio">Auspicio</MenuItem>
                <MenuItem value="evento">Evento</MenuItem>
                <MenuItem value="inversion-social">Inversión Social</MenuItem>
                <MenuItem value="otros">Otro</MenuItem>
               
              </Select>

              {touched.tipott && errors.tipott && (
                <Typography variant="body2" color="error">
                  {errors.tipott}
                </Typography>
              )}
            </FormControl>
          </Box>


          <p></p>
     

      
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
            

              <TextField
            label="Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos."
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.descripciontt}
            name="descripciontt"
            error={Boolean(touched.descripciontt) && Boolean(errors.descripciontt)}
            helperText={touched.descripciontt && errors.descripciontt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

{values.tipott === "inversion-social" && (
  <>

      <Typography>
      Beneficiario Potencial 
          </Typography>
         
<TextField
            label="Nombre de la Comunidad/Organización apoyada"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comunidad}
            name="comunidad"
            error={Boolean(touched.comunidad) && Boolean(errors.comunidad)}
            helperText={touched.comunidad && errors.comunidad}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

     
<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de entrega de la inversión social:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>


<TextField
            label="ubicacion de la Comunidad/Organización apoyada"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.ubicacion}
            name="ubicacion"
            error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
            helperText={touched.ubicacion && errors.ubicacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Nombre de la Inversión Social (Proyecto de desarrollo comunitario):"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreproyecto}
            name="nombreproyecto"
            error={Boolean(touched.nombreproyecto) && Boolean(errors.nombreproyecto)}
            helperText={touched.nombreproyecto && errors.nombreproyecto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Nombre de la organización destinataria (si fuese aplicable)"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreorganizacion}
            name="nombreorganizacion"
            error={Boolean(touched.nombreorganizacion) && Boolean(errors.nombreorganizacion)}
            helperText={touched.nombreorganizacion && errors.nombreorganizacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Nombre del Representante Legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.replegal}
            name="replegal"
            error={Boolean(touched.replegal) && Boolean(errors.replegal)}
            helperText={touched.replegal && errors.replegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Domicilio legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.domiciliolegal}
            name="domiciliolegal"
            error={Boolean(touched.domiciliolegal) && Boolean(errors.domiciliolegal)}
            helperText={touched.domiciliolegal && errors.domiciliolegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Email Representante Legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mailreplegal}
            name="mailreplegal"
            error={Boolean(touched.mailreplegal) && Boolean(errors.mailreplegal)}
            helperText={touched.mailreplegal && errors.mailreplegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<TextField
            label="Sitio Web"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.link}
            name="link"
            error={Boolean(touched.link) && Boolean(errors.link)}
            helperText={touched.link && errors.link}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Nombre Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombrecontacto}
            name="nombrecontacto"
            error={Boolean(touched.nombrecontacto) && Boolean(errors.nombrecontacto)}
            helperText={touched.nombrecontacto && errors.nombrecontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Mail Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailcontacto}
            name="emailcontacto"
            error={Boolean(touched.emailcontacto) && Boolean(errors.emailcontacto)}
            helperText={touched.emailcontacto && errors.emailcontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Telefono Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.telefonocontacto}
            name="telefonocontacto"
            error={Boolean(touched.telefonocontacto) && Boolean(errors.telefonocontacto)}
            helperText={touched.telefonocontacto && errors.telefonocontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
 




      
        <TextField
            label="Valor anticipado de la contribución de La Organización al desarrollo comunitario (en dinero y en especie)"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valoranticipado}
            name="valoranticipado"
            error={Boolean(touched.valoranticipado) && Boolean(errors.valoranticipado)}
            helperText={touched.valoranticipado && errors.valoranticipado}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
          



<TextField
            label="Señale cual es la inversión y su valor. Incluya Presupuesto o cotización."
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.valortt)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Duración de la transferencia de valor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.duraciontt}
            name="duraciontt"
            error={Boolean(touched.duraciontt) && Boolean(errors.duraciontt)}
            helperText={touched.duraciontt && errors.duraciontt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>

¿Hay pagos a terceros como consultores u organizaciones para ejecutar la donación?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y adjunte mas abajo la diligencia debida de este consultor u organización.</Typography>
<RadioGroup
  aria-label="Opción"
  name="oppagos"
  value={values.oppagos}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

{values.oppagos === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures4", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures4 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Respaldo de Diligencia Debida Respectiva de Pago a tercero (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures4.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          

   
   </div>
      )}

    
 

 </FormControl>  


 <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>

¿Realizó el procedimiento de  Diligencias Debida del Beneficiario o de quíen entrega la donación?</Typography>
<RadioGroup
  aria-label="Opción"
  name="opdd"
  value={values.opdd}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.opdd === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Respaldo de Diligencia Debida Respectiva  (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures1.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          

   
   </div>
      )}
</FormControl>  


        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Borrador de Convenio o Acuerdo Formal (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>
   
      
   <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Carta de  solicitud (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>






    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
      </Box>

{values.tipott === "donacion" && (
  <>

      <Typography>

          </Typography>
<TextField
            label="Nombre de la Donación:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreproyecto}
            name="nombreproyecto"
            error={Boolean(touched.nombreproyecto) && Boolean(errors.nombreproyecto)}
            helperText={touched.nombreproyecto && errors.nombreproyecto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Nombre de la organización destinataria (con/sin fin de lucro):"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreorganizacion}
            name="nombreorganizacion"
            error={Boolean(touched.nombreorganizacion) && Boolean(errors.nombreorganizacion)}
            helperText={touched.nombreorganizacion && errors.nombreorganizacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<div>
  <p></p>
      <div style={{ marginBottom: '10px' }}>Fecha de entrega de la donación:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>
<Typography variant="h6" gutterBottom>

Es la organización con fin de lucro?:</Typography>
<RadioGroup
  aria-label="Opción"
  name="oplucro"
  value={values.oplucro}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

<TextField
            label="Nombre del Representante Legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.replegal}
            name="replegal"
            error={Boolean(touched.replegal) && Boolean(errors.replegal)}
            helperText={touched.replegal && errors.replegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Domicilio Legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.domiciliolegal}
            name="domiciliolegal"
            error={Boolean(touched.domiciliolegal) && Boolean(errors.domiciliolegal)}
            helperText={touched.domiciliolegal && errors.domiciliolegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Email del Representante Legal"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mailreplegal}
            name="mailreplegal"
            error={Boolean(touched.mailreplegal) && Boolean(errors.mailreplegal)}
            helperText={touched.mailreplegal && errors.mailreplegal}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<TextField
            label="Sitio Web"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.link}
            name="link"
            error={Boolean(touched.link) && Boolean(errors.link)}
            helperText={touched.link && errors.link}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Nombre Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombrecontacto}
            name="nombrecontacto"
            error={Boolean(touched.nombrecontacto) && Boolean(errors.nombrecontacto)}
            helperText={touched.nombrecontacto && errors.nombrecontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Mail Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailcontacto}
            name="emailcontacto"
            error={Boolean(touched.emailcontacto) && Boolean(errors.emailcontacto)}
            helperText={touched.emailcontacto && errors.emailcontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Telefono Contacto principal en la organización destinataria"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.telefonocontacto}
            name="telefonocontacto"
            error={Boolean(touched.telefonocontacto) && Boolean(errors.telefonocontacto)}
            helperText={touched.telefonocontacto && errors.telefonocontacto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
 

 



 <TextField
            label="ubicacion de la Comunidad/Organización apoyada"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comunidad}
            name="comunidad"
            error={Boolean(touched.comunidad) && Boolean(errors.comunidad)}
            helperText={touched.comunidad && errors.comunidad}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
          

      
        <TextField
            label="Valor anticipado de la contribución de La Organización al desarrollo comunitario (en dinero y en especie)"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valoranticipado}
            name="valoranticipado"
            error={Boolean(touched.valoranticipado) && Boolean(errors.valoranticipado)}
            helperText={touched.valoranticipado && errors.valoranticipado}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
          


  
<p></p>
<TextField
            label="Duración de la transferencia de valor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.duraciontt}
            name="duraciontt"
            error={Boolean(touched.duraciontt) && Boolean(errors.duraciontt)}
            helperText={touched.duraciontt && errors.duraciontt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




   <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>

¿Hay pagos a terceros como consultores u organizaciones para ejecutar la donación?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y adjunte mas abajo la diligencia debida de este consultor u organización.</Typography>
<RadioGroup
  aria-label="Opción"
  name="oppagos"
  value={values.oppagos}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

{values.oppagos === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures4", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures4 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Respaldo de Diligencia Debida Respectiva de Pago a tercero (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures4.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          

   
   </div>
      )}

    
 

 </FormControl>  


 <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>

¿Realizó el procedimiento de  Diligencias Debida del Beneficiario o de quíen entrega la donación?</Typography>
<RadioGroup
  aria-label="Opción"
  name="opdd"
  value={values.opdd}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.opdd === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Respaldo de Diligencia Debida Respectiva  (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures1.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>
          

   
   </div>
      )}
</FormControl>  
 <p></p>
<div>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Borrador de Convenio o Acuerdo Formal (5Mb Máximo)  </p>
                    ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>
   </div>
      
   <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Carta de  solicitud (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>




    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
    

    {values.tipott === "regalo" && (
  <>

      <Typography>
Regalo
          </Typography>
          <p></p>

        
<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de entrega del regalo:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>

<TextField
            label="Nombre Beneficiario 1 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb1}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 1 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargob1}
            name="cargob1"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 1 del Regalo "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizacionb1}
            name="organizacionb1"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario del Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob1"
  value={values.funcionariob1}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>



<TextField
            label="Nombre Beneficiario 2 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb2}
            name="nombreb2"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 2 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            name="cargob2"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 2 del Regalo "
            onBlur={handleBlur}
            onChange={handleChange}
            name="organizacionb2"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario del Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob2"
  value={values.funcionariob2}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>



<TextField
            label="Razón del (los) regalo(s) / ocasión"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propositott}
            name="propositott"
            error={Boolean(touched.propositott) && Boolean(errors.propositott)}
            helperText={touched.propositott && errors.propositott}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona del (los) regalo(s)"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.valortt)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="La institución a la que pertenece el Beneficiario, ¿Lo permite?"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.orgpermiso}
            name="orgpermiso"
            error={Boolean(touched.orgpermiso) && Boolean(errors.orgpermiso)}
            helperText={touched.orgpermiso && errors.orgpermiso}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<p></p>
<Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte infromación adicional si corresponde </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>



    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}
    

    {values.tipott === "comida" && (
  <>

<p></p>

    
     
<div>
  <p></p>
      <div style={{ marginBottom: '10px' }}>Fecha de acontecimiento de la comida:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>
<TextField
            label="Nombre Beneficiario 1 de la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb1}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 1 de la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargob1}
            name="cargob1"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 1 de la comida "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizacionb1}
            name="organizacionb1"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario del Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob1"
  value={values.funcionariob1}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>



<TextField
            label="Nombre Representante que da la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombrerepresentantes1}
            name="nombrerepresentantes1"
            error={Boolean(touched.nombrerepresentantes1) && Boolean(errors.nombrerepresentantes1)}
            helperText={touched.nombrerepresentantes1 && errors.nombrerepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Representante que da la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargosrepresentantes1}
            name="cargosrepresentantes1"
            error={Boolean(touched.cargosrepresentantes1) && Boolean(errors.cargosrepresentantes1)}
            helperText={touched.cargosrepresentantes1 && errors.cargosrepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
<p></p>

<TextField
            label="Número total de asistentes propuestos"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.numeroasistentes}
            name="numeroasistentes"
            error={Boolean(touched.numeroasistentes) && Boolean(errors.numeroasistentes)}
            helperText={touched.numeroasistentes && errors.numeroasistentes}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Costo por persona de comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.valortt)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<p></p>





    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}

  

{values.tipott === "evento" && (
  <>

      <Typography>
Invitacion a Evento
          </Typography>
          <p></p>

    
<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de inicio del Evento:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>


<TextField
            label="Nombre Beneficiario 1 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb1}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 1 del Regalo"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargob1}
            name="cargob1"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 1 del Regalo "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizacionb1}
            name="organizacionb1"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario del Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob1"
  value={values.funcionariob1}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>



<TextField
            label="Nombre Representante que da la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            name="nombrerepresentantes1"
            error={Boolean(touched.nombrerepresentantes1) && Boolean(errors.nombrerepresentantes1)}
            helperText={touched.nombrerepresentantes1 && errors.nombrerepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Representante que da la comida"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargosrepresentantes1}
            name="cargosrepresentantes1"
            error={Boolean(touched.cargosrepresentantes1) && Boolean(errors.cargosrepresentantes1)}
            helperText={touched.cargosrepresentantes1 && errors.cargosrepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
<p></p>

<TextField
            label="Número total de asistentes propuestos:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.numeroasistentes}
            name="numeroasistentes"
            error={Boolean(touched.numeroasistentes) && Boolean(errors.numeroasistentes)}
            helperText={touched.numeroasistentes && errors.numeroasistentes}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona de comida en UF"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.v)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Modo de Viaje</InputLabel>

              <Select
                label="Modos de viaje para el evento"
                value={values.modoviaje}
                name="modoviaje"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.modoviaje) && Boolean(errors.modoviaje)}
              >
                <MenuItem value="">
                  <em>Modos de viaje para el evento</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="aereo">Aéreo</MenuItem>
                <MenuItem value="busauto">Auto/Bus</MenuItem>
                <MenuItem value="tren">Tren</MenuItem>
                <MenuItem value="combinación">Combinación</MenuItem>
              
              </Select>

              {touched.modoviaje && errors.modoviaje && (
                <Typography variant="body2" color="error">
                  {errors.modoviaje}
                </Typography>
              )}
            </FormControl>
          </Box>


          <p></p>


<TextField
            label="Si es combinación, favor describir: "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.descripcionviaje}
            name="descripcionviaje"
            error={Boolean(touched.descripcionviaje) && Boolean(errors.descripcionviaje)}
            helperText={touched.descripcionviaje && errors.descripcionviaje}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Clases de viaje</InputLabel>

              <Select
                label="Clase de Viaje"
                value={values.clasesviaje}
                name="clasesviaje"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.clasesviaje) && Boolean(errors.clasesviaje)}
              >
                <MenuItem value="">
                  <em>Seleccionar Clases de Viaje</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="turista">Turista</MenuItem>
                <MenuItem value="ejecutiva">Ejecutiva</MenuItem>
                <MenuItem value="primera">Primera</MenuItem>
                <MenuItem value="charter">Charter</MenuItem>
              
              </Select>

              {touched.modoviaje && errors.modoviaje && (
                <Typography variant="body2" color="error">
                  {errors.modoviaje}
                </Typography>
              )}
            </FormControl>
          </Box>


    



    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}



{values.tipott === "viaje" && (
  <>

      <Typography>
Invitacion a Viaje
          </Typography>
          <p></p>

     
<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de inicio del viaje:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>
          <TextField
            label="Propósito del viaje propuesto"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propositott}
            name="propositott"
            error={Boolean(touched.propositott) && Boolean(errors.propositott)}
            helperText={touched.propositott && errors.propositott}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Destino"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.ubicacion}
            name="ubicacion"
            error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
            helperText={touched.ubicacion && errors.ubicacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />





                
             

<TextField
            label="Nombre Beneficiario 1 del viaje"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb1}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 1 del viaje"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargob1}
            name="cargob1"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 1 del viaje "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizacionb1}
            name="organizacionb1"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario del Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob1"
  value={values.funcionariob1}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>



<TextField
            label="Nombre Representante que va en el viaje"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombrerepresentantes1}
            name="nombrerepresentantes1"
            error={Boolean(touched.nombrerepresentantes1) && Boolean(errors.nombrerepresentantes1)}
            helperText={touched.nombrerepresentantes1 && errors.nombrerepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Representante que va en el viaje"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargosrepresentantes1}
            name="cargosrepresentantes1"
            error={Boolean(touched.cargosrepresentantes1) && Boolean(errors.cargosrepresentantes1)}
            helperText={touched.cargosrepresentantes1 && errors.cargosrepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
<p></p>

<TextField
            label="Número total de viajeros propuestos:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.numeroasistentes}
            name="numeroasistentes"
            error={Boolean(touched.numeroasistentes) && Boolean(errors.numeroasistentes)}
            helperText={touched.numeroasistentes && errors.numeroasistentes}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Modo de Viaje</InputLabel>

              <Select
                label="Modos de viaje para el evento"
                value={values.modoviaje}
                name="modoviaje"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.modoviaje) && Boolean(errors.modoviaje)}
              >
                <MenuItem value="">
                  <em>Modos de viaje para el evento</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="aereo">Aéreo</MenuItem>
                <MenuItem value="busauto">Auto/Bus</MenuItem>
                <MenuItem value="tren">Tren</MenuItem>
                <MenuItem value="combinación">Combinación</MenuItem>
              
              </Select>

              {touched.modoviaje && errors.modoviaje && (
                <Typography variant="body2" color="error">
                  {errors.modoviaje}
                </Typography>
              )}
            </FormControl>
          </Box>


          <p></p>


<TextField
            label="Si es combinación, favor describir: "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.descripcionviaje}
            name="descripcionviaje"
            error={Boolean(touched.descripcionviaje) && Boolean(errors.descripcionviaje)}
            helperText={touched.descripcionviaje && errors.descripcionviaje}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Clases de viaje</InputLabel>

              <Select
                label="Transferencia de Valor"
                value={values.clasesviaje}
                name="clasesviaje"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.clasesviaje) && Boolean(errors.clasesviaje)}
              >
                <MenuItem value="">
                  <em>Seleccionar Clases de Viaje</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="turista">Turista</MenuItem>
                <MenuItem value="ejecutiva">Ejecutiva</MenuItem>
                <MenuItem value="primera">Primera</MenuItem>
                <MenuItem value="charter">Charter</MenuItem>
              
              </Select>

              {touched.modoviaje && errors.modoviaje && (
                <Typography variant="body2" color="error">
                  {errors.modoviaje}
                </Typography>
              )}
            </FormControl>
          </Box>


          <p></p>

          <Typography variant="h6" gutterBottom>

Se proveedrá alojamiento?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="opalojamiento"
  value={values.opalojamiento}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.opalojamiento === 'Si' && ( // Only render if the answer is 'Si'
<div>
        
      
              <TextField
                label="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.conflictxt}
                name="conflictxt"
                error={Boolean(touched.conflictxt) && Boolean(errors.conflictxt)}
                helperText={touched.conflictxt && errors.conflictxt} // Corrected line
                multiline
                fullWidth
                minRows={12}
                maxRows={20}
                maxLength={1000}
                sx={{ borderRadius: "20px", background: 'white',  gridColumn: "span 5"
              }}
              
              />
   </div>
      )}




<TextField
            label="Presupuesto general del viaje propuesto:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.valortt)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />






<TextField
            label="Costo por persona del viaje propuesto:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.costoporpersonatransporte}
            name="costoporpersonatransporte"
            error={Boolean(touched.costoporpersonatransporte) && Boolean(errors.costoporpersonatransporte)}
            helperText={touched.costoporpersonatransporte && errors.costoporpersonatransporte}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona de invitación a evento"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.costoporpersonainvitacion}
            name="costoporpersonainvitacion"
            error={Boolean(touched.costoporpersonainvitacion) && Boolean(errors.costoporpersonainvitacion)}
            helperText={touched.costoporpersonainvitacion && errors.costoporpersonainvitacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona del costo de comida más alto esperado:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.costoporpersonacomida}
            name="costoporpersonacomida"
            error={Boolean(touched.costoporpersonacomida) && Boolean(errors.costoporpersonacomida)}
            helperText={touched.costoporpersonacomida && errors.costoporpersonacomida}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona de cualquier otro gasto previsible de este viaje:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.costoporpersonaotros}
            name="costoporpersonaotros"
            error={Boolean(touched.costoporpersonaotros) && Boolean(errors.costoporpersonaotros)}
            helperText={touched.costoporpersonaotros && errors.costoporpersonaotros}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Describa otros gastos previsibles:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.otrosgastos}
            name="otrosgastos"
            error={Boolean(touched.otrosgastos) && Boolean(errors.otrosgastos)}
            helperText={touched.otrosgastos && errors.otrosgastos}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles2(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures2", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures2 || values.pictures2.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Carta de solicitud (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures2.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

           




    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}





{values.tipott === "auspicio" && (
  <>

      <h3><Typography>Auspicio</Typography></h3>
     
      
<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de inicio del Auspicio:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
    <p></p>

          <TextField
            label="Tipo de Auspicio"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.tipoauspicio}
            name="tipoauspicio"
            error={Boolean(touched.tipoauspicio) && Boolean(errors.tipoauspicio)}
            helperText={touched.tipoauspicio && errors.tipoauspicio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<TextField
            label="Duracion"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.duraciontt}
            name="duraciontt"
            error={Boolean(touched.duraciontt) && Boolean(errors.duraciontt)}
            helperText={touched.duraciontt && errors.duraciontt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Lugar"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.ubicacion}
            name="ubicacion"
            error={Boolean(touched.ubicacion) && Boolean(errors.ubicacion)}
            helperText={touched.ubicacion && errors.ubicacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Propósito del evento"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propositott}
            name="propositott"
            error={Boolean(touched.propositott) && Boolean(errors.propositott)}
            helperText={touched.propositott && errors.propositott}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Razón de negocio para el auspicio:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.razonauspicio}
            name="razonauspicio"
            error={Boolean(touched.razonauspicio) && Boolean(errors.razonauspicio)}
            helperText={touched.razonauspicio && errors.razonauspicio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Nombre de la entidad que organiza el evento"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreorganizacion}
            name="nombreorganizacion"
            error={Boolean(touched.nombreorganizacion) && Boolean(errors.nombreorganizacion)}
            helperText={touched.nombreorganizacion && errors.nombreorganizacion}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Costo total del evento"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.costoevento}
            name="costoevento"
            error={Boolean(touched.costoevento) && Boolean(errors.costoevento)}
            helperText={touched.costoevento && errors.costoevento}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo del auspicio propuesto:"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.valortt)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Nombre Representante(s) que va en el viaje"
            onBlur={handleBlur}
            onChange={handleChange}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


                
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles3(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures3", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures3 || values.pictures3.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Borrador de Convenio o Acuerdo Formal (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures3.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

              
                <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles1(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>Adjunte Respaldo de Diligencia Debida del Beneficiario (5Mb Máximo) </p>
                    ) : (
                      <div>
                        {values.pictures1.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>



<p></p>


          <Typography variant="h6" gutterBottom>

          (i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal? </Typography>
<RadioGroup
  aria-label="Opción"
  name="oporganizadoreventoestatal"
  value={values.oporganizadoreventoestatal}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.oporganizadoreventoestatal === 'Si' && ( // Only render if the answer is 'Si'
<div>
        
      
              <TextField
                label="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtorganizadoreventoestatal}
                name="txtorganizadoreventoestatal"
                error={Boolean(touched.txtorganizadoreventoestatal) && Boolean(errors.txtorganizadoreventoestatal)}
                helperText={touched.txtorganizadoreventoestatal && errors.txtorganizadoreventoestatal} // Corrected line
                multiline
                fullWidth
                minRows={12}
                maxRows={20}
                maxLength={1000}
                sx={{ borderRadius: "20px", background: 'white', gridColumn: "span 5"
              }}
              
              />
   </div>
      )}

<TextField
            label="(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombrerepresentantes1}
            name="nombrerepresentantes1"
            error={Boolean(touched.nombrerepresentantes1) && Boolean(errors.nombrerepresentantes1)}
            helperText={touched.nombrerepresentantes1 && errors.nombrerepresentantes1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<Typography variant="h6" gutterBottom>

(iii) ¿Habrá otras empresas auspiciando?</Typography>
<RadioGroup
aria-label="Opción"
name="opotrosauspicios"
value={values.opotrosauspicios}
onChange={handleChange}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
<FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.opotrosauspicios === 'Si' && ( // Only render if the answer is 'Si'
<div>


    <TextField
      label="Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche:"
      onBlur={handleBlur}
      onChange={handleChange}
      variant="filled"
      value={values.txtotrosauspicios}
      name="txtotrosauspicios"
      error={Boolean(touched.txtotrosauspicios) && Boolean(errors.txtotrosauspicios)}
      helperText={touched.txtotrosauspicios && errors.txtotrosauspicios} // Corrected line
      multiline
      fullWidth
      minRows={12}
      maxRows={20}
      maxLength={1000}
      sx={{ borderRadius: "20px", gridColumn: "span 5"
    }}
    
    />
</div>
)}

<Typography variant="h6" gutterBottom>

(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?</Typography>
<RadioGroup
aria-label="Opción"
name="opmencionauspicio"
value={values.opmencionauspicio}
onChange={handleChange}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
<FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

<Typography variant="h6" gutterBottom>

(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?</Typography>
<RadioGroup
aria-label="Opción"
name="opmkt"
value={values.opmkt}
onChange={handleChange}
sx={{ borderRadius: "20px",
"& .MuiSvgIcon-root": {
color: palette.primary.light, // Selected radio color (red)
},
"& .Mui-checked": {
color: "#FFFFFF", // Not selected radio color (white)
},
}}
>
<FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
<FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>


{values.opmkt === 'Si' && ( // Only render if the answer is 'Si'
<div>


    <TextField
      label="Si la respuesta es Sí, porfavor explique:"
      onBlur={handleBlur}
      onChange={handleChange}
      variant="filled"
      value={values.txtmkt}
      name="txtmkt"
      error={Boolean(touched.txtmkt) && Boolean(errors.txtmkt)}
      helperText={touched.txtmkt && errors.txtmkt} // Corrected line
      multiline
      fullWidth
      minRows={12}
      maxRows={20}
      maxLength={1000}
      sx={{ borderRadius: "20px", gridColumn: "span 5"
    }}
    
    />
</div>
)}


<TextField
            label="(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.  "
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.formadeaporte}
            name="formadeaporte"
            error={Boolean(touched.formadeaporte) && Boolean(errors.formadeaporte)}
            helperText={touched.formadeaporte && errors.formadeaporte}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.oppreciojusto}
            name="oppreciojusto"
            error={Boolean(touched.oppreciojusto) && Boolean(errors.oppreciojusto)}
            helperText={touched.oppreciojusto && errors.oppreciojusto}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


    {/* New file drag and drop option */}
   {/* Drag and drop file upload */}
 
   </>
)}



{values.tipott === "otros" && (
  <>

      <h4><Typography>Cualquier otra cosa de valor</Typography></h4>

<TextField
            label="Nombre Beneficiario 1"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.nombreb1}
            name="nombreb1"
            error={Boolean(touched.nombreb1) && Boolean(errors.nombreb1)}
            helperText={touched.nombreb1 && errors.nombreb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label="Cargo Beneficiario 1"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargob1}
            name="cargob1"
            error={Boolean(touched.cargob1) && Boolean(errors.cargob1)}
            helperText={touched.cargob1 && errors.cargob1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Organización Beneficiario 1"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.organizacionb1}
            name="organizacionb1"
            error={Boolean(touched.organizacionb1) && Boolean(errors.organizacionb1)}
            helperText={touched.organizacionb1 && errors.organizacionb1}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<Typography variant="h6" gutterBottom>

Funcionario de Gobierno?    </Typography>
<RadioGroup
  aria-label="Opción"
  name="funcionariob1"
  value={values.funcionariob1}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>




<TextField
            label="Razon / proposito"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propositott}
            name="propositott"
            error={Boolean(touched.propositott) && Boolean(errors.propositott)}
            helperText={touched.propositott && errors.propositott}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label="Costo por persona en UF"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.valortt}
            name="valortt"
            error={Boolean(touched.valortt) && Boolean(errors.v)}
            helperText={touched.valortt && errors.valortt}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<p></p>

<div>
  <p></p>
  <div style={{ marginBottom: '10px' }}>Fecha de entrega:</div>
      <DatePicker
        selected={values.entrega}
        onChange={(date) => handleChange({ target: { name: 'entrega', value: date } })}
        onBlur={handleBlur}
        name="entrega"
        className="form-control" // Add your custom classes here
        placeholderText="Seleccione Fecha"
        style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
      />
    </div>
 
   </>
)}




        {/* BUTTONS */}
        <Box
  display="flex"
  justifyContent="center"  // Center horizontally
  alignItems="center"      // Center vertically (optional, if needed)
>
        <Button
  fullWidth
  type="submit"
  color="secondary"
  variant="contained"
  sx={{
    borderRadius: '20px',
    m: '2rem 0',
    p: '1rem',
    width: '50%', // Set the width to 50%
    backgroundColor: theme.palette.primary.red,
    color: theme.palette.background.light,
    '&:hover': { color: theme.palette.primary.light },
  }}
>
{<FormattedMessage id="Enviar"/>}
</Button>

            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                  {<FormattedMessage id="Envio exitoso"/>}
                  </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateTtvalue;
import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, useTheme, useMediaQuery } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import { FormattedMessage } from 'react-intl';
import EmailRecoveryModal from './EmailRecoveryModal';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
  ticketid: yup.string().required("Campo obligatorio"),
});

const FollowComplaint = ({ open, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [emailRecoveryOpen, setEmailRecoveryOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues = {
    ticketid: "",
  };

  const handleFormSubmit = (values) => {
    const { ticketid } = values;
    const url = `https://compliax.onrender.com/formepd/campaign/${ticketid}`;
    window.location.href = url; // Use window.location.href to navigate
  };
  

  const handleEmailRecoveryOpen = () => {
    setEmailRecoveryOpen(true);
  };

  const handleEmailRecoveryClose = () => {
    setEmailRecoveryOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isMobile ? '90%' : '30%',
            maxWidth: 'none',
            overflowY: 'auto'
          }
        }}
      >
        <DialogTitle>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
            Declarar Conflicto de Interés
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
                <Box>
                  <Typography variant="h5" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Ingresa ticket que recibiste en tu Email.
                  </Typography>
                  <TextField
                    label={<FormattedMessage id="Ticket" />}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ticketid}
                    name="ticketid"
                    error={Boolean(touched.ticketid) && Boolean(errors.ticketid)}
                    helperText={touched.ticketid && errors.ticketid}
                    fullWidth
                    sx={{
                      borderRadius: "20px",
                      '& input': {
                        background: 'white',
                      },
                    }}
                  />
                  <Typography variant="body2" align="right" sx={{ mt: 2 }}>
                    <a href="#" onClick={handleEmailRecoveryOpen} style={{ color: 'blue', textDecoration: 'underline' }}>
                      Recuperar ticket con email
                    </a>
                  </Typography>
                  <Box display="flex" justifyContent="center">
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{
                        color: "white",
                        m: "2rem 0",
                        p: "1rem",
                        backgroundColor: "rgb(15, 15, 98)",
                      }}
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="Siguiente" />
                      <ArrowForward sx={{ ml: 2 }} />
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <EmailRecoveryModal open={emailRecoveryOpen} onClose={handleEmailRecoveryClose} />
    </>
  );
};

export default FollowComplaint;

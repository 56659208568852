import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import { FormattedMessage } from 'react-intl';
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Grid from "@mui/material/Grid";
import EmailRecoveryModal from './EmailRecoveryModal'; // Import the new modal
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const validationSchema = yup.object().shape({
  ticketid: yup.string().required("Campo obligatorio"),
});

//SEGUIMIENTO DE LAS DENUNCIAS ANONIMAS

const FollowComplaint = ({ open, onClose }) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const theme = useTheme();
  const [currentStep, setCurrentStep] = useState(1);
  const [ticketData, setticketData] = useState([]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [uploadedFiles, setUploadedFiles] = useState([]); // Nuevo estado para los archivos subidos
  const [uploadedFileName, setUploadedFileName] = useState('');
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define isMobile using useMediaQuery
  const [emailRecoveryOpen, setEmailRecoveryOpen] = useState(false);

  const initialValuesEditCampaign = {
    picture: "",
    description: "",
    picturePath: "",
    ticketid: "",

};



AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });
  
  // Create an S3 object
  const s3 = new AWS.S3();

  useEffect(() => {}, [REACT_APP_API_ENDPOINT]);

  const handleFileUpload = async (event) => { // Añade values como parámetro
    const files = event.target.files;
    const uploadedFilesArray = Array.from(files);
    setUploadedFiles(uploadedFilesArray);
    setUploadedFileName(uploadedFilesArray[0].name); // Assuming only one file is uploaded
 
    try {
      const formData = new FormData();
  // Append newly uploaded files to S3
  if (uploadedFiles.length > 0) {
    uploadedFiles.forEach((file) => {
      formData.append('pictures', file); // Append the actual file
    });
        // Define the uploadFileToS3 function
        const uploadFileToS3 = async (file) => {
          const uniqueSuffix = Date.now() + "-";
          const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
          formData.append('picturePath', key); // Append the actual file

        AWS.config.update({
        
        accessKeyId: REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
        region: "sa-east-1", // Set your AWS region
        });
          const s3 = new AWS.S3();

          // Configure the S3 bucket and key where you want to upload the file
          const bucketName = 'repoeticpro';
    

          // Configure S3 upload parameters
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: file, // The actual file object to upload
          };


// Use the AWS S3 client to upload the file to S3
try {


  await s3.upload(params).promise();
  console.log(`File uploaded to S3: ${key}`);
  return key; // Return the key for reference
} catch (error) {
  console.error(`Error uploading file to S3: ${error.message}`);
  throw error;
}
};


    const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));

}
      // Example of saving user data using fetch
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
      
      const { token } = await tokenResponse.json();

      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}complaints/files/${ticketData._id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      getForm(); // This already updates the form.picturePath with the merged array
      setUploadedFiles([]);
    } catch (err) {
      console.log("Error creating complaint:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
              err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }

 
  };
  


  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      const { token } = await tokenResponse.json();

      const responseclient = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${values.ticketid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (responseclient.ok) {
        const ticketiddata = await responseclient.json();
        setticketData(ticketiddata);
        console.log("setticketData", ticketiddata);
        setShowSuccessDialog(true);
      }

      onSubmitProps.resetForm();
    } catch (err) {
      console.log("Error fetching complaint data:", err);
      if (err.response) {
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
    
  };

  const handleEmailRecoveryOpen = () => {
    setEmailRecoveryOpen(true);
  };
  
  const handleEmailRecoveryClose = () => {
    setEmailRecoveryOpen(false);
  };
  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      const { token } = await tokenResponse.json();
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${ticketData._id}/add-dialogue`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sender: "Denunciante",
          detail: newMessage,
          dateOccurred: new Date().toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      // Refresh the form data to update the dialogues
      getForm();
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const getForm = async () => {
    try {
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      const { token } = await tokenResponse.json();
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${ticketData._id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setticketData(data);
      } else {
        console.error('Failed to fetch updated complaint data');
      }
    } catch (error) {
      console.error('Error fetching updated complaint data:', error);
    }
  };

  return (
    <>
   <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        width: isMobile ? '90%' : '30%', // Set width based on screen size
        maxWidth: 'none',
        overflowY: 'auto' // Ensure scrolling if content overflows
      }
    }}
  >        <DialogTitle>
      <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
      Seguimiento de Denuncia
          </Typography>
        
        </DialogTitle>
        <DialogContent>
   
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesEditCampaign}
            validationSchema={validationSchema}
          >
            {({
             values,
             errors,
             touched,
             handleBlur,
             handleChange,
             handleSubmit,
             setFieldValue,
             resetForm,
                     isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
                <p></p>

                {currentStep === 1 && (
                  <Box>
                         <Typography variant="h5" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>
Ingresa número de ticket
          </Typography>
                    <TextField
                      label={<FormattedMessage id="Número de ticket"/>}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ticketid}
                      name="ticketid"
                      error={Boolean(touched.ticketid) && Boolean(errors.ticketid)}
                      helperText={touched.ticketid && errors.ticketid}
                      fullWidth
                      sx={{
                        borderRadius: "20px",
                        '& input': {
                          background: 'white',
                        },
                      }}
                    />
                    <Typography variant="body2" align="right" sx={{ mt: 2 }}>
  <a href="#" onClick={handleEmailRecoveryOpen} style={{ color: 'blue', textDecoration: 'underline' }}>
    Recuperar ticket con email
  </a>
</Typography>
                    <Box display="flex" justifyContent="center">
                      <Button
                        fullWidth
                        type="submit"
                        color="secondary"
                        variant="contained"
                        sx={{
                          color: "white", // Set font color to white

                          m: "2rem 0",
                          p: "1rem",
                          backgroundColor: "rgb(15, 15, 98)",
                        }}
                      >
                        
                        <FormattedMessage id="Siguiente"/>
                        <ArrowForward sx={{ ml: 2 }} />

                      </Button>
                    </Box>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>


      {/* Success dialog */}
      <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
            Denuncia  # {ticketData._id}
          </Typography>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
  Crear Denuncia
</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="h4"><strong>Organización:</strong> {ticketData.location}</Typography>
            <Typography variant="h4"><strong>Estado:</strong> {ticketData.estado}</Typography>
            <Typography variant="h4"><strong>Tipo de Denuncia:</strong> {ticketData.tipoconflicto}</Typography>
            <Typography variant="h4"><strong>Email Denunciante:</strong> {ticketData.emaildenunciante !== "undefined" ? ticketData.emaildenunciante : "Anónimo"}</Typography>
            <Typography variant="h4"><strong>Archivos:</strong> {ticketData.picturePath}</Typography>

            <Typography variant="h4"><strong>Creado:</strong> {new Date(ticketData.createdAt).toLocaleString()}</Typography>
            <Typography variant="h4"><strong>Ultima revisión:</strong> {new Date(ticketData.updatedAt).toLocaleString()}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h4"><strong>Chat</strong></Typography>
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {ticketData.dialogues && ticketData.dialogues.length > 0 ? (
                ticketData.dialogues.map((dialogue, index) => (
                  <Paper key={index} elevation={3} sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6"><strong>{dialogue.sender}:</strong></Typography>
                    <Typography variant="body1">{dialogue.detail}</Typography>
                    <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>{new Date(dialogue.dateOccurred).toLocaleString()}</Typography>
                  </Paper>
                ))
              ) : (
                <Typography variant="body1">No ha comenzado un dialogo aún.</Typography>
              )}
               {uploadedFileName && (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="body1"><strong>Archivo subido:</strong> {uploadedFileName}</Typography>
    </Paper>
  )}
            </Box>
            
            <Box mt={2} display="flex" justifyContent="space-between">
 

            <Grid container alignItems="center">
  <Grid item>
    <input
      type="file"
      id="fileInput"
      style={{ display: 'none' }}
      onChange={(event) => handleFileUpload(event)} // Pass event to handleFileUpload
    />
    <label htmlFor="fileInput">
      <IconButton color="primary" component="span">
        <AttachFileIcon />
      </IconButton>
    </label>
  </Grid>
  <Grid item>
    <TextField
      fullWidth
      label="Mensaje Nuevo"
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      multiline
      rows={1} // Set to the desired number of rows
      variant="outlined"
      sx={{
        width: '400px', // Fixed width for the TextField
        mr: 2, // Adjusted margin for spacing
      }}
    />
  </Grid>
  <Grid item>
    <IconButton color="primary" onClick={handleSendMessage}>
      <SendIcon />
    </IconButton>
  </Grid>
</Grid>
</Box>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <EmailRecoveryModal open={emailRecoveryOpen} onClose={handleEmailRecoveryClose} />

    </>
  );
};

export default FollowComplaint;

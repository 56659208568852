import { Box, Typography, useTheme, IconButton, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import RealizadoIcon from "@mui/icons-material/TaskAlt";
import Grid from "@mui/material/Grid";
import { Checkbox, FormControlLabel } from "@mui/material";

import PersonAddIcon from "@mui/icons-material/AttachMoney";

import { tokens } from "../../theme";
import CreateContab from "../../components/CreateContab";
import { useDispatch, useSelector } from "react-redux";
import StatBoxnom from "../../components/StatBoxnom";  
import UploadIcon from "@mui/icons-material/Source";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import DropfilesContab from "../../components/DropfilesContab";
import { useParams } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";
import CreateClient from "../../components/CreateClient";

import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';


const Contab = ({ }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const [showCreateClient, setShowCreateClient] = useState(false);
const { _id, picturePath, email } = useSelector((state) => state.user);
const [selectedRowIds, setSelectedRowIds] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 
  const [showAll, setShowAll] = useState(false);
  const [filterRealizados, setFilterRealizados] = useState(false);
  const [showRealizados, setShowRealizados] = useState(false);
  const [showRealizados2, setShowRealizados2] = useState(false);
  const [showPagoPendiente, setShowPagoPendiente] = useState(true); // New state for the checkbox
  const [showTodosPendientes, setShowTodosPendientes] = useState(true); // New state for Todos Pendientes checkbox
  const [showDelayMaximo, setShowDelayMaximo] = useState(true); // State for Delay Máximo de 7 Días checkbox

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalDescriptionsCount, setTotalDescriptionsCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editedRow, setEditedRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]); // New state for selected rows
  const [openDropfileModal, setOpenDropfileModal] = useState(false);
  const [rowIdForDropfile, setRowIdForDropfile] = useState(null);

  const handleRowDoubleClick = (params) => {
    setSelectedRow(params.row);
    setEditedRow({ ...params.row }); // Initialize editedRow with the selected row data

    setOpenModal(true);
  };

   // Function to handle the "Mostrar Todo" button click
   const handleShowAllClick = () => {
    setShowAll(!showAll);
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? '' : format(date, 'dd-MM-yy');
  };

  const handleFieldChange = (event, field) => {
    setEditedRow({ ...editedRow, [field]: event.target.value });
  };

   // Calculate the count of each area type from filtered data
 
   const handleRowSelection = (selectionModel) => {
    const selectedRowsData = data.filter((row) => selectionModel.includes(row.id));
    setSelectedRows(selectedRowsData);
  };

  const handlePagoPendienteChange = (event) => {
    setShowPagoPendiente(event.target.checked);
  };
  const handleDelayMaximoChange = (event) => {
    setShowDelayMaximo(event.target.checked);
  };
  const handleTodosPendientesChange = (event) => {
    setShowTodosPendientes(event.target.checked);
  };

 // Filter the data based on the checkbox state
 let filteredData = data.filter((row) => {
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  const searchInputLower = searchInput.toLowerCase();
  return rowDataAsString.toLowerCase().includes(searchInputLower);
});

if (showRealizados) {
  filteredData = filteredData.filter((row) => row.estado !== "Realizado");

}

if (showTodosPendientes) {
  filteredData = filteredData.filter((row) => row.estado !== "Realizado");
}

if (showDelayMaximo) {
  filteredData = filteredData.filter((row) => row.delay > -8); // Fix the condition to `row.delay > 7`
}

if (showPagoPendiente) {
  filteredData = filteredData.filter((row) => row.estado !== "Pago Pendiente");
  
}



  const handlePatchData = async () => {
    try {
      const formattedRow = {
        ...editedRow,
        entrega: editedRow.entrega ? (editedRow.entrega) : null,
        entregado: editedRow.entregado ? (editedRow.entregado) : null,
        fechapagoRec: editedRow.fechapagoRec ? (editedRow.fechapagoRec) : null,
      };
      const response = await fetch(`${REACT_APP_API_ENDPOINT}contab/${editedRow.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formattedRow),
      });
      if (!response.ok) {
        throw new Error('Failed to update row');
      }
      // Update UI or any necessary state after successful update
      setOpenModal(false); // Close modal after successful update
    } catch (error) {
      console.error('Error updating row:', error);
      // Handle error (e.g., show error message)
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };

  

  
  const formatCurrency = (value) => {
    // Use Number.toLocaleString() to format the number as currency
    return   '$' + Number(value).toLocaleString('sp-SP', { style: 'currency', currency: 'CLP' }) ;
};

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();

      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}contab/contract/${idcontrato}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const formData = await response.json();
        const dataWithFormattedDate = formData.map((row) => {
          let delay = null;
          let pagoRec = row.pagoRec;
          let estado = row.estado;
          let gastoAjuste = row.gastoAjuste;


          if (row.gastoAjuste === "NA") {
            gastoAjuste = "NA";
          }

          if (row.entrega) {
            const entregaDate = new Date(row.entrega); // Parse entrega date string

            delay = Math.ceil((currentDate - entregaDate) / (1000 * 60 * 60 * 24));
           
            if (row.tipo === "Factura" && !row.entregado && delay >= 5) {
              estado = "Envio Atrasado";
              pagoRec = "Atrasado";
            }


            if (delay > 30 && row.estado !== "Realizado") {
              pagoRec = "Atrasado";
            }
          }
  

        
  
          return {
            ...row,
            id: row._id,
            createdAt: row.createdAt ? (row.createdAt) : "",
            entrega: row.entrega ? (row.entrega) : "",
            entregado: row.entregado ? (row.entregado) : "",
            fechapagoRec: row.fechapagoRec ? (row.fechapagoRec) : "",
            delay,
            pagoRec,
            estado,
          };
        });
  
         // Conditionally filter the data based on showRealizados2
         const filteredData = showRealizados2
         ? dataWithFormattedDate.filter((row) => row.delay >= -7) // Apply the filter only if showRealizados2 is true
         : dataWithFormattedDate;
  
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();

  }, [isFormSubmitted, dateRange, REACT_APP_API_ENDPOINT, token, showRealizados2]);
  
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
    }
  };

   // Function to save the changes for a specific row

  

  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;

    try {
        let updatedRow;
        const currentDate = new Date().toISOString(); // Current date in ISO format

        switch (action) {
          case "edit": 
          await handleEditClick(params);
          return;
                case "delete":
                await deleteRow(rowId); // Assuming deleteRow is an async function
                return; // Exit function after deletion
               
                case "dropfile":

      setRowIdForDropfile(rowId); // Assuming you set the row ID
      setOpenDropfileModal(true); // Ensure this is set to true
      
      return;
  
                  case "realizado":

                switch (params.row.estado) {
                    case "Envio Pendiente":
                        updatedRow = { ...params.row, pagoRec: '', entregado: currentDate, estado: 'Pago Pendiente' };
                        console.log("de envio pendiente a Pago Pendiente");
                        break;
                    case "Pendiente":
                        updatedRow = { ...params.row, pagoRec: '', entregado: currentDate, estado: 'Envio Pendiente' };
                        console.log("de pendiente a envio pendiente");
                        break;
                    case "Realizado":
                        updatedRow = { ...params.row, pagoRec: '', fechapagoRec: currentDate, estado: 'Pago Pendiente' };
                        console.log("de realizado a pago pendiente");
                        break;
                    case "Pago Pendiente":
                        updatedRow = { ...params.row, pagoRec: '', fechapagoRec: currentDate, estado: 'Realizado' };
                        console.log("de pago pendiente a Realizado");
                        break;
                    default:
                        updatedRow = { ...params.row, pagoRec: '', entregado: currentDate, estado: 'Realizado' };
                        break;
                }
                break;
            default:
                return; // Exit function if action is not supported
        }
        updatedRow.entrega = (updatedRow.entrega);
        updatedRow.entregado = (updatedRow.entregado);
        updatedRow.fechapagoRec = updatedRow.fechapagoRec;

        // Save the changes to the row
        await saveRowChanges(rowId, updatedRow);

        // Optionally, update the UI or any necessary state after saving

    } catch (error) {
        console.error('Error handling row click:', error);
        // Handle error (e.g., show error message)
    }
};




const saveRowChanges = async (rowId, updatedRow) => {
  try {
    const response5 = await fetch(`${REACT_APP_API_ENDPOINT}contab/${rowId}`, {

      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedRow),
    });
    if (response5.ok) {
      const updatedData = data.map((row) => row.id === updatedRow.id ? updatedRow : row);
      setData(updatedData);
    } else {
      // Handle the error, e.g., show a notification
    }
  } catch (error) {
    console.error("Error saving row changes:", error);
    // Optionally handle the error, e.g., show a notification
  }
};

const handleEditClick = (params) => {
  const { row, field } = params;
  const updatedRow = { ...row, [field]: params.value };

  setEditedRows((prevEditedRows) => ({
    ...prevEditedRows,
    [row.id]: updatedRow,
  }));

  // Save the edited row
  saveRowChanges(row._id, updatedRow);
};
const deleteRow = async (rowId) => {
  try {
    await fetch(`${REACT_APP_API_ENDPOINT}contab/${rowId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const updatedData = data.filter((row) => row.id !== rowId);
    setData(updatedData);
  } catch (error) {
    console.error("Error deleting row:", error);
  }
};

const dropfile = (rowId) => {
  setRowIdForDropfile(rowId); // Set the rowId to state
  setOpenDropfileModal(true); // Open the modal for DropfilesContab
};


const [sortModel, setSortModel] = useState([
  {
    field: "estado",
    sort: "asc",
  },
]);

const handleSearchInputChange = (event) => {
  setSearchInput(event.target.value);
};

const areaCounts = filteredData.reduce((counts, item) => {
  const monto = item.monto || 'Unknown';
  counts[monto] = (counts[monto] || 0) + 1;
  return counts;
}, {});

const datax = Object.entries(areaCounts).map(([label, count]) => ({ label, count }));

const columns = [
  {
    field: "idCliente",
    headerName: "Cliente",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "nombreBranch",
    headerName: "Nombre Branch",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "contraparte",
    headerName: "Contraparte",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "concepto",
    headerName: "Concepto",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "tipo",
    headerName: "Tipo",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "numeroDoc",
    headerName: "Documento",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "entrega",
    headerName: "Due Date",
    flex: 1,
    editable: true,
    valueGetter: (params) => params.value ? formatDate(params.value) : '',
    headerClassName: "bold-header",
  },
  {
    field: "DelayEntrega",
    headerName: "DSO",
    flex: 1,
    editable: true,
    valueGetter: (params) => {
      const { entrega, fechapagoRec } = params.row;
      if (entrega && fechapagoRec) {
        const delayEntrega = Math.ceil((new Date(fechapagoRec) - new Date(entrega)) / (1000 * 60 * 60 * 24));
        return delayEntrega > 0 ? delayEntrega : 0; // Ensure no negative delays
      }
      return '';
    },
    headerClassName: "bold-header",
  },
  {
    field: "entregado",
    headerName: "Delivered",
    editable: true,
    valueGetter: (params) => params.value ? formatDate(params.value) : '',
    flex: 1,
    headerClassName: "bold-header",
  },
  {
    field: "delay",
    headerName: "Delay",
    flex: 1,
    headerClassName: "bold-header",
  },
  {
    field: "fechapagoRec",
    headerName: "Pagado el",
    flex: 1.5,
    editable: true,
    headerClassName: "bold-header",
    valueGetter: (params) => params.value ? formatDate(params.value) : '',
  },
  {
    field: "pagoRec",
    headerName: "Alerta",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
    renderCell: (params) => (
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", color: params.value === "Atrasado" ? "red" : "inherit" }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "rutBranch",
    headerName: "rutBranch",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "product",
    headerName: "product",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "gastoAjuste",
    headerName: "Ajuste/Retiro",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
    renderCell: (params) => (
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", color: params.value === "No" ? "red" : "inherit" }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
  },
  {
    field: "monto",
    headerName: "Monto",
    flex: 1,
    editable: true,
    headerClassName: "bold-header",
    renderCell: (params) => formatCurrency(params.value),
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    flex: 2,
    headerClassName: "bold-header",
    renderCell: (params) => (
      <Box display="flex" justifyContent="space-between" width="100%">
        <IconButton onClick={() => handleRowClick(params, "realizado")}>
          <RealizadoIcon />
        </IconButton>
        <IconButton color="primary" onClick={() => handleRowClick(params, "edit")}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleRowClick(params, "dropfile")}>
          <UploadIcon />
        </IconButton>
        <IconButton onClick={() => handleRowClick(params, "delete")}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
  },
];


const totalMonto = selectedRows.reduce((sum, row) => sum + Number(row.monto), 0);

return (
  <Box m="20px">
    <Header title={<FormattedMessage id="CONTAB" />} subtitle={<FormattedMessage id="Administrar mi compromisos, pagos, facturación, cobranza" />} />

    <Box>
      <Button
        onClick={() => setShowCreateClient(prevState => !prevState)}
        sx={{
          borderRadius: "20px",
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[900],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        <Upload sx={{ borderRadius: "20px", mr: "10px" }} />
        <FormattedMessage id="Nuevo Item" />
      </Button>
      {showCreateClient && <CreateContab />}
    </Box>

   
    <Box mt="10px">
    

 
    </Box>
    <FormControlLabel
        control={
          <Checkbox
          checked={showDelayMaximo}
          onChange={handleDelayMaximoChange}
            color="primary"
          />
        }
        label="Ocultar  Historia (7d)"
      />
    <FormControlLabel
        control={
          <Checkbox
            checked={showTodosPendientes}
            onChange={handleTodosPendientesChange}
            color="primary"
          />
        }
        label="Ocultar  Realizados"
      />
    <FormControlLabel
        control={
          <Checkbox
            checked={showPagoPendiente}
            onChange={handlePagoPendienteChange}
            color="primary"
          />
        }
        label="Ocultar Pago Pendiente"
      />
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
        <StatBoxnom title="Monto" subtitle={formatCurrency(totalMonto)} />
        <Grid item xs={12} sm={6} md={4} sx={{ padding: "10px", backgroundColor: "white" }}>

        <TextField
    label="Buscar"
    variant="outlined"
    value={searchInput}
    onChange={(e) => setSearchInput(e.target.value)}
    style={{ marginRight: "10px" }}
  />
      </Grid>
    </Grid>
    </Grid>

  


    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              montoProp: false,
              idCliente: false,
              numeroDoc: false,
              concepto: false,
              contraparte: false, 
              rutBranch: false, 
              product: false, 
            },
          },
        }}
        checkboxSelection
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: GridToolbar }}
        headerClassName="bold-header"
        rows={filteredData.map((item) => ({
          ...item,
          id: item._id,
        }))}
        columns={columns}
        onRowClick={handleRowClick}
        onRowDoubleClick={handleRowDoubleClick}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onRowSelectionModelChange={handleRowSelection}

      />

    
    </Box>
    <Modal open={openDropfileModal} onClose={() => setOpenDropfileModal(false)}>
  <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', borderRadius: 4, boxShadow: 24, p: 4 }}>
    <DropfilesContab _id={rowIdForDropfile} />
  </Box>
</Modal>
  </Box>
);
};

export default Contab;


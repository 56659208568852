// ComplaintDialog.js
import React, { useState, useEffect } from 'react';
import { Box, Button,
    TextField, //Import the Select component
    MenuItem,  // Import the MenuItem component
    Typography,  useMediaQuery,  List, ListItem, ListItemText, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Select, FormControl, InputLabel } from '@mui/material';

    import { FormattedMessage } from 'react-intl';
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import LogoImage from '../assets/eticprologo8.png';
import RegSession from "./RegSession";
import { useTheme } from '@mui/material/styles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FindIn from '@mui/icons-material/Troubleshoot';
import AddIcon from '@mui/icons-material/AlarmAddRounded';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// CREAR DENUNCIA ANONIMA

const ComplaintDialog = ({ open, onClose }) => {
  const [selectedClient, setSelectedClient] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Define isMobile using useMediaQuery

  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [clients, setClients] = useState([]);
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const { palette } = useTheme();
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [ticketid, setTicket] = useState(""); // Add this state
  const [logoEmp, setLogoEmp] = useState(""); // Add this state

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [logClient, setLoggedclient] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientSelected, setClientSelected] = useState(false);
  const [tipoconflictoSelected, setTipoconflictoSelected] = useState(false);

  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const   REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  const validationSchema = yup.object().shape({
    // Define your validation schema here
   
    tipoconflicto: yup.string().required('Tipo de concepto es requerido'),
       

       
        password: yup.string()
        .min(6, 'La contraseña debe tener al menos 6 caracteres')
        .notRequired('La contraseña es requerida'),
        
        emaildenunciante: yup.string().when('tipoconflicto', {
          is: (value) => ["Abuso o Acoso o Discriminación", "Violencia en el lugar de trabajo"].includes(value),
          then: yup.string().email('Debe ser un email válido').required('El email es obligatorio'),
          otherwise: yup.string().email('Debe ser un email válido').notRequired(),
        }),
        

      

  });

  const initialValuesEditCampaign = {
   
    userId:"",
    location:"",
    relacion:"",
    areacometio: "",
    cargocometio: "",
    queocurrio:"",
    cuandoocurrio:"",
    desdecuando:"",
    dondeocurrio: "",
    comoevidencio:"",
    quiencometio:"",
    habladocon:"",
    masinfo:"",
    
    tipoconflicto:"",
    picturePath:"",
    
    estado: "Por Evaluar",

    opauth:"",
    authtxt:"",
    emaildenunciante: "",
    emailaviso: "",
    idevaluacion: "",
    dateval: "",
    selectedInvestigador: "",
  
    dateinv: "",
    idinvestigacion: "",
    resumeninv: "",
    
    dateveredicto:"",
    veredicto: "",
    detalleveredicto: "",
    idveredicto: "",
    company: "",
    flag: "-",


  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    onClose(); // Close the main dialog as well
  };


  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ }),
        });
    
        const { token } = await tokenResponse.json();

        const response = await fetch(`${REACT_APP_API_ENDPOINT}clients/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data1 = await response.json();
          setClients(data1);
        }

    
        
      } catch (error) {
        console.error("Failed to fetch clients", error);
      }
    };

    fetchClients();
  }, [REACT_APP_API_ENDPOINT]);



// Define a function to send an SMS
const sendSMS = async ({ phonesms, smsTemplate }) => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}sendSms/sendSms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        phonesms: phonesms,
        smsTemplate: smsTemplate,
      }),
    });
    console.log("enviandosms a ",phonesms);

    if (!response.ok) {
      throw new Error(`Failed to send SMS with status ${response.status}`);
    }

    console.log("SMS sent successfully");
  } catch (error) {
    console.error("Error sending SMS:", error);
  }
};

  const handleFormSubmit = async (values, onSubmitProps) => {
    let userData1; // Declare userData1 outside the try block
    let emailTemplate = ""; // Initialize emailTemplate variable
    let subject = ""; // Initialize emailTemplate variable
    setLoading(true);  // Show loading dialog

    if (!clientSelected) {
      alert('Porfavor seleccione una Organización a Denunciar');
      return;}

  setForm(uploadedFiles);
          try {
            
            await validationSchema.validate(values, { abortEarly: false });

            const formData = new FormData();
         // Append existing files as File objects
         if (form.picturePath && form.picturePath.length > 0) {
          form.picturePath.forEach((file) => {
          formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
          });
        }
    
        // Append newly uploaded files to S3
        if (uploadedFiles.length > 0) {
    
              // Define the uploadFileToS3 function
              const uploadFileToS3 = async (file) => {
                AWS.config.update({
                accessKeyId: REACT_APP_ACCESS_KEY_ID,
                secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
                region: "sa-east-1", // Set your AWS region
                });
                const s3 = new AWS.S3();
    
                // Configure the S3 bucket and key where you want to upload the file
                const bucketName = 'repoeticpro';
                const uniqueSuffix = Date.now() + "-";
                const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
           
                // Configure S3 upload parameters
                const params = {
                  Bucket: bucketName,
                  Key: key,
                  Body: file, // The actual file object to upload
                };
    

      // Use the AWS S3 client to upload the file to S3
      try {
        await s3.upload(params).promise();
        console.log(`File uploaded to S3: ${key}`); 
           formData.append("picturePath", key);
           console.log(`picturePath appended to formData: ${formData.picturePath}`);

        return key; // Return the key for reference
      } catch (error) {
        const validationErrors = {};
      error.inner.forEach(err => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
    
     

    };
    const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
  }

      // Append other form data to the FormData
      formData.append("idcontrato", idcontrato);
      formData.append("location", selectedClient);
      formData.append("relacion", values.relacion);
      formData.append("areacometio", values.areacometio);
      formData.append("cargocometio", values.cargocometio);
      formData.append("queocurrio", values.queocurrio);
      formData.append("cuandoocurrio", values.cuandoocurrio);
      formData.append("desdecuando", values.desdecuando);
      formData.append("dondeocurrio", values.dondeocurrio);
      formData.append("comoevidencio", values.comoevidencio);
      formData.append("quiencometio", values.quiencometio);
      formData.append("habladocon", values.habladocon);
      formData.append("masinfo", values.masinfo);
      formData.append("tipoconflicto", values.tipoconflicto);
      formData.append("estado", "Por Evaluar");
      formData.append("emaildenunciante", values.emaildenunciante);
      formData.append("password", values.password);
      formData.append("flag", values.flag);

   
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ }),
      });

  
      const { token } = await tokenResponse.json();

      const responseclient = await fetch(`${REACT_APP_API_ENDPOINT}clients/${idcontrato}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (responseclient.ok) {
        const loggedclient1 = await responseclient.json();
        setLoggedclient(loggedclient1);
        console.log("loggedclient", loggedclient1.nombreempresa);
      }


if (values.emaildenunciante){
      const userStoreRes = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({
          password: "098765",
          email: values.emaildenunciante,  // Corrected this line
          userprofile: "Denunciante",
          idcontrato: idcontrato,
        }),
      });
    }


        const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}complaints`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
      
        let comresId = null;
      
        if (userResponse.ok) {
          const comres = await userResponse.json(); // Parse response body as JSON
          comresId = comres._id;
          setTicket(comresId);
          setShowSuccessDialog(true);
          onSubmitProps.resetForm();
        } else {
          console.error(
            "Error storing user data:",
            userResponse.status,
            userResponse.statusText
          );
      

          try {
            const errorResponse = await userResponse.json(); // Try to parse response as JSON
            console.error("Error details:", errorResponse);
      
            if (errorResponse.code === 11000) {
              console.warn("Duplicate key error detected:", errorResponse);
              // Handle the duplicate key error (e.g., notify user, continue process)
              alert("A user with this email already exists, but your complaint has been logged.");
            } else {
              alert(`Error: ${errorResponse.error}`); // Display the error message to the user
            }
          } catch (parseError) {
            console.error("Failed to parse error response:", parseError);
            alert("An unexpected error occurred. Please try again later."); // Fallback error message
          }
      
          // Even if there was an error, set the comresId if available
          if (comresId) {
            setTicket(comresId);
            setShowSuccessDialog(true);
            onSubmitProps.resetForm();
          }
        }
        setLoading(false);  // Show loading dialog

/// MAIL A CLIENTE DE DENUNCIA PARA ACCION

      const userResponse99 = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      
      });
      if (userResponse99.status === 200) {

        userData1 = await userResponse99.json();

        const allUsers = userData1.userprofile; // Assuming user data is stored in the userprofile property
        const comiteeticaUsers = userData1.filter(user => 
          user.userprofile === "comitetica" || user.userprofile === "adminetica"
        );
        
       
        

  subject = `Importante: Denuncia recibida  ${values.flag}`;

  const comiteeticaEmails = comiteeticaUsers.map(user => user.email);

  // Filter out the superadmin users and get their emails
  const urgenteEmails = userData1
  .filter(user => user.userprofile === "superadmin" )
  .map(user => user.email);

  // Prepare the subject for the email

  // Combine committee emails with all superadmin emails
  const emailAddresses = [...comiteeticaEmails, ...urgenteEmails].filter(Boolean); // Remove any null values

  // Loop through each superadmin email in urgenteEmails
  for (const urgenteEmailsingle of urgenteEmails) {
    // Fetch user information including "celular" field for each superadmin
    const userResponseURG = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${urgenteEmailsingle}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!userResponseURG.ok) {
      throw new Error(`Failed to fetch user information with status ${userResponseURG.status}`);
    }

    const userData = await userResponseURG.json();
    console.log("userData a quien va el sms", urgenteEmailsingle);

    const firstUser = userData[0]; // Access the first element of the array

    // Check if the 'celular' field exists
    if (firstUser && firstUser.celular) {
      const { celular } = firstUser;
      const phonesms = `+${celular}`; // Add the "+" sign to the phone number

      // Send SMS if celular exists
      await sendSMS({
        phonesms,
        smsTemplate: `Hola, te informamos que ${selectedClient} ha recibido una Denuncia, más detalles porfavor revisa tu ${urgenteEmailsingle}. Eticpro`,
      });
      console.log(`SMS sent successfully to ${phonesms}`);
    } else {
      console.error(`Celular is not defined for the user with email ${urgenteEmailsingle}`);
    }
  }


/// MAIL A CLIENTE DE DENUNCIA PARA ACCION envio

  const emailTemplateRecEticpro = `<!DOCTYPE html>
  <html>
  <head>
    <style>
      .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        position: relative;
      }
      .logo-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .logo-box {
        text-align: center;
        margin-bottom: 20px;
      }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
  </head>
  <body>
    <div class="frame">
    
      <img src=${logoEmp} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
      <p>Estimado(a) Usuario,</p>
      <p>Le informamos que hemos recibido una denuncia para revisión correspondiente a la organización ${selectedClient}. Le invitamos ingresar a Eticpro en nuestro link de abajo, módulo Denuncias para revisar el estado de la denuncia y su acción</p>
      <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
       <p>Ticket de denuncia: ${comresId} </p>

      <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
      <p>Gracias por su atención y cooperación.</p>
      <p>Equipo Encargado de Prevención de Delitos</p>
      <p>Eticpro.</p>
      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    </div>
  </body>
  </html>
  `;

        const emailResponseCOMITE = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            emails: emailAddresses,
            emailTemplate: emailTemplateRecEticpro,
            subject,
            mailserver: logClient.mailserver,
            mailport: logClient.mailport,
            mailuser: logClient.mailuser,
            passmail: logClient.passmail,
            cipherstype: logClient.cipherstype,
          }),
        });
                  
    
            if (emailResponseCOMITE.ok) {
            
             
              // Email sent successfully
           console.log(`Email sent successfully to ${comiteeticaEmails}`);
          } else {
            // Error sending email
            console.log(`Failed to send email to ${comiteeticaEmails}`);
          }
               /////NUEVO MAIL A DENUNCIANTE
            const subjectRec = `Importante: Denuncia recibida`;

            const emailTemplateRec = `<!DOCTYPE html>
            <html>
            <head>
              <style>
                .frame {
                  border: 2px solid #ccc;
                  padding: 20px;
                  border-radius: 10px;
                  width: 80%;
                  margin: 0 auto;
                  text-align: left;
                  position: relative;
                }
                .logo-container {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }
                .logo-box {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .box {
                  background-color: #f2f2f2;
                  padding: 10px;
                  border-radius: 5px;
                }
              </style>
            </head>
            <body>
              <div class="frame">
             
                <img src=${logoEmp} alt="Logoemp" style="width: 15%;" /> <!-- Updated width to 10% -->
                <p>Estimado(a) Usuario,</p>
                <p>Le informamos que hemos recibido la denuncia para revisión correspondiente a la organización ${selectedClient}. Le invitamos ingresar a Eticpro en nuestro link de abajo, boton Canal de Denuncia, opción hacer seguimiento para ingresar el número de ticket indicado aquí:</p>
                <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                 <p> TICKET DE DENUNCIA : ${comresId} </p>

                <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
                <p>Gracias por su atención y cooperación.</p>
                <p>Equipo Encargado de Prevención de Delitos</p>
                <p>Eticpro.</p>
                <p><a href="www.eticpro.com">www.eticpro.com</a></p>
              </div>
            </body>
            </html>
            `;
            if (!values.emaildenunciante) {
              console.error("Email aviso no fue entregado");
              return; // Exit early if the email is not defined
            }
            const requestBody = {
              emails: [values.emaildenunciante], // Properly format the emails as an array
              emailTemplate: emailTemplateRec,
              subject: subjectRec,
              mailserver: logClient.mailserver,
              mailport: logClient.mailport,
              mailuser: logClient.mailuser,
              passmail: logClient.passmail,
              cipherstype: logClient.cipherstype,
            };
            
            console.log("Request Body:", requestBody);
            
            const emailResponseexterno = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(requestBody),
            });
            
         
                      
        
                if (emailResponseexterno.ok) {
                  // Email sent successfully
                  console.log(`Email sent successfully to ${values.emaildenunciante}`);
                } else {
                  // Error sending email
                  console.log(`Failed to send email to ${values.emaildenunciante}`);
                }
                      
                
  
      }
      } catch (err) {
        console.log("Error creating complaint:", err);
      
        if (err.response) {
          console.log("Response status:", err.response.status);
      
          // Use the .text() method to get the response body as text
          err.response.text().then(responseText => {
            console.log("Response data:", responseText);
          });
        } else {
          console.error("Unexpected error:", err);
          alert("An unexpected error occurred. Please try again later.");
        }
      
        // Ensure the form is reset and dialog is shown even on error
        setShowSuccessDialog(true);
        onSubmitProps.resetForm();
      }
      
  };

  const handleTipoconflictoChange = (event) => {
    // Update tipoconflictoSelected based on whether tipoconflicto has a value
    setTipoconflictoSelected(!!event.target.value);
  };


  
  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
    if (inputValue.length >= 3) {
      const filtered = clients.filter(client =>
        client.nombreempresa.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredClients(filtered);
    } else {
      setFilteredClients([]);
     
    }
  };

  const handleClientSelect = async (client) => {
    try {
      setSelectedClient(client.nombreempresa);
      setSearchInput(client.nombreempresa);
      setFilteredClients([]);
  
      // Generate token
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const { token } = await tokenResponse.json();
  
      // Fetch client data based on nombreempresa
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}clients/nombre/${client.nombreempresa}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (userResponse.status === 200) {
        const userData = await userResponse.json();
        console.log('Fetched contract data:', userData);
  
        // Set idcontrato from userData
        setIdcontrato(userData[0]._id);
        setLogoEmp(userData[0].logo);



      } else {
        console.error('Failed to fetch user data:', userResponse.status);
      }
  
      setClientSelected(true); // Mark client as selected
  
    } catch (error) {
      console.error('An error occurred:', error);
      alert('An unexpected error occurred. Please try again later.'); // Fallback error message
    }
  };
  

  const handleDownloadTxt = () => {
    const content = `Eticpro, Canal de denuncia\nNúmero de ticket de denuncia: ${ticketid}`;
    const element = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "ticketid.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (

    
    <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        width: isMobile ? '90%' : '30%', // Set width based on screen size
        maxWidth: 'none',
        overflowY: 'auto' // Ensure scrolling if content overflows
      }
    }}
  >
      <DialogTitle>
      <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
  Crear Denuncia
</Typography>
        </DialogTitle>
      <DialogContent>
      <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={validationSchema}
    >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
              isSubmitting,

    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>


{currentStep === 1 && (
                <Box>
                   <div className="mt-4 text-left">

      <Typography variant="h5" fontWeight="bold">

      Elegir organización
</Typography>
    </div>
                  <Typography variant="body2" color="textSecondary">
                    {searchInput.length < 3 && "Escriba por lo menos 3 letras y seleccione."}
                  </Typography>
                  <TextField
                    label={<FormattedMessage id="Organización donde hará la Denuncia" />}
                    onChange={handleSearchInputChange}
                    value={searchInput}
                    fullWidth
                    sx={{
                      marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                        background: 'white',
                      },
                    }}
                  />
                  {filteredClients.length > 0 && (
                    <Paper>
                      <List>
                        {filteredClients.map((client) => (
                          <ListItem key={client._id} button onClick={() => handleClientSelect(client)}>
                            <ListItemText primary={client.nombreempresa} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  )}
                  <Box display="flex" justifyContent="center">
                


                  </Box>
                  
                  <div className="mt-4 text-left">
  <p></p>
  <p></p>
      <Typography variant="h5" fontWeight="bold">

      Concepto
</Typography>
    </div>
    <p></p>


                  <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
      <InputLabel htmlFor="tipoconflicto">{<FormattedMessage id="Concepto"/>}</InputLabel>
      <Select
  label="Tipo de Conflicto"
  onChange={(e) => {
    handleChange(e); // Update other form values
    const selectedValue = e.target.value;

    // Update the flag based on selected value
    if (
      selectedValue === "Abuso o Acoso o Discriminación" ||
      selectedValue === "Violencia en el lugar de trabajo"
    ) {
      setFieldValue("flag", "URGENTE");
    } else {
      setFieldValue("flag", ""); // Reset or set to a different value if necessary
    }

    handleTipoconflictoChange(e); // Keep this if you have additional logic
  }}
  onBlur={handleBlur}
  value={values.tipoconflicto}
  name="tipoconflicto"
  error={touched.tipoconflicto && Boolean(errors.tipoconflicto)}
  helperText={touched.tipoconflicto && errors.tipoconflicto}
  fullWidth
  variant="outlined"
>
  <MenuItem value="Abuso o Acoso o Discriminación">
    {<FormattedMessage id="Abuso, Acoso o Discriminación laboral o sexual" />}
  </MenuItem>
  <MenuItem value="Violencia en el lugar de trabajo">
    {<FormattedMessage id="Violencia en el lugar de trabajo" />}
  </MenuItem>
  <MenuItem value="Delitos P. Jurídica">
    {<FormattedMessage id="Delitos de responsabilidad penal de persona jurídica" />}
  </MenuItem>
  <MenuItem value="Relaciones Stakeholders">
    {<FormattedMessage id="Relaciones con stakeholders" />}
  </MenuItem>
  <MenuItem value="Robo Recursos.">
    {<FormattedMessage id="Robo, fraude o mal uso recursos" />}
  </MenuItem>
  <MenuItem value="Libre Competencia.">
    {<FormattedMessage id="Libre competencia" />}
  </MenuItem>
  <MenuItem value="Proteccion Información.">
    {<FormattedMessage id="Protección de confidencialidad de información" />}
  </MenuItem>
  <MenuItem value="Conflicto de Interés">
    {<FormattedMessage id="Conflicto de Interés" />}
  </MenuItem>
  <MenuItem value="Otro">{<FormattedMessage id="Otro" />}</MenuItem>
</Select>

    </FormControl>
    <Box display="flex" justifyContent="center">

  
<Button
              onClick={() => {
                if (tipoconflictoSelected && clientSelected) {
                  setCurrentStep(currentStep + 1);
                } else {
                  alert('Por favor seleccione Tipo de Conflicto  u Organización antes de continuar.');
                }
              }}
              endIcon={<ArrowForward />}
              disabled={!tipoconflictoSelected || !clientSelected || isSubmitting}
            >
              
            </Button>
</Box>

                </Box>
              )}
              

          <p></p>
          {currentStep === 2 && (
  <Box mx="auto">
    <div className="mt-4 text-left">
      <Typography variant="h5" fontWeight="bold">
        Registra tu email
      </Typography>
      <p className="text-md lg:text-lg">
        {["Abuso o Acoso o Discriminación", "Violencia en el lugar de trabajo"].includes(values.tipoconflicto)
          ? "Este paso es Obligatorio, por escoger el tipo de denuncia correspondiente a Ley Karin."
          : "Este paso es OPCIONAL, pero es útil para dar seguimiento a tu denuncia en caso de que pierdas tu ticket."
        }
      </p>
    </div>

    <TextField
      label={<FormattedMessage id="Email" />}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.emaildenunciante}
      name="emaildenunciante"
      error={Boolean(touched.emaildenunciante) && Boolean(errors.emaildenunciante)}
      helperText={touched.emaildenunciante && errors.emaildenunciante}
      multiline
      fullWidth
      sx={{
        marginBottom: "5px",
        marginTop: "5px",
        borderRadius: "20px",
        '& input': {
          background: 'white', // Set the background color to white
        },
      }}
    />

    <Box display="flex" justifyContent="center">
      <Button onClick={handlePreviousStep} startIcon={<ArrowBack />}>
        Anterior
      </Button>
      
      <Button    
        onClick={handleNextStep}
        endIcon={<ArrowForward />}
        disabled={Boolean(errors.emaildenunciante)} // Disable if email is invalid
      >
        Siguiente
      </Button>
    </Box>
  </Box>
)}

  {currentStep === 3 && (

<Box>
<div className="mt-4 text-left">
 
          <Typography variant="h5" fontWeight="bold">
          Descripción de la denuncia 1/2
          </Typography>

    </div>
        <TextField
            label={<FormattedMessage id="Area a denunciar"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.areacometio}
            name="areacometio" // Match the field name with the schema
            error={Boolean(touched.areacometio) && Boolean(errors.areacometio)}
            helperText={touched.areacometio && errors.areacometio}
            multiline
            fullWidth
            sx={{
              borderRadius: "20px",
              gridColumn: "span 4",
              '& input': {
                background: 'white', // Set the background color to white
              },
            }}
          />

<TextField
            label={<FormattedMessage id="Cargo a denunciar"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cargocometio}
            name="cargocometio"
            error={Boolean(touched.cargocometio) && Boolean(errors.cargocometio)}
            helperText={touched.cargocometio && errors.cargocometio}
            multiline
            fullWidth

            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<TextField
            label={<FormattedMessage id="Relación con la organización que denuncia"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.relacion}
            name="relacion"
            error={Boolean(touched.relacion) && Boolean(errors.relacion)}
            helperText={touched.relacion && errors.relacion}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Qué ocurrió?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.queocurrio}
            name="queocurrio"
            error={Boolean(touched.queocurrio) && Boolean(errors.queocurrio)}
            helperText={touched.queocurrio && errors.queocurrio}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cuándo ocurrió?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cuandoocurrio}
            name="cuandoocurrio"
            error={Boolean(touched.cuandoocurrio) && Boolean(errors.cuandoocurrio)}
            helperText={touched.cuandoocurrio && errors.cuandoocurrio}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />

          

<TextField
            label={<FormattedMessage id="¿Desde cuándo cree usted que ha estado ocurriendo?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.desdecuando}
            name="desdecuando"
            error={Boolean(touched.desdecuando) && Boolean(errors.desdecuando)}
            helperText={touched.desdecuando && errors.desdecuando}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />
              <Box display="flex" justifyContent="center">

            <Button onClick={handlePreviousStep} startIcon={<ArrowBack />}>
  

</Button>
<Button onClick={handleNextStep} endIcon={<ArrowForward />}>
  
</Button>
</Box>

          </Box>
 )}
  {currentStep === 4 && (

<Box>

<Typography variant="h5" fontWeight="bold">

Descripción de la denuncia 2/2
</Typography>
   
<TextField
            label={<FormattedMessage id="¿Dónde ocurrió este incidente o infracción específicamente?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.dondeocurrio}
            name="dondeocurrio"
            error={Boolean(touched.dondeocurrio) && Boolean(errors.dondeocurrio)}
            helperText={touched.dondeocurrio && errors.dondeocurrio}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cómo se dio cuenta de cuenta de esta situación?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comoevidencio}
            name="comoevidencio"
            error={Boolean(touched.comoevidencio) && Boolean(errors.comoevidencio)}
            helperText={touched.comoevidencio && errors.comoevidencio}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />



<TextField
            label={<FormattedMessage id="¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.quiencometio}
            name="quiencometio"
            error={Boolean(touched.quiencometio) && Boolean(errors.quiencometio)}
            helperText={touched.quiencometio && errors.quiencometio}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Indique si ha hablado con alguien de esto o denunciado el hecho"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.habladocon}
            name="habladocon"
            error={Boolean(touched.habladocon) && Boolean(errors.habladocon)}
            helperText={touched.habladocon && errors.habladocon}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Otra información que complemente lo anterior"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.masinfo}
            name="masinfo"
            error={Boolean(touched.masinfo) && Boolean(errors.masinfo)}
            helperText={touched.masinfo && errors.masinfo}
            multiline
            fullWidth
            sx={{ marginBottom: "5px", marginTop: "5px", borderRadius: "20px", gridColumn: "span 4", '& input': {
                background: 'white', // Set the background color to white
            },
          }}
          />
 <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                  });
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>{<FormattedMessage id="Agregue aquí su evidencia, máximo 5Mb"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

  </Box>

  <Box display="flex" justifyContent="center">

  <Button onClick={handlePreviousStep} startIcon={<ArrowBack />}>
  
</Button>
</Box>
<Button
     disabled={isSubmitting}

              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                color: "white", // Set font color to white

                m: "2rem 0",
                p: "1rem",
                backgroundColor: "rgb(15, 15, 98)",
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}  
        </Button>
        
   
            

   

            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                              {<FormattedMessage id= "Envio Exitoso"/>}

              </Box>
            )}
          </Box>
        )}
        </form>
        
     
     )}
    </Formik>

    </DialogContent>

      {/* Success dialog */}
      
      
    <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <FindIn style={{ fontSize: '4.6rem', marginBottom: '0.5rem' }} /> 
        <Typography variant="h3" fontWeight="bold">
          ¡Su denuncia ha sido creada exitosamente!
          </Typography>
          <p></p>
          <Typography variant="h5" fontWeight="bold">

          Guarde su número de ticket
        </Typography>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" fontWeight="bold" style={{ backgroundColor: 'rgb(236, 253, 255)', padding: '0.5rem', borderRadius: '4px', marginBottom: '1rem' }}>
          <span style={{ color: 'red' }}>{ticketid}</span>
        </Typography>
        <Button 
          onClick={handleDownloadTxt} 
          style={{ 
            textTransform: 'none', 
            color: 'black', 
            marginBottom: '1rem', 
            border: '1px solid black', 
            borderRadius: '4px', 
            padding: '0.5rem 1rem' 
          }} 
          endIcon={<ArrowDownwardIcon />}
        >
          Descargar .txt
        </Button>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          Antes de salir, guarde bien su número de ticket.
          Solo aparecerá esta vez y es muy importante para dar
          seguimiento a su denuncia. Puede copiarlo y pegarlo,
          descargarlo o tomar una captura de pantalla.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <button className="nav-button nav-button-demo" onClick={handleCloseSuccessDialog}>
          Entendido, salir
        </button>
      </DialogActions>
    </Dialog>
    </Dialog>
  );
};

export default ComplaintDialog;

import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useReactToPrint } from "react-to-print";
import { FormattedMessage } from 'react-intl';

const redText = { color: 'red' }; // Define the CSS style for green text

const ComplaintDetailWidget = () => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the URL
  const { REACT_APP_API_ENDPOINT } = process.env;
 // Calculate the difference between createdAt and the current date in days
 const currentDate = new Date();
 
 const greenText = {
  color: 'green',
  fontWeight: 'bold',
};
  const fetchFiles = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState

  };
 
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [token, _id]);
  
  if (!form) {
    return null;
  }

  if (!form || form.length === 0) {
    return null;
  }
  const {

    userId,
    idcontrato,
    location,
    opauth,
    emailauth,
    authtxt,
  createdAt,
    relacion,
    areacometio,
    cargocometio,
    queocurrio,
    cuandoocurrio,
    desdecuando,
    dondeocurrio,
    comoevidencio,
    quiencometio,
    habladocon,
    masinfo,
    tipoconflicto,
    picturePath,
    estado,
  
    emailaviso,
    emaildenunciante,
    idevaluacion,
    dateval,
    selectedInvestigador,
  
    dateinv,
    idinvestigacion,
    resumeninv,
    
    dateveredicto,
    veredicto,
    detalleveredicto,
    idveredicto,
    flag,
  
  } = form;

  const createdDate = new Date(createdAt);
  const timeDifference = currentDate - createdDate;
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <div ref={componentRef} className="card">

    <Box m="2rem 0">

      <Box>

      <Typography variant="h2">{<FormattedMessage id="Denuncia"/>}<span style={greenText}></span></Typography>
      <Typography variant="h4">{<FormattedMessage id="Tipo: "/>}<span style={greenText}>{tipoconflicto}</span></Typography>

      <Typography variant="h4">{<FormattedMessage id="Estado: "/>} <span style={greenText}>{estado}</span></Typography>
      <Typography variant="h4">{<FormattedMessage id="Prioridad: "/>}<span style={redText}>{flag}</span></Typography>

      <div>
            <FormattedMessage id="Demora: " />{' '}
            <span style={greenText}>{dayDifference} días</span>
          </div>
     
      <div>{<FormattedMessage id="Recibida: "/>} <span style={greenText}>{new Date(createdAt).toLocaleDateString('es-ES')}</span></div>
      <div>{<FormattedMessage id="Email Denunciante: "/>} <span style={greenText}>{emaildenunciante}</span></div>
      <div>{<FormattedMessage id="Investigador: "/>} <span style={greenText}>{selectedInvestigador}</span></div>

          
      <div>{<FormattedMessage id="ID:"/>} <span style={greenText}>{_id.slice(-0)}</span></div>

<p></p>
  {/* New selection field for clients */}  
          </Box>

          <p></p>

        <Box

          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >

<TextField
  label={<FormattedMessage id="Organización donde ocurre la situación"/>}
  value={location}
            name="location"
           multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />
          
              <TextField
            label={<FormattedMessage id="Area a denunciar"/>}
            value={areacometio}
            name="areacometio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="Cargo a denunciar"/>}
            value={cargocometio}
            name="cargocometio"
           multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />




<TextField
            label={<FormattedMessage id="Relación con la organización que denuncia"/>}
          

            value={relacion}
            name="relacion"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Qué ocurrió?"/>}
          

            value={queocurrio}
            name="queocurrio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cuándo ocurrió?"/>}
          

            value={cuandoocurrio}
            name="cuandoocurrio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Desde cuándo cree usted que ha estado ocurriendo?"/>}
          

            value={desdecuando}
            name="desdecuando"
             multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Dónde ocurrió este incidente o infracción específicamente?"/>}
          

            value={dondeocurrio}
            name="dondeocurrio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="¿Cómo se dio cuenta de cuenta de esta situación?"/>}
          

            value={comoevidencio}
            name="comoevidencio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />



<TextField
            label={<FormattedMessage id="¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?"/>}
          

            value={quiencometio}
            name="quiencometio"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Indique si ha hablado con alguien de esto o denunciado el hecho"/>}
          

            value={habladocon}
            name="habladocon"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


<TextField
            label={<FormattedMessage id="Otra información que complemente lo anterior"/>}
            value={masinfo}
            name="masinfo"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

<TextField
            label={<FormattedMessage id="Evento"/>}
            value={tipoconflicto}
            name="tipoconflicto"
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />


</Box>



</Box>
        </div>

);
};

export default ComplaintDetailWidget;






import React, { useEffect, useState, useRef } from 'react';
import { Divider } from "@mui/material";
import { Checkbox } from "@mui/material";

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
    InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useReactToPrint } from "react-to-print";

const greenText = { color: 'green' }; // Define the CSS style for green text

const DdDetailWidget = ({ formData, formTotalDays }) => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the 
  const { REACT_APP_API_ENDPOINT } = process.env;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getForm = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}ddPostulante/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState

  };
 

  useEffect(() => {
    getForm();
  }, [token]); // Remove unnecessary square brackets

  

  if (!form) {
    return null;
  }

 
  const {
    

 //gral
 solicitante,
 estado,
 idcontrato,
 createdAt,

//dd ext contacto
rut,
telefono,
firstName,
lastName,
email,
location,
occupation,
area,


//cdd
opSansionado,
txtSansionado,
opParentesco,
txtParentesco,
opRelacion,
txtRelacion,
opOtroPos,
txtOtroPos,
opParaiso,
txtParaiso,
opRecomendado,
txtRecomendado,
confirmRead,

//approval
aprobador,
opAuth,
txtAuth,
opMitigacion,
txtMitigacion,
idevaluacion,
dateval,
emailaviso,


  } = form;



  
  const formatDate = (dateString) => {
     if (!dateString) {
    return "";
  }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };


  
  return (
    <WidgetWrapper m="2rem 0">
            <div ref={componentRef} className="card">

    <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Cuestionario a Postulante"/>} <span style={greenText}></span></Typography>        

    <Typography variant="h9" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{_id.slice(-0)}</span></Typography>        
    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Recibida:"/>} <span style={greenText}>{formatDate(createdAt)}</span></Typography>        
    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Demora:"/>} <span style={greenText}>{formTotalDays} días</span></Typography>        

    <Box/>


    
      <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>

  
   <Typography variant="h4">{<FormattedMessage id="Formulario de Declaración"/>}</Typography>
      <Typography variant="h6">{<FormattedMessage id="Ingrese sus Datos"/>}</Typography>
     

<Box>
  {/* New selection field for clients */}

          </Box>

          <p></p>
   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          {iscreateform && (
            <>
 <TextField
        fullWidth
        variant="filled"
        type="text"
        label={<FormattedMessage id="Rut"/>}
        value={rut}
        InputProps={{ readOnly: true }}
        sx={{ borderRadius: "20px", gridColumn: "span 1" }}
      />


<TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Nombres"/>}
              value={firstName}
              InputProps={{ readOnly: true }}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Apellidos"/>}
              InputProps={{ readOnly: true }}
              value={lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Empleador u organización"/>}
              InputProps={{ readOnly: true }}
              value={location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Area"/>}
              InputProps={{ readOnly: true }}

              value={area}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Cargo"/>}
              InputProps={{ readOnly: true }}

              value={occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Celular"/>}
              InputProps={{ readOnly: true }}
              value={telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />
 


            </>

      
          )}
 </Box>
<Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>
<div>
<Typography variant="h6" gutterBottom>
<Box
    border="2px solid"
    p="1rem"
    mt="2rem"
    mb="2rem"
    bgcolor="white"
  >
    <Typography>
    Nuestra organización esta comprometida con la integridad y el cumplimiento de la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas, UK Bribery Act del Reino Unido y Foreign Corrupt Practices Act (FCPA) de EE. UU. Para el logro total de dicho compromiso, la organización ha adoptado una política de revisión previa a todos los postulantes con las que se relaciona. El programa de revisión de postulantes requiere que estas provean la información necesaria para que la organización pueda revisar y evaluar los antecedentes necesarios con el fin de cumplir con la ley. Esta revisión consiste en un cuestionario de diligencia debida que deberá ser contestado por el postulante. 
      </Typography>
 


  </Box>

</Typography>

{<FormattedMessage id="1.	En relación con la Ley N°20.393 ¿Alguna vez has sido sancionado por uno de los delitos detallados en la Ley 20.393? (En el caso de ser positiva la respuesta, por favor detallar)"/>}
<RadioGroup
      aria-label="Opción"
      name="opSansionado"
      value={opSansionado}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtSansionado}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>


   {<FormattedMessage id="2.	¿Tienes relación de parentesco por consanguinidad o afinidad hasta el tercer grado con un funcionario público o PEP (persona políticamente expuesta)? (Favor suministrar detalles)"/>}
<RadioGroup
      aria-label="Opción"
      name="opParentesco"
      value={opParentesco}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtParentesco}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>
  
   {<FormattedMessage id="3.	¿Tienes relación con algún colaborador de la Organización, o existe algún conflicto de interés con algún colaborador?"/>}
<RadioGroup
      aria-label="Opción"
      name="opRelacion"
      value={opRelacion}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtRelacion}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="4.	En el caso de tener conocimiento de otros postulantes. ¿Existe algún conflicto de interés con alguno?"/>}
<RadioGroup
      aria-label="Opción"
      name="opOtroPos"
      value={opOtroPos}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtOtroPos}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="5.	¿Cuentas con algún domicilio o cuentas bancarias en alguno de los países considerados paraísos fiscales*?"/>}
<RadioGroup
      aria-label="Opción"
      name="opParaiso"
      value={opParaiso}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtParaiso}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="6.	¿Según tu conocimiento, fuiste recomendado por algún funcionario público, como por ejemplo, empleados de Codelco, Ministerios, Servicios públicos, Intendencias u otros? "/>}
<RadioGroup
      aria-label="Opción"
      name="opRecomendado"
      value={opRecomendado}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtRecomendado}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>



<FormControlLabel
  control={
    <Checkbox
      checked={  <span style={{ fontSize: '20px' }}>✓</span>
    }
      disabled={true} // Disable the checkbox
      name="confirmRead"
      color="primary"
    />
  }
  label={<FormattedMessage id="He leído y comprendido la información anterior."/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>

</div>
</Box>
       
      
</Box>



<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Resultado Evaluación:"/>} <span style={greenText}> </span></Typography>        
   

        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Aprobada:"/>}  <span style={greenText}>{opAuth}, {txtAuth}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Mitigación:"/>} <span style={greenText}>{opMitigacion}, {txtMitigacion}</span></Typography>        
    
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Aprobador"/>} <span style={greenText}>{aprobador.firstName} {aprobador.lastName}, {aprobador.email}</span></Typography>        

        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{idevaluacion}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Email Aviso:"/>} <span style={greenText}>{emailaviso}</span></Typography>        

        </Box>

        <Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
  <Button
    variant="contained"
    startIcon={<Print />}
    onClick={handlePrint}
  >
    {<FormattedMessage id="Imprimir en PDF"/>}
  </Button>
</Box>
   
        </div>

</WidgetWrapper>


);
};

export default DdDetailWidget;







import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import LogoImage from '../assets/eticprologo8.png';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Check1 from '../assets/check.png';
import Cross from '../assets/cross.png';

const emailRecoverySchema = yup.object().shape({
  email: yup.string().email("Debe ser un email válido").required("Campo obligatorio"),
});

const EmailRecoveryModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleEmailSubmit = async (values, onSubmitProps) => {
    try {
      const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const { token } = await tokenResponse.json();
  
      const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/email/${values.email}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
  
        if (data && data.length > 0) {
          const comresIds = data.map((comres) => comres._id);
          const comresIdsString = comresIds.join(', ');
  
          const emailTemplateRecEticpro = `<!DOCTYPE html>
          <html>
          <head>
            <style>
              .frame {
                border: 2px solid #ccc;
                padding: 20px;
                border-radius: 10px;
                width: 80%;
                margin: 0 auto;
                text-align: left;
                position: relative;
              }
              .logo-container {
                position: absolute;
                top: 10px;
                right: 10px;
              }
              .logo-box {
                text-align: center;
                margin-bottom: 20px;
              }
              .box {
                background-color: #f2f2f2;
                padding: 10px;
                border-radius: 5px;
              }
            </style>
          </head>
          <body>
            <div class="frame">
              <div class="logo-container">
                <img src="${LogoImage}" alt="Logo" style="width: 100%;" />
              </div>
              <p>Estimado(a) Usuario,</p>
              <p>Le invitamos a ingresar a Eticpro en nuestro link de abajo, módulo Denuncias para revisar el estado de su denuncia.</p>
              <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
              <p>Sus Tickets de denuncia son: ${comresIdsString}</p>
              <p>Para soporte escríbanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
              <p>Gracias por su atención y cooperación.</p>
              <p>Equipo Encargado de Prevención de Delitos</p>
              <p>Eticpro.</p>
              <p><a href="www.eticpro.com">www.eticpro.com</a></p>
            </div>
          </body>
          </html>
          `;
  
          const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              emails: values.email,
              emailTemplate: emailTemplateRecEticpro,
              subject: 'Importante: Denuncia recibida',
            }),
          });
  
          if (emailResponse.ok) {
            setSuccessMessage(`Tu ticket ha sido enviado a ${values.email}`);
            setShowSuccessDialog(true);
          } else {
            setErrorMessage('No se pudo enviar el correo.');
            setShowErrorDialog(true);
          }
        } else {
          setErrorMessage('Lo sentimos, no tenemos registrado ese correo.');
          setShowErrorDialog(true);
        }
  
        onClose();
      } else {
        setErrorMessage('Hubo un problema al procesar tu solicitud.');
        setShowErrorDialog(true);
      }
    } catch (err) {
      console.error('Error:', err);
      setErrorMessage('Hubo un problema al procesar tu solicitud.');
      setShowErrorDialog(true);
    }
  };

  const SuccessDialog = ({ open, onClose, email }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <img src={Check1} alt="Success Icon" style={{ display: 'block', margin: '0 auto 1rem auto', width: '50px' }} />
        <Typography variant="h2" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          Tu ticket ha sido enviado a:
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
          {email}
        </Typography>
        <Typography variant="body" align="center">
          Asegúrate de revisar también tu carpeta de correo no deseado.
        </Typography>
        <p></p>
        <Button
          fullWidth
          onClick={onClose}
          color="secondary"
          variant="contained"
          sx={{
            color: 'white',
            m: '2rem 0',
            p: '1rem',
            backgroundColor: 'rgb(15, 15, 98)',
          }}
        >
          Ingresar Ticket              
          <ArrowForward sx={{ ml: 2 }} />
        </Button>
      </DialogContent>
    </Dialog>
  );
  


const ErrorDialog = ({ open, onClose, message }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
    <img
        src={Cross}
        alt="Error Icon"
        style={{
          display: 'block',
          color: 'green',
          fontSize: '4rem',
          margin: '0 auto 1rem auto',
          width: '50px',
        }}
      />
     

    </DialogTitle>
    <DialogContent>
    <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
    Lo Sentimos, no tenemos registrados ese correo.    
        </Typography>
        <p></p>
        <Button
          fullWidth
          onClick={onClose}
          color="secondary"
          variant="contained"
          sx={{
            color: 'white',
            m: '2rem 0',
            p: '1rem',
            backgroundColor: 'rgb(15, 15, 98)',
          }}
        >
          Salir
          <ArrowForward sx={{ ml: 2 }} />
        </Button>
   
    </DialogContent>
  </Dialog>
);


  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isMobile ? '90%' : '30%',
            maxWidth: 'none',
            overflowY: 'auto',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
            Recuperación de Ticket
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={emailRecoverySchema}
            onSubmit={handleEmailSubmit}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h5" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Ingresa el email que registraste al crear tu denuncia
                </Typography>
                <p></p>
                <Typography variant="h7" align="left">
                  Si registraste tu email al crear tu denuncia, recibirás un correo con tu número de ticket.
                </Typography>
                <p></p>

                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                  sx={{
                    borderRadius: '20px',
                    '& input': {
                      background: 'white',
                    },
                  }}
                />
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    color: 'white',
                    m: '2rem 0',
                    p: '1rem',
                    backgroundColor: 'rgb(15, 15, 98)',
                  }}
                >
                  Recuperar Ticket
                </Button>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
<SuccessDialog 
  open={showSuccessDialog} 
  onClose={() => setShowSuccessDialog(false)} 
  email={successMessage.replace('Tu ticket ha sido enviado a ', '')} 
/>      <ErrorDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} message={errorMessage} />
    </>
  );
};

export default EmailRecoveryModal;

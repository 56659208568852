import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { FormattedMessage } from 'react-intl';
import JSZip from 'jszip';
import { DOMParser } from 'xmldom';
import ExcelJS from 'exceljs'; // Import ExcelJS for XLSX handling
import mammoth from "mammoth";
import RegSession from "./RegSession";
import Tesseract from 'tesseract.js';
import { PDFDocument } from 'pdf-lib';


const campaignSchema = yup.object().shape({
  picture: yup
    .mixed()
    .test("fileType", "Unsupported file type", (value) => {
      if (!value) return true; // Allow empty value
      return (
        value && [
          'image/png',
          'image/jpg',

          'application/pdf',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/msword', // DOC
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
          'text/csv'
        ].includes(value.type)
      );
    })
    .required("Required"),
});


const greenText = { color: 'green' }; // Define the CSS style for green text

const Dropfiles = ({ _id, onFileUploaded }) => {
  const userprofile = useSelector((state) => state.user.userprofile);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);
  const [isExtracting, setIsExtracting] = useState(false); // Add state for extraction process
  
  const [totalWCount, setTotalWCount] = useState(0);
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const REACT_APP_API_ENDPOINTBP = process.env.REACT_APP_API_ENDPOINTBP;
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  
  const [totalWordCount, setTotalWordCount] = useState(0);

  
  const initialValuesEditCampaign = {
    idcontrato: idcontrato, // Update the initial value
    picturePath:"",
    picture: "",
    description: "",
    textExtracted: "",
    embeddingQ: "",
    dialogQ: "",
    queryQ: "",

};

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setUploadedFiles(selectedFiles);
  };


  


    const getForm = async () => {

      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINTBP}repository/contract/${idcontrato}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

      
        const data = await response.json();
        setForm(data);
        setLoading(false);
        // Set the dialogues state
        const fetchedDialogues = data.dialogues || [];
        setDialogues(fetchedDialogues);

      } catch (error) {
        setError('An error occurred while fetching form data.');
        setLoading(false);
      }
    };

    useEffect(() => {
        getForm();
      }, []); // Empty dependency array
      
    

      const extractTextFromExcel = async (file) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(file);
    
        let textExtracted = '';
    
        workbook.eachSheet(sheet => {
          sheet.eachRow(row => {
            row.eachCell(cell => {
              textExtracted += `${cell.value} `;
            });
            textExtracted += '\n';
          });
        });
    
        return textExtracted.trim(); // Trim to remove any leading/trailing whitespace
      };

      const extractTextFromPPT = async (file) => {
        try {
          const zip = await JSZip.loadAsync(file);
          const textExtracted = [];
      
          // Iterate through each slide
          zip.forEach(async (relativePath, zipEntry) => {
            if (relativePath.startsWith('ppt/slides/slide')) {
              const contentXml = await zipEntry.async('string');
              const doc = new DOMParser().parseFromString(contentXml, 'text/xml');
              const textNodes = doc.getElementsByTagName('a:t');
      
              // Collect text from all text nodes in the slide
              let slideText = '';
              for (let i = 0; i < textNodes.length; i++) {
                slideText += textNodes[i].textContent + ' ';
              }
              textExtracted.push(slideText.trim());
            }
          });
      
          console.log('Text extracted from all slides:', textExtracted);
          return textExtracted;
        } catch (error) {
          console.error('Error extracting text from PPT:', error);
          throw error;
        }
      };
      

const extractTextFromDoc = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const result = await mammoth.extractRawText({ arrayBuffer: event.target.result });
        resolve(result.value);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

  const extractTextFromPDF = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function () {
        const typedArray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        let text = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            text += item.str + ' ';
          });
        }
        resolve(text);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const extractImagesFromPDF = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function () {
        try {
          const typedArray = new Uint8Array(this.result);
          const pdfDoc = await PDFDocument.load(typedArray);
          const images = [];

          for (const page of pdfDoc.getPages()) {
            const { objects } = page.node;
            for (const [key, value] of Object.entries(objects)) {
              if (value.constructor.name === 'PDFImage') {
                const image = value;
                const base64Image = await image.embed();
                images.push(base64Image);
              }
            }
          }
          resolve(images);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };


  const extractTextFromImage = async (file) => {
    return new Promise((resolve, reject) => {
      Tesseract.recognize(file, 'es', {
        logger: (m) => console.log(m),
      })
        .then(({ data: { text } }) => {
          resolve(text);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const extractTextFromPDFImages = async (file) => {
    const reader = new FileReader();
  
    return new Promise((resolve, reject) => {
      reader.onload = async function () {
        try {
          const typedArray = new Uint8Array(this.result);
          const loadingTask = pdfjsLib.getDocument({ data: typedArray });
          const pdf = await loadingTask.promise;
          const pageTexts = [];
  
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: 2 });
  
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
  
            await page.render(renderContext).promise;
  
            const imgData = canvas.toDataURL('image/png');
  
            const { data } = await Tesseract.recognize(imgData, 'eng', {
              logger: (m) => console.log(m),
            });
  
            pageTexts.push(data.text.trim());
          }
  
          resolve(pageTexts.join('\n'));
        } catch (error) {
          reject(error);
        }
      };
  
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };



    const handleFormSubmit = async (values, onSubmitProps) => {
      setIsExtracting(true); // Start loading indicator

      // Fetch the form data first
      try {
        await getForm();
      } catch (error) {
        console.error('Error fetching form data:', error);
        return;
      }
      let textExtracted = '';
      const file = uploadedFiles[0]; // Assuming single file upload
      const formData = new FormData();

      if (file.type === 'application/pdf') {
        
        const textExtractedFromPDF = await extractTextFromPDF(file);
    //  const textExtractedFromImages = await extractTextFromPDFImages(file);
    //  textExtracted = `${textExtractedFromPDF}\n${textExtractedFromImages}`;
      textExtracted = `${textExtractedFromPDF}`;

      } else if (
        file.type === 'application/vnd.ms-powerpoint' ||
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        textExtracted = await extractTextFromPPT(file);
      } else if (
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        textExtracted = await extractTextFromExcel(file);
      } else if (
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        textExtracted = await extractTextFromDoc(file);
      } else if (file.type.startsWith('image/')) {
        textExtracted = await extractTextFromImage(file);
      } else {
        console.warn('Unsupported file type:', file.type);
        return;
      }

    // Count the number of words in the extracted text
  const embeddingQ = textExtracted.split(/\s+/).filter(Boolean).length;
  console.log('Word count:', embeddingQ);
  console.log('Word count75m:', embeddingQ/ 75000 );

  setTotalWCount((embeddingQ / 750000).toFixed(5));
  try {
    if (form.picturePath && form.picturePath.length > 0) {
      form.picturePath.forEach((file) => {
        formData.append("picturePath", values.picture.name);
        formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
        formData.append('idcontrato', idcontrato);
        formData.append('userId', loggedInUserId);
        formData.append('textExtracted', textExtracted);
        formData.append('embeddingQ', embeddingQ);

      });
    }

    // Append newly uploaded files to S3
    if (uploadedFiles.length > 0) {

          // Define the uploadFileToS3 function
          const uploadFileToS3 = async (file) => {
            // Set up AWS S3 client


          // Load your AWS credentials fconsrom environment variables
          AWS.config.update({
          
          accessKeyId: REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
          region: "sa-east-1", // Set your AWS region
          });
            const s3 = new AWS.S3();

            // Configure the S3 bucket and key where you want to upload the file
            const bucketName = 'repobatonpass';
            const key = `${file.name}`; // Set the desired key for the file

            // Configure S3 upload parameters
            const params = {
              Bucket: bucketName,
              Key: key,
              Body: file, // The actual file object to upload
            };

  
  // Use the AWS S3 client to upload the file to S3
  try {
    await s3.upload(params).promise();
    console.log(`File uploaded to S3: ${key}`);
    return key; // Return the key for reference
  } catch (error) {
    console.error(`Error uploading file to S3: ${error.message}`);
    throw error;
  }
  
};


      const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
      // s3Urls will contain the S3 URLs of the uploaded files
      // Add these URLs to your data or database as needed
      formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
      formData.append('userId', loggedInUserId);
      formData.append("picturePath", values.picture.name);
    formData.append('idcontrato', idcontrato);
    formData.append('textExtracted', textExtracted);

    }
    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "NewFile", fileName: values.picture.name, embeddingQ: totalWCount });

        // Rest of your code for submitting the form
    
        // Example of saving user data using fetch
        const userResponse = await fetch(`${REACT_APP_API_ENDPOINTBP}repository`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const savedUser = await userResponse.json();

        // Rest of your code for handling the response
        
        setIsExtracting(false); // Stop loading indicator

        // Reset the form after successful submission
        onSubmitProps.resetForm();
        getForm();
        onFileUploaded();

      } catch (err) {
        console.log("Error creating file:", err);
    
        if (err.response) {
          console.log("Response status:", err.response.status);
    
          // Use the .text() method to get the response body as text
          err.response.text().then(responseText => {
            console.log("Response data:", responseText);
          });
        }

      }
    };
    

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
 <p></p>


    <Box>
    <Box display="flex">    

      <Box width="100%" >
        <Box flexBasis="50%" marginRight="1rem">
         
       
          
          
          <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
              multiple={false}
              onDrop={(acceptedFiles) => {
                setUploadedFiles(acceptedFiles);
                setFieldValue("picture", acceptedFiles[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  border={`2px dashed ${theme.palette.primary.main}`}
                  p="1rem"
                  sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!values.picture ? (
                    <Typography>Agregue Archivo Aquí (Ppt, Pptx, Doc, Docx, PDF, Xls, Xlsx).</Typography>
                  ) : (
                    <Box display="flex" justifyContent="space-between">
                      <Typography>{values.picture.name}</Typography>
                      <EditOutlinedIcon />
                    </Box>
                  )}
                </Box>
              )}
            </Dropzone>
                </Box>
 {/* BUTTONS */}
 <Box>
        {/* Button */}
        <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            sx={{
              borderRadius: "8px",    // Adjust border radius as needed
              m: "1rem 0",            // Adjust margins as needed
              p: "0.5rem 1rem",       // Adjust padding to make the button smaller
              fontSize: "0.8rem",     // Adjust font size to make it smaller
            }}
          >
            Guardar Archivos
          </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                Envio exitoso
              </Box>
            )}
    </Box>
          
    </Box>
    </Box>
    </Box>
    </Box>
    <Dialog
            open={isExtracting}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Procesando extracción de Texto</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Porfavor espera mientras se esta extrayendo el texto.
              </DialogContentText>
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            </DialogContent>
          </Dialog>
  </form>
      )}
    </Formik>
);
};

export default Dropfiles;
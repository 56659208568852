import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import ProgressCircle from './ProgressCircle';
import { FormattedMessage } from 'react-intl';

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      border="1px solid rgba(0, 0, 0, 0.12)"
      borderRadius="8px"
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
      p="9px" // Further reduced padding to decrease vertical size
      bgcolor="white"
      mb="1px" // Further reduced margin-bottom to decrease vertical size
      mr="3px" // Add margin on the right to create horizontal space between StatBoxes
      display="inline-block" // Ensure inline-block display to prevent breaking to new lines
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography variant="h2" fontWeight="bold" sx={{ borderRadius: "20px", color: colors.grey[100] }}>
            {title}
          </Typography>
          <Typography variant="h5">{subtitle}</Typography>
        </Box>
        <Box>
          <ProgressCircle progress={progress} size="50" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="0px"> {/* Further reduced margin-top */}
        <Typography variant="h5" fontStyle="italic"></Typography>
      </Box>
    </Box>
  );
};

export default StatBox;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyPostWidget from "./MyPostWidget";
import { useLocation } from "react-router-dom";
import Papa from "papaparse"; // Library for parsing CSV files
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper'; // Import the Paper component
import { tokens } from "../../src/theme";
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";
import LogoImage from '../assets/eticprologo8.png';

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;
const greenText = { color: 'green' }; // Define the CSS style for green text

const RepeatCampaign = ({allEmails}) => {

  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const userId = useSelector((state) => state.user._id);
  const cmf = useSelector((state) => state.cmf);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [emailTemplate, setemailTemplate] = useState([]); // State to store the parsed CSV data
  const loggedinempresa = useSelector((state) => state.user.location);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const userEmail = useSelector((state) => state.user.email);
  const [csvData, setCsvData] = useState([]); // State to store the parsed CSV data
  const loggedInUserId = useSelector((state) => state.user._id);
  const userprofile = useSelector((state) => state.user.userprofile); 

  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [selectedInfoKey, setSelectedInfoKey] = useState(null); // Added state to store selected info key
  const greenText = { color: 'green' }; // Define the CSS style for green text
  const [showInvalidRutPopup, setShowInvalidRutPopup] = useState(false);
  const [invalidRutMessage, setInvalidRutMessage] = useState("");
  const [invalidRuts, setInvalidRuts] = useState([]);
  const loggedclient = useSelector((state) => state.client);
  const [profilePicture, setProfilePicture] = useState("");

  const isValidRut = (rut) => {
    if (!rut || rut.trim().length < 3) return false;
    const rutClean = rut.replace(/[^0-9Kk]/g, "").toUpperCase();
    const rutBody = rutClean.substring(0, rutClean.length - 1);
    let dv = rutClean.slice(-1);
    let sum = 0;
    let factor = 2;
  
    for (let i = rutBody.length - 1; i >= 0; i--) {
      sum += factor * parseInt(rutBody.charAt(i), 10);
      factor = (factor === 7) ? 2 : factor + 1;
    }
  
    const dvCalculated = 11 - (sum % 11);
    if (dvCalculated === 11) dv = '0';
    if (dvCalculated === 10) dv = 'K';
  
    return dv === (dvCalculated === 11 ? '0' : dvCalculated === 10 ? 'K' : String(dvCalculated));
  };
  

 
  const campaignSchema = yup.object().shape({
    // Define your validation schema here
    comments: yup.string().required("Nombre de Campaña es Requerido"),
    opCampaign: yup.string().required("Porfavor seleccione una opción"),
    link: yup.string().notRequired("Comentario o link a adjuntar en Email"),
    email: yup.string().required("Se requiere  Email del destinatario"),
    rut: yup.string().notRequired(), // Otherwise, make it not required

    

  });
  const initialValuesEditCampaign = {
    comments: "",
    opCampaign: "login",
    opLoad: "TXT",
    description: "",
    email: userEmail,
    idcontrato: idcontrato, // Include idcontrato in the initial values
    link: "",
    
  };
  const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: userEmail,
  password: "",
  location: "",
  occupation: "",
  picture: "",
  idcontrato: idcontrato,
  area: "",
  rut: "",
  celular: "",
  status: "decpendiente",

};
let userIduser; // Declare userIduser outside the try block
        let formData6; // Declare formData6 outside the try block
        let emailTemplateTxt = ""; // Initialize emailTemplate variable


      // Save all the emails from the CSV data into the description array
  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log('Form submitted!', values);
    let formData6; // Declare formData6 outside the try block

        let userIduser; // Declare userIduser outside the try block
        let loggedclient; // Declare loggedclient outside the try block
        let subject = ""; // Initialize emailTemplate variable
        let postId; // Define postId outside the loop
        let mailport= "";
        let  mailserver= "";
        let  mailuser= "";
        let  passmail= "";
        setInvalidRuts([]);
        let emailTemplate = ""; // Initialize emailTemplate variable

        let  cipherstype= "";

        try {
          const response6 = await fetch(`${REACT_APP_API_ENDPOINT}clients/${idcontrato}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
        });
  
        if (response6.status === 200) {
           formData6 = await response6.json();
          const clientname = formData6.nombreempresa;
          setProfilePicture(formData6.logo);
        }
        } catch (error) {
          console.log(error);
          // Handle error
        }
        
        const csvEmails = allEmails.map((email) => email.trim());


      const response7 = await fetch(`${REACT_APP_API_ENDPOINT}posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...values,
          email: userEmail,
          idcontrato,
          userId,
          endDate,
          description: csvEmails, // Save all the emails in the description array
        }),
      });
                         
          if (response7.ok) {
            console.log(`post guardado exitosamente ${csvEmails}`);
    
                      
          const responseData7 = await response7.json();
          postId = responseData7.savedPost._id; // Assign the value to postId
        

              }

for (let i = 0; i < csvEmails.length; i++) {
                         
                          const status = values.opCampaign !== "CI" ? "Invitado" : "decpendiente";
                          const  postId2 = postId;  

                          try {
                            setLoading(true);
                    
                            const response5 = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${csvEmails[i]}`, {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                            });
                    
                            if (response5.status === 200) {
                              const formData5 = await response5.json();
                    
                              if (Array.isArray(formData5) && formData5.length > 0 && formData5[0]._id) {
                                userIduser = formData5[0]._id;
                              }     
                            }                   

                            
                            if (values.opCampaign === "CI") {

                              subject = `Importante: ${loggedclient.nombreempresa} requiere que complete el formulario para cumplir con la legislación ley N°20.393`;
                              
                              
                              emailTemplate = `
                              <!DOCTYPE html>
                              <html>
                              <head>
                                
                              <style>
                              .frame {
                                border: 2px solid #ccc;
                                padding: 20px;
                                border-radius: 10px;
                                width: 80%;
                                margin: 0 auto;
                                text-align: left; /* Align text to the left */
                                position: relative; /* Add relative positioning to the frame */
                              }
                              
                              .logo-container {
                                position: absolute;
                                top: 10px; /* Adjust top value to position the logo vertically */
                                right: 10px; /* Adjust right value to position the logo horizontally */
                              }
                              
                              /* Styles for the box containing the logo */
                              .logo-box {
                                text-align: center;
                                margin-bottom: 20px;
                              }
                                  .box {
                                    background-color: #f2f2f2;
                                    padding: 10px;
                                    border-radius: 5px;
                                  }
                                </style>
                              </head>
                              <body>
                              <div class="frame">
                              <div class="logo-container">
                                <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                              </div>
                              <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                <p>Por requerimiento de ${formData6.nombreempresa}, le invitamos a completar el siguiente formulario en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                <p>La información recopilada es esencial para que tu organización pueda cumplir con la Ley #21.595. Tu participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
                                <p><a href="https://compliax.onrender.com/formepd/campaign/${postId2},${idcontrato},${userIduser},${encodeURIComponent(csvEmails[i])},${cmf}">Link al formulario</a></p>
                                
                                <p>Gracias por su atención y cooperación.</p>
                                <p>Equipo Encargado de Prevención de Delitos.</p>
                                <p>Eticpro.</p>
                                <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.
                                </p>
                                <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>

                              </div>
                              </body>
                              </html>`;
                              



                                  }
                                  if (values.opCampaign === "login") {

                                    // Invitación usar Eticpro 
                            subject = `Invitación de ${formData6.nombreempresa} a usar Eticpro`;

                            emailTemplate = `
                            <!DOCTYPE html>
                            <html>
                            <head>
                                <style>
                                    .frame {
                                        border: 2px solid #ccc;
                                        padding: 20px;
                                        border-radius: 10px;
                                        width: 80%;
                                        margin: 0 auto;
                                        text-align: left; /* Align text to the left */
                                        position: relative; /* Add relative positioning to the frame */
                                    }
                        
                                    .logo-container {
                                        position: absolute;
                                        top: 10px; /* Adjust top value to position the logo vertically */
                                        right: 10px; /* Adjust right value to position the logo horizontally */
                                    }
                        
                                    /* Styles for the box containing the logo */
                                    .logo-box {
                                        text-align: center;
                                        margin-bottom: 20px;
                                    }
                                    .box {
                                        background-color: #f2f2f2;
                                        padding: 10px;
                                        border-radius: 5px;
                                    }
                                </style>
                            </head>
                            <body>
                                <div class="frame">
                                    <div class="logo-container">
                                        <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                    </div>
                                    <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">


                                    <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                    <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a usar la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                    <p>Las actividades de control para prevenir delitos que podrán utilizar son:</p>
                                    <ol>
                                        <li>Declaración de Conflicto de Interés</li>
                                        <li>Registro de Reuniones con Funcionarios Públicos o Personas de Interés</li>
                                        <li>Transferencias de Valor</li>
                                        <li>Canal de Denuncias</li>
                                        <li>Repositorio con Procedimientos e Información del Modelo de Prevención de Delitos</li>
                                    </ol>
                                    <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                                    <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                                    <p>Usuario: ${csvEmails[i]}</p>
                                    <p>Contraseña: (Primeros 6 dígitos de su Rut o vaya a Recuperar Contraseña sino la recuerda)</p>
                                    </p>
                                    <p>Paso 2: En el menú de la izquierda vaya a Mis Declaraciones y haga click en Nueva Declaración, luego conteste su declaración y envíe.</p>
                                    <p>${values.link}</p>
                                    <p>Gracias por su atención y cooperación.</p>
                                    <p>Equipo Encargado de Prevención de Delitos.</p>
                                    <p>Eticpro.</p>
                                    <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                    <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                    <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
                                    </div>
                            </body>
                            </html>`;
                        

                                      
                                  } 
                                  if (values.opCampaign === "TV") {


                                    // Invitación usar Eticpro 
                                    subject = `Invitación de ${formData6.nombreempresa} a Declarar Transferencia de Valor en Eticpro`;

                                    emailTemplate = `
                                    <!DOCTYPE html>
                                    <html>
                                    <head>
                                        <style>
                                            .frame {
                                                border: 2px solid #ccc;
                                                padding: 20px;
                                                border-radius: 10px;
                                                width: 80%;
                                                margin: 0 auto;
                                                text-align: left; /* Align text to the left */
                                                position: relative; /* Add relative positioning to the frame */
                                            }
                                
                                            .logo-container {
                                                position: absolute;
                                                top: 10px; /* Adjust top value to position the logo vertically */
                                                right: 10px; /* Adjust right value to position the logo horizontally */
                                            }
                                
                                            /* Styles for the box containing the logo */
                                            .logo-box {
                                                text-align: center;
                                                margin-bottom: 20px;
                                            }
                                            .box {
                                                background-color: #f2f2f2;
                                                padding: 10px;
                                                border-radius: 5px;
                                            }
                                        </style>
                                    </head>
                                    <body>
                                        <div class="frame">
                                            <div class="logo-container">
                                                <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                            </div>
                                            <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                            <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                            <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus Transferencias de Valor en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                            
                                            <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                                            <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                                            <p>Usuario: ${csvEmails[i]}</p>
                                            <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
                                            <p>Paso 2: En el menú de la izquierda vaya a Mis Transferencias de Valor y haga click en Nueva Transferencia, luego conteste su declaración y envíe.</p>
                                            <p>${values.link}</p>
                                            <p>Gracias por su atención y cooperación.</p>
                                            <p>Equipo Encargado de Prevención de Delitos.</p>
                                            <p>Eticpro.</p>
                                            <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                            <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                            <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
                                            </div>
                                    </body>
                                    </html>`;
                                

                                      
                                  } 
                                  if (values.opCampaign === "PEP") {




                                    // Invitación usar Eticpro 
                                    subject = `Invitación de ${formData6.nombreempresa} a Declarar Reuniones en Eticpro`;

                                    emailTemplate = `
<!DOCTYPE html>
<html>
<head>
<style>
    .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left; /* Align text to the left */
        position: relative; /* Add relative positioning to the frame */
    }

    .logo-container {
        position: absolute;
        top: 10px; /* Adjust top value to position the logo vertically */
        right: 10px; /* Adjust right value to position the logo horizontally */
    }

    /* Styles for the box containing the logo */
    .logo-box {
        text-align: center;
        margin-bottom: 20px;
    }
    .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
    }
</style>
</head>
<body>
<div class="frame">
    <div class="logo-container">
        <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
    </div>
    <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

    <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
    <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus reuniones con personas de interés   en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
    
    <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
    <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
    <p>Usuario: ${csvEmails[i]}</p>
    <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
    <p>Paso 2: En el menú de la izquierda vaya a Mis Reuniones y haga click en Nueva Reunión, luego conteste su declaración y envíe.</p>
    <p>${values.link}</p>
    <p>Gracias por su atención y cooperación.</p>
    <p>Equipo Encargado de Prevención de Delitos.</p>
    <p>Eticpro.</p>
    <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
</div>
</body>
</html>`;


                                      
                                  } 
                                  if (values.opCampaign === "Read") {


                                    // Invitación usar Eticpro 
                                    subject = `Invitación de ${formData6.nombreempresa} a leer nuevo documento en Eticpro`;

            
emailTemplate = `
<!DOCTYPE html>
<html>
<head>
<style>
    .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left; /* Align text to the left */
        position: relative; /* Add relative positioning to the frame */
    }

    .logo-container {
        position: absolute;
        top: 10px; /* Adjust top value to position the logo vertically */
        right: 10px; /* Adjust right value to position the logo horizontally */
    }

    /* Styles for the box containing the logo */
    .logo-box {
        text-align: center;
        margin-bottom: 20px;
    }
    .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
    }
</style>
</head>
<body> 

<div class="frame">
<div class="logo-container">
<img src="${LogoImage}" alt="Logo" className="logo" style={{  width: '10%' }}/>
</div>
<img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

    <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
    <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a leer y confirmar entendimiento de un nuevo documento en el Repositorio para cumplir con los Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro.</p>
    
    <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
    <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
    <p>Usuario: ${csvEmails[i]}</p>
    <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
    <p>    Paso 2: En el menú de la izquierda vaya a Repositorio y haga click en los documento que estan pendientes por usted de confirmar lectura y entendimiento(Color Rojo). Los de color verde ya están confirmados por Usted.</p>
    <p>${values.link}</p>
    <p>Gracias por su atención y cooperación.</p>
    <p>Equipo Encargado de Prevención de Delitos.</p>
    <p>Eticpro.</p>
    <p><a href="www.eticpro.com">www.eticpro.com</a></p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
    <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
</div>
</body>
</html>`;
                                  } 




                               
                        const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({
                          emails: [csvEmails[i]],
                            emailTemplate,
                            subject,
                            mailserver: formData6.mailserver,
                            mailport: formData6.mailport,
                            mailuser: formData6.mailuser,
                            passmail: formData6.passmail,
                            cipherstype: formData6.cipherstype,
                          }),
                        });
                                  
                    
                            if (emailResponse.ok) {
                              // Email sent successfully
                              console.log(`Email sent successfully to ${csvEmails[i]}`);
                            } else {
                              // Error sending email
                              console.log(`Failed to send email to ${csvEmails[i]}`);
                            }
                                    
           
                                
                  } 
                   catch (error) {
  console.log(error);
  // Handle error
                   }
                  }

                
                      
                  if (postId) {

                    switch (values.opCampaign) {
                      case "CI":
                          subject = `Importante: ${formData6.nombreempresa} requiere que complete el formulario para cumplir con la legislación ley N°20.393`;
                  
                          emailTemplateTxt = `
                              Estimados(as) Colaboradores(as) y Directores(as),
                              Por requerimiento de ${formData6.nombreempresa}, le invitamos a completar el siguiente formulario en el siguiente link para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                              La información recopilada es esencial para que su organización pueda cumplir con la Ley #21.595. Su participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.
                              Link al formulario
                              Gracias por su atención y cooperación.
                              Equipo Encargado de Prevención de Delitos.
                              Eticpro.
                              www.eticpro.com
                              Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                              No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es}
                          `;
                          break;
                  
                      case "login":
                          subject = `Invitación de ${formData6.nombreempresa} a usar Eticpro`;
                  
                          emailTemplateTxt = `
                              Estimados(as) Colaboradores(as) y Directores(as),
                              Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a usar la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                              Las actividades de control para prevenir delitos que podrán utilizar son:
                              1. Declaración de Conflicto de Interés
                              2. Registro de Reuniones con Funcionarios Públicos o Personas de Interés
                              3. Transferencias de Valor
                              4. Canal de Denuncias
                              5. Repositorio con Procedimientos e Información del Modelo de Prevención de Delitos
                              Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:
                              https://compliax.onrender.com/
                              Usuario: 
                              Contraseña: (Primeros 6 dígitos de su Rut o vaya a Recuperar Contraseña sino la recuerda)
                              Paso 2: En el menú de la izquierda vaya a Mis Declaraciones y haga click en Nueva Declaración, luego conteste su declaración y envíe.
                              Gracias por su atención y cooperación.
                              Equipo Encargado de Prevención de Delitos.
                              Eticpro.
                              www.eticpro.com
                              Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                              No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es}
                          `;
                          break;
                  
                      case "TV":
                          subject = `Invitación de ${formData6.nombreempresa} a Declarar Transferencia de Valor en Eticpro`;
                  
                          emailTemplateTxt = `
                              Estimados(as) Colaboradores(as) y Directores(as),
                              Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus Transferencias de Valor en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                              Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:
                              https://compliax.onrender.com/
                              Usuario: }
                              Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)
                              Paso 2: En el menú de la izquierda vaya a Mis Transferencias de Valor y haga click en Nueva Transferencia, luego conteste su declaración y envíe.
                              Gracias por su atención y cooperación.
                              Equipo Encargado de Prevención de Delitos.
                              Eticpro.
                              www.eticpro.com
                              Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                              No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es }
                          `;
                          break;
                  
                      case "PEP":
                          subject = `Invitación de ${formData6.nombreempresa} a Declarar Reuniones en Eticpro`;
                  
                          emailTemplateTxt = `
                              Estimados(as) Colaboradores(as) y Directores(as),
                              Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus reuniones con personas políticamente expuestas  en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                              Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:
                              https://compliax.onrender.com/
                              Usuario: }
                              Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)
                              Paso 2: En el menú de la izquierda vaya a Mis Reuniones y haga click en Nueva Reunión, luego conteste su declaración y envíe.
                              Gracias por su atención y cooperación.
                              Equipo Encargado de Prevención de Delitos.
                              Eticpro.
                              www.eticpro.com
                              Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                              No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es }
                          `;
                          break;

                          case "Read":
subject = `Invitación de ${formData6.nombreempresa} a leer nuevo documento en Eticpro`;

                          emailTemplateTxt = `
                              Estimados(as) Colaboradores(as) y Directores(as),
                              Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a leer un nuevo documento en la plataforma ETICPRO. Este documento es de importancia y requerimos su revisión.
                              Paso 1: Para acceder al documento, por favor vaya al siguiente enlace seguro:
                              https://compliax.onrender.com/
                              Usuario: }
                              Contraseña: (Vaya a Recuperar Contraseña si no la recuerda)
                              Paso 2: Una vez iniciada sesión, podrá encontrar el documento en la sección correspondiente y revisarlo en detalle.
                              Agradecemos su atención y cooperación.
                              Equipo Encargado de Prevención de Delitos.
                              Eticpro.
                              www.eticpro.com
                              Para obtener soporte adicional, no dude en escribirnos a hello@eticpro.com o comunicarse por WhatsApp al +569 77657352.
                              Recuerde que puede acceder a nuestra plataforma en cualquier momento ingresando a www.eticpro.com, haciendo clic en el botón "Entrar", y utilizando su dirección de correo electrónico como usuario.
                          `;
                          break;
                    }
                  

                    const response9 = await fetch(`${REACT_APP_API_ENDPOINT}posts/${postId}`, {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    body: JSON.stringify({
                      link: emailTemplateTxt,
                    }),
                  });
                  
                    // Handle the response..
                    if (response9.ok) {
                      // Email sent successfully
                    } else {
                      // Error sending email
                      console.log(`Failed to save postid`);
                    }
                } else {
                    console.error("postId is not set");
                }
                switch (values.opCampaign) {
                  case "CI":
                    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampCI" });
                    break;
                  case "login":
                    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "Camplogin" });
                    break;
                  case "TV":
                    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampTV" });
                    break;
                  case "PEP":
                    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampPEP" });
                    break;
                  case "Read":
                    RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampRead" });
                    break;
                }

                onSubmitProps.resetForm();
                navigate("/successPage", {
                  state: { message: "Campaña repetida con éxito" }
              });                              
};

  const handleInfoIconClick = (event, infoKey) => {
    setInfoAnchorEl(event.currentTarget);
    setSelectedInfoKey(infoKey);
  };
  const handleInfoPopoverClose = () => {
    setInfoAnchorEl(null);
  };

  const infoPopoverContent = {
    CI: "Envía Formulario de Conflicto de interés y registra a cada usuario en Eticpro.",
    login: "Envía invitación a los usuarios a declarar sus reuniones y registra a cada usuario en Eticpro.",
    MAS: "Carga datos en forma masiva usando planilla .csv ",

    FILE: "Asegúrense de seguir estos pasos:\n\n1. No Modificar la Primera Fila: La primera fila de la planilla contiene encabezados importantes para cada columna, como 'nombres', 'apellidos', 'rut', 'email', 'organización' y 'área'. Estos encabezados son esenciales para identificar la información correcta en cada columna. No deben ser modificados ni alterados de ninguna manera.\n\n2. Agregar Datos de los destinatarios: A partir de la segunda fila en adelante, encontrarán filas en blanco. En estas filas, deberán llenar la información solicitada para cada destinatario y es mandatorio que tengan al menos su email. \n\n 3. Información Relevante: Ingrese  RUT de cada uno sin puntos ni guiones. Ingresen el nombre de la organización, área o departamento al que pertenecen."
};
  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };


  return (
    <Formik
    onSubmit={handleFormSubmit}
    initialValues={{ ...initialValuesEditCampaign, ...initialValuesRegister }}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
        
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{ borderRadius: "20px",
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
          <p></p>
          <TextField
            label={<FormattedMessage id="Nombre de la Campaña"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comments}
            name="comments"
            error={Boolean(touched.comments) && Boolean(errors.comments)}
            helperText={touched.comments && errors.comments}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4" }}
          />

            <TextField
            label={<FormattedMessage id="Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.link}
            name="link"
            error={Boolean(touched.link) && Boolean(errors.link)}
            helperText={touched.link && errors.link}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4" }}
          />

<Box>
<Typography variant="h5" gutterBottom>
{<FormattedMessage id="Tipo de Invitación"/>}

</Typography>
<RadioGroup
    aria-label="Opción"
    name="opCampaign"
    value={values.opCampaign}
    onChange={handleChange}
  >

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="login"
        control={<Radio />}
        label={<FormattedMessage id="Usar Eticpro(login)"/>}
        
      />
      <IconButton
        aria-label="info"
        size="small"
        onClick={(event) => handleInfoIconClick(event, "login")}
      >
        <InfoIcon />
      </IconButton>
    </Box>
    <Box display="flex" alignItems="center">
  <FormControlLabel
    value="CI"
    control={<Radio />}
    label={<FormattedMessage id="Declarar Conflicto de Interés(CI)"/>}
   
   />
  <IconButton
    aria-label="info"
    size="small"
    onClick={(event) => handleInfoIconClick(event, "CI")}
  >
    <InfoIcon />
  </IconButton>
</Box>

<Box display="flex" alignItems="center">
      <FormControlLabel
        value="TV"
        control={<Radio />}
        label={<FormattedMessage id="Hacer Transferencia de Valor(TV)"/>}
        
      />
      <IconButton
        aria-label="info"
        size="small"
        onClick={(event) => handleInfoIconClick(event, "TV")}
      >
        <InfoIcon />
      </IconButton>
    </Box>

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="PEP"
        control={<Radio />}
        label={<FormattedMessage id="Hacer Reuniones"/>}
        
      />
      <IconButton
        aria-label="info"
        size="small"
        onClick={(event) => handleInfoIconClick(event, "PEP")}
      >
        <InfoIcon />
      </IconButton>
    </Box>
    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="DueD"
        control={<Radio />}
        label={<FormattedMessage id="Hacer Diligencia Debida(DueD)"/>}
        
      />
      <IconButton
        aria-label="info"
        size="small"
        onClick={(event) => handleInfoIconClick(event, "DueD")}
      >
        <InfoIcon />
      </IconButton>
    </Box>

    <Box display="flex" alignItems="center">

    <FormControlLabel
        value="Read"
        control={<Radio />}
        label={<FormattedMessage id="Leer Documento(Read)"/>}
        
      />
      <IconButton
        aria-label="info"
        size="small"
        onClick={(event) => handleInfoIconClick(event, "Read")}
      >
        <InfoIcon />
      </IconButton>
    </Box>

</RadioGroup>
</Box>


<Popover
      open={Boolean(infoAnchorEl)}
      anchorEl={infoAnchorEl}
      onClose={handleInfoPopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Typography sx={{ borderRadius: "20px", p: 2 }}>
        {infoPopoverContent[selectedInfoKey]}
      </Typography>
    </Popover>
    <Dialog
  open={showInvalidRutPopup}
  onClose={() => setShowInvalidRutPopup(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"RUT Inválido"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {invalidRutMessage}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowInvalidRutPopup(false)} color="primary" autoFocus>
      Entendidos
    </Button>
  </DialogActions>
</Dialog>



<Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
 



            {/* Display Parsed CSV Data */}
            <Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
          
</Box>

</Box>


 </Box>

 {/* BUTTONS */}
 <Box>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ borderRadius: "20px",
              m: "2rem 0",
              p: "1rem",
              backgroundColor: theme.palette.primary.red,
              color: theme.palette.background.light,
              "&:hover": { color: theme.palette.primary.light },
            }}
            disabled={loading} // Disable the button when loading is true

          >
            {<FormattedMessage id="Enviar"/>}
          </Button>
{/* Loading Popup */}
<Dialog open={loading} onClose={() => {}}>
        <Box sx={{ borderRadius: "20px", p: 2, textAlign: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="body1" mt={2}>
          {<FormattedMessage id="Procesando datos..."/>}
          </Typography>
        </Box>
      </Dialog>

          <Box>

          </Box>
        </Box>
      </form>
    )}
  </Formik>
);
};

export default RepeatCampaign;
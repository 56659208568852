import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import ProgressCircle from './ProgressCircle';
import { FormattedMessage } from 'react-intl';


const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      alignItems="center"      // Center vertically
      height="100%"            // Make sure the container takes up the full height
    >
      <Box
        border="1px solid rgba(0, 0, 0, 0.12)"
        borderRadius="8px"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
        p="16px"
        bgcolor="white"
        mb="3px"
        mr="3px"
        display="inline-block"
        sx={{
          width: '200px',
          textAlign: 'center',  // Center text content
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            {icon}
            <Typography variant="h2" fontWeight="bold" sx={{ borderRadius: "20px", color: colors.grey[100] }}>
              {title}
            </Typography>
            <Typography variant="h5">{subtitle}</Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography variant="h5" fontStyle="italic"></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatBox;

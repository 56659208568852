import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import OfficeViewer from './OfficeViewer'; // Make sure the path is correct

const { REACT_APP_API_ENDPOINT } = process.env;

const PptViewerModal = ({ open, onClose, pdfUrl, filename, textStyleColor }) => {
  const [documents, setDocuments] = useState([]);
  const [agreement, setAgreement] = useState(false);
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef();

  useEffect(() => {


    if (open && pdfUrl) {
      setDocuments([{ uri: pdfUrl }]);
    }
  }, [open, pdfUrl]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [numPages]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'download.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleAgreementChange = (event) => {
    setAgreement(event.target.checked);
  };

  const handleClose = async () => {
    if (agreement) {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}session/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: loggedInUserId,
            fileName: filename,
            type: "Read",
            idcontrato: idcontrato,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to log confirmation');
        }
      } catch (error) {
        console.error('Error logging confirmation:', error);
      }
    }
    onClose();
  };


  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    
    // Scroll to the top of the previous page
    const container = containerRef.current;
    if (container) {
      const prevPageTop = container.scrollHeight / numPages * (prevPage - 1);
      container.scrollTop = prevPageTop;
    }
  };

  // Navigation handlers
   const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, numPages);
    setCurrentPage(nextPage);

    // Scroll to the top of the next page
    const container = containerRef.current;
    if (container) {
      const nextPageTop = (container.scrollHeight / numPages) * (nextPage - 1);
      container.scrollTop = nextPageTop;
    }
  };
 

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="ppt-viewer" aria-describedby="ppt-viewer-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -40%)',
          width: '100%',
          height: '110%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className="control-panel"
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: '#000',
            color: '#fff',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
          }}
        >
    {/* Navigation Buttons */}
    <Typography sx={{ color: '#fff' }}>{`Página ${currentPage} / ${numPages}`}</Typography>
   
          <Button onClick={handleDownload} sx={{ color: '#fff', marginLeft: 'auto' }}>Descargar</Button>
          <Button onClick={handleClose} sx={{ color: '#fff', marginLeft: '10px' }}>
            Cerrar
          </Button>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          {documents.length > 0 && (
            <OfficeViewer fileUrl={pdfUrl} page={currentPage} onDocumentLoadSuccess={onDocumentLoadSuccess} />
         
         )}
           <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {textStyleColor !== 'green' && textStyleColor !== undefined && (
            <>
              <Checkbox
                checked={agreement}
                onChange={handleAgreementChange}
                inputProps={{ 'aria-label': 'He leído y entiendo el documento' }}
              />
              <Typography variant="body1" sx={{ color: '#000' }}>He revisado el contenido de este documento.</Typography>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={!agreement}
                sx={{ ml: 2, backgroundColor: '#000', color: '#fff' }}
              >
                Confirmar
              </Button>
            </>
          )}
        </Box> 

        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
          <Button 
            onClick={handlePrevPage} 
            disabled={currentPage <= 1} 
            sx={{ color: '#000' }}
          >
            Anterior
          </Button>
          <Typography variant="body1" sx={{ color: '#000' }}>
            {currentPage} / {numPages}
          </Typography>
          <Button 
            onClick={handleNextPage} 
            disabled={currentPage >= numPages} 
            sx={{ color: '#000' }}
          >
            Siguiente
          </Button>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {textStyleColor !== 'green' && textStyleColor !== undefined && (
            <>
              <Checkbox
                checked={agreement}
                onChange={handleAgreementChange}
                inputProps={{ 'aria-label': 'He leído y entiendo el documento' }}
              />
              <Typography variant="body1" sx={{ color: '#000' }}>He leído y entiendo el contenido de este documento.</Typography>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={!agreement}
                sx={{ ml: 2, backgroundColor: '#000', color: '#fff' }}
              >
                Confirmar
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PptViewerModal;

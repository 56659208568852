import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";

const campaignSchema = yup.object().shape({
  // Define your validation schema here
  picture: yup.string().required("required"),

});


const greenText = { color: 'green' }; // Define the CSS style for green text

const Dropfiles = ({ _id, onFileUploaded }) => {
  const userprofile = useSelector((state) => state.user.userprofile);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);

  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  

  
  const initialValuesEditCampaign = {
    idcontrato: idcontrato, // Update the initial value
    picturePath:"",
    picture: "",
    description: "",

};

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setUploadedFiles(selectedFiles);
  };


  


    const getForm = async () => {

      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}repository/contract/${idcontrato}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

      
        const data = await response.json();
        setForm(data);
        setLoading(false);
        // Set the dialogues state
        const fetchedDialogues = data.dialogues || [];
        setDialogues(fetchedDialogues);

      } catch (error) {
        setError('An error occurred while fetching form data.');
        setLoading(false);
      }
    };

    useEffect(() => {
        getForm();
      }, []); // Empty dependency array
      
    

    const handleFormSubmit = async (values, onSubmitProps) => {
      RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "FileUpload" });

      // Fetch the form data first
      try {
        await getForm();
      } catch (error) {
        console.error('Error fetching form data:', error);
        return;
      }
    
      try {
        const formData = new FormData();
     // Append existing files as File objects
     if (form.picturePath && form.picturePath.length > 0) {
      form.picturePath.forEach((file) => {
      formData.append("picturePath", values.picture.name);
      formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
      formData.append('idcontrato', idcontrato);
      formData.append('userId', loggedInUserId);
      });
    }

    // Append newly uploaded files to S3
    if (uploadedFiles.length > 0) {

          // Define the uploadFileToS3 function
          const uploadFileToS3 = async (file) => {
            // Set up AWS S3 client


          // Load your AWS credentials fconsrom environment variables
          AWS.config.update({
          
          accessKeyId: REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
          region: "sa-east-1", // Set your AWS region
          });
            const s3 = new AWS.S3();

            // Configure the S3 bucket and key where you want to upload the file
            const bucketName = 'repoeticpro';
            const key = `${file.name}`; // Set the desired key for the file

            // Configure S3 upload parameters
            const params = {
              Bucket: bucketName,
              Key: key,
              Body: file, // The actual file object to upload
            };

  
  // Use the AWS S3 client to upload the file to S3
  try {
    await s3.upload(params).promise();
    console.log(`File uploaded to S3: ${key}`);
    return key; // Return the key for reference
  } catch (error) {
    console.error(`Error uploading file to S3: ${error.message}`);
    throw error;
  }
};


      const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
      // s3Urls will contain the S3 URLs of the uploaded files
      // Add these URLs to your data or database as needed
      formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
      formData.append('userId', loggedInUserId);
      formData.append("picturePath", values.picture.name);
    formData.append('idcontrato', idcontrato);
    }

        // Rest of your code for submitting the form
    
        // Example of saving user data using fetch
        const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}repository`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        const savedUser = await userResponse.json();

        // Rest of your code for handling the response
    
        // Reset the form after successful submission
        onSubmitProps.resetForm();
        getForm();
        onFileUploaded();

      } catch (err) {
        console.log("Error creating file:", err);
    
        if (err.response) {
          console.log("Response status:", err.response.status);
    
          // Use the .text() method to get the response body as text
          err.response.text().then(responseText => {
            console.log("Response data:", responseText);
          });
        }
      }
    };
    

  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
 <p></p>


    <Box>
    <Box display="flex">    

      <Box width="100%" >
        <Box flexBasis="50%" marginRight="1rem">
         
         {/* Check if the user is "comitetica" or "superadmin" */}
       
          
          
          <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                multiple={true}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("picture", acceptedFiles[0])
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.picture ? (
                      <p>Agregue  Archivo  Aquí .</p>
                    ) : (
                     <FlexBetween>
                            <Typography>{values.picture.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        )}
                  </Box>
                )}
              </Dropzone>
                </Box>
 {/* BUTTONS */}
 <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Guardar Archivos"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                Envio exitoso
              </Box>
            )}
    </Box>
          
    </Box>
    </Box>
    </Box>
    </Box>
  </form>
      )}
    </Formik>
);
};

export default Dropfiles;





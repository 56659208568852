import React, { useEffect, useState, useRef } from 'react';
import { Divider } from "@mui/material";
import { Checkbox } from "@mui/material";

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
    InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useReactToPrint } from "react-to-print";

const greenText = { color: 'green' }; // Define the CSS style for green text

const DdDetailWidget = ({ formData, formTotalDays }) => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the 
  const { REACT_APP_API_ENDPOINT } = process.env;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getForm = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}dd/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState

  };
 

  useEffect(() => {
    getForm();
  }, [token]); // Remove unnecessary square brackets

  

  if (!form) {
    return null;
  }

 
  const {
    

 //gral
 solicitante,
 estado,
 idcontrato,
 createdAt,

//dd ext contacto
rut,
telefono,
firstName,
lastName,
email,
location,
occupation,
area,


//cdd
opImplementado,
txtImplementado,
opCertificado,
txtCertificado,
opOficial,
txtOficial,
opCapacitaciones,
txtCapacitaciones,
opCapacitacionesproveedores,
txtCapacitacionesproveedores,
opCodigoetica,
txtCodigoetica,
opManualcumplimiento,
txtManualcumplimiento,
opCanaldenuncia,
txtCanaldenuncia,
opRepholding,
txtRepholding,
opChequeo,
txtChequeo,
opPep,
txtPep,
opInvestigacionI,
txtInvestigacionI,
opInvestigacionII,
txtInvestigacionII,
opTransmit,
txtTransmit,
confirmRead,

//checklist
txtComseeker,
opFuentesla,
txtFuentesla,
opInfodirecta,
txtInfodirecta,
opNegativa,
txtNegativa,
opDifdds,
txtDifdds,
opAntanalisis,
txtAntanalisis,

nationality,
nationalitycon,
nationalityrep,


//approval
aprobador,
opAuth,
txtAuth,
opMitigacion,
txtMitigacion,
idevaluacion,
dateval,
emailaviso,
dateChecklist,
updatedAt,

  } = form;



  
  const formatDate = (dateString) => {
     if (!dateString) {
    return "";
  }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };


  
  return (
    <WidgetWrapper m="2rem 0">
            <div ref={componentRef} className="card">

    <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Cuestionario de Debida Diligencia"/>} <span style={greenText}></span></Typography>        

    <Typography variant="h9" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{_id.slice(-0)}</span></Typography>        
    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Recibida:"/>} <span style={greenText}>{formatDate(createdAt)}</span></Typography>        
    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Demora:"/>} <span style={greenText}>{formTotalDays} días</span></Typography>        

    <Box/>


    
      <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>

  
   <Typography variant="h4">{<FormattedMessage id="Formulario de Declaración"/>}</Typography>
      <Typography variant="h6">{<FormattedMessage id="Ingrese sus Datos"/>}</Typography>
     

<Box>
  {/* New selection field for clients */}

          </Box>

          <p></p>
   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          {iscreateform && (
            <>
 <TextField
        fullWidth
        variant="filled"
        type="text"
        label={<FormattedMessage id="Rut"/>}
        value={rut}
        InputProps={{ readOnly: true }}
        sx={{ borderRadius: "20px", gridColumn: "span 1" }}
      />


<TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Nombres"/>}
              value={firstName}
              InputProps={{ readOnly: true }}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Apellidos"/>}
              InputProps={{ readOnly: true }}
              value={lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Empleador u organización"/>}
              InputProps={{ readOnly: true }}
              value={location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Area"/>}
              InputProps={{ readOnly: true }}

              value={area}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Cargo"/>}
              InputProps={{ readOnly: true }}

              value={occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Celular"/>}
              InputProps={{ readOnly: true }}
              value={telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />
 


            </>

      
          )}
 </Box>
<Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>
<div>

{<FormattedMessage id="1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opImplementado}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtImplementado}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>


{<FormattedMessage id="2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opCertificado}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtCertificado}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>
  
   {<FormattedMessage id="3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opOficial}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtOficial}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación."/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opCapacitaciones}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtCapacitaciones}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opCapacitacionesproveedores}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtCapacitacionesproveedores}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opCodigoetica}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtCodigoetica}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>


   {<FormattedMessage id="7. ¿Su organización tiene un canal de denuncias?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opCanaldenuncia}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtCanaldenuncia}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opRepholding}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtRepholding}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opChequeo}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtChequeo}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="10. ¿Algún Gerente, ejecutivo de la alta administración, superior jerárquico  o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opPep}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtPep}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opInvestigacionI}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtInvestigacionI}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración,  superior jerárquico o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opInvestigacionII}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtInvestigacionII}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>

   {<FormattedMessage id="13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?"/>}
<RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opTransmit}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>

<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
       
                InputProps={{ readOnly: true }}
                variant="filled"
                value={txtTransmit}
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 1"
              }}
              
              />
   </div>


<FormControlLabel
  control={
    <Checkbox
      checked={  <span style={{ fontSize: '20px' }}>✓</span>
    }
      disabled={true} // Disable the checkbox
      name="confirmRead"
      color="primary"
    />
  }
  label={<FormattedMessage id="He leído y comprendido la información anterior."/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>

</div>
</Box>
       
      
</Box>



<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Resultado Resolución : "/>} <span style={greenText}> </span></Typography>        
   

        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Resultado Aprobacion : "/>}  <span style={greenText}>{opAuth}, {txtAuth}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Mitigación: "/>} <span style={greenText}>{opMitigacion}, {txtMitigacion}</span></Typography>        
    
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Aprobador : "/>} <span style={greenText}>{aprobador.firstName} {aprobador.lastName}, {aprobador.email}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Fecha Checklist : "/>} <span style={greenText}>{dateChecklist}</span></Typography>        
        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Fecha Aprobación : "/>} <span style={greenText}>{dateval}</span></Typography>        

        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID :"/>} <span style={greenText}>{idevaluacion}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Email Aviso : "/>} <span style={greenText}>{emailaviso}</span></Typography>        

        </Box>

        <Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
  <Button
    variant="contained"
    startIcon={<Print />}
    onClick={handlePrint}
  >
    {<FormattedMessage id="Imprimir en PDF"/>}
  </Button>
</Box>
   
        </div>

</WidgetWrapper>


);
};

export default DdDetailWidget;







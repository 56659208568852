import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../../assets/eticprologo1.png';

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <>
      {/* Header */}
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        bgcolor={theme.palette.background.alt}
        py={2}
        index={2} // Set a higher z-index to bring the header to the front
      >


      </Typography>



      {/* Login Form */}
      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
        mt={8} // Add margin top to create space for the header
        zIndex={1} // Set a lower z-index for the login form box
      >
        <Typography fontWeight="500" variant="h5" sx={{ borderRadius: "20px", mb: "1.5rem" }}>
    
        </Typography>
        <Form />
      </Box>
    </>
  );
};

export default LoginPage;
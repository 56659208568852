import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FlexBetween from "./FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AWS from 'aws-sdk';
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
accessKeyId: REACT_APP_ACCESS_KEY_ID,
secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
region: "sa-east-1", // Set your AWS region
});

// Create an S3 object
const s3 = new AWS.S3();

const currentDate = new Date();
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateDuediligencePostulante = ({ _id }) => {
  
  const location = useLocation();
  const loggedinUseremail = useSelector((state) => state.user.email);
  const loggedinusercontrato = useSelector((state) => state.user.idcontrato);
  const userId = useSelector((state) => state.user._id);
  const [dds, setDds] = useState([]); // Initialize as an empty array
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 
  const userprofile = useSelector((state) => state.user.userprofile); 

  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [idcompanyx, setIdcompanyx] = useState([]);
  const [picturePathbk, setPicturePathbk] = useState([]);

  const [score, setScore] = useState(0); // State to hold the score
  const [risk, setRisk] = useState(0); // State to hold the score
  const [emailTemplate, setemailTemplate] = useState([]); // State to store the parsed CSV data
  const [valuex, setValues] = useState({
    emailcontacto: '', // your other state variables
  });
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [company, setCompany] = useState([]); // State to hold the list of company
  const loggedInUserId = useSelector((state) => state.user._id);
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const { palette } = useTheme();
  const [idcontrato, setIdcontrato] = useState(""); // Add this state
  const [form, setForm] = useState({ picturePath: "" }); // Initialize as an empty object 
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [newPostulante, setNewPostulante] = useState('');

  const [showInfo, setShowInfo] = useState(false);
  const [showInfo2, setShowInfo2] = useState(false);
  const loggedclient = useSelector((state) => state.client);

  const countries = ['Chile',
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
    'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
    'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon',
    'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica',
    'Cote d\'Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czechia (Czech Republic)', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti',
    'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini (fmr. "Swaziland")',
    'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea',
    'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel',
    'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
    'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands',
    'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar (formerly Burma)',
    'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia (formerly Macedonia)',
    'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
    'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
    'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
    'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates',
    'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',
  ];
  
  const campaignSchema = yup.object().shape({

    rutcontacto: yup.string().when('emailcontacto', {
      is: 'Nuevo Postulante',
      then: yup.string().required('RUT Contacto es requerido'),
      otherwise: yup.string(),
    }),
  });
  
  
  
  
  
  const handleInfoToggle = () => {
    setShowInfo(prev => !prev);
  };

  const campaignSchema1 = yup.object().shape({
   
 
  });

  const initialValuesEditCampaign = {
   
    solicitante: loggedInUserId,
    estado: "Por Evaluar",
    idcontrato: loggedinusercontrato, // Update the initial value
    emailaviso: loggedinUseremail,
  
//user
postulante: true,
firstName: "",
lastName: "",
email: "",
password: "",
userprofile: "ddpostulante",
idcontrato: "",
campaignid: "",
rut: "",
celular: "",
status: "Por Evaluar",


 //Organización
 idcompany: "",



 firstNameContacto: "",
 lastNameContacto: "",
 rutcontacto: "",
 nationalitycon: "",

 emailcontacto: "",
 userIdcontacto: "",

 celularcontacto: "",


 reason: "",
 comments: "",



//approval
opAuth: "",
txtAuth: "",
opMitigacion: "",
txtMitigacion: "",

picturePath: [],

  };
  
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}postulante/contract/${loggedinusercontrato}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setDds(data);
        } else {
          // Handle error when fetching clients
          console.log("Failed to fetch clients");
        }
      } catch (error) {
        console.log(error);
        // Handle error
      }
    };

    fetchClients();
  }, [token]);

  const fetchCompanyData = async (companyId) => {
    try {
        
     const response = await fetch(`${REACT_APP_API_ENDPOINT}postulante/${companyId}`, {
     
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
         },
       });
 
       if (response.ok) {
         const companyData = await response.json();
         setSelectedCompanyData(companyData);
         console.log("companydata", companyData);
       } else {
         console.error('Error fetching company data:', response.status, response.statusText);
       }
     } catch (error) {
       console.error('Error fetching company data:', error);
     }
   };

  const handleFormSubmit = async (values, onSubmitProps) => {
        setForm(uploadedFiles);

     

  try {
  const formData = new FormData();

  let locationValue;

    if (values.emailcontacto === 'Nuevo Postulante') {
      locationValue = newPostulante;
    } else {
      locationValue = values.emailcontacto;
    }


      const password = values.rutcontacto.substring(0, 6); // Get the first 6  digits of the "rut" as the password

      let selectedCompany;

    // Fetch company data if it's an existing company
    if (values.emailcontacto !== 'Nuevo Postulante') {
      selectedCompany = dds.find(
        (company) => company.emailcontacto === values.emailcontacto
      );

      if (selectedCompany) {
        await fetchCompanyData(selectedCompany._id);
      }
    }
    const estado = "En Evaluacion";

    let companyDataToPost;

    // Use the fetched company data if it's an existing company
    if (selectedCompany && selectedCompanyData) {
      companyDataToPost = {
     
        solicitante: loggedInUserId,
        estado: estado,
        idcontrato: loggedinusercontrato,
        

        // Use the fetched company data
        emailcontacto: selectedCompanyData.emailcontacto,
        firstNameContacto: selectedCompanyData.firstNameContacto,
        lastNameContacto: selectedCompanyData.lastNameContacto,
        nationalitycon: selectedCompanyData.nationalitycon,
        rutcontacto: selectedCompanyData.rutcontacto,
        celularcontacto: selectedCompanyData.celularcontacto,
        reason: selectedCompanyData.reason,
        comments: selectedCompanyData.comments,
        idcompany: selectedCompanyData._id,

      };
      

    } else {

      // Use the original form values
      companyDataToPost = {
        solicitante: loggedInUserId,
        estado: estado,
        idcontrato: loggedinusercontrato,

        emailcontacto: values.emailcontacto === 'Nuevo Postulante' ? newPostulante : values.emailcontacto,
    
        firstNameContacto: values.firstNameContacto,
        lastNameContacto: values.lastNameContacto,
        nationalitycon: values.nationalitycon,
        rutcontacto: values.rutcontacto,

        celularcontacto: values.celularcontacto,
        reason: values.reason,
        comments: values.comments,
        pictures: formData.pictures,
      };
    }


       // Append existing files as File objects
         if (form.picturePath && form.picturePath.length > 0) {
          form.picturePath.forEach((file) => {
          formData.append('pictures', s3Urls); // You can store the S3 URLs in your data
     
          });
        }

    // Append newly uploaded files to S3
    if (uploadedFiles.length > 0) {

          // Define the uploadFileToS3 function
          const uploadFileToS3 = async (file) => {
            AWS.config.update({
            accessKeyId: REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
            region: "sa-east-1", // Set your AWS region
            });
            const s3 = new AWS.S3();

            // Configure the S3 bucket and key where you want to upload the file
            const bucketName = 'repoeticpro';
            const uniqueSuffix = Date.now() + "-";
            const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
       
            // Configure S3 upload parameters
            const params = {
              Bucket: bucketName,
              Key: key,
              Body: file, // The actual file object to upload
            };


  // Use the AWS S3 company to upload the file to S3
  try {
    await s3.upload(params).promise();
    const uploadResult = await s3.upload(params).promise();
    
    console.log(`File uploaded to S3: ${uploadResult.key}`);

    // Ensure that picturePath is an array (initialize if undefined)
    companyDataToPost.picturePath = companyDataToPost.picturePath || [];
    
    // Add the key to the picturePath array
    companyDataToPost.picturePath.push(uploadResult.key);

    return uploadResult.key; // Return the key for reference


  } catch (error) {
    console.error(`Error uploading file to S3: ${error.message}`);
    throw error;
  }

};

const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
console.log("s3Urls", s3Urls);


}
// Make the POST request with the selected company data or original form values
const companyResponse = await fetch(`${REACT_APP_API_ENDPOINT}postulante`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(companyDataToPost),
});

const responseData5 = await companyResponse.json();
if (responseData5.status === 200){



companyDataToPost.idcompany = responseData5._id;
console.log("se guardo postulante", responseData5._id);

setIdcompanyx(responseData5._id);
}


 
 else {

  const companyResponserut = await fetch(`${REACT_APP_API_ENDPOINT}postulante/rut/${companyDataToPost.rutcontacto}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

  });

  const responseDatarut = await companyResponserut.json();
  setIdcompanyx(responseDatarut._id);
  console.log("se busco rut existente", companyDataToPost.rutcontacto);
  console.log("companydataxx",   companyDataToPost);

}

//crea usuario
  const userResponse2 = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password: password,
      firstName: companyDataToPost.firstNameContacto,
      lastName: companyDataToPost.lastNameContacto,
      email: companyDataToPost.emailcontacto,
      userprofile: "postulante",
      idcontrato: loggedinusercontrato,
      celular: companyDataToPost.celularcontacto,
      status: "PostulanteDD",
      rut: companyDataToPost.rutcontacto,
      reason: companyDataToPost.reason,
      comments: companyDataToPost.comments,
      nationalitycon: companyDataToPost.nationalitycon,

    }),


  });
  const response2 = await userResponse2.json();
  let userIdd = null; // Declare idcompany in an outer scope
  userIdd = response2._id; // Assign a value to idcompany
  companyDataToPost.postulanteId = response2._id;


      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}ddPostulante`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...companyDataToPost, nombrecliente: loggedclient.nombreempresa,
        }),  
      
      });


      const responseData = await userResponse.json();



      if (responseData.ok) {
        const { _id: ddId } = userResponse;

        setShowSuccessPopup(true);
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 5000);


       

     
      } else {
      console.error("Error storing user data:", userResponse.status, userResponse.statusText);
      }
      RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CreateDueDPostulante" });

      onSubmitProps.resetForm();
      navigate("/successPage", {
        state: { message: "Cuestionario enviado con éxito a Postulante " }
    });


    } catch (err) {
      console.log("Error creating dd:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
  
        // Use the .text() method to get the response body as text
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
  };
 

  


  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 
<Box>
<FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
    <InputLabel>{<FormattedMessage id="Seleccionar  Email del Postulante"/>}</InputLabel>

    <Select
            label={<FormattedMessage id="Email de Postulante que se hará la inteligencia debida"/>}
            value={values.emailcontacto}
            name="emailcontacto"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);

              const selectedCompany = dds.find(
                (company) => company.emailcontacto === e.target.value
              );

              if (selectedCompany || e.target.value === 'Nuevo Postulante') {
                setValues((prevValues) => ({ ...prevValues, emailcontacto: e.target.value }));
                setNewPostulante(e.target.value);

                // Fetch company data if it's an existing company
                if (selectedCompany) {
                  console.log("companyselected", selectedCompany._id);
                  fetchCompanyData(selectedCompany._id);
                }
              }
            }}
      
      
      error={Boolean(touched.emailcontacto) && Boolean(errors.emailcontacto)}
    >
      <MenuItem value="">
        <em>{<FormattedMessage id="Seleccionar  Postulante"/>}</em>
      </MenuItem>

      {[...new Set(dds.map(company => company.emailcontacto))].map((emailcontacto, index) => (
  <MenuItem key={index} value={emailcontacto}>
    {emailcontacto} {/* You may include additional information here */}
  </MenuItem>
))}





      <MenuItem value="Nuevo Postulante">{<FormattedMessage id="Nueva Nuevo Postulante"/>}</MenuItem>
    </Select>

    {/* Campo de entrada para la Nuevo Postulante */}
    {values.emailcontacto === "Nuevo Postulante" && (
      <>

<TextField
          label={<FormattedMessage id="Email Postulante"/>}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setValues((prevValues) => ({ ...prevValues, emailcontacto: e.target.value }));
            setNewPostulante(e.target.value);
          }}
          value={newPostulante === undefined ? '' : newPostulante}
          name="newPostulante"
          error={Boolean(touched.emailcontacto) && Boolean(errors.emailcontacto)}
          fullWidth
          sx={{
            borderRadius: "20px",
            gridColumn: "span 4",
            '& input': {
              background: 'white',
            },
          }}
        />

<TextField
        label={<FormattedMessage id="Rut/ID del Postulante"/>}
        onBlur={handleBlur}
        onChange={(event) => {
          // Use a regular expression to allow only numbers and no dots or characters
          const newValue = event.target.value.replace(/[^0-9K]/gi, '');
          handleChange({ target: { name: 'rutcontacto', value: newValue } });
        }}
        value={values.rutcontacto}
        name="rutcontacto"
        error={Boolean(touched.rutcontacto) && Boolean(errors.rutcontacto)}
        helperText={touched.rutcontacto && errors.rutcontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />



<TextField
        label={<FormattedMessage id="Nombres del Postulante"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.firstNameContacto}
        name="firstNameContacto"
        error={Boolean(touched.firstNameContacto) && Boolean(errors.firstNameContacto)}
        helperText={touched.firstNameContacto && errors.firstNameContacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

<TextField
        label={<FormattedMessage id="Apellidos del Postulante"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.lastNameContacto}
        name="lastNameContacto"
        error={Boolean(touched.lastNameContacto) && Boolean(errors.lastNameContacto)}
        helperText={touched.lastNameContacto && errors.lastNameContacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />

 
      




<FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
    <InputLabel id="nationalitycon-label">{<FormattedMessage id="Nacionalidad Postulante"/>}</InputLabel>
    <Select
      labelId="nationalitycon-label"
      id="nationalitycon"
      name="nationalitycon"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationalitycon}
      error={Boolean(touched.nationalitycon) && Boolean(errors.nationalitycon)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationalitycon && errors.nationalitycon && (
      <FormHelperText error>{errors.nationalitycon}</FormHelperText>
    )}
  </FormControl>






      <TextField
        label={<FormattedMessage id="Telefono del Postulante"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.celularcontacto}
        name="celularcontacto"
        error={Boolean(touched.celularcontacto) && Boolean(errors.celularcontacto)}
        helperText={touched.celularcontacto && errors.celularcontacto}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />


     

      <TextField
        label={<FormattedMessage id="Razón de Contratación del Postulante"/>} 
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.reason}
        name="reason"
        error={Boolean(touched.reason) && Boolean(errors.reason)}
        helperText={touched.reason && errors.reason}
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />




      <TextField
        label={<FormattedMessage id="Comentarios Adicionales"/>}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.comments}
        name="comments"
        error={Boolean(touched.comments) && Boolean(errors.comments)}
        helperText={touched.comments && errors.comments}
        multiline
        fullWidth
        sx={{
          borderRadius: "20px",
          gridColumn: "span 4",
          '& input': {
            background: 'white',
          },
        }}
      />
    </>
  )}



</FormControl>


 </Box>

          <p></p>

          <Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>



</Box>

   <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
                <Dropzone
                onDrop={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                  });
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p>{<FormattedMessage id="Agregue aquí los Archivos , máximo 5Mb"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>




                </Box>

                <div>
    

   
    </div>
          
        {/* BUTTONS */}
        <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
              {<FormattedMessage id="Envio exitoso"/>}
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateDuediligencePostulante;
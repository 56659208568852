import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider, tableBodyClasses } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";
   import Grid from "@mui/material/Grid";
import StatBoxnumber from "../../components/statboxnumber";

import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Alert from '@mui/material/Alert';

const { REACT_APP_API_ENDPOINT } = process.env;


const ItemTypes = {
  RISK: "risk",
};


const RiskItem = ({ risk, onRiskDrop, onDeleteRisk }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RISK,
    item: { type: ItemTypes.RISK, risk },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });


  const handleDeleteClick = () => {
    onDeleteRisk(risk);
  };
  
    return (
      <div
        ref={drag}
        onClick={() => onRiskDrop(risk)}
        style={{
          opacity: isDragging ? 0.5 : 1,
          padding: "8px",
          border: "1px solid #ddd",
          marginBottom: "8px",
          cursor: "move",
        }}
      >
<Typography>{risk.name}</Typography>
      <IconButton onClick={handleDeleteClick}>
        <DeleteIcon />
      </IconButton>   
         </div>
    );
  };

  
  const RiskMatrixCell = ({ onRiskDrop, risks, rowIndex, colIndex, resetMatrix }) => {
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.RISK,
      drop: (item) => onRiskDrop(item.risk, rowIndex, colIndex),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });
  
    const [, drag] = useDrag({
      type: ItemTypes.RISK,
      item: { type: ItemTypes.RISK, risk: risks },
    });
  
    return (
      <div
        ref={(node) => drop(drag(node))}
        style={{
          border: "2px solid #000",
          height: "130px",
          width: "180px",
          textAlign: "center",
          backgroundColor: isOver ? "orange" : "white",
        }}
      >
        {risks.map((risk, index) => (
          <div key={index}>{risk.name}</div>
        ))}
       
      </div>
    );
  };
  
 
  
const RiskMatrix = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [newRisk, setNewRisk] = useState("");

  const token = useSelector((state) => state.token);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userprofile);
  const navigate = useNavigate();
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedColIndex, setSelectedColIndex] = useState(null);
  const [calculatedScore, setCalculatedScore] = useState(0); // New state for score
  const [availableRisks, setAvailableRisks] = useState([]);


  const deleteRisk = async (riskToDelete) => {
    try {
      const endpoint = `${REACT_APP_API_ENDPOINT}risklist/${riskToDelete._id}`;
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        // If the deletion is successful, update the availableRisks state by removing the deleted risk
        setAvailableRisks((prevRisks) => prevRisks.filter((risk) => risk._id !== riskToDelete._id));
      } else {
        console.error('Failed to delete risk:', response.statusText);
        // Handle the error appropriately
      }
    } catch (error) {
      console.error('Error deleting risk:', error.message);
      // Handle the error appropriately
    }
  };
  


    const [risks, setRisks] = useState([]);
 
    const [resetMatrix, setResetMatrix] = useState(false); // New state for reset

    const userId = useSelector((state) => state.user._id);

    const initialValuesEditCampaign = {

      userId: userId,
      idcontrato: idcontrato,
      risk: "",
      severity: "",
      freq: "",
      control: "",
      estadomatriz: "",
      comment: "",
      score: "",
    
    };

    const [fetchedRisks, setFetchedRisks] = useState([]);

    useEffect(() => {
      const fetchAvailableRisks = async () => {
        try {
          const endpoint = `${REACT_APP_API_ENDPOINT}risklist/contract/${idcontrato}`;
          const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (response.ok) {
            const risksData = await response.json();
            setAvailableRisks(risksData);
          } else {
            console.error('Failed to fetch available risks:', response.statusText);
            // You might want to handle this error, set an error state, or display an error message to the user
          }
        } catch (error) {
          console.error('Error fetching available risks:', error.message);
          // You might want to handle this error, set an error state, or display an error message to the user
        }
      };
    
      // Call the fetch function when the component mounts
      fetchAvailableRisks();
    }, [token, idcontrato]); // Dependency on 'token' and 'idcontrato'
    

    const calculateTotalScore = (risks) => {
      let totalScore = 0;
    
      // Iterate through each cell in the matrix
      risks.forEach((cell) => {
        // Iterate through each item within the cell
        cell.items.forEach((item) => {
          // Calculate the score for the current item based on its position
          const itemScore = (cell.rowIndex + 1) * (cell.colIndex + 1);
          // Add the item score to the total score
          totalScore += itemScore;
        });
      });
    
      // Log the total score
      console.log("Total Score:", totalScore);
    
      return totalScore;
    };
    
    

// Function to move an item within the matrix
const moveItem = (risk, rowIndex, colIndex) => {
  setRisks((prevRisks) => {
    const updatedRisks = [...prevRisks];

    // Find the index of the existing risk at the target position
    const existingRiskIndex = updatedRisks.findIndex(
      (r) => r.rowIndex === rowIndex && r.colIndex === colIndex
    );

    // If there's an existing risk at the target position
    if (existingRiskIndex !== -1) {
      // Add the new risk to the existing risk's items array
      updatedRisks[existingRiskIndex].items.push({ ...risk });
    } else {
      // If there's no existing risk, create a new risk entry
      updatedRisks.push({ rowIndex, colIndex, items: [{ ...risk }] });
    }

    // Calculate the score based on the updated risks
    const updatedScore = calculateTotalScore(updatedRisks);
    setCalculatedScore(updatedScore);

    return updatedRisks;
  });

  setAvailableRisks((prevRisks) =>
    prevRisks.filter((item) => item.name !== risk.name)
  );
};



// Function to add a new risk to the matrix
const addNewRisk = async () => {
  try {
    if (newRisk.trim() !== "") {
      // Make a POST request to add the new risk to the risk list
      const endpoint = `${REACT_APP_API_ENDPOINT}risklist`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newRisk, idcontrato: idcontrato, userId: userId, estado: "Pendiente"}),
      });

      if (response.ok) {
        // If the request is successful, update the availableRisks state
        setAvailableRisks((prevRisks) => [...prevRisks, { name: newRisk }]);
        setNewRisk("");
      } else {
        console.error('Failed to add new risk:', response.statusText);
        // Handle the error appropriately
        setErrorMessage("Riesgo ya existe, use otro nombre.");
      }
    }
  } catch (error) {
    console.error('Error adding new risk:', error.message);
    // Handle the error appropriately
    setErrorMessage("An error occurred while adding the new risk.");
  }
};

// Function to reset the matrix
const resetMatrixHandler = async () => {
  try {
    // Fetch the available risks from the server
    const endpoint = `${REACT_APP_API_ENDPOINT}risklist/contract/${idcontrato}`;
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const risksData = await response.json();
      // Update the availableRisks state with fetched risks
      setAvailableRisks(risksData);
      // Reset the risks state to empty array
      setRisks([]);
      // Reset the calculated score to 0
      setCalculatedScore(0);
      // Set resetMatrix state to true
      setResetMatrix(true);
    } else {
      console.error('Failed to fetch available risks:', response.statusText);
      // Handle the error appropriately
    }
  } catch (error) {
    console.error('Error fetching available risks:', error.message);
    // Handle the error appropriately
  }
};

// Function to save the risk matrix
const saveRisk = async () => {
  try {
    const endpoint = `${REACT_APP_API_ENDPOINT}risks/`;

    if (risks.length === 0) {
      console.error('No risks to save');
      return;
    }

    const requestBody = {
      userId: userId,
      idcontrato: idcontrato,
      estadomatriz: "Pendiente",
      riskData: risks, // Array of risks
      score: calculatedScore, // Include the calculated score
    };

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const responseBody = await response.json();

    if (response.ok) {
      console.log('Risks saved successfully!');
      // You can update the UI or perform other actions if needed
      navigate("/successPage", {
        state: { message: "Riesgos guardados con éxito" }
    });

    } else {
      console.error('Failed to save risks:', response.statusText);
      // Handle the error appropriately
    }
  } catch (error) {
    if (error.code === 11000) {
      // If the error is due to a duplicate name
      throw new Error("El riesgo ya existe. Debe guardar con otro nombre.");
    } else {
      // For other errors, rethrow the original error
      throw error;
    }
  }
};

// Call the functions as needed within your component

      

      


    const renderMatrix = () => {
        const consequences = ["Menor", "Moderado", "Mayor"]; // Updated to Spanish
        const likelihoods = ["Frecuencia Baja", "Frecuencia Media", "Frecuencia Alta"]; // Updated to Spanish
    
        return (
          <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "4px" }}>
            {/* Empty cell for the top-left corner */}
            <div style={{ border: "0px solid #000", height: "30px", width: "12px" }}></div>
    
            {/* Consequence labels */}
            {consequences.map((consequence, rowIndex) => (
              <div
                key={`consequence-label-${rowIndex}`}
                style={{
                  border: "2px solid #000",
                  height: "30px",
                  width: "180px",
                  textAlign: "center",
                }}
              >
                {consequence}
              </div>
            ))}
    
            {likelihoods.map((likelihood, colIndex) => (
              <React.Fragment key={`likelihood-label-${colIndex}`}>
                {/* Likelihood labels */}
              
                  {likelihood}
             
    
                {/* RiskMatrixCell components */}
                {consequences.map((consequence, rowIndex) => (
                  <RiskMatrixCell
                    key={`${consequence}-${likelihood}`}
                    onRiskDrop={(risk) => moveItem(risk, rowIndex, colIndex)}
                    risks={risks.find((r) => r.rowIndex === rowIndex && r.colIndex === colIndex)?.items || []}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    resetMatrix={resetMatrix}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        );
      };
    

      
      return (

        <div style={{ background: "white", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
   <Typography variant="h2">{<FormattedMessage id="Matriz de Riesgo"/>}</Typography>

    <div>


<Typography variant="h6"></Typography>

</div>
<div style={{ background: "white", minHeight: "100vh", display: "flex", flexDirection: "row" }}>

        <DndProvider backend={HTML5Backend}>
    
    
          <div style={{ border: '2px solid #000', padding: '10px', margin: '20px', textAlign: 'center' }}>
            {/* Wrapping the matrix in a box with border, padding, and margin */}
            
            <div style={{ flex: 1 }}>
       <h2>Riesgos Disponibles</h2>
            {availableRisks.map((risk, index) => (
                <RiskItem key={index} risk={risk} onRiskDrop={moveItem} onDeleteRisk={deleteRisk} />
                ))}
            {/* Add TextField for entering a new risk */}
            <TextField
              label="Nuevo Riesgo"
              variant="outlined"
              value={newRisk}
              onChange={(e) => setNewRisk(e.target.value)}
              style={{ marginTop: '10px' }}
            />
            {/* Add Button to add the new risk */}
            <p></p>
            <Button variant="contained" color="primary" onClick={addNewRisk} style={{ marginTop: '10px' }}>
              Agregar Riesgo
            </Button>
            </div>
          </div>
          <Box p={3} border="1px solid" borderRadius={8} flex={1}>


          <div style={{ textAlign: 'center' }}>
             <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
              Score: {calculatedScore}
            </Typography>
            <h2>Gravedad</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {renderMatrix()}
          </div>
          {errorMessage && (
  <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage("")}>
      <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
      </Alert>
  </Snackbar>
)}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button onClick={resetMatrixHandler}>Reset</button>
            {/* Add Save button */}
            <button onClick={() => saveRisk(selectedRisk, selectedRowIndex, selectedColIndex)}>Guardar</button>
          </div>
          </Box>


         
        </DndProvider>
        </div>
      
        </div>

      );
    };
    

    export default RiskMatrix;
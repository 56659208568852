// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import RiskevPage from "../riskevPage";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";   import Grid from "@mui/material/Grid";
import StatBoxnumber from "../../components/statboxnumber";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";

import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MuiAlert from '@mui/material/Alert';


const Myrisks = () => {
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const [snackbarMessage, setSnackbarMessage] = useState('');

// Inside the RiskMatrix component
const [isModalOpen, setIsModalOpen] = useState(false);
const [riskName, setRiskName] = useState("");
const [description, setDescription] = useState("");
const [newRisk, setNewRisk] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const userId = useSelector((state) => state.user._id);

  const idcontrato = useSelector((state) => state.user.idcontrato);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile);
  const [forms, setForms] = useState(null);
  const [availableRisks, setAvailableRisks] = useState([]);

  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const loggedInUserprof = useSelector((state) => state.user.userprofile); 
  const [formTotalDays, setformTotalDays] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isDeleteMade, setIsDeleteMade] = useState(false);



  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };

  const fetchAvailableRisks = async () => {
    try {
      const endpoint = `${REACT_APP_API_ENDPOINT}risklist/contract/${idcontrato}`;
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const risksData = await response.json();
        setAvailableRisks(risksData);
      } else {
        console.error('Failed to fetch available risks:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching available risks:', error.message);
    }
  };


    const handleRowClickrisk = (params, action) => {
      const rowId = params.row._id;
    
     if (action === "delete") {
        // Add code to delete the row from the database using the rowId
        deleteRowRisk(rowId);
      }
   
   
    };


    const handleRowClick = (params, action) => {
      const rowId = params.row._id;
    
      if (action === "edit") {
        const { row, field } = params;
        const updatedRow = { ...row, [field]: params.value };
    
        // Update the local state immediately for a responsive user experience
        setData((prevData) => {
          const rowIndex = prevData.findIndex((r) => r.id === rowId);
          const newData = [...prevData];
          newData[rowIndex] = { ...newData[rowIndex], ...updatedRow };
          return newData;
        });
    
        // Save the changes to the server in the background
        saveRowChanges(rowId, updatedRow);
      } else if (action === "delete") {
        // Add code to delete the row from the database using the rowId
        deleteRow(rowId);
      }
    };

    const saveRowChanges = async (rowId, updatedRow) => {
      console.log("Updated data1:", updatedRow); // Log the updated data to the console
    
      try {
        const response21 = await fetch(`${REACT_APP_API_ENDPOINT}risklist/${rowId}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json" // Ensure correct content type
          },
          body: JSON.stringify(updatedRow),
        });
        
        // Log the response body or any relevant data from the response
        console.log("Response:", response21);
        if (response21.ok) {
          const updatedData = await response21.json(); // Get the updated data from the response
          console.log("Updated data from server:", updatedData); // Log the updated data received from the server
        }
      } catch (error) {
        console.error("Error saving row changes:", error);
      }
    };
  
    
const handleCellDoubleClick = (params) => {
  navigate(`/riskevPage/${params.row._id}`);
};

const handleCellRiskDoubleClick = (params) => {
  navigate(`/riskMitPage/${params.row._id}`);
};



const deleteRowRisk = async (rowId) => {
    try {
        await fetch(`${REACT_APP_API_ENDPOINT}risklist/${rowId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Update the data and filteredData states after deleting the row
        const updatedData = data.filter((row) => row.id !== rowId);
        setData(updatedData);
        setFilteredData(updatedData);
        setIsDeleteMade(true);
    } catch (error) {
        console.error("Error deleting row:", error);
    }
};

const deleteRisk = async (rowId) => {
  try {
    const endpoint = `${REACT_APP_API_ENDPOINT}risks/${rowId}`;
    const response = await fetch(endpoint, {
      method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Update the data and filteredData states after deleting the row
        const updatedData = data.filter((row) => row.id !== rowId);
        setData(updatedData);
        setFilteredData(updatedData);
        setIsDeleteMade(true);
    } catch (error) {
        console.error("Error deleting row:", error);
    }
};

const addNewRisk = async () => {
  try {
    if (riskName.trim() !== "") {
      setLoading(true);

      // Make a POST request to add the new risk
      const endpoint = `${REACT_APP_API_ENDPOINT}risklist`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: riskName, idcontrato: idcontrato, userId: userId, estado: "Pendiente"}),
      });

      if (response.ok) {
        // If the request is successful, update the availableRisks state
        setAvailableRisks((prevRisks) => [...prevRisks, { name: newRisk }]);
        setNewRisk("");
    } else {
        const responseData = await response.json();
        if (responseData.error === "Duplicate key error") {
          setSnackbarMessage("Nombre del Riesgo ya existe, intente otro nombre.");
         setIsModalOpen(false); // Close the modal
         setSnackbarOpen(true);

        } else {
            console.error('Failed to add new risk:', response.statusText);
            // Handle other types of errors appropriately
        }
    }
}
} catch (error) {
console.error('Error adding new risk:', error.message);
// Handle the error appropriately
}
};

  

 useEffect(() => {
    setShowSuccessPopup(false);
    fetchAvailableRisks();
        fetchData();

}, [isFormSubmitted, dateRange, isDeleteMade]); // Include isDeleteMade in the dependency array

  
const fetchData = async () => {
  try {
    setIsLoading(true); // Set loading to true before fetching data


    

    let endpoint = `${REACT_APP_API_ENDPOINT}risks/contract/${loggedidcontrato}`;
 
 
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    delete formData.password;

    // Add unique id and format createdAt
    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      id: row._id,
      createdAt: row.createdAt
        ? new Date(row.createdAt).toLocaleDateString("en-EN", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        : "",
    }));
    setData(formData);
    setFilteredData(formData); // Set filteredData initially with the full data
   

   
  } catch (error) {
    console.error("Error fetching data:", error);
    setIsLoading(false); // Set loading to false in case of an error

  }
};





  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };





  const createdAtValueFormatter = (params) => {
    if (params.value) {
      const date = new Date(params.value);
      return date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    }}


    const columns = [
      {
        field: "createdAt",
        headerName: "Realizado",
        flex: 1,
        valueFormatter: createdAtValueFormatter,
        headerClassName: "bold-header" ,
      },
      { field: "score", headerName: "Score", flex: 1, headerClassName: "bold-header" },
      { field: "estadomatriz", headerName: "estadomatriz", flex: 1, headerClassName: "bold-header"},
      {
        field: "actions",
        headerClassName: "bold-header",
        headerName: <FormattedMessage id="Action"/>,
        width: 100,
        renderCell: (params) => (
          <>     
            {userProfile === "superadmin" && ( // Check if user is a superadmin
              <IconButton
                color="secondary"
                onClick={() => handleRowClick(params, "delete")} // Pass "delete" action
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        ),
      },
  ];

  const columnsrisk = [
   
    
    { field: "name", headerName: <FormattedMessage id="Nombre"/>, flex: 1 , 
    headerClassName: "bold-header",},
    { 
     field: "updatedAt", 
     headerName: <FormattedMessage id="Creado"/>, 
     flex: 1, 
     headerClassName: "bold-header",
     valueGetter: (params) => formatDate(params.value) // Use formatDate to format the updatedAt field
   },
    { field: "description", headerName: <FormattedMessage id="Detalle"/>, flex: 1 , editable: true,
    headerClassName: "bold-header",},
    { field: "tipo", headerName: <FormattedMessage id="Tipo"/>, flex: 1 , editable: true,

    headerClassName: "bold-header",},
 
    { field: "responsible", headerName: <FormattedMessage id="Responsable"/>, flex: 1 , 
    headerClassName: "bold-header",},
    
    { field: "dueDate", headerName: <FormattedMessage id="Fecha Entrega"/>, flex: 1 , 
    headerClassName: "bold-header",},
 
    { field: "mitigation", headerName: <FormattedMessage id="Mitigación"/>, flex: 1 , 
    headerClassName: "bold-header",},
 
    { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1 , editable: true,
    headerClassName: "bold-header",},
    {
      field: "actions",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Action"/>,
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          {userProfile === "superadmin" && ( // Check if user is a superadmin

          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
          )}
        </>
      ),
    },
  ];

const processedRisks = availableRisks.map((risk, index) => ({
  ...risk,
  id: risk._id || index + 1, // Use _id if available, otherwise generate a unique ID
}));


// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  
  // Generate a random 24-character string
  const handleCreateCampaignClick = async () => {
    try {
      setLoading(true); // Start loading

    
        navigate(`/risk/`);



    } catch (error) {
      console.log("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Stop loading, whether the request was successful or not
    }
  };
  

  const handleAddRiskClick = async () => {
    try {
      setLoading(true); // Start loading

    
      setIsModalOpen(true);




    } catch (error) {
      console.log("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Stop loading, whether the request was successful or not
    }
  };
  

 

  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

//****************************************************
return (

  
  <Box m="10px">

<Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>

<Box display="flex" justifyContent="space-between" alignItems="center">
   

     </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>

  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "200%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "140%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
 <Header title={<FormattedMessage id="Matrices de Riesgo" />}  />
    <Button
          onClick={handleCreateCampaignClick}
          sx={{ borderRadius: "20px",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[900],
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '10px 20px',
          }}
        >
          <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
          <FormattedMessage id="Nueva Matriz de Riesgo"/>

        </Button>



    

{/* DATAGRID */}        

    <Box
      m="40px 0 0 0"
      height="45vh"
      sx={{ borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
     
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",

        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      
      <DataGrid
        rowHeight={40}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        columns={columns}
        rows={filteredData.map((row) => ({
          ...row,
          id: row._id, 
        }))}
        components={{ Toolbar: GridToolbar }}
      
        rowsPerPageOptions={[5, 10, 25]} // Include 25 as an option
        onCellDoubleClick={handleCellDoubleClick}

        onRowClick={handleRowClick}
      />
    </Box>
    
<p></p>
<Header title={<FormattedMessage id="Mis Riesgos" />}  />
<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <MuiAlert onClose={handleSnackbarClose} severity="error" elevation={6} variant="filled">
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
    <Button
          onClick={handleAddRiskClick}
          sx={{ borderRadius: "20px",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[900],
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '10px 20px',
          }}
        >
            Agregar Riesgo
        </Button>
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <DialogTitle>Agregar Nuevo Riesgo</DialogTitle>
  <DialogContent>
    <TextField
      label="Nombre del Riesgo"
      value={riskName}
      onChange={(e) => setRiskName(e.target.value)}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Descripción"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      fullWidth
      multiline
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
    <Button onClick={addNewRisk} color="primary">Guardar</Button>
  </DialogActions>
</Dialog>



{/* DATAGRID */}    
<Box
      m="40px 0 0 0"
      height="45vh"
      sx={{ borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
     
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",

        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      
      <DataGrid
        rowHeight={40}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        columns={columnsrisk}
       rows={processedRisks}
       onCellDoubleClick={handleCellRiskDoubleClick}

      
        components={{ Toolbar: GridToolbar }}
      
        rowsPerPageOptions={[5, 10, 25]} // Include 25 as an option

        onRowClick={handleRowClickrisk}
      />
    </Box>



{/* DATAGRID */}    

    
<p></p>


</Box>
);
};


export default Myrisks;
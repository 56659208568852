import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik'; // Import Formik components
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Header from "../../components/Header";

const greenText = { color: 'green' };

const RiskMitPages = () => {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [users, setUsers] = useState(null);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  
  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  
  useEffect(() => {
    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}risklist/${_id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

        const data = await response.json();
        setForm(data);
        setLoading(false);
      } catch (error) {
        setError('An error occurred while fetching form data.');
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          },
        });

        if (!userResponse.ok) {
          throw new Error('Failed to fetch users data');
        }

        const usersData = await userResponse.json();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    
    getForm();
    fetchUsers();

  }, [isFormSubmitted]);


  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!form || Object.keys(form).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>No form data found.</Typography>
      </Box>
    );
  }

  const handleSubmit = async (values, onSubmitProps) => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}risklist/${_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          responsible: values.selectedResponsible,
          estado: "En Proceso",
          dueDate: values.dueDate,
          mitigation: values.mitigation,
          description: values.description,

        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update responsible person');
      }
  
      // Optionally, you can add logic here to handle successful update
      // For example, you can redirect the user or show a success message
  
      // Set isFormSubmitted to trigger useEffect and refetch data if necessary
      setIsFormSubmitted(true);
  
      // Reset form values
      navigate(-1); // -1 indicates navigating back one page

    } catch (error) {
      console.error('Error updating responsible person:', error);
      // Optionally, you can handle the error by displaying a message to the user
    }
  };

  const handleDeleteConfirmation = (confirmed) => {
    if (confirmed) {
      // If user confirmed deletion
      handleDelete(); // Call the handleDelete function
    }
    // Close the modal regardless of the user's choice
    closeModal();
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}risklist/${_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete record');
      }

      // Optionally, you can add logic here to handle successful deletion
      // For example, you can redirect the user or show a success message

      navigate(-1); // Navigate back after successful deletion
    } catch (error) {
      console.error('Error deleting record:', error);
      // Optionally, you can handle the error by displaying a message to the user
    }
  };

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };

  
  return (
    <Box display="flex">
        <Box width="100%" padding="2rem 6%" display="flex" flexDirection="column" justifyContent="space-between">
        <Header title={form.name} />

        <Paper elevation={3} style={widgetStyle}>
  <Typography variant="h4" style={{ color: 'green', marginBottom: '2rem' }}>{form.estado}</Typography>

        
        <Formik
          initialValues={{
            selectedResponsible: form && form.responsible ? form.responsible : '', // Check if form and form.responsible are defined before accessing
            dueDate: form && form.dueDate ? form.dueDate : '', // Check if form and form.dueDate are defined before accessing
            mitigation: form && form.mitigation ? form.mitigation : '', // Check if form and form.mitigation are defined before accessing
            description: form && form.description ? form.description : '', // Check if form and form.description are defined before accessing
          
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, touched, errors }) => (
            <Form>
  <div>
  <label htmlFor="description">Descripción:</label>
  <textarea
    id="description"
    name="description"
    value={values.description}
    onChange={handleChange}
    onBlur={handleBlur}
    style={{ width: '100%', minHeight: '100px', fontFamily: 'inherit' }} // Set font family to inherit

  />
</div>
{touched.description && errors.description && (
  <Typography variant="body2" color="error">
    {errors.description}
  </Typography>
)}
              <FormControl sx={{ borderRadius: "20px", gridColumn: 'span 12' }}>
                
              <InputLabel sx={{ width: '200%' }}>{<FormattedMessage id="Seleccione Responsable"/>}</InputLabel>

              <Select
  name="selectedResponsible"
  label={<FormattedMessage id="Seleccione Responsable" />}
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.selectedResponsible}
  error={touched.selectedResponsible && Boolean(errors.selectedResponsible)}
  sx={{ width: '150%', minHeight: '100px' }} // Adjust width to occupy 130% of original width
>
  <MenuItem value="">
    <em>{<FormattedMessage id="Seleccionar Responsable"/>}</em>
  </MenuItem>
  {users && users.map((user) => (
    <MenuItem key={user._id} value={user.email}>
      {user.email}
    </MenuItem>
  ))}
</Select>
<p></p>
<Select
  name="selectedTipo"
  label={<FormattedMessage id="Seleccione Tipo" />}
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.selectedTipo}
  error={touched.selectedTipo && Boolean(errors.selectedTipo)}
  sx={{ width: '150%', minHeight: '100px' }} // Adjust width to occupy 130% of original width
>
  <MenuItem value="">
    <em>{<FormattedMessage id="Seleccionar Tipo"/>}</em>
  </MenuItem>
  {users && users.map((user) => (
    <MenuItem key={form._id} value={form.tipo}>
      {form.tipo}
    </MenuItem>
  ))}
</Select>


                {touched.selectedResponsible && errors.selectedResponsible && (
                  <Typography variant="body2" color="error">
                    {errors.selectedResponsible}
                  </Typography>
                )}
              </FormControl>

            

<div>
  <label htmlFor="mitigation">Mitigacion</label>
  <textarea
    id="mitigation"
    name="mitigation"
    value={values.mitigation}
    onChange={handleChange}
    onBlur={handleBlur}
    style={{ width: '100%', minHeight: '100px', fontFamily: 'inherit' }} // Set font family to inherit

  />
</div>
{touched.mitigation && errors.mitigation && (
  <Typography variant="body2" color="error">
    {errors.mitigation}
  </Typography>
)}


<p></p>
<div>
        <label htmlFor="dueDate">Due Date</label>
        <input
          type="date"
          id="dueDate"
          name="dueDate"
          value={values.dueDate}
          onChange={handleChange}
          onBlur={handleBlur}
          
        />
      </div>
      {touched.dueDate && errors.dueDate && (
        <Typography variant="body2" color="error">
          {errors.dueDate}
        </Typography>
      )}

    <p></p>
     
<button type="submit" style={{ width: '50%', backgroundColor: 'green', color: 'white', padding: '10px', borderRadius: '5px', border: 'none', cursor: 'pointer' }}>
Guardar
      </button>
      <p></p>
       {/* Delete button */}

   
{isModalOpen && (
  <div className="modal-overlay">
    <div className="modal">
      <div className="modal-content">
        <p>¿Está seguro de borrar?</p>
        <div>
          <button onClick={() => handleDeleteConfirmation(true)}>Sí</button>
          <button onClick={() => handleDeleteConfirmation(false)}>No</button>
        </div>
      </div>
    </div>
  </div>
)}

       <button 
  onClick={(e) => { e.preventDefault(); openModal(); }} // Prevent form submission and open the modal
  style={{
            width: '50%', 
            backgroundColor: 'red', 
            color: 'white', 
            padding: '10px', 
            borderRadius: '5px', 
            border: 'none', 
            cursor: 'pointer',
            marginBottom: '10px' // Add some space between delete and guardar buttons
          }}
        >
          Borrar
        </button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
    </Box>
  );
};

export default RiskMitPages;

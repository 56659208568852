import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyPostWidget from "./MyPostWidget";
import { useLocation } from "react-router-dom";
import Papa from "papaparse"; // Library for parsing CSV files
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper'; // Import the Paper component
import { tokens } from "../../src/theme";
import { FormattedMessage } from 'react-intl';
import RegSession from "./RegSession";
import LogoImage from '../assets/eticprologo1.png';

const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;
const greenText = { color: 'green' }; // Define the CSS style for green text

const Campaign = ({ _id }) => {
  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const userId = useSelector((state) => state.user._id);
  const userprofile = useSelector((state) => state.user.userprofile);

  const cmf = useSelector((state) => state.cmf);
  const token = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [emailTemplate, setemailTemplate] = useState([]); // State to store the parsed CSV data
  const loggedinempresa = useSelector((state) => state.user.location);
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const userEmail = useSelector((state) => state.user.email);
  const [csvData, setCsvData] = useState([]); // State to store the parsed CSV data
  const loggedInUserId = useSelector((state) => state.user._id);

  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [selectedInfoKey, setSelectedInfoKey] = useState(null); // Added state to store selected info key
  const greenText = { color: 'green' }; // Define the CSS style for green text
  const [showInvalidRutPopup, setShowInvalidRutPopup] = useState(false);
  const [invalidRutMessage, setInvalidRutMessage] = useState("");
  const [invalidRuts, setInvalidRuts] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [noEnviarEmail, setNoEnviarEmail] = useState(true);

  const isValidRut = (rut) => {
    if (!rut || rut.trim().length < 3) return false;
    const rutClean = rut.replace(/[^0-9Kk]/g, "").toUpperCase();
    const rutBody = rutClean.substring(0, rutClean.length - 1);
    let dv = rutClean.slice(-1);
    let sum = 0;
    let factor = 2;
  
    for (let i = rutBody.length - 1; i >= 0; i--) {
      sum += factor * parseInt(rutBody.charAt(i), 10);
      factor = (factor === 7) ? 2 : factor + 1;
    }
  
    const dvCalculated = 11 - (sum % 11);
    if (dvCalculated === 11) dv = '0';
    if (dvCalculated === 10) dv = 'K';
  
    return dv === (dvCalculated === 11 ? '0' : dvCalculated === 10 ? 'K' : String(dvCalculated));
  };
  

  // Function to handle file drop and parse CSV
  const handleCsvDrop = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const result = Papa.parse(text, { header: true, skipEmptyLines: true });
      setCsvData(result.data); // Store the parsed CSV data in state
  
      // Validate RUTs and update invalidRuts state
      const tempInvalidRuts = result.data.filter(row => !isValidRut(row.rut)).map(row => row.rut);
      setInvalidRuts(tempInvalidRuts);
    };
    reader.readAsText(file);
  };
  
  const campaignSchema = yup.object().shape({
    // Define your validation schema here
    comments: yup.string().required("Nombre de Campaña es Requerido"),
    opCampaign: yup.string().required("Porfavor seleccione una opción"),
    link: yup.string().notRequired("Comentario o link a adjuntar en Email"),
    email: yup.string().required("Se requiere  Email del destinatario"),
    rut: yup.string().notRequired(), // Otherwise, make it not required

 

  });
  const initialValuesEditCampaign = {
    comments: "",
    opCampaign: "",
    opLoad: "",
    description: "",
    email: userEmail,
    idcontrato: idcontrato, // Include idcontrato in the initial values
    link: "",
    envioMail: "",
    
  };
  const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: userEmail,
  password: "",
  location: "",
  occupation: "",
  picture: "",
  idcontrato: idcontrato,
  area: "",
  rut: "",
  celular: "",
  status: "decpendiente",

};

const handleCheckboxChange = (event) => {
  setNoEnviarEmail(event.target.checked);
};


      // Save all the emails from the CSV data into the description array
  const handleFormSubmit = async (values, onSubmitProps) => {

        let userIduser; // Declare userIduser outside the try block
        let formData6; // Declare formData6 outside the try block
        let emailTemplate = ""; // Initialize emailTemplate variable
        let emailTemplateTxt = ""; // Initialize emailTemplate variable

        let subject = ""; // Initialize emailTemplate variable
        let postId; // Define postId outside the loop
        let mailport= "";
        let  mailserver= "";
        let  mailuser= "";
        let  passmail= "";

        setInvalidRuts([]);

        let  cipherstype= "";

        try {
        const response6 = await fetch(`${REACT_APP_API_ENDPOINT}clients/${idcontrato}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
      });

      if (response6.status === 200) {
        formData6 = await response6.json();
        const clientname = formData6.nombreempresa;
        setProfilePicture(formData6.logo);


    }
  } catch (error) {
    console.log(error);
    // Handle error
  }
//mensaje a checklist pending
if (values.opCampaign === "ChecklistDD") {
  try {
    setLoading(true);

    // Fetch formData12 based on idcontrato
    const response12 = await fetch(`${REACT_APP_API_ENDPOINT}dd/contract/${idcontrato}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response12.ok) {
      const formData12 = await response12.json();
      // Filter formData12 entries where estado is "En Evaluacion"
      const evaluacionList = formData12.filter(item => item.estado === "En Evaluacion");
      console.log("evaluacionList", evaluacionList);

      // Initialize an array to store all emails
      const sentEmails = [];

      for (const entry of evaluacionList) {
        // Fetch user data for each formData12.solicitante
        const response14 = await fetch(`${REACT_APP_API_ENDPOINT}users/${entry.solicitante}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response14.ok) {
          const formData14 = await response14.json();
          // Check if formData14.email exists and hasn't been added already
          if (formData14.email && !sentEmails.includes(formData14.email)) {
            // Add email to the array
            sentEmails.push(formData14.email);

            const subject = `Invitación de ${formData6.nombreempresa} a Completar Checklist Diligencia Debida`;

            const emailTemplate = `
              <!DOCTYPE html>
              <html>
              <head>
              <style>
                .frame {
                  border: 2px solid #ccc;
                  padding: 20px;
                  border-radius: 10px;
                  width: 80%;
                  margin: 0 auto;
                  text-align: left;
                  position: relative;
                }
                .logo-container {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }
                .logo-box {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .box {
                  background-color: #f2f2f2;
                  padding: 10px;
                  border-radius: 5px;
                }
              </style>
              </head>
              <body>
              <div class="frame">
                <div class="logo-container">
                  <img src="${LogoImage}" alt="Logo" style="width: 10%;" />
                </div>
                <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                <p>Estimado(a) Colaborador(a)</p>
                <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarle a completar el Checklist de Diligencia Debida para cumplir con los Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro.</p>

                <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                <p>Usuario: ${formData14.email}</p>
                <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>

                <p>Paso 2: En el menú de la izquierda vaya a Diligencia Debida y haga click en Contraparte para revisar el listado de Diligencias Debidas que aguardan su Checklist según el estado "En Evaluación".</p>
                <p>${values.link}</p>

                <p>Gracias por su atención y cooperación.</p>
                <p>Equipo Encargado de Prevención de Delitos.</p>
                <p>Eticpro.</p>
                <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
                <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
                <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es ${formData14.email}</p>
                <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
              </div>
              </body>
              </html>
            `;

            const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                emails: [formData14.email],
                emailTemplate,
                subject,
                mailserver: formData6.mailserver,
                mailport: formData6.mailport,
                mailuser: formData6.mailuser,
                passmail: formData6.passmail,
                cipherstype: formData6.cipherstype,
              }),
            });

            if (emailResponse.ok) {
              console.log(`Email sent successfully to ${formData14.email}`);
            } else {
              console.log(`Failed to send email to ${formData14.email}`);
            }

          } else if (sentEmails.includes(formData14.email)) {
            console.log(`Skipping email: ${formData14.email} already processed`);
          } else {
            console.error(`No email found for solicitante: ${entry.solicitante}`);
          }
        } else {
          console.error(`Failed to fetch user for solicitante: ${entry.solicitante}`);
        }
      }

      // Split the sentEmails array into separate emails as individual items
      const description = sentEmails.flatMap(email => email.split(',').map(e => e.trim()));

      const response7 = await fetch(`${REACT_APP_API_ENDPOINT}posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...values,
          email: userEmail,
          idcontrato,
          userId,
          endDate,
          description: description, // Store emails as separate items in the description array
          envioMail: noEnviarEmail,
        }),
      });

      if (response7.ok) {
        const responseData7 = await response7.json();
        onSubmitProps.resetForm();
        navigate("/successPage", {
          state: { message: "Campaña creada con éxito" }
        });
      }
    } else {
      console.error('Failed to fetch formData12.');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  } finally {
    setLoading(false);
  }
}


if (values.opCampaign === "CierreTV") {
  try {
    setLoading(true);
    console.log("cierre tv elegido");

    // Fetch formData12 based on idcontrato
    const response12 = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/contract/${idcontrato}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response12.ok) {
      const formData12 = await response12.json();
      console.log("formData12", formData12);

      // Filter formData12 entries where estado is "Por Evaluar" and estadoCierre is "Pendiente"
      const evaluacionList = formData12.filter(item => item.estado === "Evaluada" && item.estadoCierre === "Pendiente");
      console.log("evaluacionList", evaluacionList);

      // Create a Set to store unique emails
      const sentEmails = new Set();

      for (const entry of evaluacionList) {
        console.log("email a enviar solicitante", entry.emailSolicitante);

        // Fetch user data for each formData12.solicitante
        const response14 = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${entry.emailSolicitante}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response14.ok) {
          const formData14 = (await response14.json())[0];
          console.log("formdata14", formData14);

          if (formData14.email && !sentEmails.has(formData14.email)) {
            // Add email to the Set to ensure it's only processed once
            sentEmails.add(formData14.email);

            const subject = `Invitación de ${formData6.nombreempresa} a realizar Cierre Transferencia de Valor`;

            const emailTemplate = `
              <!DOCTYPE html>
              <html>
              <head>
              <style>
                .frame {
                  border: 2px solid #ccc;
                  padding: 20px;
                  border-radius: 10px;
                  width: 80%;
                  margin: 0 auto;
                  text-align: left;
                  position: relative;
                }
                .logo-container {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                }
                .logo-box {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .box {
                  background-color: #f2f2f2;
                  padding: 10px;
                  border-radius: 5px;
                }
              </style>
              </head>
              <body>
              <div class="frame">
                <div class="logo-container">
                  <img src="${LogoImage}" alt="Logo" style="width: 10%;" />
                </div>
                <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">
                <p>Estimado(a) Colaborador(a)</p>
                <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarle a realizar el Cierre de la Transferencia de valor presentada por Ud. para cumplir con los Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro.</p>
                <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                <p>Usuario: ${formData14.email}</p>
                <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
                <p>Paso 2: En el menú de la izquierda vaya a Transferencia de Valor y haga click en la transferencia de valor que tiene estado "Aprobada" y con  Cierre "Pendiente" para realizar la acción de subir evidencia del cierre al interior del formulario desplegado, Sección "CIERRE".</p>
                <p>${values.link}</p>
                <p>Gracias por su atención y cooperación.</p>
                <p>Equipo Encargado de Prevención de Delitos.</p>
                <p>Eticpro.</p>
                <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
                <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
                <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es ${formData14.email}</p>
                <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
              </div>
              </body>
              </html>
            `;

            const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                emails: [formData14.email],
                emailTemplate,
                subject,
                mailserver: formData6.mailserver,
                mailport: formData6.mailport,
                mailuser: formData6.mailuser,
                passmail: formData6.passmail,
                cipherstype: formData6.cipherstype,
              }),
            });

            if (emailResponse.ok) {
              console.log(`Email sent successfully to ${formData14.email}`);
            } else {
              console.log(`Failed to send email to ${formData14.email}`);
            }
          } else if (sentEmails.has(formData14.email)) {
            console.log(`Skipping email: ${formData14.email} already processed`);
          } else {
            console.error(`No email found for solicitante: ${entry.solicitante}`);
          }
        } else {
          console.error(`Failed to fetch user for solicitante: ${entry.solicitante}`);
        }
      }

      // Convert the sentEmails Set to an array and send as separate items in the description
      const description = [...sentEmails].map(email => email.trim());

      // Store the description with the emails
      const response7 = await fetch(`${REACT_APP_API_ENDPOINT}posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...values,
          email: userEmail,
          idcontrato,
          userId,
          endDate,
          description: description, // Store emails as separate items in the description array
          envioMail: noEnviarEmail,
        }),
      });

      if (response7.ok) {
        const responseData7 = await response7.json();
        onSubmitProps.resetForm();
        navigate("/successPage", {
          state: { message: "Campaña creada con éxito" }
        });
      }
    } else {
      console.error('Failed to fetch formData12.');
    }

  } catch (error) {
    console.error('An error occurred:', error);
  } finally {
    setLoading(false);
  }
}


  
    

        if (values.opLoad === "MAS") {
          let postId; // Declare postId here so it's accessible throughout the function

          const tempInvalidRuts = [];
        for (const row of csvData) {
          console.log("Validating RUT:", row.rut);
          if (!isValidRut(row.rut)) {
            console.log("Invalid RUT Detected:", row.rut);
            tempInvalidRuts.push(row.rut);
          }
        }

        if (tempInvalidRuts.length > 0) {
          setInvalidRuts(tempInvalidRuts);
          console.log("Invalid RUTs:", tempInvalidRuts);
        }
  


              const csvEmails = csvData.map((row) => row.email.trim()); // Extract emails from CSV data
              const response7 = await fetch(`${REACT_APP_API_ENDPOINT}posts`, {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${token}`,
                                    },
                                  body: JSON.stringify({
                                    ...values,
                                    email: userEmail,
                                    idcontrato,
                                    userId,
                                    endDate,
                                    description: csvEmails, // Save all the emails in the description array
                                    envioMail: noEnviarEmail,
                                  }),
                                });
                            
                               
                                if (response7.ok) {
                                  console.log(`post guardado exitosamente ${csvEmails}`);
                          
                              
                      
                          const responseData7 = await response7.json();
                          postId = responseData7.savedPost._id; // Assign the value to postId

                  
                  

                              }

                              //aqui viene el motor de envio y cada mensaje y guardado de cada usuario para masivo!!
      for (let i = 0; i < csvData.length; i++) {
                                const { email, rut, nombres, apellidos, empresa, area, cargo, nacionalidad } = csvData[i];
                                const password = rut.trim().substring(0, 6);
                                const status = values.opCampaign !== "CI" ? "Invitado" : "decpendiente";
                                const  postId2 = postId;  

                                try {
                                  setLoading(true);
                          
                                  const response5 = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${email}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${token}`,
                                    },
                                  });
                          
                                  if (response5.status === 200) {
                                    const formData5 = await response5.json();
                          
                                    if (Array.isArray(formData5) && formData5.length > 0 && formData5[0]._id) {
                                      userIduser = formData5[0]._id;
                                      console.log("Found existing user, userIduser", userIduser);
                                    } else {
                                      console.log("User not found, creating a new user...");
                          
                                      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${token}`,
                                        },
                                        body: JSON.stringify({
                                          userprofile: "declare",
                                          status: status,
                                          password,
                                          idcontrato: idcontrato,
                                          campaignid: postId,
                                          location: empresa,
                                          email,
                                          rut,
                                          firstName: nombres,
                                          lastName: apellidos,
                                          area,
                                          occupation: cargo,
                                          nationality: nacionalidad
                                        }),
                                      });
                          
                                      if (userResponse.ok) {
                                        const userData = await userResponse.json();
                                        userIduser = userData._id;
                                        console.log("response ok, userIduser", userIduser);
                                      }
                                    }
                          
                                    if (values.opCampaign === "DDP") {

                                      subject = `Importante: ${formData6.nombreempresa} requiere que complete el cuestionario para cumplir con la legislación ley N°20.393`;
                                      
                                      
                                       const emailTemplateRec = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        .frame {
          border: 2px solid #ccc;
          padding: 20px;
          border-radius: 10px;
          width: 80%;
          margin: 0 auto;
          text-align: left;
          position: relative;
        }
        .logo-container {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .logo-box {
          text-align: center;
          margin-bottom: 20px;
        }
        .box {
          background-color: #f2f2f2;
          padding: 10px;
          border-radius: 5px;
        }
      </style>
    </head>
    <body>
      <div class="frame">
        <div class="logo-container">
        </div>
        <img src="https://repoeticpro.s3.sa-east-1.amazonaws.com/eticprologo8.png" alt="Logoemp" style="width: 20%"> 

        <p>Estimado(a) Postulante de ${nombrecliente}:</p>
        <p>Por requerimiento de ${nombrecliente}, le invitamos a completar el siguiente formulario de diligencia debida en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
        <p>La información recopilada es esencial para que la organización a la cual postula pueda cumplir con la Ley #21.595. Tu participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
        <p><a href="${process.env.BASE_URL}/ddextPostulante/campaign/${postId2},${idcontrato},${userIduser},${encodeURIComponent(email)}">Link al formulario</a></p>
        <p>Ticket Declaracion: ${postId2},${idcontrato},${userIduser},${encodeURIComponent(email)}</p>

        <p>Gracias por su atención y cooperación.</p>
        <p>Equipo Encargado de Prevención de Delitos.</p>
        <p>Eticpro.</p>
        <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
      </div>
    </body>
    </html>
  `;
                                      
  
  
  
                                          }
                                          if (values.opCampaign === "CI") {

                                            subject = `Importante: ${formData6.nombreempresa} requiere que complete el formulario para cumplir con la legislación ley N°20.393`;
                                            
                                            
                                            emailTemplate = `
                                            <!DOCTYPE html>
                                            <html>
                                            <head>
                                              
                                            <style>
                                            .frame {
                                              border: 2px solid #ccc;
                                              padding: 20px;
                                              border-radius: 10px;
                                              width: 80%;
                                              margin: 0 auto;
                                              text-align: left; /* Align text to the left */
                                              position: relative; /* Add relative positioning to the frame */
                                            }
                                            
                                            .logo-container {
                                              position: absolute;
                                              top: 10px; /* Adjust top value to position the logo vertically */
                                              right: 10px; /* Adjust right value to position the logo horizontally */
                                            }
                                            .ticket-declaracion {
                                                  background-color: yellow;
                                                  color: red;
                                                  padding: 5px;
                                                  border-radius: 3px;
                                                  display: inline-block;
                                                }
                                            /* Styles for the box containing the logo */
                                            .logo-box {
                                              text-align: center;
                                              margin-bottom: 20px;
                                            }
                                                .box {
                                                  background-color: #f2f2f2;
                                                  padding: 10px;
                                                  border-radius: 5px;
                                                }
                                              </style>
                                            </head>
                                            <body>
                                            <div class="frame">
                                            <div class="logo-container">
                                            <img src=${LogoImage} alt="Logo" style="width: 100%;" />
                                            </div>
                                            <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">
      
                                              <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                              <p>Por requerimiento de ${formData6.nombreempresa}, le invitamos a completar el siguiente formulario en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                              <p>La información recopilada es esencial para que tu organización pueda cumplir con la Ley #21.595. Tu participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
                                              <p><a href="https://compliax.onrender.com/formepd/campaign/${postId2},${idcontrato},${userIduser},${encodeURIComponent(email)},${cmf}">Link al formulario</a></p>
                                              <p></p>
                                              <p>Ticket Declaracion: <span class="ticket-declaracion">${postId2},${idcontrato},${userIduser},${encodeURIComponent(email)},${cmf}</span></p>

                                              <p>Gracias por su atención y cooperación.</p>
                                              <p>Equipo Encargado de Prevención de Delitos.</p>
                                              <p>Eticpro.</p>
                                              <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                              <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                              <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.
                                              </p>
                                              <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                              <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
        
                                            </div>
                                            </body>
                                            </html>`;
                                            
        
        
        
                                                }
                                          if (values.opCampaign === "login") {
  
                                            // Invitación usar Eticpro 
                                    subject = `Invitación de ${formData6.nombreempresa} a usar Eticpro`;
  
                                    emailTemplate = `
                                    <!DOCTYPE html>
                                    <html>
                                    <head>
                                        <style>
                                            .frame {
                                                border: 2px solid #ccc;
                                                padding: 20px;
                                                border-radius: 10px;
                                                width: 80%;
                                                margin: 0 auto;
                                                text-align: left; /* Align text to the left */
                                                position: relative; /* Add relative positioning to the frame */
                                            }
                                
                                            .logo-container {
                                                position: absolute;
                                                top: 10px; /* Adjust top value to position the logo vertically */
                                                right: 10px; /* Adjust right value to position the logo horizontally */
                                            }
                                
                                            /* Styles for the box containing the logo */
                                            .logo-box {
                                                text-align: center;
                                                margin-bottom: 20px;
                                            }
                                            .box {
                                                background-color: #f2f2f2;
                                                padding: 10px;
                                                border-radius: 5px;
                                            }
                                        </style>
                                    </head>
                                    <body>
                                        <div class="frame">
                                            <div class="logo-container">
                                                <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                            </div>
                                            <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                            <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                            <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a usar la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de control y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                            <p>Las actividades de control para prevenir delitos que podrán utilizar son:</p>
                                            <ol>
                                                <li>Declaración de Conflicto de Interés</li>
                                                <li>Registro de Reuniones con Funcionarios Públicos o Personas de Interés</li>
                                                <li>Transferencias de Valor</li>
                                                <li>Canal de Denuncias</li>
                                                <li>Repositorio con Procedimientos e Información del Modelo de Prevención de Delitos</li>
                                            </ol>
                                            <p>Para comenzar vaya al nuestro sitio web eticpro.com y haga clickee en Boton Entrar</p>
                                        
                                            <p>Su Usuario es: ${email}</p>
                                            <p>Su Contraseña es: (Escriba los primeros 6 dígitos de su Rut), recuerde siempre recuperar su contraseña en la opción "Recuperar Contraseña")</p>
                                            </p>
                                        
                                            <p>${values.link}</p>

                                            <p>Muchas Gracias por su compromiso al participar en estos controles.</p>
                                            <p>Saludos Cordiales</p>
                                            <p>Equipo Encargado de Prevención de Delitos.</p>
                                            <p>Eticpro.</p>
                                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                            <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                            <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
                                            </div>
                                    </body>
                                    </html>`;
                                
  
                                              
                                          } 
                                          if (values.opCampaign === "TV") {
  
  
                                            // Invitación usar Eticpro 
                                            subject = `Invitación de ${formData6.nombreempresa} a Declarar Transferencia de Valor en Eticpro`;
  
                                            emailTemplate = `
                                            <!DOCTYPE html>
                                            <html>
                                            <head>
                                                <style>
                                                    .frame {
                                                        border: 2px solid #ccc;
                                                        padding: 20px;
                                                        border-radius: 10px;
                                                        width: 80%;
                                                        margin: 0 auto;
                                                        text-align: left; /* Align text to the left */
                                                        position: relative; /* Add relative positioning to the frame */
                                                    }
                                        
                                                    .logo-container {
                                                        position: absolute;
                                                        top: 10px; /* Adjust top value to position the logo vertically */
                                                        right: 10px; /* Adjust right value to position the logo horizontally */
                                                    }
                                        
                                                    /* Styles for the box containing the logo */
                                                    .logo-box {
                                                        text-align: center;
                                                        margin-bottom: 20px;
                                                    }
                                                    .box {
                                                        background-color: #f2f2f2;
                                                        padding: 10px;
                                                        border-radius: 5px;
                                                    }
                                                </style>
                                            </head>
                                            <body>
                                                <div class="frame">
                                                    <div class="logo-container">
                                                        <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                                    </div>
                                                    <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                                    <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                                    <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus Transferencias de Valor en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                                    
                                                    <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                                                    <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                                                    <p>Usuario: ${email}</p>
                                                    <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
                                                    <p>Paso 2: En el menú de la izquierda vaya a Mis Transferencias de Valor y haga click en Nueva Transferencia, luego conteste su declaración y envíe.</p>
                                                    <p>${values.link}</p>
                                                    <p>Gracias por su atención y cooperación.</p>
                                                    <p>Equipo Encargado de Prevención de Delitos.</p>
                                                    <p>Eticpro.</p>
                                                    <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                                    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                                    <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                                    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                                    <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
                                                    </div>
                                            </body>
                                            </html>`;
                                        
  
                                              
                                          } 
                                          if (values.opCampaign === "PEP") {
  
  
  
  
                                            // Invitación usar Eticpro 
                                            subject = `Invitación de ${formData6.nombreempresa} a Declarar Reuniones en Eticpro`;
  
                                            emailTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
            .frame {
                border: 2px solid #ccc;
                padding: 20px;
                border-radius: 10px;
                width: 80%;
                margin: 0 auto;
                text-align: left; /* Align text to the left */
                position: relative; /* Add relative positioning to the frame */
            }
  
            .logo-container {
                position: absolute;
                top: 10px; /* Adjust top value to position the logo vertically */
                right: 10px; /* Adjust right value to position the logo horizontally */
            }
  
            /* Styles for the box containing the logo */
            .logo-box {
                text-align: center;
                margin-bottom: 20px;
            }
            .box {
                background-color: #f2f2f2;
                padding: 10px;
                border-radius: 5px;
            }
        </style>
    </head>
    <body>
        <div class="frame">
            <div class="logo-container">
                <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
            </div>
            <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

            <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
            <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus reuniones con personas de interés   en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
            
            <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
            <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
            <p>Usuario: ${email}</p>
            <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
            <p>Paso 2: En el menú de la izquierda vaya a Mis Reuniones y haga click en Nueva Reunión, luego conteste su declaración y envíe.</p>
            <p>${values.link}</p>
            <p>Gracias por su atención y cooperación.</p>
            <p>Equipo Encargado de Prevención de Delitos.</p>
            <p>Eticpro.</p>
            <p><a href="www.eticpro.com">www.eticpro.com</a></p>
            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
            <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
            <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
        </div>
    </body>
    </html>`;
  
  
                                              
                                          } 
                                          
                                          if (values.opCampaign === "Read") {
  
  
                                            // Invitación usar Eticpro 
                                            subject = `Invitación de ${formData6.nombreempresa} a leer nuevo documento en Eticpro`;
  
                    
    emailTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
            .frame {
                border: 2px solid #ccc;
                padding: 20px;
                border-radius: 10px;
                width: 80%;
                margin: 0 auto;
                text-align: left; /* Align text to the left */
                position: relative; /* Add relative positioning to the frame */
            }
  
            .logo-container {
                position: absolute;
                top: 10px; /* Adjust top value to position the logo vertically */
                right: 10px; /* Adjust right value to position the logo horizontally */
            }
  
            /* Styles for the box containing the logo */
            .logo-box {
                text-align: center;
                margin-bottom: 20px;
            }
            .box {
                background-color: #f2f2f2;
                padding: 10px;
                border-radius: 5px;
            }
        </style>
    </head>
    <body>
        <div class="frame">
            <div class="logo-container">
                <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
            </div>
            <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

            <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
            <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a leer y confirmar entendimiento de un nuevo documento en el Repositorio parara cumplir los con Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro. Eticpro es una plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
            
            <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
            <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
            <p>Usuario: ${email}</p>
            <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
            <p>    Paso 2: En el menú de la izquierda vaya a Repositorio y haga click en los documento que estan pendientes por usted de confirmar lectura y entendimiento(Color Rojo). Los de color verde ya están confirmados por Usted.</p>
            <p>${values.link}</p>
            <p>Gracias por su atención y cooperación.</p>
            <p>Equipo Encargado de Prevención de Delitos.</p>
            <p>Eticpro.</p>
            <p><a href="www.eticpro.com">www.eticpro.com</a></p>
            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
            <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
            <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
            <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${email}</p>
        </div>
    </body>
    </html>`;
                                          } 

                                          if (!noEnviarEmail){
                
                              const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                  emails: [email],
                                  emailTemplate,
                                  subject,
                                  mailserver: formData6.mailserver,
                                  mailport: formData6.mailport,
                                  mailuser: formData6.mailuser,
                                  passmail: formData6.passmail,
                                  cipherstype: formData6.cipherstype,
                                }),
                              });
                                        
                          
                                  if (emailResponse.ok) {
                                    // Email sent successfully
                                    console.log(`Email sent successfully to ${email}`);
                                  } else {
                                    // Error sending email
                                    console.log(`Failed to send email to ${email}`);
                                  } 
                                } 
                  // Error saving post in the database
                                      } 
                        } 
                       catch (error) {
      console.log(error);
      // Handle error
                       }
                      }  



                      if (postId) {


                        const response9 = await fetch(`${REACT_APP_API_ENDPOINT}posts/${postId}`, {
                          method: "PUT",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                        body: JSON.stringify({
                          link: emailTemplate,
                        }),
                      });
                      
                        // Handle the response..
                        if (response9.ok) {
                          // Email sent successfully
                        } else {
                          // Error sending email
                          console.log(`Failed to save postid`);
                        }
                    } else {
                        console.error("postId is not set");
                    }

                    switch (values.opCampaign) {
                      case "CI":
                        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CI" });
                        break;
                      case "login":
                        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "login" });
                        break;
                      case "TV":
                        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "TV" });
                        break;
                      case "PEP":
                        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "PEP" });
                        break;
                      case "Read":
                        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "Read" });
                        break;
                    }
                    
                   
                    onSubmitProps.resetForm();
                    navigate("/successPage", {
                      state: { message: "Campaña creada con éxito" }
                  });                      
           }
   
   
        if (values.opLoad === "TXT") {
            let postId; // Declare postId here so it's accessible throughout the function
  
            const csvEmails = values.description.split(',').map((email) => email.trim());
                        
            const response7 = await fetch(`${REACT_APP_API_ENDPOINT}posts`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                ...values,
                email: userEmail,
                idcontrato,
                userId,
                endDate,
                description: csvEmails, // Save all the emails in the description array
                envioMail: noEnviarEmail,
              }),
            });
                               
                if (response7.ok) {
          
                            
                const responseData7 = await response7.json();
                postId = responseData7.savedPost._id; // Assign the value to postId
              

                    }

  for (let i = 0; i < csvEmails.length; i++) {
                               
                                const status = values.opCampaign !== "CI" ? "Invitado" : "decpendiente";
                                const  postId2 = postId;  

                                try {
                                  setLoading(true);
                          
                                  const response5 = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${csvEmails[i]}`, {
                                    method: "GET",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${token}`,
                                    },
                                  });
                          
                                  if (response5.status === 200) {
                                    const formData5 = await response5.json();
                          
                                    if (Array.isArray(formData5) && formData5.length > 0 && formData5[0]._id) {
                                      userIduser = formData5[0]._id;
                                      console.log("Found existing user, userIduser", userIduser);
                                    } else {
                                      console.log("User not found, creating a new user...");
                          
                                      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users`, {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${token}`,
                                        },
                                        body: JSON.stringify({
                                          userprofile: "declare",
                                          status: status,
                                          idcontrato: idcontrato,
                                          campaignid: postId,
                                          email: csvEmails[i],
                                          password: "098765",
                                          
                                        }),
                                      });
                          
                                      if (userResponse.ok) {
                                        const userData = await userResponse.json();
                                        userIduser = userData._id;
                                        console.log("response ok, userIduser", userIduser);
                                      }
                                    }       
                                  }                   
     
                                  if (values.opCampaign === "DDP") {

                                    subject = `Importante: ${formData6.nombreempresa} requiere que complete el cuestionario para cumplir con la legislación ley N°20.393`;
                                    
                                    
                                    const emailTemplateRec = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        .frame {
          border: 2px solid #ccc;
          padding: 20px;
          border-radius: 10px;
          width: 80%;
          margin: 0 auto;
          text-align: left;
          position: relative;
        }
        .logo-container {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .logo-box {
          text-align: center;
          margin-bottom: 20px;
        }
        .box {
          background-color: #f2f2f2;
          padding: 10px;
          border-radius: 5px;
        }
      </style>
    </head>
    <body>
      <div class="frame">
        <div class="logo-container">
        </div>
        <img src="https://repoeticpro.s3.sa-east-1.amazonaws.com/eticprologo8.png" alt="Logoemp" style="width: 20%"> 

        <p>Estimado(a) Postulante de ${nombrecliente}:</p>
        <p>Por requerimiento de ${nombrecliente}, le invitamos a completar el siguiente formulario de diligencia debida en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
        <p>La información recopilada es esencial para que la organización a la cual postula pueda cumplir con la Ley #21.595. Tu participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
        <p><a href="${process.env.BASE_URL}/ddextPostulante/campaign/${postId2},${idcontrato},${userIduser},${encodeURIComponent(csvEmails[i])}">Link al formulario</a></p>
        <p>Ticket Declaracion: ${postId2},${idcontrato},${userIduser},${encodeURIComponent(csvEmails[i])}</p>

        <p>Gracias por su atención y cooperación.</p>
        <p>Equipo Encargado de Prevención de Delitos.</p>
        <p>Eticpro.</p>
        <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
      </div>
    </body>
    </html>
  `;
                                      
                                    



                                        }
                                  if (values.opCampaign === "CI") {

                                    subject = `Importante: ${formData6.nombreempresa} requiere que complete el formulario para cumplir con la legislación ley N°20.393`;
                                    
                                    
                                    emailTemplate = `
                                    <!DOCTYPE html>
                                    <html>
                                    <head>
                                      
                                    <style>
                                    .frame {
                                      border: 2px solid #ccc;
                                      padding: 20px;
                                      border-radius: 10px;
                                      width: 80%;
                                      margin: 0 auto;
                                      text-align: left; /* Align text to the left */
                                      position: relative; /* Add relative positioning to the frame */
                                    } 
                                    
                                    .logo-container {
                                      position: absolute;
                                      top: 10px; /* Adjust top value to position the logo vertically */
                                      right: 10px; /* Adjust right value to position the logo horizontally */
                                    }
                                    
                                    /* Styles for the box containing the logo */
                                    .logo-box {
                                      text-align: center;
                                      margin-bottom: 20px;
                                    }
                                      .ticket-declaracion {
                                    background-color: white;
                                    color: black;
                                    padding: 5px;
                                    border-radius: 3px;
                                    display: inline-block;
                                  }
                                        .box {
                                          background-color: #f2f2f2;
                                          padding: 10px;
                                          border-radius: 5px;
                                        }
                                      </style>
                                    </head>
                                    <body>
                                    <div class="frame">
                                    <div class="logo-container">
                                      <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                    </div>
                                    <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                      <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                      <p>Por requerimiento de ${formData6.nombreempresa}, le invitamos a completar el siguiente formulario en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                      <p>La información recopilada es esencial para que tu organización pueda cumplir con la Ley #21.595. Tu participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
                                      <p><a href="https://compliax.onrender.com/formepd/campaign/${postId2},${idcontrato},${userIduser},${encodeURIComponent(csvEmails[i])},${cmf}">Link al formulario</a></p>
                                      <p>Ticket Declaracion: <span class="ticket-declaracion">${postId2},${idcontrato},${userIduser},${encodeURIComponent(csvEmails[i])},${cmf}</span></p>

                                      <p>Gracias por su atención y cooperación.</p>
                                      <p>Equipo Encargado de Prevención de Delitos.</p>
                                      <p>Eticpro.</p>
                                      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                      <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                      <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.
                                      </p>
                                      <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                      <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>

                                    </div>
                                    </body>
                                    </html>`;
                                    



                                        }
                                        if (values.opCampaign === "login") {

                                          // Invitación usar Eticpro 
                                  subject = `Invitación de ${formData6.nombreempresa} a usar Eticpro`;

                                  emailTemplate = `
                                  <!DOCTYPE html>
                                  <html>
                                  <head>
                                      <style>
                                          .frame {
                                              border: 2px solid #ccc;
                                              padding: 20px;
                                              border-radius: 10px;
                                              width: 80%;
                                              margin: 0 auto;
                                              text-align: left; /* Align text to the left */
                                              position: relative; /* Add relative positioning to the frame */
                                          }
                              
                                          .logo-container {
                                              position: absolute;
                                              top: 10px; /* Adjust top value to position the logo vertically */
                                              right: 10px; /* Adjust right value to position the logo horizontally */
                                          }
                              
                                          /* Styles for the box containing the logo */
                                          .logo-box {
                                              text-align: center;
                                              margin-bottom: 20px;
                                          }
                                          .box {
                                              background-color: #f2f2f2;
                                              padding: 10px;
                                              border-radius: 5px;
                                          }
                                      </style>
                                  </head>
                                  <body>
                                  <div class="frame">
                                  <div class="logo-container">
                                      <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                  </div>
                                  <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                  <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                  <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a usar la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de control y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                  <p>Las actividades de control para prevenir delitos que podrán utilizar son:</p>
                                  <ol>
                                      <li>Declaración de Conflicto de Interés</li>
                                      <li>Registro de Reuniones con Funcionarios Públicos o Personas de Interés</li>
                                      <li>Transferencias de Valor</li>
                                      <li>Canal de Denuncias</li>
                                      <li>Repositorio con Procedimientos e Información del Modelo de Prevención de Delitos</li>
                                  </ol>
                                  <p>Para comenzar vaya al siguiente link de abajo:</p>
                                  <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                                  <p>Su Usuario es: ${csvEmails[i]}</p>
                                  <p>Su Contraseña es: (Escriba los 6 Primeros digitos de su Rut)</p>
                                  </p>
                              
                                  <p>${values.link}</p>
                                  <p>Muchas Gracias por su compromiso al participar en estos controles.</p>
                                  <p>Saludos Cordiales</p>
                                  <p>Equipo Encargado de Prevención de Delitos.</p>
                                  <p>Eticpro.</p>
                                  <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                  <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                  <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                  <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                  <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
                                  </div>
                                       
                                          
                                  </body>
                                  </html>`;
                              

                                            
                                        } 
                                        if (values.opCampaign === "TV") {


                                          // Invitación usar Eticpro 
                                          subject = `Invitación de ${formData6.nombreempresa} a Declarar Transferencia de Valor en Eticpro`;

                                          emailTemplate = `
                                          <!DOCTYPE html>
                                          <html>
                                          <head>
                                              <style>
                                                  .frame {
                                                      border: 2px solid #ccc;
                                                      padding: 20px;
                                                      border-radius: 10px;
                                                      width: 80%;
                                                      margin: 0 auto;
                                                      text-align: left; /* Align text to the left */
                                                      position: relative; /* Add relative positioning to the frame */
                                                  }
                                      
                                                  .logo-container {
                                                      position: absolute;
                                                      top: 10px; /* Adjust top value to position the logo vertically */
                                                      right: 10px; /* Adjust right value to position the logo horizontally */
                                                  }
                                      
                                                  /* Styles for the box containing the logo */
                                                  .logo-box {
                                                      text-align: center;
                                                      margin-bottom: 20px;
                                                  }
                                                  .box {
                                                      background-color: #f2f2f2;
                                                      padding: 10px;
                                                      border-radius: 5px;
                                                  }
                                              </style>
                                          </head>
                                          <body>
                                              <div class="frame">
                                                  <div class="logo-container">
                                                      <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
                                                  </div>
                                                  <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

                                                  <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
                                                  <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus Transferencias de Valor en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
                                                  
                                                  <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
                                                  <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
                                                  <p>Usuario: ${csvEmails[i]}</p>
                                                  <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
                                                  <p>Paso 2: En el menú de la izquierda vaya a Mis Transferencias de Valor y haga click en Nueva Transferencia, luego conteste su declaración y envíe.</p>
                                                  <p>${values.link}</p>
                                                  <p>Gracias por su atención y cooperación.</p>
                                                  <p>Equipo Encargado de Prevención de Delitos.</p>
                                                  <p>Eticpro.</p>
                                                  <p><a href="www.eticpro.com">www.eticpro.com</a></p>
                                                  <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                                  <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
                                                  <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
                                                  <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
                                                  </div>
                                          </body>
                                          </html>`;
                                      

                                            
                                        } 
                                        if (values.opCampaign === "PEP") {




                                          // Invitación usar Eticpro 
                                          subject = `Invitación de ${formData6.nombreempresa} a Declarar Reuniones en Eticpro`;

                                          emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
      <style>
          .frame {
              border: 2px solid #ccc;
              padding: 20px;
              border-radius: 10px;
              width: 80%;
              margin: 0 auto;
              text-align: left; /* Align text to the left */
              position: relative; /* Add relative positioning to the frame */
          }

          .logo-container {
              position: absolute;
              top: 10px; /* Adjust top value to position the logo vertically */
              right: 10px; /* Adjust right value to position the logo horizontally */
          }

          /* Styles for the box containing the logo */
          .logo-box {
              text-align: center;
              margin-bottom: 20px;
          }
          .box {
              background-color: #f2f2f2;
              padding: 10px;
              border-radius: 5px;
          }
      </style>
  </head>
  <body>
      <div class="frame">
          <div class="logo-container">
              <img src="${LogoImage}" alt="Logo" className="logo" style={{ width: '10%' }}/>
          </div>
          <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

          <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
          <p>Por requerimiento de ${formData6.nombreempresa}, les invitamos cordialmente a registrar sus reuniones con personas de interés en la plataforma ETICPRO. Esta herramienta tecnológica facilita la participación en actividades de control y evidencia del Modelo de Prevención de Delitos (MPD), conforme a la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
          <p>Las personas de interés incluyen funcionarios públicos con poder de decisión que puedan influir en Schwager, así como líderes de comunidades indígenas o vecinales afectadas por nuestras operaciones. No registrar estas reuniones, ya sean presenciales u online, en ETICPRO podría exponer a la organización y a las personas involucradas a riesgos legales.</p>

          <p>A continuación, los pasos para acceder a la plataforma:</p>

          <p>1. Ingrese a: <a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
          <p>2. Usuario: ${csvEmails[i]}</p>
          <p>3. Contraseña: (Vaya a Recuperar Contraseña si la has olvidado)</p>
          <p>4. En el menú de la izquierda  selecciona "Mis Reuniones"</p>
          <p>5. Haz clic en "Nueva Reunión" y completa tu declaración y envíala.</p>

          <p>${values.link}</p>


          <p>Gracias por tu colaboración y compromiso con la transparencia.</p>

          <p>Equipo Encargado de Prevención de Delitos.</p>
          <p>Eticpro.</p>
          <p><a href="www.eticpro.com">www.eticpro.com</a></p>
          <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
          <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
          <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
          <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
      </div>
  </body>
  </html>`;


                                            
                                        } 
                                        if (values.opCampaign === "Read") {


                                          // Invitación usar Eticpro 
                                          subject = `Invitación de ${formData6.nombreempresa} a leer nuevo documento en Eticpro`;

                  
  emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
      <style>
          .frame {
              border: 2px solid #ccc;
              padding: 20px;
              border-radius: 10px;
              width: 80%;
              margin: 0 auto;
              text-align: left; /* Align text to the left */
              position: relative; /* Add relative positioning to the frame */
          }

          .logo-container {
              position: absolute;
              top: 10px; /* Adjust top value to position the logo vertically */
              right: 10px; /* Adjust right value to position the logo horizontally */
          }

          /* Styles for the box containing the logo */
          .logo-box {
              text-align: center;
              margin-bottom: 20px;
          }
          .box {
              background-color: #f2f2f2;
              padding: 10px;
              border-radius: 5px;
          }
      </style>
  </head>
  <body> 
 
      <div class="frame">
      <div class="logo-container">
      <img src="${LogoImage}" alt="Logo" className="logo" style={{  width: '10%' }}/>
    </div>
    <img src="${formData6.logo}" alt="Logo" style="width: 70px; height: 30px;">

          <p>Estimados(as) Colaboradores(as) y Directores(as),</p>
          <p>Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a leer y confirmar entendimiento de un nuevo documento en el Repositorio para cumplir con los Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro.</p>
          
          <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
          <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
          <p>Usuario: ${csvEmails[i]}</p>
          <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
          <p>    Paso 2: En el menú de la izquierda vaya a Repositorio y haga click en los documento que estan pendientes por usted de confirmar lectura y entendimiento(Color Rojo). Los de color verde ya están confirmados por Usted.</p>
          <p>${values.link}</p>
          <p>Gracias por su atención y cooperación.</p>
          <p>Equipo Encargado de Prevención de Delitos.</p>
          <p>Eticpro.</p>
          <p><a href="www.eticpro.com">www.eticpro.com</a></p>
          <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
          <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
          <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
          <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${csvEmails[i]}</p>
          <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
      </div>
  </body>
  </html>`;
                                        } 




                                  if (!noEnviarEmail){
                              const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                emails: [csvEmails[i]],
                                  emailTemplate,
                                  subject,
                                  mailserver: formData6.mailserver,
                                  mailport: formData6.mailport,
                                  mailuser: formData6.mailuser,
                                  passmail: formData6.passmail,
                                  cipherstype: formData6.cipherstype,
                                }),
                              });
                            
                          
                                  if (emailResponse.ok) {
                                    // Email sent successfully
                                    console.log(`Email sent successfully to ${csvEmails[i]}`);
                                  } else {
                                    // Error sending email
                                    console.log(`Failed to send email to ${csvEmails[i]}`);
                                  }
                                          
                                }
                                      
                        } 
                         catch (error) {
        console.log(error);
        // Handle error
                         }
                        }
  
                      
                            
                        if (postId) {

                          switch (values.opCampaign) {
                            case "CI":
                                subject = `Importante: ${formData6.nombreempresa} requiere que complete el formulario para cumplir con la legislación ley N°20.393`;
                        
                                emailTemplateTxt = `
                                    Estimados(as) Colaboradores(as) y Directores(as),
                                    Por requerimiento de ${formData6.nombreempresa}, le invitamos a completar el siguiente formulario en el siguiente link para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                                    La información recopilada es esencial para que su organización pueda cumplir con la Ley #21.595. Su participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.
                                    Link al formulario
                                    Gracias por su atención y cooperación.
                                    Equipo Encargado de Prevención de Delitos.
                                    Eticpro.
                                    www.eticpro.com
                                    Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                                    No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es}
                                `;
                                break;
                        
                            case "login":
                                subject = `Invitación de ${formData6.nombreempresa} a usar Eticpro`;
                        
                                emailTemplateTxt = `
                                    Estimados(as) Colaboradores(as) y Directores(as),
                                    Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a usar la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                                    Las actividades de control para prevenir delitos que podrán utilizar son:
                                    1. Declaración de Conflicto de Interés
                                    2. Registro de Reuniones con Funcionarios Públicos o Personas de Interés
                                    3. Transferencias de Valor
                                    4. Canal de Denuncias
                                    5. Repositorio con Procedimientos e Información del Modelo de Prevención de Delitos
                                    Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:
                                    https://compliax.onrender.com/
                                    Usuario: 
                                    Contraseña: (Primeros 6 dígitos de su Rut o vaya a Recuperar Contraseña sino la recuerda)
                               
                                    Equipo Encargado de Prevención de Delitos.
                                    Eticpro.
                                    www.eticpro.com
                                    Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                                    No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es}
                                `;
                                break;
                        
                            case "TV":
                                subject = `Invitación de ${formData6.nombreempresa} a Declarar Transferencia de Valor en Eticpro`;
                        
                                emailTemplateTxt = `
                                    Estimados(as) Colaboradores(as) y Directores(as),
                                    Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a declarar sus Transferencias de Valor en la herramienta tecnológica ETICPRO, plataforma que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                                    Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:
                                    https://compliax.onrender.com/
                                    Usuario: }
                                    Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)
                                    Paso 2: En el menú de la izquierda vaya a Mis Transferencias de Valor y haga click en Nueva Transferencia, luego conteste su declaración y envíe.
                                    Gracias por su atención y cooperación.
                                    Equipo Encargado de Prevención de Delitos.
                                    Eticpro.
                                    www.eticpro.com
                                    Para soporte, escríbanos a hello@eticpro.com | Wsup: +569 77657352.
                                    No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es }
                                `;
                                break;
                        
                            case "PEP":
                                subject = `Invitación de ${formData6.nombreempresa} a Declarar Reuniones en Eticpro`;
                        
                                emailTemplateTxt = `
                                Estimados(as) Colaboradores(as) y Directores(as),

                                Por requerimiento de ${formData6.nombreempresa}, les invitamos cordialmente a registrar sus reuniones con personas de interés en la plataforma ETICPRO. Esta herramienta tecnológica facilita la participación en actividades de control y evidencia del Modelo de Prevención de Delitos (MPD), conforme a la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.
                                
                                Las personas de interés incluyen funcionarios públicos con poder de decisión que puedan influir en Schwager, así como líderes de comunidades indígenas o vecinales afectadas por nuestras operaciones. No registrar estas reuniones, ya sean presenciales u online, en ETICPRO podría exponer a la organización y a las personas involucradas a riesgos legales.
                                
                                A continuación, los pasos para acceder a la plataforma:
                                
                                Ingrese a: https://compliax.onrender.com/
                                Usuario: 
                                Contraseña: (Vaya a Recuperar Contraseña si la has olvidado)
                                En el menú de la izquierda selecciona "Mis Reuniones"
                                Haz clic en "Nueva Reunión" y completa tu declaración y envíala.
                                ${values.link}
                                
                                Gracias por tu colaboración y compromiso con la transparencia.
                                
                                Equipo Encargado de Prevención de Delitos.
                                Eticpro.
                                www.eticpro.com
                                `;
                                break;

                                case "Read":
    subject = `Invitación de ${formData6.nombreempresa} a leer nuevo documento en Eticpro`;

                                emailTemplateTxt = `
                                    Estimados(as) Colaboradores(as) y Directores(as),
                                    Por requerimiento de ${formData6.nombreempresa}, tenemos el agrado de invitarlos a leer un nuevo documento en la plataforma ETICPRO. Este documento es de importancia y requerimos su revisión.
                                    Paso 1: Para acceder al documento, por favor vaya al siguiente enlace seguro:
                                    https://compliax.onrender.com/
                                    Usuario: }
                                    Contraseña: (Vaya a Recuperar Contraseña si no la recuerda)
                                    Paso 2: Una vez iniciada sesión, podrá encontrar el documento en la sección correspondiente y revisarlo en detalle.
                                    Agradecemos su atención y cooperación.
                                    Equipo Encargado de Prevención de Delitos.
                                    Eticpro.
                                    www.eticpro.com
                                    Para obtener soporte adicional, no dude en escribirnos a hello@eticpro.com o comunicarse por WhatsApp al +569 77657352.
                                    Recuerde que puede acceder a nuestra plataforma en cualquier momento ingresando a www.eticpro.com, haciendo clic en el botón "Entrar", y utilizando su dirección de correo electrónico como usuario.
                                `;
                                break;
                          }
                        

                          const response9 = await fetch(`${REACT_APP_API_ENDPOINT}posts/${postId}`, {
                            method: "PUT",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                          body: JSON.stringify({
                            link: emailTemplateTxt,
                          }),
                        });
                        
                          // Handle the response..
                          if (response9.ok) {
                            // Email sent successfully
                          } else {
                            // Error sending email
                            console.log(`Failed to save postid`);
                          }
                      } else {
                          console.error("postId is not set");
                      }
                      switch (values.opCampaign) {
                        case "CI":
                          RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampCI" });
                          break;
                        case "login":
                          RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "Camplogin" });
                          break;
                        case "TV":
                          RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampTV" });
                          break;
                        case "PEP":
                          RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampPEP" });
                          break;
                        case "Read":
                          RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CampRead" });
                          break;
                      }
  
                      onSubmitProps.resetForm();
                      navigate("/successPage", {
                        state: { message: "Campaña creada con éxito" }
                    });             
                        
             }

          
  };

  const handleInfoIconClick = (event, infoKey) => {
    setInfoAnchorEl(event.currentTarget);
    setSelectedInfoKey(infoKey);
  };
  const handleInfoPopoverClose = () => {
    setInfoAnchorEl(null);
  };

  const infoPopoverContent = {
    login: "Envía invitación a los usuarios a declarar sus reuniones y registra a cada usuario en Eticpro.",
    MAS: "Carga datos en forma masiva usando planilla .csv ",
    TXT: "Carga datos en forma masiva mails separados por comas ",

    FILE: "Asegúrense de seguir estos pasos:\n\n1. No Modificar la Primera Fila: La primera fila de la planilla contiene encabezados importantes para cada columna, como 'nombres', 'apellidos', 'rut', 'email', 'empresa' y 'área'. Estos encabezados son esenciales para identificar la información correcta en cada columna. No deben ser modificados ni alterados de ninguna manera.\n\n2. Agregar Datos de los destinatarios: A partir de la segunda fila en adelante, encontrarán filas en blanco. En estas filas, deberán llenar la información solicitada para cada destinatario y es mandatorio que tengan al menos su email. \n\n 3. Información Relevante: Ingrese  RUT de cada uno sin puntos ni guiones. Ingresen el nombre de la organización, área o departamento al que pertenecen."
};
  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };


  return (
    <Formik
    onSubmit={handleFormSubmit}
    initialValues={{ ...initialValuesEditCampaign, ...initialValuesRegister }}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
        
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{ borderRadius: "20px",
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        
          <p></p>
          <TextField
            label={<FormattedMessage id="Nombre de la Campaña"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.comments}
            name="comments"
            error={Boolean(touched.comments) && Boolean(errors.comments)}
            helperText={touched.comments && errors.comments}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4" }}
          />

            <TextField
            label={<FormattedMessage id="Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.link}
            name="link"
            error={Boolean(touched.link) && Boolean(errors.link)}
            helperText={touched.link && errors.link}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4" }}
          />

<Box>
<Typography variant="h5" gutterBottom>
{<FormattedMessage id="Tipo de Invitación"/>}

</Typography>
<RadioGroup
    aria-label="Opción"
    name="opCampaign"
    value={values.opCampaign}
    onChange={handleChange}
  >

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="login"
        control={<Radio />}
        label={<FormattedMessage id="Usar Eticpro(login)"/>}
        
      />
  
    </Box>
    <Box display="flex" alignItems="center">
  <FormControlLabel
    value="CI"
    control={<Radio />}
    label={<FormattedMessage id="Conflicto de Interés(CI): Contestar Formulario"/>}
   
   />

</Box>

<Box display="flex" alignItems="center">
      <FormControlLabel
        value="TV"
        control={<Radio />}
        label={<FormattedMessage id="Transferencias de Valor(TV): Declarar"/>}
        
      />

    </Box>

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="CierreTV"
        control={<Radio />}
        label={<FormattedMessage id="Transferencias de Valor(TV): Cerrar Aprobadas"/>}
        
        />
   
        </Box>

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="PEP"
        control={<Radio />}
        label={<FormattedMessage id="Reuniones: Declarar"/>}
        
      />
   
    </Box>
  
   

    <Box display="flex" alignItems="center">

    <FormControlLabel
        value="Read"
        control={<Radio />}
        label={<FormattedMessage id="Documento: Leer"/>}
        
      />
   
    </Box>

    <Box display="flex" alignItems="center">
      <FormControlLabel
        value="ChecklistDD"
        control={<Radio />}
        label={<FormattedMessage id="Diligencia Debida: Avanzar con Checklist "/>}
        
        />
   
        </Box>

   
</RadioGroup>
</Box>


<Popover
      open={Boolean(infoAnchorEl)}
      anchorEl={infoAnchorEl}
      onClose={handleInfoPopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Typography sx={{ borderRadius: "20px", p: 2 }}>
        {infoPopoverContent[selectedInfoKey]}
      </Typography>
    </Popover>
    <Dialog
  open={showInvalidRutPopup}
  onClose={() => setShowInvalidRutPopup(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"RUT Inválido"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {invalidRutMessage}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowInvalidRutPopup(false)} color="primary" autoFocus>
      Entendido
    </Button>
  </DialogActions>
</Dialog>


{ (values.opCampaign !== "ChecklistDD" && values.opCampaign !== "CierreTV") && (
    <RadioGroup
aria-label="Opción"
name="opLoad"
value={values.opLoad}
onChange={handleChange}
>



<Typography variant="h5" gutterBottom>
{<FormattedMessage id="Tipo de Carga"/>}

</Typography>

<Box display="flex" alignItems="center">
  <FormControlLabel
    value="MAS"
    control={<Radio />}

    label={<FormattedMessage id="Carga Masiva" />}
  />
  <IconButton
    aria-label="info"
    size="small"
    onClick={(event) => handleInfoIconClick(event, "MAS")}
  >
    <InfoIcon />
  </IconButton>
</Box>

<Box display="flex" alignItems="center">
  <FormControlLabel
    value="TXT"
    control={<Radio />}

    label={<FormattedMessage id="Emails" />}
  />
  <IconButton
    aria-label="info"
    size="small"
    onClick={(event) => handleInfoIconClick(event, "v")}
  >
    <InfoIcon />
  </IconButton>
</Box>


</RadioGroup>
)}

{
 (values.opCampaign !== "ChecklistDD" && values.opCampaign !== "CierreTV") && (
  <Box display="flex" alignItems="center">
    <FormControlLabel
      control={
        <Checkbox
          checked={noEnviarEmail}
          onChange={handleCheckboxChange}
        />
      }
      label={<FormattedMessage id="No enviar email" />}
    />
  </Box>
)}



{/* Conditional rendering of Dropbox */}
{values.opLoad === "TXT" && 
 (values.opCampaign !== "ChecklistDD" && values.opCampaign !== "CierreTV") && (
    <Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
    {/* Textbox for Email Input */}
    <TextField
      label={<FormattedMessage id="Ingresar Emails separados por coma(,)  (Enrola usuarios nuevos dejando en blanco nombre,rut y otra info personal para que los completen los usuario posteriormente"/>}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.description}
      name="description"
      multiline
      rows={8}
      fullWidth
      sx={{ borderRadius: "20px", gridColumn: "span 4" }}
    />

   
  </Box>
)}



{values.opLoad === "MAS" && (
<Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
 

           {/* CSV Drop Zone */}
           <Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => handleCsvDrop(e.target.files[0])}
              style={{ display: "none" }}
              id="csv-dropzone"
            />
            <label htmlFor="csv-dropzone">
              <Box
                sx={{ borderRadius: "20px",
                  border: "2px dashed #ccc",
                  borderRadius: "5px",
                  p: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >

<Box width="100%" padding="2rem 6%" display="flex" flexDirection="column" justifyContent="space-between">
    <Paper elevation={3} style={widgetStyle}>
      <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
      {<FormattedMessage id="Suba Aquí el Archivo"/>}

        <IconButton
    aria-label="info"
    size="small"
    onClick={(event) => handleInfoIconClick(event, "FILE")}
  >
    <InfoIcon />
  </IconButton>
      </Typography>


      </Paper>
</Box>          

                    </Box>
            </label>
          </Box>

            {/* Display Parsed CSV Data */}
            <Box sx={{ borderRadius: "20px", gridColumn: "span 4" }}>
            {csvData.length > 0 && (
  <div>
    <Typography variant="h6" gutterBottom>
      {/* Message indicating data is loaded */}
    </Typography>
    <ul>
      {csvData.map((row, index) => (
        <li key={index} style={{ color: invalidRuts.includes(row.rut) ? 'red' : 'inherit' }}>
          Nombres: {row.nombres}, Apellidos: {row.apellidos}, Rut: {row.rut}
          {invalidRuts.includes(row.rut) && ' (RUT Inválido)'} {/* Highlight invalid RUTs */}, Email: {row.email}, Organización: {row.empresa}, Area: {row.area}, Cargo: {row.cargo}, Nacionalidad: {row.nacionalidad}
        </li>
      ))}
    </ul>
  </div>
)}

</Box>

</Box>
)}




 </Box>

 {/* BUTTONS */}
 <Box>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ borderRadius: "20px",
              m: "2rem 0",
              p: "1rem",
              backgroundColor: theme.palette.primary.red,
              color: theme.palette.background.light,
              "&:hover": { color: theme.palette.primary.light },
            }}
            disabled={loading} // Disable the button when loading is true

          >
            {<FormattedMessage id="Enviar"/>}
          </Button>
{/* Loading Popup */}
<Dialog open={loading} onClose={() => {}}>
        <Box sx={{ borderRadius: "20px", p: 2, textAlign: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="body1" mt={2}>
          {<FormattedMessage id="Procesando datos..."/>}
          </Typography>
        </Box>
      </Dialog>

          <Box>

          </Box>
        </Box>
      </form>
    )}
  </Formik>
);
};

export default Campaign;
import React, { useState } from 'react';
import { Box, TextField, Button ,CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DialogWidget = ({ onSendMessage }) => {
  const [messageDetail, setMessageDetail] = useState('');
  const theme = useTheme();

  const handleSendMessage = () => {
    if (messageDetail.trim() === '') {
      return; // Prevent sending empty messages
    }

    onSendMessage(messageDetail); // No need to pass sender, use loggedInUserId from parent
    setMessageDetail('');
  };

  return (
    <Box>
    <TextField
      label={<FormattedMessage id="Su mensaje aquí..."/>}
      value={messageDetail}
      onChange={(e) => setMessageDetail(e.target.value)}
      fullWidth
      multiline
      rows={3}
      margin="none" // Change margin to "none" to remove space
    />
    <Button 
    fullWidth 
    type="submit"
    variant="contained" 
    color="secondary" 
    sx={{ borderRadius: "20px",
      m: "2rem 0",
      p: "1rem",
      backgroundColor: theme.palette.primary.red,
      color: theme.palette.background.light,
      "&:hover": { color: theme.palette.primary.light },
    }}
    onClick={handleSendMessage}

    >
      {<FormattedMessage id="Enviar"/>} Mensaje
    </Button>
  </Box>
  );
};

export default DialogWidget;

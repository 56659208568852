import { Box, Typography, useTheme, IconButton, Button } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";

import { tokens } from "../../theme";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { FormattedMessage } from 'react-intl';


const Allmeetings = ({}) => {
  
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const [user, setUser] = useState([]); // Updated: Initialize data state
  const [userData, setUserData] = useState([]); // Updated: Initialize user data state

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredData, setFilteredData] = useState([]);
  // Rename one of the variables to something different, e.g., filteredSearchData
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchData(); // Fetch meetings data and user data
  }, []); // Empty dependency array will ensure this effect runs only once on mount

  const fetchData = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      const meetingsWithIds = formData.map((meeting) => {
        // Map the _id property to id
        const rowId = meeting._id;
  
        return {
          ...meeting,
          id: rowId, // Map _id to id
        };
      });
  
      setData(meetingsWithIds);
      setFilteredData(meetingsWithIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  



  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  


      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}meetings/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}meetings/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
      setFilteredData(updatedData);
    }
  };
  

  

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
  
    const filteredData = data.filter((row) => {
      const { firstName, lastName, email } = row;
      const fullName = `${firstName} ${lastName}`.toLowerCase();
  
      return (
        fullName.includes(inputValue.toLowerCase()) ||
        email.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  
    setFilteredData(filteredData);
  };

  const columns = [

    { field: "_id", headerName: "id", flex: 1, editable: true,headerClassName: "bold-header",
  },
    { field: "participantes", headerName: "Participantes ", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "title", headerName: "Asunto", flex: 1, editable: true, headerClassName: "bold-header", },
    { field: "start", headerName: "Fecha", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "detalle", headerName: "Detalle", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "willPayReceiveValue", headerName: "Pago/Recepción", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "empresa", headerName: "Contraparte", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "area", headerName: "Area Contraparte", flex: 1, editable: true ,headerClassName: "bold-header",
  },
    { field: "idcontrato", headerName: "id contrato", flex: 1, editable: true ,headerClassName: "bold-header",
  },

  
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleRowClick(params, "edit")}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleRowClick(params, "delete")}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  

  return (
    <Box m="20px">
      <Header title={<FormattedMessage id="Meetings" />} subtitle={<FormattedMessage id="Administrar todas las Reuniones" />} />

      <div>

      <p>ID Contrato: {idcontrato}</p>
   
   

    </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",

          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: GridToolbar }}

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick} // Updated: Pass the click handler
          headerClassName="bold-header"
          rowsPerPageOptions={[10, 20, 50]} // Include 25 in the options

        />
      </Box>

      
    </Box>
  );
};

export default Allmeetings;

import { useDispatch, useSelector } from "react-redux";

import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
const { REACT_APP_API_ENDPOINT } = process.env;


const PdfViewerModal = ({ open, onClose, pdfUrl, filename, textStyleColor }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(0.8);
  const [agreement, setAgreement] = useState(false); // Initially false
  const containerRef = useRef();
  const token = useSelector((state) => state.token);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedInUserId = useSelector((state) => state.user._id);
  const idcontrato = useSelector((state) => state.user.idcontrato);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const pageHeight = container.scrollHeight / numPages;
    const scrolledPage = Math.floor((container.scrollTop + pageHeight / 2) / pageHeight) + 1;
    setCurrentPage(scrolledPage);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [numPages]);


  useEffect(() => {
    // Reset currentPage to 1 whenever pdfUrl changes
    setCurrentPage(1);
  }, [pdfUrl]);

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  
  const handlePreviousPage = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    
    // Scroll to the top of the previous page
    const container = containerRef.current;
    if (container) {
      const prevPageTop = container.scrollHeight / numPages * (prevPage - 1);
      container.scrollTop = prevPageTop;
    }
  };
  // Navigation handlers
   const handleNextPage = () => {
    const nextPage = Math.min(currentPage + 1, numPages);
    setCurrentPage(nextPage);

    // Scroll to the top of the next page
    const container = containerRef.current;
    if (container) {
      const nextPageTop = (container.scrollHeight / numPages) * (nextPage - 1);
      container.scrollTop = nextPageTop;
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'download.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleAgreementChange = (event) => {
    setAgreement(event.target.checked);
  };

  const handleClose = async (file) => {
    console.log("guardando checkbox");
    if (agreement) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}session/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: loggedInUserId,
            fileName: filename,
            type: "Read",
            idcontrato: idcontrato,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to log confirmation');
        }
      } catch (error) {
        console.error('Error logging confirmation:', error);
        // Handle error
      }
    }
    onClose();
  };

 


  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="pdf-viewer"
      aria-describedby="pdf-viewer-modal"
    >
      <Box sx={{ 
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', height: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 4, overflow: 'hidden', display: 'flex', flexDirection: 'column',
      }}>
        <Box className="control-panel" sx={{
    position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#000', color: '#fff', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    gap: '8px',
}}>
    <Button onClick={handleZoomOut} sx={{ color: '#fff', minWidth: 'auto' }}>-</Button>
    <Typography sx={{ color: '#fff' }}>{`Zoom: ${Math.round(zoom * 100)}%`}</Typography>
    <Button onClick={handleZoomIn} sx={{ color: '#fff', minWidth: 'auto' }}>+</Button>
    {/* Navigation Buttons */}
    <Button onClick={handlePreviousPage} disabled={currentPage === 1} sx={{ color: '#fff', textTransform: 'none' }}>Anterior</Button>
    <Typography sx={{ color: '#fff' }}>{`Página ${currentPage} / ${numPages}`}</Typography>
    <Button onClick={handleNextPage} sx={{ color: '#fff', textTransform: 'none' }}>Siguiente</Button>
    <Button onClick={handleDownload} sx={{ color: '#fff', maarginLeft: '10px', marginLeft: 'auto' }}>Descargar</Button>
</Box>

<Box ref={containerRef} sx={{ overflowY: 'auto', flexGrow: 1, height: '100%' }}>
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Box key={`page_${index + 1}`} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#4f4f4f', margin: '16px 0', padding: '15px',
              }}>
                <Page pageNumber={index + 1} scale={zoom} />
              </Box>
            ))}
          </Document>
        </Box>
      
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {textStyleColor !== 'green' && textStyleColor !== undefined && (
    <>
      <Checkbox
        checked={agreement}
        onChange={handleAgreementChange}
        inputProps={{ 'aria-label': 'He leído y entiendo el documento' }}
      />
      <Typography variant="body1" sx={{ color: '#000' }}>He leído y entiendo el contenido de este documento.</Typography>
      <Button
        variant="contained"
        onClick={() => {
          handleClose(pdfUrl);
          onClose();
        }}
        disabled={!agreement}
        sx={{ ml: 2, backgroundColor: '#000', color: '#fff' }}
      >
        Confirmar
      </Button>
    </>
  )}
</Box>




      </Box>
    </Modal>
  );
};

export default PdfViewerModal;

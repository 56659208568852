import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  useRadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { format } from "date-fns";
import { Checkbox } from "@mui/material";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
  opauth: yup.string().notRequired("notRequired"),
  authtxt: yup.string().notRequired("notRequired"),
  opmitigacion: yup.string().notRequired("notRequired"),
  mitigaciontxt: yup.string().notRequired("notRequired"),
  emailaviso: yup.string().notRequired("notRequired"),
  comentariosAdi: yup.string().notRequired("notRequired"),

});

const initialValuesEditForm = {

    opNegativa: "",
    txtNegativa: "",
    opDifdds: "",
    txtDifdds: "",
    opAntanalisis: "",
    txtAntanalisis: "",
    confirmNa: "",
  estado: "",
  emailaviso: "",  // Ensure a default value or an empty string

};

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUsermail = useSelector((state) => state.user.email);
  const logoempresa = useSelector((state)  => state.client.logo);
  const loggedclient = useSelector((state) => state.client);

  useEffect(() => {
    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}dd/${_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const formData = await response.json();
          setForm(formData); // Set the form data obtained from the server
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    getForm(); // Call the function to fetch the form data
  
  }, [_id, token]);



  const handleFormSubmit = async (values, onSubmitProps) => {
    try {

      // Generate a random 16-character string for idevaluation
      const generateId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';
        for (let i = 0; i < 16; i++) {
          id += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return id;
      };
  
      // Patch the form data with new values and estado
    const response = await fetch(`${REACT_APP_API_ENDPOINT}dd/${_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        opNegativa: values.opNegativa,
        txtNegativa: values.txtNegativa,
        opDifdds: values.opDifdds,
        txtDifdds: values.txtDifdds,
        txtMitigacion: values.txtNegativa,
        opAntanalisis: values.opAntanalisis,
        txtAntanalisis: values.txtAntanalisis,
        confirmNa: values.confirmNa,
        emailaviso: values.emailaviso,
        dateChecklist: currentDate,  // Set the updatedAt value to the current date
        estado: "En Aprobacion",
      }),
    });
    const responseData = await response.json();

    console.log(responseData);
     
    if (response.ok) {

      // Send email after the patch is successful
      const subject = `Estado de Diligencia Debida en ${loggedclient.nombreempresa}`;

      const emailTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          .frame {
            border: 2px solid #ccc;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            margin: 0 auto;
            text-align: left;
            position: relative;
          }
          .logo-container {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          .logo-box {
            text-align: center;
            margin-bottom: 20px;
          }
          .box {
            background-color: #f2f2f2;
            padding: 10px;
            border-radius: 5px;
          }
        </style>
      </head>
      <body>
        <div class="frame">
          <div class="logo-container">
          <img src=${LogoImage} alt="Logo" style="width: 100%;" />
          </div>
          <img src=${logoempresa} alt="Logoemp" style="width: 20%;" /> <!-- Updated width to 10% -->
          <p>Estimada Contraparte:</p>

          <p>Le informamos que se ha revisado de parte del equipo de Compliance la diligencia debida en la organización ${loggedclient.nombreempresa}. Le invitamos ingresar a Eticpro en nuestro link de abajo, módulo Diligencia Debida para revisar el estado.</p>
          <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
          <p>Para soporte escribanos a <a href="mailto:hello@eticpro.com">hello@eticpro.com</a> o por Whatsapp al +56 977 657 352.</p>
          <p>Gracias por su atención y cooperación.</p>
          <p>Equipo Encargado de Prevención de Delitos</p>
          <p>Eticpro.</p>
          <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        </div>
      </body>
      </html>
    `;

      const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: [formData.emailaviso, formData.emailcontacto],
          emailTemplate: emailTemplate,
          subject: subject,
        }),
      });

      if (emailResponse.ok) {
        // Email sent successfully
        console.log("Email sent successfully");
      } else {
        // Error sending email
        console.log("Failed to send email");
      }
    } else {
      // Handle unsuccessful patch request
      console.log("Failed to update form data");
    }

    onSubmitProps.resetForm();
    navigate("/dd");
  } catch (error) {
    console.log(error);
    // Handle error
  }
};
useEffect(() => {

const getForm = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}dd/${_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const formData = await response.json();
      setForm(formData);
    } else {
      console.log("Failed to get form");
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

}, []);


useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);


  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
      validationSchema={editformSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {iseditform && (
              <>
        <Typography variant="h3">{<FormattedMessage id="Para su Acción"/>}:  Checklist</Typography>

<p></p>
                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>

                  {<FormattedMessage id="1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?"/>}
                                    </Typography>                  
                  <RadioGroup
                    aria-label="Opción"
                    name="opNegativa"
                    value={values.opNegativa}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.txtNegativa}
                  name="txtNegativa"
                  error={Boolean(touched.txtNegativa) && Boolean(errors.txtNegativa)}
                  helperText={touched.txtNegativa && errors.txtNegativa}
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
                  <Typography variant="h6" gutterBottom>
                  {<FormattedMessage id="2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?"/>}                  </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opDifdds"
                    value={values.opDifdds}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.txtDifdds}
                  name="txtDifdds"
                  error={Boolean(touched.txtDifdds) && Boolean(errors.txtDifdds)}
                  helperText={touched.txtDifdds && errors.txtDifdds}
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
                <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
 <Typography variant="h6" gutterBottom>
 {<FormattedMessage id="3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?"/>}
                                 </Typography>
                  <RadioGroup
                    aria-label="Opción"
                    name="opAntanalisis"
                    value={values.opAntanalisis}
                    onChange={handleChange}
                    sx={{ borderRadius: "20px",
                      "& .MuiSvgIcon-root": {
                        color: palette.primary.light, // Selected radio color (red)
                      },
                      "& .Mui-checked": {
                        color: "#FFFFFF", // Not selected radio color (white)
                      },
                    }}
                  >
                    <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.txtAntanalisis}
                  name="txtDifdds"
                  error={Boolean(touched.txtAntanalisis) && Boolean(errors.txtAntanalisis)}
                  helperText={touched.txtAntanalisis && errors.txtAntanalisis}
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />


<FormControlLabel
  control={
    <Checkbox
      checked={values.confirmNa}
      onChange={(e) => {
        setFieldValue("confirmNa", e.target.checked);
        setCheckboxChecked(e.target.checked);
      }}
      name="confirmNa"
      color="primary"
    />
  }
  label={<FormattedMessage id="Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte"/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>
                
<TextField
                  label={<FormattedMessage id="Compartir resultado a email, separado por comas"/>}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.emailaviso}
                  name="emailaviso"
                  error={Boolean(touched.emailaviso) && Boolean(errors.emailaviso)}
                  helperText={touched.emailaviso && errors.emailaviso}
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />

              </>
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit" color="secondary" variant="contained"
              
              onClick={() => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  estado: "Resuelta",
                }));
             
                setCurrentDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));

              }}
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.primary.red,
                color: palette.background.light,
                "&:hover": { color: palette.primary.light },
              }}
            >
              {<FormattedMessage id="Enviar"/>}
            </Button>
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;

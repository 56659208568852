import React, { useContext, useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FlexBetween from "../../components/FlexBetween";
import Checklist from "@mui/icons-material/CheckCircleOutline";
import Flaky from "@mui/icons-material/Flaky";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MailsOutlinedIcon from "@mui/icons-material/SendSharp";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import CrisisAlert from "@mui/icons-material/CrisisAlert";
import CompareArrows from "@mui/icons-material/CompareArrows";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Attachment from "@mui/icons-material/Attachment";

import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Settings from "@mui/icons-material/Settings";



import { setIdcontrato } from "../../state";
import { setCmf} from "../../state";
import { FormattedMessage } from 'react-intl';
import { setClient } from "../../state";


const Sidebar = ({ userId }) => {
  const idcontrato = useSelector((state) => state.user.idcontrato); // Add the idcontrato selector

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const token = useSelector((state) => state.token);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const navigate = useNavigate();
  const [formState, setFormState] = useState(null); // Rename form to formState
  const [profilePicture, setProfilePicture] = useState(null);
  const { _id, picturePath } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const loggedclient = useSelector((state) => state.client);

  const menuItems = [
    {
      title: "Clients",
      to: "/clients",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "Contab",
      to: "/contab",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin", "admincontab"],
    },


    {
      title: "All Meetings",
      to: "/allmeetings",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "All Campaigns",
      to: "/allcamp",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "All Forms",
      to: "/alldecl",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "All WhistleBlower",
      to: "/allcomplaints",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "Value Transfer",
      to: "/allttvalues",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: ["superadmin"],
    },
    {
      title: "Mis Pendientes",
      to: "/",
      icon: <HomeOutlinedIcon />,
      allowedRoles: [
        "declare",
        "admin","admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "superadmin",
        "aprobador",
        

        "investigador",
        "gestionpersonas",
        "sales",
        "aprobador",

      ],
    },
    {
      title: "Mis Pendientes(D)",
      to: "/dashdeclare",
      icon: <HomeOutlinedIcon />,
      allowedRoles: ["superadminx"],
    },
    {
      title: "Mis Declaraciones",
      to: "/myforms",
      icon: <TimelineOutlinedIcon />,
      allowedRoles: [
        "declare", 
        "investigador",
        "admin","admincontab",
        "coadmin",
        "superadmin",
        "comitetica", "adminetica",
        "aprobador", 

        "gestionpersonas",
       "sales",
      ],
    },
    {
      title: "Campañas",
      to: "/campaigns",
      icon: <MailsOutlinedIcon />,
      allowedRoles: [
        "admin",
        "admincontab",
        "aprobador", 

        "coadmin",
        "superadmin",
        "comitetica", "adminetica",
        "sales",
      ],
    },
    {
      title: "Declaraciones",
      to: "/forms",
      icon: <ReceiptOutlinedIcon />,
      allowedRoles: [
        "admin",
        "admincontab",
        "superadmin",
        "coadmin",
        "comitetica", "adminetica",
        "aprobador", 
"sales",

      ],
    },
    {
      title: "Reuniones",
      to: "/meetings",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: [
        "declare",
        "admin",
        "admincontab",
        "superadmin",
        "coadmin",
        "aprobador",  

        "comitetica", "adminetica",
        "investigador",
        "gestionpersonas",
        "sales",
      ],
    },
    {
      title: "Calendario",
      to: "/reuniones",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: [
       
        "superadmin",
       
      ],
    },

 
    {
      title: "Transferencias de Valor",
      to: "/ttvalues",
      icon: <CompareArrows />,
      allowedRoles: [
        "declare",
        "admin","admincontab",
        "superadmin",
        "coadmin",
        "aprobador",
        "comitetica", "adminetica",
        "investigador",
        "gestionpersonas",
        "sales",
        "aprobador",  



      ],
    },
    {
      title: "Actividad",
      to: "/allsessions",
      icon: <ArrowForward />,
      allowedRoles: [
        "superadmin",
        "aprobador",

        "admin","admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "investigador",


      ],
    },
    {
      title: "Usuarios",
      to: "/users",
      icon: <PeopleOutlinedIcon />,
      allowedRoles: [
        "superadmin",
        "admin",
        "admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "investigador",
        "sales",
      ],
    },
    {
      title: "Denuncias",
      to: "/complaints",
      icon: <CrisisAlert />,
      allowedRoles: [
        "externo",
        "declare",
        "admin",
        "admincontab",
        "coadmin",
        "superadmin",
        "comitetica", "adminetica",
        "aprobador",
        "investigador",
        "gestionpersonas",
        "sales",
      ],
    },
    {
      title: "Diligencia Debida",
      icon: <Checklist />,
      allowedRoles: [
        "declare",
        "superadmin",
        "admin",
        "admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "aprobador",
        "investigador",
        "gestionpersonas",
        "sales",
      ],
      subItems: [
           
        {
          title: "Lista Maestra",
          to: "/company",
          icon: <Flaky />,
          allowedRoles: [
            "declare",
            "superadmin",
            "admin",
            "admincontab",
            "coadmin",
            "comitetica", "adminetica",
            "aprobador",
            "investigador",
            "gestionpersonas",
            "sales",
          ],
        },
        {
          title: "Contraparte",
          to: "/dd",
          icon: <Checklist />,
          allowedRoles: [
            "declare",
            "superadmin",
            "admin",
            "admincontab",
            "coadmin",
            "comitetica", "adminetica",
            "aprobador",
            "investigador",
            "gestionpersonas",
            "sales"
          ],
        },
       
      
     
        {
          title: "Postulante",
          to: "/ddPostulante",
          icon: <Checklist />,
          allowedRoles: [
           
            "superadmin",
            "admin",
            "admincontab",
            "coadmin",
            "comitetica", "adminetica",
            "aprobador",
            "gestionpersonas",
            "investigador",
"sales",

          ],
        },
      ],
    },

  
    {
      title: "Repositorio",
      to: "/repository",
      icon: <Attachment />,
      allowedRoles: [
        "declare",
        "superadmin",
        "admin","admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "aprobador",
        "investigador",
        "gestionpersonas",
       "sales"
      ],
    },
    {
      title: "Chat AI(Demo)",
      to: "/repositoryBP",
      icon: <Attachment />,
      allowedRoles: [
       "admincontab",
        "superadmin","sales"
       
     
       
      ],
    },
    {
      title: "Configuración",
      to: "/form",
      icon: <Settings />,
      allowedRoles: [
        "externo",
        "declare",
        "admin",
        "admincontab",
        "superadmin",
        "coadmin",
        "comitetica", "adminetica",
        "aprobador",
        "investigador",
        "gestionpersonas",
        "sales",
      ],
    },
    {
      title: "Análisis de Riesgos",
      to: "/myrisks",
      icon: <HelpOutlineOutlinedIcon />,
      allowedRoles: [
        "superadmin", "sales"
    

      ],
    },
    {
      title: "Glosario",
      to: "/faq",
      icon: <HelpOutlineOutlinedIcon />,
      allowedRoles: 
      [
        "declare",
        "superadmin",
        "admin","admincontab",
        "coadmin",
        "comitetica", "adminetica",
        "aprobador",
        "postulante",
        "investigador",
        "gestionpersonas",
        "sales",
    ],
    },
  ];

const Item = ({ title, to, icon, selected, setSelected, subItems }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
      if (subItems) {
        setIsExpanded(!isExpanded);
      } else {
        setSelected(title);
      }
    };
  
    return (
      <>
        <MenuItem
          key={title + (to || "")}
          active={selected === title}
          style={{ color: colors.grey[100] }}
          onClick={handleClick}
          icon={icon}
        >
          <Typography>
            <FormattedMessage id={String(title)} />
          </Typography>
          {to && <Link to={to} />}
        </MenuItem>
        {isExpanded && subItems && (
          <Box paddingLeft="20px">
            {subItems.map((subItem) => (
              <Item
                key={subItem.title}
                title={subItem.title}
                to={subItem.to}
                icon={subItem.icon}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </Box>
        )}
      </>
    );
  };
  

  const getUser = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}users/${loggedInUserId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
    const { picturePath } = data.picturePath;
    const { idcontrato } = data;


    let filteredItems = [];
    const name = data.firstName + data.lastName;
    setFormState(data); // Update setForm to setFormState
    setIdcontrato(idcontrato);
    

    if (data.userprofile === "admin") {
      filteredItems = menuItems.filter((item) => item.allowedRoles.includes("admin"));
    } else if (data.userprofile === "adminetica") {
      filteredItems = menuItems.filter((item) => item.allowedRoles.includes("adminetica"));
    
    
    } else if (data.userprofile === "superadmin") {
      filteredItems = menuItems.filter((item) => item.allowedRoles.includes("superadmin"));
    } else if (data.userprofile === "declare") {
      filteredItems = menuItems.filter((item) => item.allowedRoles.includes("declare"));
    
  } else if (data.userprofile === "coadmin") {
    filteredItems = menuItems.filter((item) => item.allowedRoles.includes("coadmin"));
  
} else if (data.userprofile === "gestionpersonas") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("gestionpersonas"));

} else if (data.userprofile === "admincontab") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("admincontab"));


} else if (data.userprofile === "investigador") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("investigador"));

} else if (data.userprofile === "externo") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("externo"));

} else if (data.userprofile === "sales") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("sales"));

} else if (data.userprofile === "aprobador") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("aprobador"));

} else if (data.userprofile === "postulante") {
  filteredItems = menuItems.filter((item) => item.allowedRoles.includes("postulante"));

}

       
    setProfilePicture(picturePath);
    setFilteredMenuItems(filteredItems);
  };

  useEffect(() => {
    getUser();
    const fetchClients = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}clients/${idcontrato}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data1 = await response.json();
          dispatch(setClient({ client: data1 }));
          setProfilePicture(data1.logo);
        }
      } catch (error) {
        console.log("Error fetching client data:", error);
      }
    };
  
    fetchClients(); // Call the fetchClients function when the component mounts
  
  }, [idcontrato, token, dispatch]);


  
  if (!formState) {
    return null;
  }



  if (!user) {
    return null;
  }

  const { userprofile } = user;
  const formdata = formState; // Access the first object in the array

  return (

    <Box
      sx={{ borderRadius: "20px",

       
       
      

        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}


          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h7" color={colors.grey[100]}>

                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
             
          {/*
              <Box display="flex" justifyContent="center" alignItems="center">
                   <UserImage image={picturePath} />
                
              
              </Box>
          */}
              <Box textAlign="center">
                
              <Typography variant="h4" color={colors.grey[100]} fontWeight="bold" sx={{ borderRadius: "20px", m: "15px 0 0 0" }}>
  {formdata.lastName ? `${formdata.firstName} ${formdata.lastName}` : formdata.firstName}
                 </Typography>
             

{profilePicture && (
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          <img src={loggedclient.logo} alt="Client Logo" style={{ width: "70px", height: "30px" }} />
        </Box>
      )}

               <Typography >
            
               {formdata.userprofile}
               </Typography>
            

              </Box>
            </Box>
          )}

<Box paddingLeft={isCollapsed ? undefined : "10%"}>
  {filteredMenuItems.map((item) => (
    <Item
      key={item.title}
      title={item.title}
      to={item.to}
      icon={item.icon}
      selected={selected}
      setSelected={setSelected}
      subItems={item.subItems}
    />
  ))}
</Box>

        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { FormattedMessage } from 'react-intl';


const Team = ({ _id }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredData, setFilteredData] = useState([]);
  // Rename one of the variables to something different, e.g., filteredSearchData
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;


  useEffect(() => {
    fetchData(); // Remove idcontrato from the fetchData call
  }, [idcontrato]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      setData(dataWithFormattedDate); // Set the fetched data to the state here
      setFilteredData(dataWithFormattedDate);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = (params) => {
    const rowId = params.row._id; // Assuming _id is the unique identifier for the row
    navigate(`/formev/${rowId}`);
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
  
    const filteredData = data.filter((row) => {
      const { firstName, lastName, email } = row;
      const fullName = `${firstName} ${lastName}`.toLowerCase();
  
      return (
        fullName.includes(inputValue.toLowerCase()) ||
        email.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  
    setFilteredData(filteredData);
  };

  const columns = [

 
    {
      field: "fullName",
      headerName: "Nombre",
      flex: 1,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "userprofile",
      headerName: "Perfil Usuario",
      flex: 1,
      
    },
  ];

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Administrar mi equipo" />
      <Header title={<FormattedMessage id="TEAM" />} subtitle={<FormattedMessage id="Administrar mi equipo" />} />

      <div>

      <p>ID Contrato: {idcontrato.slice(-4)}</p>
   
    </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: GridToolbar }}

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick} // Updated: Pass the click handler
        />
      </Box>
    </Box>
  );
};

export default Team;

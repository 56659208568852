import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  FormControlLabel,

  Radio,
  RadioGroup,
  
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { Checkbox } from "@mui/material";
import { FormattedMessage } from 'react-intl';

const greenText = { color: 'green' }; // Define the CSS style for green text

const DdDetailFerCheck = () => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the URL
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchFiles = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}dd/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState

  };
 
  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [token, _id]);
  
  if (!form) {
    return null;
  }

  if (!form || form.length === 0) {
    return null;
  }
  const {

    userId,
    estado,
    idcontrato: loggedinusercontrato, // Update the initial value
    emailaviso: loggedinUseremail,
  
//user
firstName,
lastName,
email,
password,
location,
occupation,
userprofile,
idcontrato,
campaignid,
area,
rut,
celular,
status,

 //Organización
 nombreempresa,
 rutempresa,
 address,
 firstNameContacto,
 lastNameContacto,
 rutcontacto,
 emailcontacto,
 cargocontacto,
 celularcontacto,
 nombrefantasia,
 representante,
 industria,
 reason,
 comments,
 createdAt,


 //fer
 opGiro,
 opContrato,
 opPagoanual,
 opReprenta,
 op80,
 opCI,
 opParaiso,
 opPaispago,
 opSugpep,
 opSii,
 opMoraprev,
 opComseeker,
 score,
 risk,
 picturePath,

//checklist
txtComseeker,
opFuentesla,
txtFuentesla,
opInfodirecta,
txtInfodirecta,
opNegativa,
txtNegativa,
opDifdds,
txtDifdds,
opAntanalisis,
txtAntanalisis,
confirmNa,


  } = form;

 
  const stringToBoolean = (value) => value === 'true';

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

  
  return (

    <Box m="2rem 0">
      <Box>

      <Typography variant="h2">{<FormattedMessage id="Diligencia Debida"/>}<span style={greenText}></span></Typography>

      <Typography variant="h4">{<FormattedMessage id="Estado"/>}: <span style={greenText}><FormattedMessage id={`${estado}`}/>, <FormattedMessage
      id={`${risk}`}/> </span></Typography>
      <div>{<FormattedMessage id="Solicitante"/>}: <span style={greenText}>
  {`${form.user.firstName} ${form.user.lastName}, ${form.user.email}`}
</span>
</div>
      <div>{<FormattedMessage id="Organizacion"/>}: <span style={greenText}>{nombreempresa}</span></div>

      <div>{<FormattedMessage id="Creada:"/>} <span style={greenText}>{new Date(createdAt).toLocaleDateString('es-ES')}</span></div>
      <div>{<FormattedMessage id="Rut Organización:"/>} <span style={greenText}>{rutempresa}</span></div>

      <div>{<FormattedMessage id="Direccion"/>}: <span style={greenText}>{address}</span></div>
      <div>{<FormattedMessage id="Contacto"/>}: <span style={greenText}>{firstNameContacto} {lastNameContacto} </span></div>
      <div>{<FormattedMessage id="Rut Contacto"/>}: <span style={greenText}>{rutcontacto}</span></div>
      <div>{<FormattedMessage id="Email Contacto"/>}: <span style={greenText}>{emailcontacto}</span></div>
      <div>{<FormattedMessage id="ID"/>}: <span style={greenText}>{_id.slice(-0)}</span></div>


<p></p>
          </Box>

          <p></p>

        <Box

          display="grid"
          gap="30px"
          gridTemplateColumns="1fr" // Set to a single column
          sx={{
            width: '100%', // Set the width to 100%
            borderRadius: "20px",
            "& > div": { gridColumn: "span 1" }, // Adjusting column span
          }}
        >
  
  <Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>

  <div>
  <Typography variant="h3" gutterBottom>
  {<FormattedMessage id="Formulario de Evaluacion"/>}
  <div>
  <span style={greenText}>
    <FormattedMessage
      id={`${risk}`}  
      values={{ score: score }}
    /> ({score})
  </span>
</div></Typography>

  </div>
  <div>
    <Typography variant="h6" gutterBottom>
      {<FormattedMessage id="(i) ¿La Contraparte efectúa alguno de los siguientes giros: fundación, corporación sin fines de lucro, transporte, turismo, catering, taller de mantención?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opGiro"
      value={opGiro}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opContrato"
      value={opContrato}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(iii) Estimación del Pago Anual superior a 100.000 USD anual?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opPagoanual"
      value={opPagoanual}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
      <FormControlLabel value="Si" control={<Radio required />} label={<FormattedMessage id="Si igual o más de 100.000 USD año"/>} />
      <FormControlLabel value="No" control={<Radio required />} label={<FormattedMessage id="Si menos de 100.000 USD año"/>} />
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Organización o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opReprenta"
      value={opReprenta}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="op80"
      value={op80}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Organización, sus filiales, o algún colaborador de esta?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opCI"
      value={opCI}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opParaiso"
      value={opParaiso}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opPaispago"
      value={opPaispago}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opSugpep"
      value={opSugpep}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opSii"
      value={opSii}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opMoraprev"
      value={opMoraprev}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>


  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opComseeker"
      value={opComseeker}

      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
  </div>
</Box>

        
</Box>

{!(estado === "En Evaluacion" || estado === "Evaluando DDext" || estado === "Finalizado") && (
  // JSX code to render when the condition is true


<Box
  display="grid"
  gap="30px"
  gridTemplateColumns="1fr"  // Set to a single column
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: "span 1" }, // Adjusting column span
  }}
>

<Box>


<p></p>
  <div>
  <Typography variant="h3" gutterBottom>
  {<FormattedMessage id="Checklist"/>}<div><span style={greenText}></span></div></Typography>

  </div>
  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opNegativa"
      value={opNegativa}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
    <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  value={txtNegativa}
                  name="txtNegativa"
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
  </div>

  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?"/>}             
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opDifdds"
      value={opDifdds}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
    <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  value={txtDifdds}
                  name="txtDifdds"
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
  </div>

  <div>
    <Typography variant="h6" gutterBottom>
    {<FormattedMessage id="3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?"/>}
    </Typography>
    <RadioGroup
      aria-label="Opción"
      name="opAntanalisis"
      value={opAntanalisis}
      
      sx={{
        borderRadius: "20px",
        "& .MuiSvgIcon-root": {
          color: palette.primary.light,
        },
        "& .Mui-checked": {
          color: "#FFFFFF",
        },
      }}
    >
           <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
                    <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
                
    </RadioGroup>
    <TextField
                  label={<FormattedMessage id="Comentarios"/>}
                  value={txtAntanalisis}
                  name="txtAntanalisis"
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={6}
                  maxLength={1000}
                  sx={{ borderRadius: "20px",
                    gridColumn: "span 4",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                />
  </div>


  </Box>

  <FormControlLabel
  control={
    <Checkbox
      checked={  <span style={{ fontSize: '20px' }}>✓</span>
    }
      disabled={true} // Disable the checkbox
      name="confirmNa"
      color="primary"
    />
  }
  label={<FormattedMessage id="Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte"/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>

 
</Box>
)
};
</Box>

);
};

export default DdDetailFerCheck;






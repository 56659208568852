import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "./WidgetWrapper";
import FlexBetween from "./FlexBetween";
import { setNotifications } from "../state";
import { FormattedMessage } from 'react-intl';
import Chip, { ChipProps } from "@mui/material/Chip";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Pause from "@mui/icons-material/Build";
import { green, red, blue } from '@mui/material/colors';

const DdPendingWidget = ({ _id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedincontract = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userprofile);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [notifications, setNotifications] = useState(0);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [delay, setDelay] = useState(0); // Replace with your actual logic to set the delay

  useEffect(() => {
    fetchData();

  }, []);


  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of year
    return `${day}/${month}/${year}`;
  };


  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}dd/user/${loggedInUserId}`;
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}dd/`;
      }
      if (["coadmin", "admin", "aprobador", "comitetica", "adminetica"].includes(userProfile)) {
        endpoint = `${REACT_APP_API_ENDPOINT}dd/contract/${loggedincontract}`;
      }
  
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
      setData(formData);
  
      const filteredData = formData
        .filter((row) => row.estado === "En Aprobacion")
        .map((row) => {
          // Calculate delay value
          const createdAtDate = new Date(row.createdAt);
          const datevalDate = row.dateval ? new Date(row.dateval) : new Date();
          const diffInMilliseconds = Math.abs(datevalDate - createdAtDate);
          const delay = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  
          return { ...row, delay }; // Add delay to row
        })
        .sort((a, b) => b.delay - a.delay); // Sort by delay in descending order
  
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row
      navigate(`/ddevPage/${rowId}`, 
        {
        state: {
          formData: params.row, // Pass the row data
          formTotalDays: params.row.formTotalDays // Pass the formTotalDays value
      
        }
      });   
    }
  };


  function getChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value === "En Aprobacionx" ) {
      return {
        icon: <WarningIcon style={{ fill: red[500] }} />,
        label: params.value,
        style: {
          borderColor: red[500]
        }
      };
    }
    
    if (params.value === "En Aprobacion") {
      return {
        icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: green[500]
        }
      };}
      if (params.value === "Por Evaluar") {
        return {
          icon: <Pause style={{ fill: blue[500] }} />,
          label: params.value,
          style: {
            borderColor: blue[500]
          }
        };
  } 
      else {
        return {
          label: params.value,

        };
    }
  }
  const columns = [


     
    {
      field: "nombreempresa",
      headerName: "Contraparte",
      flex: 1,
      minWidth: 100,
      description: "location",
      headerAlign: "left",
      headerClassName: "bold-header",
      renderCell: (params) => {
        return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
      }
    },
    {
      field: "estado",
      headerName: "Estado",
      flex: 1,
      minWidth: 100,
      description: "estado",
      headerAlign: "left",
      headerClassName: "bold-header",
      renderCell: (params) => {
        return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
      }
    },
    {
      field: "delay",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Demora"/>,
        flex: 1,
        valueGetter: (params) => {
          const createdAtDate = params.row.createdAt;
          const datevalDate = params.row.dateval;
      
          if (datevalDate) {
            // Parse datevalDate and calculate absolute delay using "es-ES" locale
            const parsedCreatedAt = new Date(params.row.createdAt);
            const parsedDateval = new Date(params.row.dateval);
            const diffInMilliseconds = Math.abs(parsedDateval - parsedCreatedAt);
            const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
            return diffInDays;
          } else if (createdAtDate) {
            // Parse createdAtDate and calculate absolute delay using "es-ES" locale
            const currentDate = new Date();
            const parsedCreatedAt = new Date(params.row.createdAt);
            const diffInMilliseconds = Math.abs(currentDate - parsedCreatedAt);
            const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
            return diffInDays;
          } else {
            return ""; // No date information available
          }
        },
        renderCell: (params) => {
          const delay = params.value; // Get the delay value from valueGetter
          return (
            <span style={{ color: delay > 3 ? 'red' : 'inherit' }}>
              {delay}
            </span>
          );
        },
      
      },
  ];

  return (
    <Box m="05px">
    <Box
      m="10px 0 0 0"
      height="35vh"
      sx={{ borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],          },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
        <DataGrid
   
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(row) => row._id} // Specify the unique id property

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick}
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default DdPendingWidget;

import React, { useEffect, useState, useRef } from 'react';
import { Divider } from "@mui/material";
import { Checkbox } from "@mui/material";

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
    InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useReactToPrint } from "react-to-print";

const greenText = { color: 'green' }; // Define the CSS style for green text

const FormDetailWidget = ({ formData, formTotalDays }) => {
  const [form, setForm] = useState(null);
  const [form1, setForm1] = useState(null);

  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";
  const industria = useSelector((state) => state.client.industria); 

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the 
  const { REACT_APP_API_ENDPOINT } = process.env;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const getForm = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState
    setForm1(data);
  };
 

  useEffect(() => {
    getForm();
  }, [token]); // Remove unnecessary square brackets

  

  if (!form) {
    return null;
  }


  const {
    
    rut,
    telefono,
    firstName,
    lastName,
    email,
    picturePath,
    location,
    occupation,
    opconflicto,
    tipoconflicto,
    conflictxt,
    estado,
    userprofile,
    opauth,
    authtxt,
    opmitigacion,
    mitigaciontxt,
    comentariosAdi,
    campaignid,
    opCampaign,
    idcontrato,
    area,
    userId,
    createdAt,
    updatedAt,
    emailaviso,
    idevaluacion,
    dateval,
    nationality,
    cmf,
    persons, 

    opDirectorio,
    rutDirectorio,
    razonSocialDirectorio,
    tipoRelacionDirectorio,
    operacionInteres,

  } = form;



  
  const formatDate = (dateString) => {
     if (!dateString) {
    return "";
  }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

  
  return (

    <WidgetWrapper m="2rem 0">
            <div ref={componentRef} className="card">

    <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Declaración de Conflicto de Interés"/>} <span style={greenText}></span></Typography>        

    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Autorización"/>}: <span style={greenText}><FormattedMessage id={`${estado}`}/></span></Typography>        
    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Email"/>}: <span style={greenText}><FormattedMessage id={`${email}`}/></span></Typography>        
    <Typography variant="h9" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{_id.slice(-0)}</span></Typography>        

    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Recibida"/>}:<span style={greenText}>{formatDate(createdAt)}</span></Typography>        
    <Typography variant="h9" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Campaña"/>}: <span style={greenText}>{campaignid.slice(-0)}</span></Typography>        
    <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Demora"/>}: <span style={greenText}>{formTotalDays} {<FormattedMessage id="Días: "/>}</span></Typography>        

    <Box/>


    
      <Box 
                  gridTemplateColumns="repeat(5, minmax(0, 1fr))"

      sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>

<Typography variant="h4">{<FormattedMessage id="Formulario de Declaración" />}
</Typography>
      <Typography variant="h6">{<FormattedMessage id="Ingrese sus Datos" />}</Typography>
     

<Box>
  {/* New selection field for clients */}

          </Box>

          <p></p>
   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          
          {iscreateform && (
            <>
 <TextField
        fullWidth
        variant="filled"
        type="text"
        label={<FormattedMessage id="Rut"/>}
        value={rut}
        InputProps={{ readOnly: true }}
        sx={{ borderRadius: "20px", gridColumn: "span 1" }}
      />


<TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Nombres" />}

              value={firstName}
              InputProps={{ readOnly: true }}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Apellidos" />}
              InputProps={{ readOnly: true }}
              value={lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Organización en la que trabaja" />}
              InputProps={{ readOnly: true }}
              value={location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Area" />}
              InputProps={{ readOnly: true }}

              value={area}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

              <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Cargo" />}
              InputProps={{ readOnly: true }}

              value={occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

<TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Nacionalidad" />}
              InputProps={{ readOnly: true }}

              value={nationality}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label={<FormattedMessage id="Celular" />}
              InputProps={{ readOnly: true }}
              value={telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

<p></p>
<Box sx={{ marginTop: '1rem' }}>
  <Typography>
  {industria === 'Iglesia' ? (
          <FormattedMessage id="¿Es Ud. Obispo, Vicario, Delegado, Ecónomo, Contralor, Canciller o Director de quien requiere la declaración?" />

      ) : (
        <FormattedMessage id="¿Es Ud. Director, Gerente, Subgerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?" />
      )}
  </Typography>
  {opDirectorio}

</Box>
 
<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 6", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>
<Box
  display="grid"
  border="2px solid"
  p="1rem"
  mt="2rem"
  mb="2rem"
  bgcolor="white"
  gap="20px"
  gridTemplateColumns="repeat(1, 1fr)" // Updated to span 12 columns
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }, // Updated to span 12 columns
  }}
>
<Typography>
    {<FormattedMessage id="Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Organización."/>},
    </Typography>
    <Typography>
    {<FormattedMessage id="Un posible conflicto de interés puede existir:"/>}
    </Typography>
    <ol>
    <li>
    {<FormattedMessage id="Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización."/>},
      </li>
      <li>
      {<FormattedMessage id="Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*"/>},
      </li>
      <li>
      {<FormattedMessage id="Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación."/>},
      </li>
    
      <li>
      {<FormattedMessage id="Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a)."/>},

      </li>
      <li>
      {<FormattedMessage id="Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde."/>},

      </li>
    </ol>
    <Typography>
    {<FormattedMessage id="Por lo tanto, declaro el posible conflicto de interés aquí."/>},
    </Typography>


    {/* Description of conflict of interest */}
    {/* Add an input field or text area where the user can describe the conflict of interest */}
  </Box>

</Typography>

{<FormattedMessage id="1. ¿Tiene conflicto de interés?"/>} : 
{opconflicto}

<Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

      <Typography variant="body1" sx={{ borderRadius: "20px", gridColumn: "span 5" }}>
      {<FormattedMessage id="2. ¿Cuál es el tipo de Conflicto?"/>}
          {tipoconflicto}
        </Typography>
      </FormControl>

      <TextField
                label={<FormattedMessage id="Explique el(los) conflicto(s) de interés aquí"/>}
                InputProps={{ readOnly: true }}
                variant="filled"
                value={conflictxt}
                fullWidth
                minRows={8}
                maxRows={20}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              />

            </>

      
          )}
</Box>
{form1.cmf === "Si" ? ( 

<>

<Box
  display="grid"
  border="2px solid"
  p="1rem"
  mt="2rem"
  mb="2rem"
  bgcolor="white"
  gap="20px"
  sx={{
    borderRadius: "20px",
    "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }, // Updated to span 12 columns
  }}
>
<Typography>
{<FormattedMessage id="Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente :"/>}

</Typography>
    <Typography>
    <p></p>
    {<FormattedMessage id="El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación:"/>}    </Typography>
  
    <Typography>
    {<FormattedMessage id="Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas."/>}    </Typography>
<p></p>
    <Typography>
    {<FormattedMessage id="ART. 100 - Son relacionadas con una sociedad las siguientes personas:"/>} 
    <ol>
      <li>
      {<FormattedMessage id="Las entidades del grupo empresarial al que pertenece la sociedad;"/>} 
</li>
<li>
{<FormattedMessage id="Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;"/>} 
</li><li>
{<FormattedMessage id="Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y"/>} 
</li><li>
{<FormattedMessage id="Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones."/>} 
</li><li>
{<FormattedMessage id="La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que:"/>} 
    <Typography>
    {<FormattedMessage id="1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;"/>} 
</Typography><Typography>
{<FormattedMessage id="2.- Sus negocios con la sociedad originan conflictos de interés;"/>} 
</Typography><Typography>
{<FormattedMessage id="3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o"/>} 
</Typography><Typography>
{<FormattedMessage id="4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad."/>} 
   {<FormattedMessage id="No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad."/>} 
    </Typography>   </li> </ol>

    </Typography>
    <Typography>
    {<FormattedMessage id="Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045"/>} 
 </Typography>
 </Box>

  </>
  ) : null}

{opconflicto === 'Si' ? ( 
 

    <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(1, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
            }}
          >
    <Typography>
      <p></p>
      {<FormattedMessage id="4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado."/>} 

      {persons.map((person, index) => (
        <div key={index}>
          <p>{<FormattedMessage id="Rut"/>}: {person.rutr}</p>
          <p>{<FormattedMessage id="Nombre del relacionado o Razon Social"/>}: {person.name}</p>
          <p>{<FormattedMessage id="Apellidos del relacionado"/>} : {person.lastname}</p>
          <p>{<FormattedMessage id="Tipo de relación conmigo"/>}: {person.relationship}</p>
          <p>{<FormattedMessage id="Nacionalidad"/>}: {person.nationalityrel}</p>
          <p>{<FormattedMessage id="Operacion en la que tengo interés o participo"/>}: {person.operation}</p>
          <Divider sx={{ marginTop: '1rem', marginBottom: '1rem', gridColumn: 'span 5' }} />

        </div>
      ))}
    </Typography>
  </Box>
) : null}




<FormControlLabel
  control={
    <Checkbox
      checked={  <span style={{ fontSize: '20px' }}>✓</span>
    }
      disabled={true} // Disable the checkbox
      name="confirmRead"
      color="primary"
    />
  }
  label={<FormattedMessage id="He leído y comprendido la información anterior."/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>



        </Box>


<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Decisión Compliance Manager:"/>} <span style={greenText}>{estado}</span></Typography>        
        <Typography variant="h8" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{idevaluacion}</span></Typography>  

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Autorizado:"/>} <span style={greenText}>{opauth}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}><span style={greenText}>{formatDate(dateval)}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Detalle:"/>} <span style={greenText}>{authtxt}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Mitigación:"/>} <span style={greenText}>{opmitigacion}- {mitigaciontxt}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Comentarios Adicionales:"/>} <span style={greenText}>{comentariosAdi}</span></Typography>  

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Compartida con:"/>} <span style={greenText}>{emailaviso}</span></Typography>  

        </Box>
        </div>

        {/*{estado === 'Evaluada' && ( // Conditionally render the button if the estado is 'evaluada'*/}
{estado !== 'XXXX' && ( // Conditionally render the button if the estado is 'evaluada'*/

<Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
  <Button
    variant="contained"
    startIcon={<Print />}
    onClick={handlePrint}
  >
    {<FormattedMessage id="Imprimir en PDF"/>}
  </Button>
</Box>
)}

</WidgetWrapper>


);
};

export default FormDetailWidget;







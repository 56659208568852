import React, { useEffect, useState, useRef } from 'react';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import { Divider } from "@mui/material";
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es'; // Import the Spanish locale
import html2canvas from 'html2canvas';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { useReactToPrint } from "react-to-print";
import { FormattedMessage } from 'react-intl';

import { Print } from '@mui/icons-material';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,

  Radio,
  RadioGroup,
    InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
} from "@mui/material";
const greenText = { color: 'green' }; // Define the CSS style for green text

const TtvaluedetWidgetFarm = () => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the URL
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [userSol, setUserSol] = useState(true);
  const [users, setUsers] = useState([]);
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [approvals, setApprovals] = useState([]);
  const [aprobadores, setAprobadores] = useState([]);

  const fetchFiles = async () => {
    try {
      // Fetch the form data
      const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });
      const data = await response.json();

      setForm(data);    
        setApprovals(data.approvals || []); // Set approvals from the fetched data
  setAprobadores(data.aprobadores || []);
      // Fetch the user data
      const responseUser = await fetch(`${REACT_APP_API_ENDPOINT}users/${data.userId}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });
      const dataUser = await responseUser.json();
      setUserSol(dataUser); // Update setForm to setFormState
  
      // Fetch approvers data (assuming data.aprobador contains an array of approver emails)
      const approverEmails = data.aprobadores || [];
      const userFetchPromises = approverEmails.map(email => {
        return fetch(`${REACT_APP_API_ENDPOINT}users/email/${encodeURIComponent(email)}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        }).then(response => response.json());
      });
      const approversData = await Promise.all(userFetchPromises);
  
      // Flatten the array of approvers data
      const flattenedApproversData = approversData.flatMap(approver => approver);
  
      setUsers(flattenedApproversData); // Set the array of all users fetched
  

  
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  
  useEffect(() => {
    if (_id) {
      fetchFiles();
    }
  }, [_id]); // Call fetchFiles when the component mounts or _id changes


  const containerRef = useRef(null);


  const {
    createdAt,
    userId,
    tipott,
    estado,
    idcontrato,// Update the initial valu
    razonauspicio,
    tipoauspicio,
    propositott,
    descripciontt,
    valortt,
    oppreciojusto,
    costoevento,
    comunidad,
    ubicacion,
    nombreproyecto,
    nombreorganizacion,
    oplucro,
    replegal,
    domiciliolegal,
    mailreplegal,
    link,
    nombrecontacto,
    telefonocontacto,
    emailcontacto,
    opdd,
    ddrespaldo,
    oppagos,
    exenciontributaria,
    cartasolicitud,
    valoranticipado,
    acuerdo,
    duraciontt,
    entrega,
   
    estadoCierre,
    orgpermiso,
    nombreb1,
    cargob1,
    organizacionb1,
    funcionariob1,
    nombreb2,
    cargob2,
    organizacionb2,
    funcionariob2,
    nombreb3,
    cargob3, 
    organizacionb3,
    funcionariob3,
    modoviaje, 
    clasesviaje,
    descripcionviaje,
    opalojamiento,
    organizacionBen,// 
        beneficios,//
        agenda,//
        opcomida,//
        recomendaciones,//
        informe,//
        utilizacion,//
        frecuencia,//
        repeticion,//
        actoresext,//
        miembros,//
        presupuesto,//
        lugar,//
        hotelapropiado,//
        traslados,//
        oradores,//
        responsable,//
        cantidadarticulos, //
        valortotalarticulos, //
    costoporpersona,
    costoporpersonatransporte,
    costoporpersonainvitacion,
    costoporpersonacomida,
    costoporpersonaotros,
    otrosgastos,
    nombrerepresentantes1,
    cargosrepresentantes1,
    nombrerepresentantes2,
    cargosrepresentantes2,
    nombrerepresentantes3,
    cargosrepresentantes3,
    nombrerepresentantes4,
    cargosrepresentantes4,
    numeroasistentes,
    oporganizadoreventoestatal,
    txtorganizadoreventoestatal,
    opotrosauspicios,
    txtotrosauspicios,
    opmencionauspicio,
    opmkt,
    txtmkt,
    formadeaporte,
    opfuncionario,
    opauth,
   
    commentcierre,
  
      titulo,
        firstNameBen,
        lastNameBen,
        rutBen,
        direccionBen,
        numerocuenta,
        apericia,
        razoncontrato,
        producto,
        nombreactividad,
        Legitimacion,
        oradorPropio,
        nombreinstitucion,
        direccioninstitucion,
        permisoinstitucion,
        detallealcance,
        grupobjetivo,
        tiemporequerido,
        resultadosesp,
        opconflicto,
        opinstitucion,
        detalleconflicto,
        comentarios,
        docregistro,
    
  
   
 
    pictures,
    aprobador,
  
  } = form || {};

  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [_id]);
  
  if (!form) {
    return null;
  }

  if (!form || form.length === 0) {
    return null;
  }

   // Submit the form data (POST request)
   const handleSaveForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form), // Send the updated form data
      });

      if (response.ok) {
        alert("Form saved successfully!");
        setIsEditMode(false); // Exit edit mode after saving
      } else {
        console.error("Failed to save form");
      }
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };


  // Load your AWS credentials fconsrom environment variables
  AWS.config.update({
          
    accessKeyId: REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
    region: "sa-east-1", // Set your AWS region
    });

  const handleDownload = (filePath, fileName) => {
        

    const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com'; // Use the correct endpoint
    const s3URL = `${s3BucketURL}/${encodeURIComponent(fileName)}`;
  
    // Initialize the AWS S3 client for downloading
    AWS.config.update({
      accessKeyId: REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
      region: "sa-east-1", // Set your AWS region
    });
    const s3 = new AWS.S3();


    const downloadLink = document.createElement('a');
    downloadLink.href = s3URL;
    downloadLink.target = '_blank'; // Open in a new tab
    downloadLink.download = fileName; // Specify the filename for the downloaded file
    downloadLink.click();

   
  };


  const hasEticproApproval = approvals.some(approval => approval.approver.includes('@eticpro.com'));

// Find the user with the smallest rangoAprobacion
const userWithSmallestRango = users.reduce((prev, curr) => 
  prev.rangoAprobacion < curr.rangoAprobacion ? prev : curr, users[0]
);


  return (

    

    <Box m="2rem 0">

<div ref={componentRef} className="card">
      <div ref={containerRef}>
    
       
    

      <Box>
      <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>Transferencia de Valor <span style={greenText}></span></Typography>        

      <Typography variant="h4">Estado: <span style={greenText}>{estado}</span></Typography>
      <Typography variant="h4">Autorizada: <span style={greenText}>{opauth}</span></Typography>

      <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>Tipo: <span style={greenText}>{tipott}</span></Typography>

      <div>Recibida: <span style={greenText}>{new Date(createdAt).toLocaleDateString('es-ES')}</span></div>
      <div>Id Transferencia: <span style={greenText}>{_id.slice(-0)}</span></div>

      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '10px' }}>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>Solicitante:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.firstName} {userSol.lastName}</span>
  </Typography>

  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>Cargo:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.occupation}</span>
  </Typography>

  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>Email:</Typography>
  <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
    <span style={greenText}>{userSol.email}</span>
  </Typography>
</div>

    

<Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
   Fecha Solicitado:{" "}
  <span style={greenText}>
    {format(new Date(createdAt), "dd MMMM yyyy HH:mm", { locale: esLocale })}
  </span>
  <p></p>
  </Typography>
      <Box>
         
     </Box>
      

          <p></p>
     

      
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ borderRadius: "20px",
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
            


{form.tipott === "CPS" && (
  <>

<Typography>
          </Typography>

          <TextField
  label="Título profesional"
variant="filled"
  value={titulo}
  name="titulo"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Nombres"
variant="filled"
  value={firstNameBen}
  name="firstNameBen"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Apellidos"
variant="filled"
  value={lastNameBen}
  name="apellido"
  
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="RUT"
variant="filled"
  value={rutBen}
  name="rutBen"

  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Dirección Particular"
variant="filled"
  value={direccionBen}
  name="direccionBen"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Forma de Pago: transferencia bancaria, aporte en especies..."
variant="filled"
  value={formadeaporte}
  name="formadeaporte"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Número de cuenta si corresponde:"
variant="filled"
  value={numerocuenta}
  name="numerocuenta"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>



<TextField
  label="Área de pericia"
variant="filled"
  value={apericia}
  name="apericia"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Razón para contratar este profesional"
variant="filled"
  value={razoncontrato}
  name="razoncontrato"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Producto"
variant="filled"
  value={producto}
  name="producto"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tipo de actividad: Charla,  Preparacion Material Cientifico, Partipacion en Panel.."
variant="filled"
  value={nombreactividad}
  name="nombreactividad"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Legitimacion del Evento"
variant="filled"
  value={Legitimacion}
  name="Legitimacion"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<TextField
  label="Para oradores: Orador propio o apoyo de terceros en reuniones?"
variant="filled"
  value={oradorPropio}
  name="oradorPropio"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<Typography variant="h6" gutterBottom>

¿Trabaja en alguna institucion?</Typography>
<RadioGroup
  aria-label="Opción"
  name="opinstitucion"
  value={opinstitucion}
variant="filled"
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>
{opinstitucion === 'Si' && ( // Only render if the answer is 'Si'
<div>
<TextField
  label="Nombre de institucion donde trabaja(si no trabaja puede dejar vacio)"
variant="filled"
  value={nombreinstitucion}
  name="nombreinstitucion"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Direccion de las institucion donde trabaja"
variant="filled"
  value={direccioninstitucion}
  name="direccioninstitucion"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
<p></p>
 Necesita permiso de su institución para ser contratado externamente? 

<RadioGroup
  label="Necesita permiso de su institución para ser contratado externamente?"
  aria-label="Opción"
  name="permisoinstitucion"
  value={permisoinstitucion}
variant="filled"
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

{permisoinstitucion === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                      <p>Porfavor Adjunte    Permiso.</p>
          
                </Box>
        
   </div>)}

</div>)}




<TextField
  label="Detalle de la Necesidad y objeto de la colaboración"
variant="filled"
  value={descripciontt}
  name="descripciontt"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Detalle Alcance del servicio: (numero de actividades,  cantidad de publico, preparación, ejecución, seguimiento)"

variant="filled"
  value={detallealcance}
  name="detallealcance"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Grupo objetivo al que va dirigido: ( auxiliares enfermería, doctores..)  "
variant="filled"
  value={grupobjetivo}
  name="grupobjetivo"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Fecha del servicio"
variant="filled"
  value={entrega}
  name="entrega"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Lugar del Servicio"
variant="filled"
  value={ubicacion}
  name="ubicacion"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tiempo requerido del servicio"
variant="filled"
  value={tiemporequerido}
  name="tiemporequerido"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Duración requerida del servicio"
variant="filled"
  value={duraciontt}
  name="duraciontt"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Monto de compensación (tasa por hora o monto total diario / servicio)"
variant="filled"
  value={valortt}
  name="valortt"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Resultados esperados y uso en los negocios"
variant="filled"
  value={resultadosesp}
  name="resultadosesp"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
¿Posee el PDS algún conflicto de interés relacionado con gerentes o miembros del directorio?"

  <RadioGroup
  aria-label="Opción"
  name="opconflicto"
  value={opconflicto}
variant="filled"
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>
{opconflicto === 'Si' && ( // Only render if the answer is 'Si'
<div>

<TextField
  label="Si tiene conflicto detalle aquí: "
variant="filled"
  value={detalleconflicto}
  name="detalleconflicto"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
</div>)}
<TextField
  label="otros Comentarios? detalle aquí:"
variant="filled"
  value={comentarios}
  name="comentarios"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>

Es el Prestador del extranjero ?
</Typography>
<RadioGroup
  aria-label="Opción"
  name="docregistro"
  value={docregistro}
variant="filled"
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>

<p>Adjuntar Documento Registro Nacional de Prestadores individuales de salud.</p>

{docregistro === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
        </Box>
          

   
   </div>
      )}


<Typography variant="h6" gutterBottom>

Adjunte    Borrador del Contrato.
</Typography>

 <Typography variant="h6" gutterBottom>

Adjunte    Antecedentes del profesional.
</Typography>

</FormControl>  
</>
)}

{form.tipott === "Contratacion Organizaciones" && (
  <>

<TextField
  label="Tipo de actividad: Charla, Congreso.."
variant="filled"
  value={nombreactividad}
  name="nombreactividad"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>


<TextField
  label="Detalle de la Necesidad y objeto de la Contraprestación"
variant="filled"
  value={descripciontt}
  name="descripciontt"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Tipo y magnitud del(los) servicios(s) a cambio (por ejemplo, numero participantes, área de la cabina en m², tiempo contratado,  logotipo, referencia a la audiencia, mención como auspiciador en el anuncio y durante el evento, otros).  "

variant="filled"
  value={detallealcance}
  name="detallealcance"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Grupo objetivo al que va dirigido: ( auxiliares enfermería, doctores..)  "
variant="filled"
  value={grupobjetivo}
  name="grupobjetivo"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Fecha del servicio"
variant="filled"
  value={entrega}
  name="entrega"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Lugar del Servicio"
variant="filled"
  value={ubicacion}
  name="ubicacion"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>



<TextField
  label="Duración requerida del servicio"
variant="filled"
  value={duraciontt}
  name="duraciontt"
 multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Monto de compensación (tasa por hora o monto total diario / servicio)"
variant="filled"
  value={valortt}
  name="valortt"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>

<TextField
  label="Resultados esperados y uso en los negocios"
variant="filled"
  value={resultadosesp}
  name="resultadosesp"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
¿Posee  algún conflicto de interés relacionado con gerentes o miembros del directorio?"

  <RadioGroup
  aria-label="Opción"
  name="opconflicto"
  value={opconflicto}
variant="filled"
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>
{opconflicto === 'Si' && ( // Only render if the answer is 'Si'
<div>

<TextField
  label="Si tiene conflicto detalle aquí: "
variant="filled"
  value={detalleconflicto}
  name="detalleconflicto"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>
</div>)}
<TextField
  label="otros Comentarios? detalle aquí:"
variant="filled"
  value={comentarios}
  name="comentarios"
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 4",
    '& input': {
      background: 'white',
    },
  }}
/>






<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },

          }}>

<Typography variant="h6" gutterBottom>

Por favor adjuntar: 1. Lista de precios, y 2.Borrador del contrato)(Mandatorio ambos): 
</Typography>

        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
   

                </Box>

 </FormControl>  


  
</>
)}


{form.tipott === "Evento" && (
 <>

 <Typography>
     </Typography>

     <TextField
label="Nombre Evento"


value={nombreactividad}
name="nombreactividad"

multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Topico/Enfoque del Evento"


value={razoncontrato}
name="razoncontrato"

multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Fecha de Realización"


value={entrega}
name="entrega"

multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>
<Box>
          <FormControl fullWidth sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
              <InputLabel>Seleccionar Tipo de Evento</InputLabel>

              <Select
                label="Tipo de Evento"
                value={descripciontt}
                name="descripciontt"
                
                
              >
                <MenuItem value="">
                  <em>Seleccionar Tipo de Evento</em>
                </MenuItem>
                {/* Replace dynamic options with fixed options */}
                <MenuItem value="Internacional">Internacional</MenuItem>
                <MenuItem value="Nacional">Nacional</MenuItem>
              
                <MenuItem value="Pequeña Reunion Clinica con Coffee/Almuerzo">Pequeña Reunion Clinica con Coffee/Almuerzo</MenuItem>
                <MenuItem value="Cena Promocional">Cena Promocional"</MenuItem>
           
        

              </Select>

            
            </FormControl>
          </Box>


          <p></p>

<TextField
label="¿Cuál es el propósito del Evento organizado por la organización  y qué mensajes se entregarán durante el Evento? ¿A qué productos, indicaciones o compuestos se referirá el Evento?"


value={propositott}
name="propositott"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Beneficios: ¿Cuáles son los beneficios anticipados del evento para la compañía? ¿Cuáles son los beneficios científicos para los participantes pasivos? ¿Cómo mejorarán las presentaciones educativas la práctica de la medicina? (Ej: Profundización del conocimiento científico de ciertos especialistas sobre indicaciones significativas de los productos de la compañía, presentación de los últimos resultados de ciertos estudios clínicos, etc.)"


value={resultadosesp}
name="resultadosesp"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Número de cuenta si corresponde:"


value={numerocuenta}
name="numerocuenta"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Prueba de servicios y ejecución: ¿Quién documentará la ejecución efectiva del evento, incluyendo los servicios relevantes? ¿Cómo se lo documentará?"


value={Legitimacion}
name="Legitimacion"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Lugar: ¿Dónde se realizará el evento?  ¿Cumple la selección del lugar los requisitos de Apropiado?"


value={ubicacion}
name="ubicacion"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes pasivos (asistentes): Número, área(s) de pericia, calificaciones, país de origen"


value={numeroasistentes}
name="numeroasistentes"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes activos (por ejemplo, oradores / consultores): Número, nombres, área(s) de pericia, calificaciones, detalles (preliminares) de los servicios (tópicos, duración, etc.), país de origen"


value={oradorPropio}
name="oradorPropio"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Indicar si se alojaran invitados en un Hotel "


value={opalojamiento}
name="opalojamiento"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Indicar si habrá servicio de comida / almuerzo / cena (preliminar)"


value={opcomida}
name="opcomida"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="En caso de contestar afirmativamente en la pregunta anterior, recordar que el restaurante y el menú deben ser Apropiados y reflejar el carácter de trabajo. Señalar el presupuesto  costos por persona, total y  describir el menu."


value={costoporpersonacomida}
name="costoporpersonacomida"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Obsequios y regalos ¿Planea distribuir obsequios o regalos? Descripción de los objetos y su valor de acuerdo a los liniamientos internos"


value={costoporpersonaotros}
name="costoporpersonaotros"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Material promocional y científico: Lista de materiales promocionales y científicos. Asegúrese que los eventos se identifiquen como promocionales con pendones "


value={txtmkt}
name="txtmkt"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Presupuesto preliminar: Estimación del marco preliminar de presupuesto (y detalles)"


value={costoporpersonainvitacion}
name="costoporpersonainvitacion"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



</>
)}

{form.tipott === "Junta Comite" && (
   <>

  <TextField
  label="Propósito: ¿Cuál es el propósito / alcance de la Junta Asesora y cuáles son sus beneficios anticipados? (¿A qué indicaciones y / o compuestos se referirá la Junta Asesora?)"
  
  
  value={propositott}
  name="propositott"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Resultado: ¿Qué servicios proveerán los actores externos en el contexto de la reunión de la Junta Asesora?"
  
  
  value={detallealcance}
  name="detallealcance"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Recolección de las recomendaciones: ¿Cómo se recogerán las recomendaciones de los actores externos?"
  
  
  value={recomendaciones}
  name="recomendaciones"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
    
  <TextField
  label="Informe / resumen: ¿Quién / qué función preparará el informe de la reunión y será responsable por los siguientes pasos? (Siempre hay un escritor médico en sitio)"
  
  
  value={informe}
  name="informe"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Utilización en el negocio: ¿Quién (empleado de BAGÓ) evaluará los pasos siguientes y dentro de qué marco de tiempo?  ¿Cómo se utilizarán los resultados de la Junta Asesora en el negocio, y cómo será documentada dicha utilización?"
  
  
  value={utilizacion}
  name="utilizacion"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Frecuencia: Si se proponen reuniones múltiples (regionales), ¿puede el número ser sostenido de forma objetiva, por ejemplo, debido a participantes de diferentes lugares, disponibilidad, gastos de viaje?"
  
  
  value={frecuencia}
  name="frecuencia"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  
  <TextField
  label="Repetición: ¿Reuniones previas han abordado el mismo / similar tema o asunto? De ser así, ¿hay una necesidad legítima de otra reunión sobre el mismo / similar tema en este momento?"
  
  
  value={repeticion}
  name="repeticion"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Número de actores externos: ¿Cuál es el número propuesto de actores externos que participarían en la Junta Asesora?  ¿De qué forma se puede sostener objetivamente ese número?  (El área de contratación  debe dar una explicación informal corta) "
  
  
  value={actoresext}
  name="actoresext"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Nombre, país de origen y calificación de los miembros identificados de la Junta Asesora (preliminar): ¿Cumplen las calificaciones de los actores externos la necesidad identificada, documentada?  - adjunte las credenciales de ser necesario"
  
  
  value={miembros}
  name="miembros"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Presupuesto y honorarios: ¿Cuál es el presupuesto total y cuál es el monto esperado de pagos a los miembros de la Junta Asesora con base en los servicios específicos y los gastos relacionados esperados? (por favor revisar que se ajusta al valor justo de mercado adjuntado formulario de contratación PDS)"
  
  
  value={presupuesto}
  name="presupuesto"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  <TextField
  label="Lugar (preliminar): ¿Cumple la selección del lugar los requisitos de compliance? (Refiérase a lineamientos internos) adjunte información del lugar  "
  
  
  value={lugar}
  name="lugar"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Refierase al hotel  del alojamiento si es distinto en donde el cual se hará la reunión (señale la razón del por qué es Apropiado)"
  
  
  value={hotelapropiado}
  name="hotelapropiado"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Traslados: indique si habrá compra de pasajes aereos o traslados terrestres "
  
  
  value={traslados}
  name="traslados"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Servicio de comida / almuerzo / cena (presupuesto preliminar) recuerde el monto máximo por persona. También adjunte el menú"
  
  
  value={costoporpersonacomida}
  name="costoporpersonacomida"
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="¿Alguno de los miembros de la junta asesora tiene algún conflicto de interés que lo relacione con gerentes o miembros del directorio de Bagó Chile S.A?"
  
  
  value={opconflicto}
  name="opconflicto"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
  
  
  <TextField
  label="Obsequios y regalos: ¿Planea distribuir obsequios corporativos?  (Refiérase a lineamientos internos) adjunte la cotización "
  
  
  value={costoporpersonainvitacion}
  name="costoporpersonainvitacion"
  multiline
  fullWidth
  sx={{
  borderRadius: "20px",
  gridColumn: "span 4",
  '& input': {
   background: 'white',
  },
  }}
  />
 

 <FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>
Obsequios y regalos: ¿Planea distribuir obsequios corporativos?  (Refiérase a lineamientos internos) adjunte la cotización.

</Typography>
<RadioGroup
  aria-label="Opción"
  name="oppagos"
  value={oppagos}
  
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label="Sí" />
  <FormControlLabel value="No" control={<Radio  required />} label="No" />
</RadioGroup>





 </FormControl>  
  
  
  
  </>
)}

{form.tipott === "Invitaciones" && (

    <>

     <TextField
label="Nombre y tópico / enfoque del Congreso al que se desea invitar "


value={nombreactividad}
name="nombreactividad"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Enfoque del Congreso al que se desea invitar "


value={descripciontt}
name="descripciontt"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Detalles del Congreso: Tipo de Congreso, internacional vs. nacional, lugar, fecha, duración, participantes, etc."


value={detallealcance}
name="detallealcance"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Tipo de invitación : alojamiento,  registro, comidas, traslados "


value={tipoauspicio}
name="tipoauspicio"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Interés / Beneficios para la compañía: ¿Cuál es el propósito e interés en invitar al PDS a participar en el Congreso y qué información se entregará durante el Congreso?  ¿Cuáles son los beneficios  de la participación del PDS para el paciente que utiliza  los productos de nuestra compañía .¿A qué productos, indicaciones o compuestos se referirá en el congreso ?"


value={propositott}
name="propositott"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Beneficios para los participantes pasivos: ¿Cuáles son los beneficios científicos para los participantes pasivos? ¿Cómo mejorarán las presentaciones educativas la práctica de la medicina? (Ej.: Profundización del conocimiento científico de ciertos especialistas sobre indicaciones significativas de los productos de la compañía, presentación de los últimos resultados de ciertos estudios clínicos, etc.)"


value={beneficios}
name="beneficios"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Cual será la prueba de asistencia al congreso (foto de diploma, certificado, foto de lista de asistencia etc.)?"


value={Legitimacion}
name="Legitimacion"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Agenda preliminar: Dimensión, escala, tamaño, magnitud de las sesiones, número de grupos, número de talleres, número de oradores por grupo, etc. – adjuntar"


value={agenda}
name="agenda"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes pasivos (asistentes): Número, área(s) de pericia, calificaciones, país de origen"


value={numeroasistentes}
name="numeroasistentes"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Participantes activos (por ejemplo, oradores / consultores): Número, nombres, área(s) de pericia, calificaciones, detalles (preliminares) de los servicios (tópicos, duración, etc.), país de origen"


value={oradores}
name="oradores"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Lugar (preliminar) por favor señale por qué es un lugar apropiado (Refiérase a lineamientos internos)"


value={lugar}
name="lugar"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Hoteles + desayuno (preliminar) (Refiérase a lineamientos internos)"


value={hotelapropiado}
name="hotelapropiado"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Servicio de comida / almuerzo / cena (preliminar)"


value={opcomida}
name="opcomida"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Presupuesto preliminar: Estimado del marco preliminar de presupuesto (y detalles)"


value={presupuesto}
name="presupuesto"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Responsable principal: Indique el nombre del empleado de la compañía que será el principal responsable de adjuntar los respaldos "


value={responsable}
name="responsable"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Material promocional y científico: Lista de materiales promocionales y científicos. Asegúrese que los eventos se identifiquen como promocionales con pendones "


value={txtmkt}
name="txtmkt"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<TextField
label="Responsable principal: Indique el nombre del empleado de la compañía que será el principal responsable de adjuntar los respaldos "


value={costoporpersonainvitacion}
name="costoporpersonainvitacion"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>

<Typography variant="h6" gutterBottom>
Adjunte borrador de carta de invitación aquí (asegúrese de que el  PDS lo firme previo al evento)
</Typography>



        <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
              

                </Box>

 </FormControl>  


</>
)}

{form.tipott === "Posicionamiento" && (


    <>
Información sobre los destinatarios de articulos de apoyo al paciente o articulos de posicionamiento de marca
     <TextField
label="Nombre de la Organización"


value={organizacionBen}
name="organizacionBen"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Dirección de la Organización – Calle, Nº, Ciudad"


value={direccionBen}
name="direccionBen"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Nombre y cargo de persona natural que recibe el artículo"


value={firstNameBen}
name="firstNameBen"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Propósito y necesidad de la entrega del artículo (posicionamiento de marca o ayuda al paciente)"


value={propositott}
name="propositott"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Descripción del artículo (mochila, cooler, bandas elásticas, bloqueadores etc)"


value={descripciontt}
name="descripciontt"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Valor monetario del artículo individual entregado"


value={costoporpersona}
name="costoporpersona"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>



<TextField
label="Cantidad de artículos entregados"


value={cantidadarticulos}
name="cantidadarticulos"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>

<TextField
label="Valor total de los artículos entregados"


value={valortotalarticulos}
name="valortotalarticulos"
multiline
fullWidth
sx={{
borderRadius: "20px",
gridColumn: "span 4",
'& input': {
 background: 'white',
},
}}
/>


</>
)}

<Box width="100%" >

        <Box flexBasis="100%" marginRight="1rem">
         
         {/* Check if the user is "comitetica" or "superadmin" */}
         <Box 
  sx={{ 
    borderRadius: "20px", 
    border: '1px solid', 
    p: '1rem', 
    mt: '1rem', 
    width: '350%'  // Increase width to 200%
  }}
>            <Typography variant="h4">Archivos Relacionados</Typography>
            {form.picturePath && form.picturePath.map((filePath, index) => {
   if (filePath) {
    const fileName = filePath.split('/').pop();
    return (
      <div key={index}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleDownload(filePath, fileName);
          }}
        >
          {fileName}
        </a>
      </div>
    );
  } else {
    return null; // or handle the case when filePath is null
  }
            })}
          </Box>
     
          </Box>
          </Box>
          </Box>

          <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
  <div>
    {/* Pending Approvals */}
    {(() => {
  const pendingUsers = users.filter((user) => 
    !approvals.some(approval => approval.approver === user.email)
  );
  console.log("XXpendingUsers",pendingUsers);
  
  if (pendingUsers.length > 0 && form.estado != "Rechazada") {
    const userWithSmallestRango = pendingUsers[0];  // Get the first pending user by index

    return (
      <div key={userWithSmallestRango.email}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
          Aprobador Pendiente: 
          <span style={greenText}>
            {`${userWithSmallestRango.firstName || ''} ${userWithSmallestRango.lastName || ''}`}
          </span>
        </Typography>
      </div>
    );
  } else {
    return <Typography variant="h6">Sin Aprobaciones Pendientes.</Typography>; // Fallback message
  }
})()}


    {/* Completed Approvals */}
    <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word', mt: '1rem' }}>
      Evaluaciones Realizadas:
    </Typography>

    {approvals.length > 0 ? approvals.map((approval) => {
      const user = users.find(user => user.email === approval.approver);

      return (
        <div key={approval.approver}>
          <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            <span style={greenText}>
              {user ? `${user.firstName} ${user.lastName}` : approval.approver}
            </span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Autorizado: <span style={greenText}>{approval.opauth}</span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Detalle: <span style={greenText}>{approval.authtxt}</span>
          </Typography>

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
            Fecha Evaluación: <span style={greenText}>{new Date(approval.dateeval).toLocaleDateString()}</span>
          </Typography>

          <hr />
        </div>
      );
    }) : (
      <Typography variant="h6" sx={{ mt: '1rem' }}>No hay evaluaciones aún.</Typography> // Fallback if no approvals
    )}
  </div>
</Box>



        </Box>

        </div>
        </div>


</Box>



);
};

export default TtvaluedetWidgetFarm;




import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  
} from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { useReactToPrint } from "react-to-print";
import { FormattedMessage } from 'react-intl';

const redText = { color: 'red' }; // Define the CSS style for green text

const ComplaintDetailWidget = () => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the URL
  const { REACT_APP_API_ENDPOINT } = process.env;
 // Calculate the difference between createdAt and the current date in days
 const currentDate = new Date();
 
 const greenText = {
  color: 'green',
  fontWeight: 'bold',
};
  const fetchFiles = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState

  };
 
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [token, _id]);
  
  if (!form) {
    return null;
  }

  if (!form || form.length === 0) {
    return null;
  }
  const {

    userId,
    idcontrato,
    location,
    opauth,
    emailauth,
    authtxt,
  createdAt,
    relacion,
    areacometio,
    cargocometio,
    queocurrio,
    cuandoocurrio,
    desdecuando,
    dondeocurrio,
    comoevidencio,
    quiencometio,
    habladocon,
    masinfo,
    tipoconflicto,
    picturePath,
    estado,
  
    emailaviso,
    emaildenunciante,
    idevaluacion,
    dateval,
    selectedInvestigador,
  
    dateinv,
    idinvestigacion,
    resumeninv,
    
    dateveredicto,
    veredicto,
    detalleveredicto,
    idveredicto,
    flag,
  
  } = form;

  const createdDate = new Date(createdAt);
  const timeDifference = currentDate - createdDate;
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <div ref={componentRef} className="card">

    <Box m="2rem 0">



          <p></p>

       
<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Decisión de Investigar:"/>}<span style={greenText}>{opauth}</span></Typography>        
        <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Responsable Decisión:"/>} <span style={greenText}>{emailauth}</span></Typography>  

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Comentarios:"/>} <span style={greenText}>{authtxt}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}><span style={greenText}>{formatDate(dateval)}</span></Typography>  
         
        </Box>


<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Resultado Investigación:"/>}<span style={greenText}>{estado}</span></Typography>        

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}><span style={greenText}>{formatDate(dateinv)}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Comentarios:"/>} <span style={greenText}>{resumeninv}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Investigador:"/>} <span style={greenText}>{selectedInvestigador}</span></Typography>  
          <Typography variant="h8" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>} <span style={greenText}>{idinvestigacion}</span></Typography>  

        </Box>



        <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Decisión Comité Ético:"/>} <span style={greenText}>{estado}</span></Typography>        

          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Decisión:"/>} <span style={greenText}>{veredicto}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}><span style={greenText}>{formatDate(dateveredicto)}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Detalle:"/>}<span style={greenText}>{detalleveredicto}</span></Typography>  
          <Typography variant="h8" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="ID:"/>}<span style={greenText}>{idveredicto}</span></Typography>  

        </Box>




</Box>
        </div>

);
};

export default ComplaintDetailWidget;






import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { es } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
  opauth: yup.string().notRequired(),
  authtxt: yup.string().notRequired("notRequired"),
  emailaviso: yup.string().notRequired("notRequired"),

});

const initialValuesEditForm = {

  opauth: "",
  authtxt: "",
  dateeval: "",
  estado: "",
  selectedInvestigador: "",
  emailauth: "",
  emailaviso: "",

}

const Form = ({ _id }) => {
  const [form, setForm] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [searchInput, setSearchInput] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const theme = useTheme();

  const [filteredClients, setFilteredClients] = useState([]);
  
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedClient, setSelectedClient] = useState(''); // Add this state

  const [pageType, setPageType] = useState("editform");
  const iseditform = pageType === "editform";
  const token = useSelector((state) => state.token);
  const [formData, setFormData] = useState(initialValuesEditForm);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [users, setUsers] = useState([]); // State to hold fetched users
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUseremail = useSelector((state) => state.user.email);
  const [profilePicture, setProfilePicture] = useState(null);
  const [clients, setClients] = useState(null);
  const loggedclient = useSelector((state) => state.client);
  const [clientSelected, setClientSelected] = useState(false);
  const [comiteeticauserx, setComiteeticaUser] = useState([]); // State to hold fetched users
  const [messages, setMessages] = useState([]); // State to hold fetched users

  

  const fetchUsers = async () => {
    try {
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (userResponse.ok) {
        
        const usersData = await userResponse.json();
        setUsers(usersData); // Set the fetched users
        const comiteeticaUsers = usersData.filter(user => 
          user.userprofile === "comitetica" || user.userprofile === "adminetica"
        );
        


        setComiteeticaUser(comiteeticaUsers);
        setClients(usersData);

      } else {
        console.log("Failed to get users");
      }
     


      } catch (error) {
        console.log("Error:", error);
      }
    };

    const sendEmail = async ({ emails, emailTemplate, subject }) => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            emails: emails,
            emailTemplate: emailTemplate,
            subject: subject,
          }),
        });
    
        if (!response.ok) {
          throw new Error(`Failed to send email with status ${response.status}`);
        }
    
        console.log("Email sent successfully");
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };
    
    // Define a function to send an SMS
    const sendSMS = async ({ phonesms, smsTemplate }) => {
     
    
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}sendSms/sendSms`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            phonesms: phonesms,
            smsTemplate: smsTemplate,
          }),
        });
    
        if (!response.ok) {
          throw new Error(`Failed to send SMS with status ${response.status}`);
        }
    
        console.log("SMS sent successfully");
      } catch (error) {
        console.error("Error sending SMS:", error);
      }
    };

    const fetchMessages = async () => {
        try {
          const messagesResponse = await fetch(`${REACT_APP_API_ENDPOINT}message/contract/${idcontrato}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
      
          if (messagesResponse.ok) {
            const messagesData = await messagesResponse.json();
            const messageId = _id; // Ensure _id is defined in your component context

      // Filter messages to only those with caseId equal to _id
      const filteredMessages = messagesData.filter(message => message.caseId === messageId);
console.log("filteredMessages",filteredMessages);
      setMessages(filteredMessages); // Set the filtered messages
    } else {
       
            console.error("Failed to fetch messages:", messagesResponse.status);
          }
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      };
      

  useEffect(() => {

    const getForm = async () => {
      try {
        
        const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {

        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const formData = await response.json();
          setForm(formData); // Set the form data obtained from the server
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

   fetchUsers(); // Fetch the list of users
   fetchMessages(); // Fetch the list of users

    getForm(); // Call the function to fetch the form data

  }, [_id, token]);
 


  const handleFormSubmit = async (values, onSubmitProps) => {
 

      try {
        const { targetUser, ...otherValues } = values; // Extract targetUser and other form values

        const emails = targetUser.split(',').map(email => email.trim()); // Split and trim email addresses
    console.log("emails", emails);
        for (const email of emails) {
          const updatedValues = { ...otherValues, fromEmail: loggedInUseremail, targetuser: email, caseId: _id, idcontrato: idcontrato, estado: "Enviado", targetprofile: values.profile };
    
          const response = await fetch(`${REACT_APP_API_ENDPOINT}message`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedValues),
          });
    
          if (!response.ok) {
            throw new Error(`Failed to send message with status ${response.status}`);
          }
    

          const subject = `Aviso de Nuevo mensaje en Eticpro`;
     
          const emailTemplate = `
  <!DOCTYPE html>
  <html>
  <head>
    
  <style>
  .frame {
    border: 2px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    position: relative; /* Add relative positioning to the frame */
  
  }
  
  .logo-container {
    position: absolute;
    top: 10px; /* Adjust top value to move the logo vertically */
    right: 10px; /* Adjust right value to move the logo horizontally */
  }
  
  /* Styles for the box containing the logo */
  .logo-box {
    text-align: center;
    margin-bottom: 20px;
  }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
  </head>
  <body>
  <div class="frame">
  <div class="logo-container">
    <img src="${LogoImage}" alt="Logo" className="logo" style={{  width: '10%' }}/>
  </div>
  <img src="${loggedclient.logo}" alt="Logo" style="width: 70px; height: 30px;">
  
  <p></p>
  
    <p>Estimado Usuario,</p>
  <p></p>
  <p></p>
  
    <p>Tiene un nuevo mensaje en Eticpro de ${loggedclient.nombreempresa}</p>
  
    <div class="box">
      <p><strong>Referencia:</strong> ${values.title}</p>
      <p><strong>Mensaje:</strong> ${values.message}</p>
    </div>
  
  
    <p>Gracias por su atención.</p>
    <p>Equipo Encargado de Prevención de Delitos.</p>
    <p>Eticpro.</p>
  
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>Escríbenos a hello@eticpro.com | Wsup: +569 77657352.
    </p>
    <hr style={{ borderTop: '1px solid #ccc', margin: '1rem 0' }} />
    <p>• No olvide que para ingresar a nuestra plataforma, debe ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${emails}
  
    </div>
  
  </body>
  </html>`;
  

await sendEmail({ emails: [email], emailTemplate, subject });

      // Fetch user information including "celular" field
      const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/email/${email}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user information with status ${userResponse.status}`);
      }

      const userData = await userResponse.json();
      const firstUser = userData[0]; // Access the first element of the array
      const { celular } = firstUser; // Extract "celular" field from user data
      const phonesms = `+${celular}`; // agrega SIGNO + AL CELULAR!!!!

      // Send SMS
      await sendSMS({ phonesms, smsTemplate: `Hola, te informamos que ${loggedclient.nombreempresa} te ha enviado un mail a ${email}. Eticpro` });
    }
    fetchMessages(); // Fetch updated messages after submitting the form

    resetForm();
    // navigate("/successPage",{state: { message: "Mensaje enviado  con éxito" }});             
  } catch (error) {
    console.error(error);
  }
};




useEffect(() => {
  if (form) {
    setFormData(form);
  }
}, [form]);



const handleSearchInputChange = (event) => {
  const inputValue = event.target.value;
  setSearchInput(inputValue);
  if (inputValue.length >= 3) {
    const filtered = clients.filter(client =>
      client.email.includes(inputValue)
    );
    setFilteredClients(filtered);
  } else {
    setFilteredClients([]);
   
  }
};


const handleClientSelect = async (client) => {
  try {
    setSearchInput(client.email); // Update searchInput with the selected client's email
    setFilteredClients([]);
    
    setFieldValue(client.email); 

    setClientSelected(true); // Mark client as selected
  } catch (error) {
    console.error('An error occurred:', error);
    alert('An unexpected error occurred. Please try again later.'); // Fallback error message
  }
};


  return (
    <Formik
    onSubmit={handleFormSubmit}
    initialValues={iseditform ? initialValuesEditForm : initialValuesEditForm}
    validationSchema={editformSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
       <Box
      display="grid"
      gap="30px"
      gridTemplateColumns="repeat(1, minmax(0, 1fr))"
      sx={{
        borderRadius: "20px",
        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      }}
    >
                      <Typography variant="h3">Historico de Emails</Typography>

      {/* Map through messages and display each as a blog post */}
      {messages.map((message) => (
        <Box
          key={message._id}
          sx={{ border: '1px solid #ccc', borderRadius: '10px', padding: '16px', backgroundColor: '#f9f9f9' }}
        >

          <Typography variant="h6">De: {message.fromEmail}</Typography> 
          <Typography variant="caption" color="textSecondary">
          {format(new Date(message.createdAt), 'MMMM dd, yyyy HH:mm:ss')} {/* This will display time in 24-hour format */}
          </Typography>
          <Typography variant="h6">Referencia:{message.title}</Typography> {/* Adjust property if necessary */}
          <Typography variant="h6">Para: {message.targetuser}</Typography> {/* Adjust property if necessary */}
          <Typography variant="body1">{message.message}</Typography> {/* Ensure this is the correct property for content */}
         
        </Box>
      ))}
    </Box>
<p></p>
        <TextField
 label="Emails Usuarios"
 onBlur={handleBlur}
 onChange={handleChange}
 value={values.targetUser}
 name="targetUser"
 error={Boolean(touched.targetUser) && Boolean(errors.targetUser)}
 helperText={touched.targetUser && errors.targetUser}
 multiline
 fullWidth
 rows={1} // Set the number of rows to 3
 InputProps={{
   style: {
     background: 'white',
   },
 }}
/>
<div>

<TextField
  label="Titulo"
  onBlur={handleBlur}
  onChange={handleChange}
  value={values.title}
  name="title"
  error={Boolean(touched.title) && Boolean(errors.title)}
  helperText={touched.title && errors.title}
  multiline
  fullWidth
  InputProps={{
    style: {
      background: 'white',
    },
  }}
/>
 </div> 
 <TextField
              label="Mensaje"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.message}
              name="message"
              error={Boolean(touched.message) && Boolean(errors.message)}
              helperText={touched.message && errors.message}
              multiline
              fullWidth
              rows={4}
              maxRows={8}
              InputProps={{
                style: {
                  background: 'white',
                },
              }}
              sx={{ gridColumn: 'span 12' }}
            />
              <Box>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              Enviar Email
            </Button>
          {/* Add a success message box */}

          </Box>
      </form>
    )}
  </Formik>
  );
};

export default Form;
import { DataGrid, GridToolbar, GridColDef, esES, GridRenderCellParams } from "@mui/x-data-grid";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Pause from "@mui/icons-material/Build";
import Chip, { ChipProps } from "@mui/material/Chip";
import { green, red, blue, orange } from "@mui/material/colors";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateComplaint from "../../components/CreateComplaint";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";  
 import Grid from "@mui/material/Grid";
import StatBoxnumber from "../../components/statboxnumber";
import { Print } from '@mui/icons-material';
import { useReactToPrint } from "react-to-print";

import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { FormattedMessage } from 'react-intl';
import CertificatePdf from '../../components/certifcidecl'; // Import the PDF component
import PdfCertModalTotalNon from "../../components/pdfcertchecknontotal"; //modal pendientes total
import PdfCertModalTotal from "../../components/pdfcertchecktotal";// modal leidos total
import CertdeclmadeModal from "../../components/certdeclmade";// modal leidos total
import CertdeclpendientesModal from "../../components/certdeclpendientes";// modal leidos total

import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";


const Forms = () => {
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const loggedidcontrato = useSelector((state) => state.user.idcontrato);
  const [totalDeclarados, setTotalDeclarados] = useState(0);
  const [totalcam, setTotalcam] = useState(0);
  const [totalcamci, setTotalcamci] = useState(0);

  const [totalEvaluados, setTotalEvaluados] = useState(0);
  const [totalCampaign, setTotalCam] = useState(0);
  const [totalAutorizados, setTotalAutorizados] = useState(0);
  const [totalConConflicto, setTotalConConflicto] = useState(0);
  const [totalConMitigacion, setTotalConMitigacion] = useState(0);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [arnombre, setArnombre] = useState([]);

  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const loggedInUserprof = useSelector((state) => state.user.userprofile); 
  const [formTotalDays, setformTotalDays] = useState();
  const componentRef = useRef();
  const [filteredUsers, setFilteredUsers] = useState([]);
    const [matchingFile, setMatchingFile] = useState(null);
    const [nonMatchingUsers, setNonMatchingUsers] = useState([]);
    const [nonMatchingFiles, setNonMatchingFiles] = useState([]);
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [currentPdf, setCurrentPdf] = useState(null);

    const [opencertdeclpendiente, setopencertdeclpendiente] = useState(false);
    const [opencertdeclmade, setopencertdeclmade] = useState(false);
  

  const handleButtonClick = async (filter) => {
    
    try {
      // Fetch all users for the specific contract
      const responseUsers = await fetch(`${REACT_APP_API_ENDPOINT}users/contract/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!responseUsers.ok) {
        console.error("Failed to fetch users");
        return;
      }
  
      const userData = await responseUsers.json();
      // Check for null or undefined before filtering
      if (userData === null) {
        console.error("User data is null");
        return;
      }

      let filteredUsers;
  
      // Apply filter based on the button clicked
      if (filter === 'declaradas') {
        setopencertdeclmade(true);

        filteredUsers = userData.filter((user) => user.status === 'declarado');
        console.log('Filtered Users for Declaradas:',filteredUsers);

      } else if (filter === 'pendientes') {
        setopencertdeclpendiente(true);

        filteredUsers = userData.filter((user) => (user.status === 'decpendiente'));
        console.log('Filtered Users for Pendientes:',filteredUsers);

      } else {
        console.error('Invalid filter');
        return;
      }
  
      // Set the state with filtered users and open the modal
      setFilteredUsers(filteredUsers);

    } catch (error) {
      console.error('Error handling button click:', error);
    }
  };
  

  let filteredData = data;


  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
  
  filteredData = data
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });


  // Calculate the count of each area type from filtered data
  const areaCounts = filteredData.reduce((counts, item) => {
    const area = item.area || 'Unknown';
    counts[area] = (counts[area] || 0) + 1;
    return counts;
  }, {});

  const conflictCounts = filteredData.reduce((counts, item) => {
    const conflicto = item.tipoconflicto;
    if (conflicto) {
      counts[conflicto] = (counts[conflicto] || 0) + 1;
    }
    return counts;
  }, {});
  

 useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);

    fetchData();

  }, [isFormSubmitted]);


const fetchData = async () => {
  try {
    setIsLoading(true); // Set loading to true before fetching data

    let endpoint = `${REACT_APP_API_ENDPOINT}formepd/user/${loggedInUserId}`;
    if (loggedInUserprof === "superadmin"  ) {
      endpoint = `${REACT_APP_API_ENDPOINT}formepd`;
    }
    // Check the user profile and update the endpoint accordingly
    if (loggedInUserprof === "admin" || loggedInUserprof === "coadmin" || loggedInUserprof === "adminetica" || loggedInUserprof === "aprobador"   ) {
      endpoint = `${REACT_APP_API_ENDPOINT}formepd/contract/${loggedidcontrato}`;
    }
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = await response.json();
    delete formData.password;

    // Add unique id and format createdAt
    const dataWithFormattedDate = formData.map((row) => ({
      ...row,
      id: row._id,
      createdAt: row.createdAt
        ? new Date(row.createdAt).toLocaleDateString("en-EN", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        : "",
    }));
    setData(formData);
    // Fetch posts(campaign) data for logged-in user
    const postsResponse = await fetch(`${REACT_APP_API_ENDPOINT}posts/contract/${loggedidcontrato}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const postsData = await postsResponse.json();
    
    const ciPostsData = postsData.filter(post => post.opCampaign === "CI");
    const totalcamci = ciPostsData.reduce((total, post) => total + post.description.length, 0);
    setTotalcamci(totalcamci);
    

    const totalcam = postsData.reduce((total, post) => total + post.description.length, 0);
    setTotalcam(totalcam);
    setIsLoading(false); // Set loading to false after processing data

    // Calculate formTotalDays for each row based on corresponding campaignId
    const dataWithFormTotalDays = dataWithFormattedDate.map((row) => {
      const matchingPost = postsData.find((post) =>
      post.description.includes(row.email)
    );
    
    const campaignCreatedAt = matchingPost
      ? new Date(matchingPost.createdAt).toISOString() // Convert to ISO 8601 format
      : null;
    
    const formCreatedAt = new Date(row.createdAt).toISOString(); // Convert to ISO 8601 format
    
    const timeDifference =
      campaignCreatedAt && formCreatedAt
        ? new Date(campaignCreatedAt) - new Date(formCreatedAt) // Calculate the time difference in milliseconds
        : 0;
    
    const formTotalDays = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
      
      return {
        ...row,
        postId: matchingPost ? matchingPost._id : "",
        campaignName: matchingPost ? matchingPost.comments : "",
        formTotalDays: formTotalDays,
      };
      setformTotalDays(formTotalDays);
      setIsLoading(false);
      setData(dataWithFormattedDate);
    });

     
    // Calculate other data and update state with the calculated data
    const totalDeclarados = dataWithFormTotalDays.length;
    setTotalDeclarados(totalDeclarados);

    const evaluadosData = dataWithFormTotalDays.filter((row) => row.estado === "Evaluada");
    const totalEvaluados = evaluadosData.length;
    setTotalEvaluados(totalEvaluados);

    const authData = dataWithFormTotalDays.filter((row) => row.opauth === "Si");
    const totalAutorizados = authData.length;
    setTotalAutorizados(totalAutorizados);

    const formsConConflicto = dataWithFormTotalDays.filter((row) => row.opconflicto === "Si");
    const totalConConflicto = formsConConflicto.length;
    setTotalConConflicto(totalConConflicto);

    const formsConMitigacion = dataWithFormTotalDays.filter((row) => row.opmitigacion === "Si");
    const totalConMitigacion = formsConMitigacion.length;
    setTotalConMitigacion(totalConMitigacion);
    setIsLoading(false); // Set loading to false after processing data

    setData(dataWithFormTotalDays);
    setForm(formData);
  } catch (error) {
    console.error("Error fetching data:", error);
    setIsLoading(false); // Set loading to false in case of an error

  }
};

const totalNoDeclarados = ((totalcam-totalDeclarados ) * 1).toFixed(0) ;
const progdeclarados = ((totalDeclarados / totalcam) / 100).toFixed(1) ; 


const progNodeclarados = ((totalNoDeclarados / totalcam) * 1).toFixed(2) ; 
const progNodeclaradosper = ((totalNoDeclarados / totalcam) * 100).toFixed(1)+ "%" ;
const progevaluados = ((totalEvaluados / totalcam) * 1).toFixed(1) ;
const progevaluadosdedeclarados = ((totalEvaluados / totalDeclarados) * 1).toFixed(2) ;
const progevaluadosdeclper = ((totalEvaluados / totalDeclarados) * 100).toFixed(1)+ "%" ;
const progconflictodecl = ((totalConConflicto / totalDeclarados) * 1).toFixed(1) ;
const progautorizadosdedeclarados = ((totalAutorizados / totalDeclarados) * 1).toFixed(2) ;
const progmitigacionaut = ((totalConMitigacion / totalAutorizados) * 1).toFixed(2) ;
const progdeclaradosper = ((totalDeclarados / totalcam) * 100).toFixed(1)+ "%" ;

const progconflictoperdecl = ((totalConConflicto / totalDeclarados) * 100).toFixed(1)+ "%" ;
const progautorizadospersin = ((totalAutorizados / totalEvaluados) );
const progmitigacionautpersin = ((totalConMitigacion / totalAutorizados)) ;
const datax = Object.entries(areaCounts).map(([label, count]) => ({ label, count }));
const dataxconflicto = Object.entries(conflictCounts).map(([label, count]) => ({ label, count }));

datax.sort((a, b) => b.count - a.count);


  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row
      navigate(`/formev/${rowId}`, {
        state: {
          formData: params.row, // Pass the row data
          formTotalDays: params.row.formTotalDays // Pass the formTotalDays value
      
        }
      });   
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };

  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Declaraciones');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'Declaraciones.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

  const createdAtValueFormatter = (params) => {
    if (params.value) {
      const date = new Date(params.value);
      return date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    }}

    

    function getChipProps(params: GridRenderCellParams): ChipProps {
      if (params.value === "Por Evaluar") {
        return {
          icon: <WarningIcon style={{ fill: red[500] }} />,
          label: params.value,
          style: {
            borderColor: red[500]
          }
        };
      }


        if (params.value === "Evaluada") {
          return {
            icon: <CheckCircleIcon style={{ fill: green[500] }} />,
            label: params.value,
            style: {
              borderColor: green[500]
            }
          };
      } else {
        return {
          icon: <Pause style={{ fill: blue[500] }} />,
          label: params.value,
          style: {
            borderColor: blue[500]
          }
        };
      }
    }



    function getChipProps1(params: GridRenderCellParams): ChipProps {
      if (params.row.opDirectorio === "Si") {
        return {
          label: params.value,
          style: {
            borderColor: red[500]
          }
        };
      
      } else {
        return {
          label: params.value,
          style: {
            borderColor: blue[50]
          }
        };
      }
    }
    
  const columns = [

    {
      field: "createdAt",headerClassName: "bold-header",
      headerName: <FormattedMessage id="Declarado" />,
      flex: 0.8,
      valueFormatter: createdAtValueFormatter, // Apply the valueFormatter
    },
    {
      field: "fullName", // New field name
      headerName: <FormattedMessage id="Nombre" />,
    flex: 1,
    headerClassName: "bold-header",
    cellClassName: "fullname-column--cell",
    renderCell: (params) => (
      <Typography variant="body2">
        {params.row.firstName} {params.row.lastName}
      </Typography>
    ),
  },
  { field: "rut", headerName: <FormattedMessage id="Rut" />, flex: 1, hide: true, headerClassName: "bold-header" },
  { field: "email", 
 
  headerName: <FormattedMessage id="Email" />, flex: 1.5 ,headerClassName: "bold-header",
},


{ field: "location", headerName: <FormattedMessage id="Organización" />, flex: 1, editable: true, headerClassName: "bold-header" },

    { field: "area", headerName: <FormattedMessage id="Area" />, flex: 1,       editable: true ,headerClassName: "bold-header",
  },
  { field: "occupation",  renderCell: (params) => {
    return <Chip variant="outlined" size="small" {...getChipProps1(params)} />;
  }, headerName: <FormattedMessage id="Cargo" />, flex: 2,       editable: true ,headerClassName: "bold-header",
},


{ field: "opconflicto", headerName: <FormattedMessage id="Conflicto" />, flex: 0.8,       editable: true ,headerClassName: "bold-header",
  
},

  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    minWidth: 100,
    description: "estado",
    headerAlign: "left",
    headerClassName: "bold-header",
    renderCell: (params) => {
      return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
    }
  },

    { field: "opauth", headerName: <FormattedMessage id="Autorizado" />, flex: 1,       editable: true ,headerClassName: "bold-header",
  },
       
 

       

];




// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "80%", // Make the box 50% wide
      borderRadius: "25px", // Adjust the value to control the roundness
      padding: "15px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "25px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "25px", // Adjust the value to control the roundness

    },
  };
    // Function to save the changes for a specific row
    const saveRowChanges = async (rowId, updatedRow) => {
      try {
        await fetch(`${REACT_APP_API_ENDPOINT}formepd/${rowId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedRow),
        });
      } catch (error) {
        console.error("Error saving row changes:", error);
      }
    };
    

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}formepd/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
    }
  };



//****************************************************
return (
  
<Box m="10px"> 


<Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>

<Box display="flex" justifyContent="space-between" alignItems="center">
    <Header title={<FormattedMessage id="Declaraciones Recibidas" />} subtitle={<FormattedMessage id="Declaraciones de Conflicto de Interés Recibidas" />} />

    </Box>
    </Grid>


    <Grid item xs={12} sm={8} md={1}>


    </Grid>
  
  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""

      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
    {/* HEADER */}

   

    

{/* GRID & CHARTS */}
<Box
display="grid"
gridTemplateColumns="repeat(12, 1fr)"
gridAutoRows="140px"
gap="5px"
>


{/* ROW 1 */}

<Box
  gridColumn={{ xs: 'span 12', md: 'span 2' }}
  display="flex"
  alignItems="center"
  justifyContent="center"
  justifySelf="center" // Center the Box element horizontally within its parent
>
        <div onClick={() => navigate("/campaigns")}>
          <StatBoxnom
                title={totalcam.toString()}
                subtitle= {<FormattedMessage id="Invitaciones (Todas)" />}

                
                onClick={() => navigate("/campaigns")}
                icon={
                  <PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />
                }
                  />
         </div>
      </Box>

      <Box
  gridColumn={{ xs: 'span 12', md: 'span 2' }}
  display="flex"
  alignItems="center"
  justifyContent="center"
  justifySelf="center" // Center the Box element horizontally within its parent
>
        <div onClick={() => navigate("/campaigns")}>
          <StatBoxnom
                title={totalcamci.toString()}
                subtitle= {<FormattedMessage id="Invitaciones Form. CI" />}

                
                onClick={() => navigate("/campaigns")}
                icon={
                  <PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />
                }
                  />
         </div>
      </Box>

      <Box
          gridColumn={{ xs: 'span 12', md: 'span 2' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          >

          <div onClick={() => navigate("/Forms")}>

              <StatBoxnom
                title= {totalDeclarados.toString()} // Convert totalEvaluados to string   
                subtitle= {<FormattedMessage id="Declaraciones" />}

                progress={progdeclarados.toString()} 
                icon={
                  <EmailIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />}
                      
                    />
          </div>
      </Box>
  

      <Box
            gridColumn={{ xs: 'span 12', md: 'span 2' }}
              display="flex"
              alignItems="center"
              justifyContent="center"

            >

            <div onClick={() => navigate("/Forms")}>

              <StatBox
                title= {totalConConflicto.toString()} // Convert totalEvaluados to string   
                subtitle= {<FormattedMessage id="Conflicto" />}
                
                progress={progconflictodecl.toString()} 
                increase={progconflictoperdecl.toString()} 
                icon={
                  <ReportProblemOutlinedIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />
                }
              />
                </div>
            </Box>

      
            <Box
            gridColumn={{ xs: 'span 12', md: 'span 2' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >

            <div onClick={() => navigate("/Forms")}>

              <StatBox
                title={totalEvaluados.toString()} 
                subtitle= {<FormattedMessage id="Evaluaciones" />}

                progress={progevaluadosdedeclarados.toString()} 
                increase={progevaluadosdeclper.toString()} 
                icon={
                  <GradingOutlinedIcon
                    sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "30px" }}
                  />
                }
              />
              </div>
            </Box>

  </Box>

 
  <p></p><Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '1rem', marginBottom: '1rem' }}>
  <Button
    variant="contained"
    startIcon={<Print />}
    onClick={() => handleButtonClick('declaradas')}
    sx={{
      borderRadius: "20px",
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      flexGrow: 1, // Allow the button to grow and take available space
      marginRight: '1rem', // Add spacing between buttons
    }}
  >
    <FormattedMessage id="Personas Declararon" />
  </Button>

  <Button
    variant="contained"
    startIcon={<Print />}
    onClick={() => handleButtonClick('pendientes')}
    sx={{
      borderRadius: "20px",
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      flexGrow: 1,
      marginRight: '1rem',
    }}
  >
    <FormattedMessage id="Personas Pendientes" />
  </Button>

  <Button
    onClick={handleDownloadXLSX}
    sx={{
      borderRadius: "20px",
      backgroundColor: colors.blueAccent[700],
      color: colors.grey[900],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
    }}
  >
    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
    <FormattedMessage id="Descargar en Excel" />
  </Button>
</Box>





{/* DATAGRID */}    
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{ borderRadius: "20px",
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              rut: false,
              location: false,

            

            },
          },
        }}
        rowHeight={30}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}

        columns={columns}
        rows={filteredData.map((row) => ({
          ...row,
          id: row._id, 
        }))}
        headerClassName="bold-header"
        components={{ Toolbar: GridToolbar }}
      
        rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option

        onRowClick={handleRowClick}
      />
    </Box>
   


<Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >
{/*circle  autorizadas de mis recibidas*/}

<Box
  gridColumn={{ xs: 'span 12', md: 'span 2' }}
  gridRow="span 2"
        backgroundColor={colors.primary[400]}
        p="30px"
    >
        <Typography variant="h5" fontWeight="600">
        <FormattedMessage id="Autorizadas" />
        </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="25px"
                >
                 <ProgressCircle progress={progautorizadospersin} size="100" />
                  <Typography
                    variant="h5"
                    color={colors.greenAccent[500]}
                    sx={{ borderRadius: "20px", mt: "15px" }}
                  >
                      <FormattedMessage id="Cantidad" />

                  </Typography>
                  <Typography>{totalAutorizados}</Typography>
                </Box>
</Box>

{/*circle  con conflicto de mis recibidas*/}

<Box
  gridColumn={{ xs: 'span 12', md: 'span 2' }}
  gridRow="span 2"
        backgroundColor={colors.primary[400]}
        p="30px"
      >
        <Typography variant="h5" fontWeight="600">
        <FormattedMessage id="Con Conflicto" />
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="25px"
        >
         <ProgressCircle progress={progconflictoperdecl} size="100" />
          <Typography
            variant="h5"
            color={colors.greenAccent[500]}
            sx={{ borderRadius: "20px", mt: "15px" }}
          >
                              <FormattedMessage id="Cantidad" />

          </Typography>
          <Typography>{totalConMitigacion}</Typography>
        </Box>
</Box>


<Box
  gridColumn={{ xs: 'span 12', md: 'span 2' }}
  gridRow="span 2"
        backgroundColor={colors.primary[400]}
        p="30px"
      >
        <Typography variant="h5" fontWeight="600">
        <FormattedMessage id="Con Mitigación" />
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="25px"
        >
         <ProgressCircle progress={progmitigacionautpersin} size="100" />
          <Typography
            variant="h5"
            color={colors.greenAccent[500]}
            sx={{ borderRadius: "20px", mt: "15px" }}
          >
                              <FormattedMessage id="Cantidad" />

          </Typography>
          <Typography>{totalConMitigacion}</Typography>
        </Box>
</Box>


{/* ROW 3 */}


{/*barras*/}
       
              <Box
                  gridColumn={{ xs: 'span 12', md: 'span 6' }}
                  gridRow="span 2"
                        backgroundColor={colors.primary[400]}
                      >
                        <Box
                          mt="25px"
                          p="0 30px"
                          display="flex "
                          justifyContent="space-between"
                          alignItems="center"
                        >
                                <Box>
                                  <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color={colors.grey[100]}
                                  >
                 <FormattedMessage id="Tipos de Conflicto" />

                             
                                  </Typography>
                                  <Typography
                                    variant="h3"
                                    fontWeight="bold"
                                    color={colors.greenAccent[500]}
                                  >
                                    {totalConConflicto}
                                  </Typography>
                                </Box>
                          <Box>
                            
                          </Box>
                        
                        
                        </Box>
                            <Box height="250px" m="-20px 0 0 0">
                            <BarChart data={dataxconflicto} />

                          
                </Box>
      </Box>
      <Box
            gridColumn={{ xs: 'span 12', md: 'span 12' }}
            gridRow="span 2"
                  backgroundColor={colors.primary[400]}
                >
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                  >
                   <FormattedMessage id="Declaraciones por Area" />

                    
                  </Typography>


                  <Box height="250px" mt="-20px">

                  <BarChart data={datax} />

                  </Box>


      </Box>

      <CertdeclpendientesModal
       open={opencertdeclpendiente}
       onClose={() => setopencertdeclpendiente(false)}
       file={arnombre}
       users={filteredUsers}
       matchingFile={matchingFile}
       nonMatchingUsers={nonMatchingUsers} // Pass non-matching users her
      />

<CertdeclmadeModal
       open={opencertdeclmade}
       onClose={() => setopencertdeclmade(false)}
       file={arnombre}
       users={filteredUsers}
       matchingFile={matchingFile}
       nonMatchingUsers={nonMatchingUsers} // Pass non-matching users her
      />
  </Box>
</Box>
);
};

export default Forms;
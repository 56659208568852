// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, Slider, Paper,  List,
  ListItem,
  ListItemText } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateMeeting from "../../components/CreateMeeting";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FindIn from '@mui/icons-material/Troubleshoot';
import AddIcon from '@mui/icons-material/AlarmAddRounded';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";   
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es"; // Importa el idioma español
import { Formik } from "formik";
import * as yup from "yup";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import Dropzone from "react-dropzone";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RegSession from "../../components/RegSession";

const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const Meetings = () => {
  const localizer = momentLocalizer(moment);
  const userId = useSelector((state) => state.user._id);
  const loggedinidcontrato = useSelector((state) => state.user.idcontrato);
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [totalcam, setTotalcam] = useState(0);
  const [events, setEvents] = useState([]);
  const [filteredData1, setFilteredData] = useState([data]);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const calendarRef = useRef(null); // Initialize the calendarRef
  const [currentEvents, setCurrentEvents] = useState([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);
  const [showCreateCampaignEdit, setShowCreateCampaignEdit] = useState(false); // Set initial value to false
  const [selectedEvent, setSelectedEvent] = useState(null); // Initialize as null or {}
  const [currentStep, setCurrentStep] = useState(1);


  let filteredData = data;

  const validationSchema = yup.object().shape({
    title: yup.string().required("El asunto es obligatorio"),
    
    willPayReceiveValue: yup.string().notRequired(""),
    participantes: yup.string().required("Los participantes son obligatorios"),
    empresa: yup.string().required("La organización es obligatoria"), // Make 'empresa' mandatory
    area: yup.string().required("El área es obligatoria"), // Make 'area' mandatory
    direccion: yup.string().required("La dirección es obligatoria"), // Make 'direccion' mandatory
    rutfunc: yup.string().notRequired(""),
    cargo: yup.string().notRequired(""),
    otrosasistentes: yup.string().notRequired(""),
    numeroacta: yup.string().notRequired(""),
    namepub: yup.string().notRequired(""),
    pictures1: yup.array().notRequired(), // Make pictures1 optional

  });
  
  const initialValuesEditCampaign = {
    userId: userId,
    idcontrato: loggedinidcontrato,
    title: "",
    end:  "",
    allDay: false,
    start: "",
    willPayReceiveValue: "",
    participantes: "",
    empresa: "",
    area: "",
    direccion: "",
    rutfunc:  "",
    cargo:  "",
    otrosasistentes:  "",
    numeroacta:  "",
    namepub:  "",
    pictures1: [],
  };
  
  
  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };

   filteredData = data
  .filter((row) => {
    // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
    const createdAt = parseInt(row.createdAt);
    return createdAt >= dateRange[0] && createdAt <= dateRange[1];
  })
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });

  // Calculate the count of each area type from filtered data
  const tipottCounts = filteredData.reduce((counts, item) => {
    const tipott = item.area || 'Unknown';
    counts[tipott] = (counts[tipott] || 0) + 1;
    return counts;
  }, {});

// Calculate the count of each conflict type from filtered data
const userEmailCounts = filteredData.reduce((counts, item) => {
  const userEmail = item.empresa || 'Unknown';
  counts[userEmail] = (counts[userEmail] || 0) + 1;
  return counts;
}, {});

 useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);
    fetchData();

  }, [isFormSubmitted, searchInput]);

  const handleFormSubmit = async (values, onSubmitProps) => {

    console.log("values", values);
    
    const uploadToS3 = async (files) => {
      const bucketName = 'repoeticpro';
      const uniqueSuffix = Date.now() + "-";
      const s3Urls = [];
  
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        region: "sa-east-1",
      });
  
      const s3 = new AWS.S3();
  
      for (const file of files) {
        const key = uniqueSuffix + file.name;
        const params = {
          Bucket: bucketName,
          Key: key,
          Body: file,
        };
  
        try {
          await s3.upload(params).promise();
          console.log(`File uploaded to S3: ${key}`);
          s3Urls.push(key);
        } catch (error) {
          console.error(`Error uploading file to S3: ${error.message}`);
          throw error;
        }
      }
  
      return s3Urls;
    };
  
    try {
     // Append form values to FormData

      if (uploadedFiles.length > 0) {
        
        const s3Urls1 = await uploadToS3(uploadedFiles);
      }

      const filesToUpload = uploadedFiles.length > 0 ? uploadedFiles : [];
// Append to form values
const updatedValues = {
  ...values,
  pictures1: filesToUpload,

};

      // Example of saving user data using fetch
      const userResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}meetings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedValues),
      });
  
      const responseData = await userResponse.json();
      console.log("Response from API:", responseData);
  
      if (userResponse.ok) {
        console.log("User data stored successfully");
        onSubmitProps.resetForm();
        navigate("/successPage", {
          state: { message: "Reunión creada con éxito" }
        });
      } else {
        console.error("Error storing user data:", userResponse.status, userResponse.statusText);
      }
    } catch (err) {
      console.log("Error creating complaint:", err);
  
      if (err.response) {
        console.log("Response status:", err.response.status);
        err.response.text().then(responseText => {
          console.log("Response data:", responseText);
        });
      }
    }
  
    const idcontrato = loggedinidcontrato;
    RegSession({ token, userProfile, loggedInUserId, idcontrato, type: "PEP" });
  };
  
  useEffect(() => {
    if (openForm) {
      calendarRef.current?.getApi().render(); // Re-render calendar on modal open
    }
  }, [openForm]);
  
  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}meetings/user/${loggedInUserId}`;
      
      // Check the user profile and update the endpoint accordingly
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/`;
      } else if (userProfile === "admin" || userProfile === "coadmin" || userProfile === "adminetica" || userProfile === "sales" || userProfile === "comitetica" || userProfile === "aprobador" ) {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/contract/${idcontrato}`;
      }
  
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const formData = await response.json();
      console.log("formData", formData);
      setCurrentEvents(formData); // Update the state with fetched events

      if (!Array.isArray(formData)) {
        throw new Error("Invalid data format");
      }
      
      const formattedData = formData.map(item => ({
        ...item,
        formattedDate: moment(item.created).format('YYYY-MM-DD'), // Create formattedDate
      }));
  
  
      const userIds = formData.map((row) => row.userId);
  
      if (!Array.isArray(userIds) || userIds.length === 0) {
        throw new Error("No user IDs found in formData");
      }
  
      const userDataPromises = userIds.map((userId) =>
        fetch(`${REACT_APP_API_ENDPOINT}users/${userId}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => response.json())
      );
  
      const userDataArray = await Promise.all(userDataPromises);
  
      const userIdToEmailMap = userDataArray.reduce((acc, userData) => {
        if (userData && userData._id && userData.email) {
          acc[userData._id] = userData.email;
        }
        return acc;
      }, {});
  
      const dataWithFormattedDate = formattedData.map((row) => ({
        ...row,
        userEmail: userIdToEmailMap[row.userId] || '',
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-EN", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      const totalcam = dataWithFormattedDate.length;
      setTotalcam(totalcam);
      
      setIsLoading(false);
      setData(dataWithFormattedDate);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };
  
  
  
 
 const datax = Object.entries(tipottCounts).map(([label, count]) => ({ label, count }));
  const dataxconflicto = Object.entries(userEmailCounts).map(([label, count]) => ({ label, count }));


  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';
  
    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row

      navigate(`/meetingsEvPage/${rowId}`); // Replace "/campaigndetail" with the actual URL of your CampaignDetailPage

    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    onClose(); // Close the main dialog as well
  };



  
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
    setIsDateSelected(false); // Reset selection state

  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  
  const handleDateClick = (selected) => {
    const calendarApi = selected.view.calendar;
    calendarApi.unselect(); // Deselect the date
  
    // Create a new event object based on the selected date
    const newEvent = {
      id: `${selected.dateStr}`,
      start: selected.start,
      end: selected.end,
      allDay: selected.allDay || false, // Ensure it's a boolean
    };
  
    // Set the current events to just the new event, effectively clearing any previous selections
    setCurrentEvents([newEvent]); // Only keep the new event
    setIsDateSelected(true); // Set date selected state
  
    // Store the selected date in the state
    setSelectedDate(selected); 
    // Store the newEvent in state to use in form submission
    setSelectedEvent(newEvent); // Make sure you have this state defined
  };
  
  


  const handleEventClick = (selected) => {
    const title  = selected.event.title;
    const start = selected.event.start;
    setDialogOpen(true);
    setSelectedEventDetails({
      ...selected.event.extendedProps,
      title,
      start,
    });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEventDetails(null);
  };


  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };


  const handleOpenForm = () => {
    setOpenForm(true);
  };
  
  const handleCloseForm = () => {
    // Reset all selected dates and events
    setCurrentEvents([]); // Clear current events
    setSelectedDate(null); // Reset selected date
    setSelectedEvent(null); // Reset selected event
    setIsDateSelected(false); // Reset date selection state
    setOpenForm(false);

  };
  
  
  const handleOpenFormEdit = () => {
    setOpenFormEdit(true);
  };
  
  const handleCloseFormEdit = () => {
    setOpenFormEdit(false);
  };
  


  const columns = [
    {
      field: "start",
      headerName: <FormattedMessage id="Ocurrió"/>,
      flex: 1, headerClassName: "bold-header",
      valueGetter: (params) => {
        // Convert the date to the "es-ES" locale format
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString("es-ES");
      },    },
    { 
      field: "user.firstName", 
      headerName: <FormattedMessage id="Nombres" />,
      flex: 1, 
      valueGetter: (params) => params.row.user ? params.row.user.firstName : "" 
    ,headerClassName: "bold-header",
  },
        { field: "user.lastName",     headerName: <FormattedMessage id="Apellidos" />
        , flex: 1, valueGetter: (params) => params.row.user.lastName ,headerClassName: "bold-header", },
    { field: "user.rut",     headerName: <FormattedMessage id="Rut" />
    , flex: 1,  valueGetter: (params) => params.row.user.rut ,headerClassName: "bold-header", },
    { field: "user.location",     headerName: <FormattedMessage id="Organización" />, 
    flex: 1, valueGetter: (params) => params.row.user.location ,headerClassName: "bold-header", },
    { field: "user.occupation", headerName: <FormattedMessage id="Rol"/>,  flex: 1, headerClassName: "bold-header",valueGetter: (params) => params.row.user.occupation },
    { field: "user.area", headerName: <FormattedMessage id="Area"/>,  flex: 1, headerClassName: "bold-header", valueGetter: (params) => params.row.user.area },
    { field: "participantes", headerName: <FormattedMessage id="Participantes"/>, flex: 1, headerClassName: "bold-header", },
    { field: "title", headerName: <FormattedMessage id="Asunto"/>,  flex: 1, headerClassName: "bold-header", },
    
    { field: "willPayReceiveValue", headerName: <FormattedMessage id="Pago/Recepción"/> , flex: 1 ,headerClassName: "bold-header", },
    { field: "empresa", headerName: <FormattedMessage id="Contraparte"/>, flex: 1,headerClassName: "bold-header", },
    { field: "area", headerName: <FormattedMessage id="Area Contraparte"/>, flex: 1,headerClassName: "bold-header", },
    { field: "direccion", headerName: <FormattedMessage id="Direccion"/>,  flex: 1 ,headerClassName: "bold-header", },
    { field: "rutfunc", headerName: <FormattedMessage id="Rut Fun."/>,  flex: 1 , headerClassName: "bold-header", },
    { field: "cargo", headerName: <FormattedMessage id="Cargo Fun."/>, flex: 1,headerClassName: "bold-header", },
    { field: "otrosasistentes", headerName: <FormattedMessage id="Asistentes"/>, flex: 1, editable: true ,headerClassName: "bold-header", },
    { field: "numeroacta", headerName: <FormattedMessage id="Acta"/>, flex: 1,headerClassName: "bold-header", },
    { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1,headerClassName: "bold-header", },

    {
      field: "createdAt",
      headerName: <FormattedMessage id="Declarado"/>,
      flex: 1, headerClassName: "bold-header",
      valueGetter: (params) => {
        // Convert the date to the "es-ES" locale format
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString("es-ES");
      },    },
 
  ];


// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  let clientJSX;

  clientJSX = (
    <Box>
      {!showCreateCampaign && (
        <Button
          onClick={handleOpenForm}
          sx={{
            borderRadius: '20px',
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[900],
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '10px 20px',
          }}
        >
          <Upload sx={{ borderRadius: '20px', mr: '10px' }} />
          <FormattedMessage id="Nueva Reunion" />
        </Button>
      )}

      {/* Dialog to display CreateMeeting component */}
      <Dialog
        open={showCreateCampaign}
        onClose={handleCloseForm}
        maxWidth="sm" // Set maxWidth to 'sm' to narrow the dialog
        fullWidth // Allows the dialog to take the full width up to the maxWidth
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '30%', // Set width to 70% to narrow by 30%
              maxWidth: 'none', // Prevents the maxWidth from overriding
            },
          },
        }}
      >
      </Dialog>
    </Box>
  );


  return (
    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
    

    {/* HEADER */}

    <Box display="flex" justifyContent="space-between" alignItems="center">
    <Header title={<FormattedMessage id="Reuniones" />} subtitle={<FormattedMessage id="Reuniones con Personas de Interés" />} />

    </Box>
    </Grid>

      <Grid item xs={12} sm={8} md={1}>
</Grid>


  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>

</Grid>

 </Grid>
 {clientJSX}


 
         <p></p>
        


{/*datagrid que lo ve el denunciante*/}
<p></p>

 <Paper elevation={3} sx={{ p: 1, mb: 1 }}>

              <Box display="flex" justifyContent="space-between">
        {/* BARRA LATERAL DEL CALENDARIO */}
   

        {/* CALENDARIO */}
      
         {/* Popup Dialog */}
         <Dialog 
open={openForm} 
onClose={handleCloseForm} 
fullScreen={!isNonMobileScreens}
disableEnforceFocus
  sx={{ 
    "& .MuiDialog-paper": { // Targeting the dialog's paper element
      width: "600px", // Set width to 300px
      maxWidth: "600px" // Ensure it doesn't expand beyond 300px
    }
  }}
>
          <DialogTitle>
          <Typography variant="h2">Crear Reunion</Typography>
          </DialogTitle>
          <DialogContent>
      <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={validationSchema}
    >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
              isSubmitting,

    }) => (
<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
 <p></p>
 
 {currentStep === 1 && (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
    
    {/* Calendar Box */}
    <Box flex="1 1 100%" ml="15px">
      <FullCalendar
        initialView="timeGridWeek"
        slotMinTime="06:00:00" // Start time at 7 AM
        slotMaxTime="22:00:00" // End time at 10 PM
        dayHeaderFormat={{ weekday: 'long' }}
        weekends={false} // Hides Saturday and Sunday
        ref={calendarRef}
        height="75vh"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay,listMonth",
        }}
        initialView="timeGridWeek"
        key={currentEvents.length} // Forces re-rendering on event count change
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        select={(selected) => handleDateClick(selected)}
        eventClick={handleEventClick}
        events={currentEvents}
        locales={[esLocale]}
        locale="es"
      />
    </Box>

    {/* Button Box */}
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <Button    
        onClick={handleNextStep}
        endIcon={<ArrowForward />}
        disabled={Boolean(errors.emailaviso)} // Disable if email is invalid
      >
        Siguiente
      </Button>
    </Box>

  </Box>
)}

              
{currentStep === 2 && (
 
 <Box>
       
 <TextField
             label={<FormattedMessage id="Organismo/Organización/Institución/Comunidad con quien se hará la reunión"/>}
 
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.empresa}
             name="empresa"
             error={Boolean(touched.empresa) && Boolean(errors.empresa)}
             helperText={touched.empresa && errors.empresa}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 <TextField
             label={<FormattedMessage id="Area funcional con quien se hará la reunión"/>}
             
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.area}
             name="area"
             error={Boolean(touched.area) && Boolean(errors.area)}
             helperText={touched.area && errors.area}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 <TextField
             label={<FormattedMessage id="Cargo de la Persona de interés"/>}
 
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.cargo}
             name="cargo"
             error={Boolean(touched.cargo) && Boolean(errors.cargo)}
             helperText={touched.cargo && errors.cargo}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 <TextField
             label={<FormattedMessage id="Nombre de la Persona de interés"/>}
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.namepub}
             name="namepub"
             error={Boolean(touched.namepub) && Boolean(errors.namepub)}
             helperText={touched.namepub && errors.namepub}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 
 
 
 <TextField
             label={<FormattedMessage id="Rut en caso de ser fiscalizador"/>}
             onBlur={handleBlur}
             onChange={(event) => {
               // Use a regular expression to allow only numbers and no dots or characters
               const newValue = event.target.value.replace(/[^0-9K]/gi, '');
               handleChange({ target: { name: 'rutfunc', value: newValue } });
             }}
             value={values.rutfunc}
             name="rutfunc"
             error={Boolean(touched.rutfunc) && Boolean(errors.rutfunc)}
             helperText={touched.rutfunc && errors.rutfunc}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 
 <TextField
             label={<FormattedMessage id="Número de participantes en la reunión"/>}
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.participantes}
             name="participantes"
             error={Boolean(touched.participantes) && Boolean(errors.participantes)}
             helperText={touched.participantes && errors.participantes}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 

     <p></p>
 
 
 
 
  
       <TextField
             label={<FormattedMessage id="Nombres de otros participantes a la reunión"/>}
             onBlur={handleBlur}
             onChange={handleChange}
             value={values.otrosasistentes}
             name="otrosasistentes"
             error={Boolean(touched.otrosasistentes) && Boolean(errors.otrosasistentes)}
             helperText={touched.otrosasistentes && errors.otrosasistentes}
             multiline
             fullWidth
   sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
           />
 
 

<TextField
            label={<FormattedMessage id="Lugar, Dirección o medio"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.direccion}
            name="direccion"
            error={Boolean(touched.direccion) && Boolean(errors.direccion)}
            helperText={touched.direccion && errors.direccion}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />
    <p></p>



<TextField
            label={<FormattedMessage id="Proposito de la Reunión"/>}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.title}
            name="title"
            error={Boolean(touched.title) && Boolean(errors.title)}
            helperText={touched.title && errors.title}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

<TextField
            label={<FormattedMessage id="Si hay invitación o regalo u otra transferencia de valor indicar valor"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.willPayReceiveValue}
            name="willPayReceiveValue"
            error={Boolean(touched.willPayReceiveValue) && Boolean(errors.willPayReceiveValue)}
            helperText={touched.willPayReceiveValue && errors.willPayReceiveValue}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />


<TextField
            label={<FormattedMessage id="Acuerdos realizados o N° Acta Reunión"/>}

            onBlur={handleBlur}
            onChange={handleChange}
            value={values.numeroacta}
            name="numeroacta"
            error={Boolean(touched.numeroacta) && Boolean(errors.numeroacta)}
            helperText={touched.numeroacta && errors.numeroacta}
            multiline
            fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
          />

  <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                
               <Dropzone
               multiple={false}
               onDrop={(acceptedFiles) => {
                 setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                 setFieldValue("pictures1", acceptedFiles); // Update Formik's field value as well, if needed
               }}
             >
               {({ getRootProps, getInputProps }) => (
                 <Box
                   {...getRootProps()}
                   border={`2px dashed ${palette.primary.main}`}
                   p="1rem"
                   sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                 >
                   <input {...getInputProps()} />
                   {!values.pictures1 || values.pictures1.length === 0 ? ( // Check if "pictures" is empty or not
                     <p>Adjunte Acta Aquí si corresponde. (5Mb Máximo) </p>
                   ) : (
                     <div>
                       {values.pictures1.map((file, index) => (
                         <FlexBetween key={index}>
                           <Typography>{file.name}</Typography>
                           <EditOutlinedIcon />
                         </FlexBetween>
                       ))}
                     </div>
                   )}
                  </Box>
                )}
              </Dropzone>




                </Box>
        
    
                <Box display="flex" justifyContent="center">
      <Button onClick={handlePreviousStep} startIcon={<ArrowBack />}>
        Anterior
      </Button>
      
     
    </Box>
        {/* BUTTONS */}
        <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >

              {<FormattedMessage id="Enviar"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                            {<FormattedMessage id="Envío exitoso"/>}

               
              </Box>
            )}
          


</Box>

 
        </Box>
 
           )}
</form>
        
     
     )}
    </Formik>

    </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      </Box>

  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
  {/* StatBox - 3 columns */}
  <Box gridColumn={{ xs: 'span 3', md: 'span 2' }} gridRow="span 2">

  </Box>
  </Box>
  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.length.toString()}
      subtitle={<FormattedMessage id="Reuniones" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
  
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.filter(row => row.estado === "Por Evaluar").length.toString()}
      subtitle={<FormattedMessage id="Por Evaluar" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>

  <div>
    <StatBoxnom
      title={filteredData.filter(row => row.estado === "Evaluada").length.toString()}
      subtitle={<FormattedMessage id="Evaluada" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
</Box>
</Paper>
<p></p>



          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{ borderRadius: "20px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
            >
            {/* Display loading spinner if isLoading is true */}
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress />
              </div>
            ) : (
             
             
              <DataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    "user.area": false,
                    "area": false,
                    "start": false,

                    "participantes": false,
                    "willPayReceiveValue": false,
                    "direccion": false,
                    "rutfunc": false,
                    "cargo": false,
                    "user.rut": false,
                    "otrosasistentes": false,
                    "numeroacta": false,
                    
                    "user.location": true,


                    // Set other columns visibility here
                  },
                },
              }}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={filteredData.map((row) => ({
                ...row,
                id: row._id,
              }))}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                  toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
              }}}
              rowsPerPageOptions={[10, 20, 50]} // Include 25 in the options
              columns={columns}
              onRowClick={handleRowClick}
              headerClassName="bold-header"

            />

            
              )}


            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              message="Copiado al portapapeles"
            />
          </Box>
          <p></p>
   
             
 {/*barras*/}
         <Box
              gridColumn={{ xs: 'span 12', md: 'span 6' }}
              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                    >
            <FormattedMessage id="Reuniones por Area" />

                    </Typography>


                    <Box height="250px" mt="-20px">

                    <BarChart data={datax} />

                    </Box>


        </Box>
   <Box
                    gridColumn={{ xs: 'span 12', md: 'span 12' }}
                    gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                        >
                          <Box
                            mt="25px"
                            p="0 30px"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight="600"
                                      color={colors.grey[100]}
                                    >
                                    <FormattedMessage id="Reuniones por Organización" />

                                    </Typography>
                                    <Typography
                                      variant="h3"
                                      fontWeight="bold"
                                      color={colors.greenAccent[500]}
                                    >
                                    </Typography>
                                  </Box>
                            <Box>
                              
                            </Box>
                          
                          
                          </Box>
                              <Box height="250px" m="-20px 0 0 0">
                              <BarChart data={dataxconflicto} />

                              </Box>
                             
                  </Box></Box>
  );
};

export default Meetings;
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TextField, Typography, Paper, Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AWS from 'aws-sdk';
import { useSelector } from "react-redux";

const FollowComplaint = ({ open, onClose }) => {
  const { REACT_APP_API_ENDPOINTBP, REACT_APP_ACCESS_KEY_ID, REACT_APP_SECRET_ACCESS_KEY } = process.env;
  const theme = useTheme();
  const [ticketData, setTicketData] = useState({ dialogues: [] });
  const loggedinusercontract = useSelector((state) => state.user.idcontrato);
  const [newMessage, setNewMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const scrollableContainerRef = useRef(null);
  const [totalWordCount, setTotalWordCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalEmbedded, setTotalEmbedded] = useState(0);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
      region: "sa-east-1",
    });
    getForm(); // Cargar los datos iniciales al montar el componente
  }, [REACT_APP_ACCESS_KEY_ID, REACT_APP_SECRET_ACCESS_KEY]);

  useEffect(() => {
    // Scroll to the bottom whenever the ticketData changes
    scrollToBottom();
  }, [ticketData]);

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const uploadedFilesArray = Array.from(files);
    setUploadedFiles(uploadedFilesArray);
    setUploadedFileName(uploadedFilesArray[0]?.name || '');

    const formData = new FormData();
    if (uploadedFilesArray.length > 0) {
      uploadedFilesArray.forEach((file) => {
        formData.append('pictures', file);
      });

      try {
        const uniqueSuffix = Date.now() + "-";
        const key = uniqueSuffix + uploadedFilesArray[0].name;
        formData.append('picturePath', key);
        formData.append('estado', "Nueva Informacion");

        const s3 = new AWS.S3();
        const params = {
          Bucket: 'repobatonpass',
          Key: key,
          Body: uploadedFilesArray[0],
        };

        await s3.upload(params).promise();
        console.log(`File uploaded to S3: ${key}`);
        await getForm(); // Actualizar los datos después de subir el archivo
      } catch (error) {
        console.error(`Error uploading file to S3: ${error.message}`);
      }
    }
  };
  const getForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINTBP}kb/contract/${loggedinusercontract}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        const totalCount = data.dialogues.reduce((acc, dialogue) => {
          const detailCount = countWords(dialogue.detail);
          const indexedResponseCount = parseFloat((countWords(dialogue.indexedResponse)));
          return acc + detailCount + indexedResponseCount;
        }, 0);
        setTotalWordCount((totalCount / 750000).toFixed(5));
        setPrice(totalCount/750000*30);

        setTicketData({
          ...data,
          dialogues: data.dialogues || []

        });
      } else {
        console.error('Failed to fetch updated complaint data');
      }
    } catch (error) {
      console.error('Error fetching updated complaint data:', error);
    }
  };
  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINTBP}kb/${loggedinusercontract}/add-dialogue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          detail: newMessage,
          sender: loggedInUserId,
          idcontrato: loggedinusercontract,
        }),
      });

      if (response.ok) {
        await getForm(); // Refresh data after sending the message
        setNewMessage(''); // Clear the new message field
        setTimeout(scrollToBottom, 100); // Scroll to the bottom after sending the message with a delay
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

 

  const startNewDialog = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINTBP}kb/${loggedinusercontract}/start-new-dialog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          idcontrato: loggedinusercontract,
          detail: 'Inicio de nuevo chat', // Proporcionar un valor no vacío
          sender: loggedInUserId,
        }),
      });

      if (response.ok) {
        const newDialogue = await response.json();
        setTicketData({
          dialogues: [newDialogue],
        });
        setUploadedFiles([]);
        setUploadedFileName('');
        setNewMessage('');
        console.log('Nuevo chat iniciado con éxito');
      } else {
        throw new Error('Failed to start new chat');
      }
    } catch (error) {
      console.error('Error al iniciar un nuevo chat:', error);
    }
  };


  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    }
  };

  return (
    <>

  
      <Typography variant="h4"><strong>Chat AI</strong></Typography>
     
      <Button variant="contained" color="secondary" onClick={startNewDialog}>
        Nuevo Chat
      </Button>

      <Box mt={4}>
     
        <Box ref={scrollableContainerRef} sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          {ticketData.dialogues.length > 0 ? (
            ticketData.dialogues.map((dialogue, index) => (
              <Paper key={index} elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1">{dialogue.detail}</Typography>
                <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                  {new Date(dialogue.dateOccurred).toLocaleString()}
                </Typography>
                {dialogue.indexedResponse && (
                  <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                    {dialogue.indexedResponse}
                  </Typography>
                )}
              </Paper>
            ))
          ) : (
            <Typography variant="body1"></Typography>
          )}
          {uploadedFileName && (
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1"><strong>Archivo subido:</strong> {uploadedFileName}</Typography>
            </Paper>
          )}
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Grid container alignItems="center">
            <Grid item>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
              <label htmlFor="fileInput">
                <IconButton color="primary" component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Mensaje Nuevo"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                multiline
                rows={4}
                variant="outlined"
                sx={{ width: isNonMobile ? '800px' : '100%', mr: 2 }}
              />
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleSendMessage}>
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default FollowComplaint;

import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { Dialog, CircularProgress } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FieldArray, Field } from "formik";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { FormattedMessage } from 'react-intl';
import RegSession from "../../components/RegSession";

const customModalStyles = {
  content: {
    width: '400px',
    height: '280px',
    margin: 'auto',
    background: '#ffffff', // Set the background color to white
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 1, 0.5)', // Set the background color of the overlay to semi-transparent black
  },

};



const Form = () => {
  const [isFormepdSavedModalOpen, setIsFormepdSavedModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Step 1: Initialize the loading state
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";
  const [isConflictxtClicked, setIsConflictxtClicked] = useState(false);
  const [cmff, setCmff] = useState(false);
  const userprofile = ""; 
  const industria = useSelector((state) => state.client.industria); 

  const location = useLocation();
  const pathnameParts = location.pathname.split("/");

  const { REACT_APP_API_ENDPOINT } = process.env;

  const createformSchema = yup.object().shape({
    rut: yup.string().required("Requerido"),
    telefono: yup.string(),
    firstName: yup.string(),
    lastName: yup.string(),
    location: yup.string(),
    occupation: yup.string(),
    opconflicto: yup.string().oneOf(["Si", "No"]),
    tipoconflicto: yup.string().oneOf(["Parentesgo ", "Participacion Societaria", "Toma de decision", "Contrate pariente", "Relacion PEP"]),
    conflictxt: yup.string(),
    estado: yup.string(),
    opauth: yup.string().oneOf(["Si", "No"]),
    authtxt: yup.string(),
    opmitigacion: yup.string().oneOf(["Si", "No"]),
    mitigaciontxt: yup.string(),
    comentariosAdi: yup.string(),

    campaignid: yup.string(),
    area: yup.string(),
    nationality: yup.string(),
    emailaviso: yup.string(),
    confirmRead: yup.boolean().oneOf([true], "Debes confirmar que has leído la información."),
  
    persons: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        lastname: yup.string(),
        nationalityrel: yup.string(),
        relationship: yup.string(),
        rutr: yup.string(),
        operation: yup.string(),
      })
    ),
  });
  
  const countries = [
    'CHILE', 'AFGANISTÁN', 'ALBANIA', 'ALEMANIA', 'ARGELIA', 'ANDORRA', 'ANGOLA', 'ANGUILA', 'ANTIGUA Y BARBUDA', 'ANTILLAS HOLANDESAS', 'ARABIA SAUDITA', 'ARGELIA',
    'ARGENTINA', 'ARMENIA', 'ARUBA', 'AUSTRALIA', 'AUSTRIA', 'AZERBAIYÁN', 'AZORES', 'BAHAMAS', 'BAHREIN', 'BALEARIC', 'BANGLADESH',
    'BARBADOS', 'BIELORRUSIA', 'BÉLGICA', 'BELICE', 'BENIN', 'BERMUDA', 'BHUTAN', 'BIRMANIA', 'BOLIVIA', 'BOSNIA', 'BOSNIA Y HERZEGOVINA', 'BOTSWANA',
    'BRASIL', 'BRUNEI', 'BULGARIA', 'BURKINA FASO', 'BURUNDI', 'CABO VERDE', 'CAMBOYA', 'CAMERÚN', 'CANADÁ', 'CHAD', 'CHANNEL ISLANDS', 'CHECHENIA',
    'CHILE', 'CHINA', 'CHIPRE', 'CIUDAD DEL VATICANO', 'COCOS', 'COLOMBIA', 'COMOROS', 'CONGO', 'COREA DEL NORTE', 'COREA DEL SUR', 'COSTA DE MARFIL', 'COSTA RICA', 'CROACIA',
    'CUBA', 'DINAMARCA', 'DJIBOUTI', 'DOMINICA', 'ECUADOR', 'EGIPTO', 'EL SALVADOR', 'EMIRATOS ÁRABES UNIDOS', 'ERITREA',
    'ESPAÑA', 'ESTADOS FEDERADOS DE MICRONESIA', 'ESTADOS UNIDOS', 'ESTONIA', 'ETIOPÍA', 'FIJI', 'FILIPINAS', 'FINLANDIA', 'FRANCIA', 'GABÓN', 'GAMBIA', 'GEORGIA', 'GEORGIA Y SANDWICH', 'GHANA',
    'GIBRALTAR', 'GRANADA', 'GRECIA', 'GROENLANDIA', 'GUADALUPE', 'GUAM', 'GUATEMALA', 'GUAYANA FRANCESA', 'GUERNSEY', 'GUINEA', 'GUINEA ECUATORIAL', 'GUINEA-BISSAU', 'GUYANA',
    'HAITÍ', 'HERZEGOVINA', 'HOLANDA', 'HONDURAS', 'HONG KONG', 'HUNGRÍA', 'INDIA', 'INDONESIA', 'IRAK', 'IRÁN', 'IRLANDA', 'ISLA ASCENSIÓN', 'ISLA BOUVET', 'ISLA DE MADEIRA', 'ISLA DE NAVIDAD',
    'ISLA FALKLAND', 'ISLA FAROE', 'ISLA NORFOLK', 'ISLANDIA', 'ISLAS CAIMÁN', 'ISLAS COOK', 'ISLAS DE NORFOLK', 'ISLAS HEARD Y MCDONALD', 'ISLAS MARIANAS DEL NORTE', 'ISLAS MARSHALL',
    'ISLAS PITCAIRN', 'ISLAS SALOMÓN', 'ISLAS VIRGENES BRITÁNICAS', 'ISLAS VIRGENES DE EE. UU.', 'ISLE OF MAN', 'ISRAEL', 'ITALIA', 'JAMAICA', 'JAPÓN', 'JERSEY', 'JORDANIA', 'KAZAKHSTAN',
    'KENIA', 'KIRGUISTÁN', 'KIRIBATI', 'KOSOVO', 'KUWAIT', 'LAOS', 'LATVIA', 'LESOTHO', 'LÍBANO', 'LIBERIA', 'LIBIA', 'LIECHTENSTEIN', 'LITUANIA', 'LUXEMBURGO', 'MACAO', 'MACEDONIA',
    'MADAGASCAR', 'MALASIA', 'MALAWI', 'MALDIVAS', 'MALI', 'MALTA', 'MARRUECOS', 'MARTINICA', 'MAURITANIA', 'MAURICIO', 'MAYOTTE', 'MÉXICO', 'MOLDOVA', 'MÓNACO', 'MONGOLIA', 
    'MONTENEGRO', 'MONTSERRAT', 'MOZAMBIQUE', 'NAMIBIA', 'NAURU', 'NEPAL', 'NICARAGUA', 'NÍGER', 'NIGERIA', 'NIUE', 'NORUEGA', 'NUEVA CALEDONIA', 'NUEVA ZELANDIA', 'OMÁN', 'PAKISTÁN', 'PALAU', 'PALESTINA', 'PANAMÁ', 
    'PAPÚA NUEVA GUINEA', 'PARAGUAY', 'PERÚ', 'PITCAIRN', 'POLINESIA FRANCESA', 'POLONIA', 'PORTUGAL', 'PUERTO RICO', 'QATAR', 'REINO UNIDO', 'REPÚBLICA CHECA', 'REPÚBLICA DE ÁFRICA CENTRAL', 'REPÚBLICA DE SUDÁN DEL SUR', 
    'REPÚBLICA DEL CONGO', 'REPÚBLICA DEMOCRÁTICA DEL CONGO', 'REPÚBLICA DOMINICANA', 'REUNIÓN', 'RUANDA', 'RUMANIA', 'RUSIA', 'SAHARA OCCIDENTAL', 'SAINT KITTS Y NEVIS', 'SAMOA', 'SAMOA AMERICANA', 'SAN MARINO', 'SAN PEDRO Y MIGUELÓN', 
    'SAN VICENTE Y LAS GRANADINAS', 'SANTA HELENA', 'SANTA LUCÍA', 'SAO TOME Y PRINCIPE', 'SENEGAL', 'SERBIA Y MONTENEGRO', 'SEYCHELLES', 'SIERRA LEONA', 'SINGAPUR', 'SIRIA', 'ESLOVAQUIA', 'ESLOVENIA', 'SOMALIA', 'SRI LANKA', 
    'ST. HELENA', 'SUDÁFRICA', 'SUDÁN', 'SUECIA', 'SUIZA', 'SURINAME', 'SVALBARD', 'SWAZILANDIA', 'TAILANDIA', 'TAIWÁN', 'TAJIKISTÁN', 'TANZANIA', 'TIMOR DEL ESTE', 'TOGO', 'TOKELAU', 'TONGA', 'TRINIDAD Y TOBAGO', 'TÚNEZ', 
    'TURCOS Y CAICOS', 'TURKMENISTÁN', 'TURQUÍA', 'TUVALU', 'UCRANIA', 'UGANDA', 'URUGUAY', 'UZBEKISTÁN', 'VANUATU', 'VATICANO', 'VENEZUELA', 'VIETNAM', 'WALLIS Y FUTUNA', 'YEMEN', 'YIBUTI', 'YUGOSLAVIA', 'ZAIRE', 'ZAMBIA', 
    'ZIMBABUE',
  ];
  
  const initialValuescreateform = {
    rut: "",
    telefono: "",
    firstName: "",
    lastName: "",
    email: "",
    nationality: "CHILE",
    location: "",
    occupation: "",
    opconflicto: "No",
    tipoconflicto: "",
    conflictxt: "",
    estado : "Por Evaluar",
    userprofile: "declare",
    opauth: "",
    authtxt: "",
    opmitigacion: "",
    mitigaciontxt: "",
    comentariosAdi: "",
    campaignid: "",
    idcontrato: "",
    area: "",
    userId: "",
    emailaviso: "",
    idevaluacion: "",
    confirmRead: false,
  
    persons: [{ name: "", lastname: "",  nationalityrel: "", relationship: "", rutr: "", operation: "" }],
    opDirectorio: "No",
    rutDirectorio: "",
    razonSocialDirectorio: "",
    tipoRelacionDirectorio: "",
    operacionInteres: "",
  
  };
  


  const [campaignid, idcontrato, userId, email, cmf] = location.pathname
    .split("/")
    .pop()
    .split(",")
    .map((value) => decodeURIComponent(value.replace("%40", "@")));


  let errorMessageElement; // Define the errorMessageElement variable

  const createform = async (values, onSubmitProps) => {
    try {
      setLoading(true); // Step 3: Set loading state to true before form submission

       // Add cmf to the values object
    const valuesWithCMF = {
      ...values,
      cmf: cmf,
    };

      
      const savedUserResponse = await fetch(`${REACT_APP_API_ENDPOINT}formepd`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(valuesWithCMF),
      });

      if (savedUserResponse.status === 201) {

        // Form successfully created
        onSubmitProps.resetForm();

        navigate("/successPage");

        const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ }),
        });
    
        const { token } = await tokenResponse.json();

        const loggedInUserId = values.userId;

        const idcontrato = values.idcontrato;





        if (!tokenResponse.ok) {
          throw new Error('Failed to generate temporary token');
        }
    
        


// Update status to "declared"
const updateUserResponse = await fetch(`${REACT_APP_API_ENDPOINT}users/${values.userId}`, {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({
    userId: values.userId, // Provide the user ID here
    status: "declarado",
  }),
});

if (updateUserResponse.status !== 200) {
  throw new Error("Failed to update user status");
}
RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "CI" });


      } else if (savedUserResponse.status === 409) {
        // Email conflict, display an error message to the user
        toast.error("Formulario ya habia sido guardado antes");
        setIsFormepdSavedModalOpen(true);
        onSubmitProps.resetForm();

        throw new Error("Email ya existia para esta campaña");
      } else {
        // Other error occurred, handle as desired
        throw new Error("Ocurrió un error");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
    
      setLoading(false); // Make sure to reset the loading state when done
    }
    
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    if (!checkboxChecked) {
      // Display the popup message here
      toast.error("Haga click en el checkbox antes de enviar");
      return;
    }
  
    if (iscreateform) {
      await createform(values, onSubmitProps);
    }
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };


  return (
    <div style={{ display: "grid", placeItems: "center", height: "300", backgroundColor: "white" }}>

<Box p={3} border="1px solid" borderRadius={8} style={{ backgroundColor: "white" }}>

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={{
      ...initialValuescreateform,
      campaignid: campaignid, // Set campaignid to the value extracted from the URL
      idcontrato: idcontrato,
      userId: userId,
      email: email.replace("%40", "@"),
      nombreempresa: "", // Add the initial value for nombreempresa
      
    }}   
    validationSchema={createformSchema}
    

  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
      {/* Loading Popup */}
      <Dialog open={loading} onClose={() => {}}>
        <Box sx={{ borderRadius: "20px", p: 2, textAlign: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="body1" mt={2}>
            Procesando datos...
          </Typography>
        </Box>
      </Dialog>

<div>

<Modal
        isOpen={isFormepdSavedModalOpen}
        onRequestClose={() => setIsFormepdSavedModalOpen(false)}
        contentLabel="Formepd Saved Modal"
        style={customModalStyles} // Apply custom styles to the modal
      >
        <h2>Lo sentimos, su declaración ya fue enviada anteriormente.</h2>
        Si tiene alguna consulta, envienos un mail a hello@unbiax.com indicando su nombre y rut para revisar su caso. Gracias
        <button
          onClick={() => setIsFormepdSavedModalOpen(false)}
          style={{ fontSize: '18px', padding: '10px 20px' }} // Add custom styles to the button
        >
          Cerrar
        </button>  
         </Modal>
      <Typography variant="h4">ETICPRO-  Formulario de Declaración</Typography>
      <Typography variant="h6">Ingrese sus Datos</Typography>
     
    </div>

   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          {iscreateform && (
            <>

<TextField
  label="Su Rut o Número de identificación completo"
  variant="filled"

  onBlur={handleBlur}
  onChange={(event) => {
    // Use a regular expression to remove any non-numeric characters
    const cleanedValue = event.target.value.replace(/[^0-9K]/gi, "");
    // Update the field value with the cleaned value
    handleChange({ target: { name: "rut", value: cleanedValue } });
  }}
  value={values.rut}
  name="rut"
  error={Boolean(touched.rut) && Boolean(errors.rut)}
  helperText={touched.rut && errors.rut}
  multiline
  fullWidth
  sx={{
    borderRadius: "20px",
    gridColumn: "span 2",
  }}  inputProps={{
    inputMode: "numeric", // Show numeric keyboard on mobile devices
  }}
/>


              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Nombres"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name="firstName"
              error={!!touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Apellidos"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name="lastName"
              error={!!touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Actual Empleador/Organización en que trabaja"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Area"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.area}
              name="area"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Cargo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.occupation}
              name="occupation"
              error={!!touched.occupation && !!errors.occupation}
              helperText={touched.occupation && errors.occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
<FormControl fullWidth sx={{ borderRadius: '20px', gridColumn: 'span 4' }}>
   
    <InputLabel id="nationality">Nacionalidad</InputLabel>
    <Select
                  variant="filled"

      labelId="nationality"
      id="nationality"
      name="nationality"
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.nationality}
      error={Boolean(touched.nationality) && Boolean(errors.nationality)}
    >
      {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
    {touched.nationality && errors.nationality && (
      <FormHelperText error>{errors.nationality}</FormHelperText>
    )}
  </FormControl>

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Telefono Celular  ej.+56xxxxx"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.telefono}
              name="telefono"
              error={!!touched.telefono && !!errors.telefono}
              helperText={touched.telefono && errors.telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />
 
 {industria === 'Iglesia' ? (
          <FormattedMessage id="¿Es Ud. Obispo, Vicario, Delegado, Ecónomo, Contralor, Canciller o Director de quien requiere la declaración?" />

      ) : (
        <FormattedMessage id="¿Es Ud. Director, Gerente, Subgerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?" />
      )}
  

  

<RadioGroup
  variant="filled"
  aria-label="Opción"
  name="opDirectorio"
  value={values.opDirectorio}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>          

              
<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>
<Box
    border="2px solid"
    p="1rem"
    mt="2rem"
    mb="2rem"
    bgcolor="white"
  >
    <div>
    <Typography>
      Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Organización.
    </Typography>
    <Typography>
      Un posible conflicto de interés puede existir:
    </Typography>
    <ol>
    <li>
        Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.
      </li>
      <li>
        Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*
      </li>
      <li>
        Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.
      </li>
    
      <li>
        Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a).

      </li>
      <li>
        Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.

      </li>
    </ol>
    <Typography>
      Por lo tanto, declaro el posible conflicto de interés aquí. 
    </Typography>


    </div>
  </Box>
  </Typography>
  <Typography>
  1. ¿Tiene conflicto de interés?
</Typography>
<RadioGroup
                  variant="filled"

  aria-label="Opción"
  name="opconflicto"
  value={values.opconflicto}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio  required />} label={<FormattedMessage id="Si" />} />
  <FormControlLabel value="No" control={<Radio  required />} label={<FormattedMessage id="No" />} />
</RadioGroup>


{values.opconflicto === 'Si' && ( // Only render if the answer is 'Si'
<div>
        <Divider sx={{ borderRadius: "20px", gridColumn: "span 5" }} />

        <Typography variant="body1" sx={{ borderRadius: "20px", gridColumn: "span 5" }}>
            2. ¿Cuál es el tipo de Conflicto?
          </Typography>
        <RadioGroup
        
          aria-label="Opción de Conflicto"
          name="tipoconflicto"
          value={values.tipoconflicto}
          onChange={handleChange}
          sx={{ borderRadius: "20px",
            "& .MuiSvgIcon-root": {
              color: palette.primary.light, // Selected radio color (red)
            },
            "& .Mui-checked": {
              color: "#FFFFFF", // Not selected radio color (white)
            },
          }}
        >
         <FormControlLabel value="Ventaja personal toma de decision" control={<Radio  required />} label="Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva " />
          <FormControlLabel value="Parentesco con Cliente/proveedor" control={<Radio  required />} label="Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*" />
          <FormControlLabel value="Participacion Societaria" control={<Radio  required />} label="Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación." />
          <FormControlLabel value="Contraté empleado que es pariente" control={<Radio  required />} label="Contraté a un empleado que es pariente mío hasta el tercer grado." />
          <FormControlLabel value="Relacion PEP" control={<Radio  required />} label="Soy pariente por afinidad o consanguinidad de alguien catalogado PEP. " />
      
        </RadioGroup>

   

        
              <TextField
                label="Explique el(los) conflicto(s) de interés aquí"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.conflictxt}
                name="conflictxt"
                error={Boolean(touched.conflictxt) && Boolean(errors.conflictxt)}
                helperText={touched.conflictxt && errors.conflictxt} // Corrected line
                multiline
                fullWidth
                minRows={8}
                maxRows={12}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>
      )}

{cmf === "Si" ? ( 
<>
<Box
    border="2px solid"
    p="1rem"
    mt="2rem"
    mb="2rem"
    bgcolor="white"
    
  >
      <Typography>
    Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente : 

    </Typography>
    <Typography>

 </Typography>
    <Typography>
    <p></p>
    El ART. 147 N°1 establece que una  sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación:    </Typography>
  
    <Typography>
   Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.    </Typography>
<p></p>
    <Typography>
    ART. 100 - Son relacionadas con una sociedad las siguientes personas:
    <ol>
      <li>
Las entidades del grupo empresarial al que pertenece la sociedad;
</li>
<li>
Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;
</li><li>
Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y
</li><li>
Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.
</li><li>
    La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que:
    <Typography>
1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;
</Typography><Typography>
2.- Sus negocios con la sociedad originan conflictos de interés;
</Typography><Typography>
3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o
</Typography><Typography>
4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.
    No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.
    </Typography>   </li> </ol>

    </Typography>
    <Typography>
 Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045
 </Typography>
  </Box>
  </>
  ) : null}

{values.opconflicto === 'Si' ? ( 
  
  <Box>
    <Typography>
    4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.
    </Typography>
    <FieldArray
      name="persons"
      render={(arrayHelpers) => (
        <div>
          {values.persons.map((person, index) => (
            <div key={index}>
             <TextField
                          label="Rut (Sin Guión)"
                          onBlur={handleBlur}
                          onChange={(event) => {
                            // Use a regular expression to allow only numbers and remove non-numeric characters
                            const newValue = event.target.value.replace(/[^0-9K]/gi, '');
                            handleChange({ target: { name: `persons[${index}].rutr`, value: newValue } });
                          }}
                          variant="filled"
                          value={person.rutr}
                          name={`persons[${index}].rutr`}
                          error={Boolean(touched.persons?.[index]?.rutr) && Boolean(errors.persons?.[index]?.rutr)}
                          helperText={touched.persons?.[index]?.rutr && errors.persons?.[index]?.rutr}
                          fullWidth
                          sx={{ borderRadius: "20px", gridColumn: "span 1" }}
                          inputProps={{
                          }}
                        />


              <TextField
                label="Nombre del relacionado o Razon Social"
                variant="filled"

                onBlur={handleBlur}
                onChange={handleChange}
                value={person.name}
                name={`persons[${index}].name`}
                error={Boolean(touched.persons?.[index]?.name) && Boolean(errors.persons?.[index]?.name)}
                helperText={touched.persons?.[index]?.name && errors.persons?.[index]?.name}
                fullWidth
                sx={{ borderRadius: "20px", gridColumn: "span 1" }}
              />

<TextField
                label="Apellidos del relacionado"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={person.lastname}
                name={`persons[${index}].lastname`}
                error={Boolean(touched.persons?.[index]?.lastname) && Boolean(errors.persons?.[index]?.lastname)}
                helperText={touched.persons?.[index]?.lastname && errors.persons?.[index]?.lastname}
                fullWidth
                sx={{ borderRadius: "20px", gridColumn: "span 1" }}
              />


<FormControl variant="filled" fullWidth sx={{ borderRadius: "20px", gridColumn: "span 1" }}>
  <InputLabel id={`relationship-label-${index}`}>Tipo de relación conmigo</InputLabel>
  <Select
    labelId={`relationship-label-${index}`}
    id={`relationship-${index}`}
    value={person.relationship}
    name={`persons[${index}].relationship`}
    onBlur={handleBlur}
    onChange={handleChange}
    error={Boolean(touched.persons?.[index]?.relationship) && Boolean(errors.persons?.[index]?.relationship)}
  >
    <MenuItem value="No Aplica">Persona Jurídica</MenuItem>

    <MenuItem value="Hermano(a)">Hermano(a)</MenuItem>
    <MenuItem value="Padre">Padre</MenuItem>
    <MenuItem value="Madre">Madre</MenuItem>
    <MenuItem value="Hijo(a)">Hijo(a)</MenuItem>
    <MenuItem value="Suegro(a)">Suegro(a)</MenuItem>
    <MenuItem value="Nieto(a)">Nieto(a)</MenuItem>
    <MenuItem value="Cuñado(a)">Cuñado(a)</MenuItem>
    <MenuItem value="Bisabuelo(a)">Bisabuelo(a)</MenuItem>
    <MenuItem value="Bisnieto(a)">Bisnieto(a)</MenuItem>
    <MenuItem value="Tío(a)">Tío(a)</MenuItem>
    <MenuItem value="Sobrino(a)">Sobrino(a)</MenuItem>




  </Select>
  <FormHelperText>{touched.persons?.[index]?.relationship && errors.persons?.[index]?.relationship}</FormHelperText>
</FormControl>


<FormControl variant="filled" fullWidth sx={{ borderRadius: "20px", gridColumn: "span 1" }}>
  <InputLabel id={`nationalityrel-label-${index}`}>Nacionalidad</InputLabel>
  <Select
    labelId={`nationalityrel-label-${index}`}
    id={`nationalityrel-${index}`}
    value={person.nationalityrel}
    name={`persons[${index}].nationalityrel`}
    onBlur={handleBlur}
    onChange={handleChange}
    error={Boolean(touched.persons?.[index]?.nationalityrel) && Boolean(errors.persons?.[index]?.nationalityrel)}
  >
     {countries.map((country, index) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
  </Select>
  <FormHelperText>{touched.persons?.[index]?.relationship && errors.persons?.[index]?.relationship}</FormHelperText>
</FormControl>

              <TextField
                label="Operacion en la que tengo interés o participo"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={person.operation}
                name={`persons[${index}].operation`}
                error={Boolean(touched.persons?.[index]?.operation) && Boolean(errors.persons?.[index]?.operation)}
                helperText={touched.persons?.[index]?.operation && errors.persons?.[index]?.operation}
                fullWidth
                sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

              <IconButton
                onClick={() => arrayHelpers.remove(index)}
                color="primary"
              >
                {/* You can replace this with your actual icon */}
                
              
            <DeleteIcon />
              </IconButton>
            </div>
          ))}
          
          <IconButton
            onClick={() => arrayHelpers.push({ name: "", lastname: "", nationalityrel: "", relationship: "", rutr: "", operation: "" })}
            color="primary"
          >
             
            <AddIcon />
           
          </IconButton>
          <span>Agregar</span>

        </div>
      )}
    />
  
  </Box>
    ) : null}

<FormControlLabel
  control={
    <Checkbox
      checked={values.confirmRead}
      onChange={(e) => {
        setFieldValue("confirmRead", e.target.checked);
        setCheckboxChecked(e.target.checked);
      }}
      name="confirmRead"
      color="primary"
    />
  }
  label="He leído y comprendido la información anterior."
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>


</FormControl>
            </>
          )}

        </Box>
      
        {/* BUTTONS */}
        <Box>
        {values.confirmRead && (
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Confirmo que la información anterior es verdadera y completa. {<FormattedMessage id="Enviar"/>}
              </Button>
            </Box>
          )}


          <Typography
            onClick={() => {
    
              resetForm();
            }}
            sx={{ borderRadius: "20px",
              textDecoration: "underline",
              color: palette.primary.light,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
          </Typography>
        </Box>
        
       
      </form>
    )}
  </Formik>
   </Box>
   </div>
  );
};





export default Form; 
import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper'; // Import the Paper component
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  CircularProgress,
} from "@mui/material";import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ChatWidget from '../../components/chatWidget';
import DropFileMeetingsWidget from '../../components/DropFilesMeetingWidget';

import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Formik } from "formik";
import * as yup from "yup";

import DialogWidget from '../../components/DialogWidget'; // Adjust the path based on your file structure

import TtvaluedetWidget from '../../components/TtvaluedetWidget';
import MeetingsEvWidget from '../../components/MeetingsEvWidget';

import Dropzone from "react-dropzone";
import Dropfiles from '../../components/Dropfilesttvalue';
import MeetingsDetailWidget from '../../components/MeetingsDetailWidget';
import { FormattedMessage } from 'react-intl';




const MeetingsEvPage = () => {
  const [form, setForm] = useState(null);
  const [user, setUser] = useState([]); // Initialize user as an empty array
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstUser, setFirstUser] = useState(null); // Define firstUser with an initial value of null

  const dispatch = useDispatch();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const { _id } = useParams();
  const loggedInUserId = useSelector((state) => state.user._id);
  const email = useSelector((state) => state.user.email);
  const userprofile = useSelector((state) => state.user.userprofile);

  const { REACT_APP_API_ENDPOINT } = process.env;
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array

  const getForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings/${_id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch form data');
      }

      const data = await response.json();
      setForm(data);
      setLoading(false);

      // Set the dialogues state
      const fetchedDialogues = data.dialogues || [];
      setDialogues(fetchedDialogues);

    } catch (error) {
      setError('An error occurred while fetching form data.');
      setLoading(false);
    }
  };




 useEffect(() => {
      setShowSuccessPopup(false);
      setSnackbarOpen(false);

  getForm();
}, [isFormSubmitted]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!form || Object.keys(form).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>No form data found.</Typography>
      </Box>
    );
  }

  const { estado } = form;

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };



  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Box display="flex" flexDirection="column">
        <Paper elevation={3} style={widgetStyle}>
        <MeetingsDetailWidget _id={_id} />
        </Paper>
        <Box mt={2} />
    

     
        <Box>

        <Paper elevation={3} style={widgetStyle}>
        {(userprofile === "admin" || userprofile === "adminetica" || userprofile === "aprobador" || userprofile === "superadmin") && form.estado === "Por Evaluar" && <MeetingsEvWidget _id={_id} />}

        </Paper>
        </Box>
        <Paper elevation={3} style={{ ...widgetStyle, marginTop: '1rem', marginBottom: '1rem' }}>
          <DropFileMeetingsWidget _id={_id} />
        </Paper>
</Box>


   

        );

      };

export default MeetingsEvPage;






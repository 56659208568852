import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Modal, Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from 'react-to-print';
import { saveAs } from 'file-saver';
import LogoImage from '../assets/eticprologo1.png';
import ExcelJS from 'exceljs';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const generateFolioNumber = () => {
  // Generate a unique folio number, you can customize this logic based on your requirements
  const uniqueNumber = Math.floor(Math.random() * 1000000);
  
  // Calculate the check digit using a simple algorithm (you may need a more robust algorithm)
  const checkDigit = uniqueNumber % 10;

  return `${uniqueNumber}-${checkDigit}`;
};


const PdfCertModal = ({ open, onClose, file, sessionFileName, pdfUrl, users, matchingFile, nonMatchingUsers }) => {
  
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [zoom, setZoom] = useState(0.8);
    const containerRef = useRef();
    const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const activitiesData = users;


  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

    const handleScroll = () => {
      const container = containerRef.current;
      const pageHeight = container.scrollHeight / numPages;
      const scrolledPage = Math.floor((container.scrollTop + pageHeight / 2) / pageHeight) + 1;
      setCurrentPage(scrolledPage);
    };
    
    useEffect(() => {
      const container = containerRef.current;
      if (container) {
         container.addEventListener('scroll', handleScroll);
         return () => {
            container.removeEventListener('scroll', handleScroll);
         };
      }
   }, [numPages]);
   
         
      useEffect(() => {
      }, [idcontrato, token, REACT_APP_API_ENDPOINT, file]);
  

   
      const componentRef = useRef();

      const handlePrint = useReactToPrint({
        content: () => {
          const printableContent = componentRef.current;
      
          // Create a new div to wrap the printable content with print styles
          const printWrapper = document.createElement('div');
          printWrapper.className = 'print-wrapper';
          printWrapper.appendChild(printableContent.cloneNode(true));
      
          // Apply print-specific styles to the wrapper
          printWrapper.style.padding = '20px';
          printWrapper.style.width = 'calc(100% - 40px)'; // Adjust width to account for padding
          printWrapper.style.height = 'calc(100% - 40px)'; // Adjust height to account for padding
      
          // Return the wrapper for printing
          return printWrapper;
        },
      });
    
      
      const handleDownload = async () => {
        if (data !== undefined && data !== null && data.length > 0) {
          try {
            const dataForExcel = users.map(user => ({
              Name: `${user.firstName} ${user.lastName}`,
              RUT: user.rut,
              File: user.filenombre,
              Email: user.email,
            }));
            // Create a new workbook
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('LecturasConfirmadas');
      
            // Define the headers for your data (assuming the first object in data has the keys)
            const headers = Object.keys(data[0]);
      
            // Add the headers to the worksheet
            const headerRow = worksheet.addRow(headers);
      
            // Style the header row
            headerRow.eachCell((cell) => {
              cell.font = { bold: true };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCCCFF' }, // Light purple
              };
            });
      
            // Add the data rows to the worksheet
            data.forEach((item) => {
              const row = [];
              headers.forEach((header) => {
                row.push(item[header]);
              });
              worksheet.addRow(row);
            });
      
            // Get the index of the "Rut" column (assuming "Rut" is one of the headers)
            const rutColumnIndex = headers.indexOf('rut') + 1; // +1 because ExcelJS is 1-based index
      
            // Apply conditional coloring to the "Rut" column
            if (rutColumnIndex > 0) {
              worksheet.getColumn(rutColumnIndex).eachCell((cell) => {
                if (!cell.value) { // Check if the cell is blank
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFE699' }, // Yellow
                  };
                }
              });
            }
      
            // Generate a blob from the workbook
            const buffer = await workbook.xlsx.writeBuffer();
      
            // Convert the buffer to a blob
            const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
            // Download the XLSX file
            saveAs(xlsxBlob, 'usuarios.xlsx');
          } catch (error) {
            console.error('Error exporting XLSX:', error);
          }
        } else {
          // Handle the case when data is empty or undefined
          console.warn('No data available for export');
        }
      };

    const handleDownloadx = async () => {
      if (users !== undefined && users !== null && users.length > 0) {
        try {
          // Create an array of objects containing user data
          const dataForExcel = users.map(user => ({
            Name: `${user.firstName} ${user.lastName}`,
            RUT: user.rut,
            File: user.filenombre,
            Email: user.email,
          }));
    
          // Create a new workbook
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Lecturasconfirmadas');
    
          // Define the headers for your data
          const headers = Object.keys(dataForExcel[0]);
    
          // Add the headers to the worksheet
          worksheet.addRow(headers);
    
          // Add the data rows to the worksheet
          dataForExcel.forEach(item => {
            const row = [];
            headers.forEach(header => {
              row.push(item[header]);
            });
            worksheet.addRow(row);
          });
    
          // Generate a blob from the workbook
          const buffer = await workbook.xlsx.writeBuffer();
    
          // Convert the buffer to a blob
          const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
          // Download the XLSX file
          saveAs(xlsxBlob, 'LecturasPendientes.xlsx');
        } catch (error) {
          console.error('Error exporting XLSX:', error);
        }
      } else {
        // Handle the case when users data is empty or undefined
        console.error('No users data available for download.');
      }
    };
    
    const folioNumber = generateFolioNumber(); // Generate a unique folio number

   
      return (
        <Modal
          open={open}
          onClose={() => onClose()}
          aria-labelledby="letter-viewer"
          aria-describedby="letter-viewer-modal"
        >
      <Box sx={{ 
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', height: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 4, overflow: 'hidden', display: 'flex', flexDirection: 'column',
        }}>
           <Box className="control-panel" sx={{
      position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#000', color: '#fff', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
      gap: '8px',
  }}>
          
           {/* <Button onClick={handleDownload} sx={{ color: '#fff', marginLeft: 'auto' }}>
              Descargar Excel
            </Button>
            */}
            <Button onClick={handlePrint} sx={{ color: '#fff', marginLeft: '10px', marginLeft: 'auto' }}>
              Imprimir PDF
            </Button>
          </Box>
   
  
  <Box  sx={{     
  overflowY: 'auto', flexGrow: 1, height: '100%' }}>
           <div ref={componentRef} className="card">
  
    {/* Display Certificate Content on a Separate Page */}
    <Box
    key="certificate_page"
    sx={{
      display: 'flex',
      flexDirection: 'column',
     
      backgroundColor: '#fff', // Dark grey background
      margin: '20px 0',
      padding: '20px',
      border: '1px solid #000', // Black border
    }}
  
  >
  <img src={LogoImage} alt="Logo" className="logo" style={{  width: '10%' }}/>
  <p></p>
  <Typography variant="h6" sx={{ marginBottom: '10px', color: '#333', textAlign: 'right'}}>
          {currentDate}
        </Typography>
  <Typography variant="h4" sx={{ marginBottom: '30px', color: '#333', textAlign: 'center', fontWeight: 'bold' }}>
  Certificación de Cumplimiento de Documentación ETICPRO
  </Typography>
  <Typography variant="h6" sx={{ marginBottom: '10px', color: '#333', textAlign: 'left' }}>
              Folio: {folioNumber}
            </Typography>
  <Typography sx={{ color: '#333' }}>
    Según los registros de usuarios inscritos en la plataforma ETICPRO a la fecha {currentDate} estas personas han leído y comprendido los documentos:
  </Typography>
    <TableContainer sx={{ marginTop: '20px' }}>
    <Table>
      <TableBody>
        {users.map((user, index) => {
          const formattedDate = new Date(user.createdAt).toLocaleDateString('es-CL'); // Format date as dd/mm/yyyy
  
          return (
            <TableRow key={index}>
              <TableCell>{user.firstName} {user.lastName}</TableCell>
              <TableCell>{user.rut}</TableCell>
              <TableCell>{user.filenombre}</TableCell>

              <TableCell>{formattedDate}</TableCell> {/* Add TableCell for user.email */}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  </Box>
          </div>
  </Box>
          </Box>
        </Modal>
      );
    };
    
    export default PdfCertModal;
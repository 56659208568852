//aqui esta el widget del form de Nueva camapaña que incluye enviar mails y guardar en posts separados por coma

import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isValid, parse } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles



const currentDate = new Date();
const endDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds
const { REACT_APP_API_ENDPOINT } = process.env;


const CreateClient = ({ _id }) => {
  const location = useLocation();
  const token = useSelector((state) => state.token);


  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const campaignSchema = yup.object().shape({
    // Define your validation schema here
    rutemp: yup.string().required("Required"),
    product: yup.string().notRequired("notRequired"),
    area: yup.string().notRequired("notRequired"),
    nombrecontacto: yup.string().notRequired("notRequired"),
    emailcontacto: yup.string().notRequired("notRequired"),
    celularcontacto: yup.string().notRequired("notRequired"),
    estado: yup.string().notRequired("notRequired"),
    nombreempresa: yup.string().required("Required"),
    cmf: yup.string().required("Required"),
    mailserver: yup.string().notRequired("Required"),
    mailport: yup.string().notRequired("Required"),
    mailuser: yup.string().notRequired("Required"),
    passmail: yup.string().notRequired("Required"),
    cipherstype: yup.string().notRequired("Required"),
    mailfactura: yup.string().notRequired("notRequired"),
    mailcom: yup.string().notRequired("notRequired"),


    oc: yup.string().notRequired("notRequired"),

  
  });
  const initialValuesEditCampaign = {
    rutemp: "",
    product: "",
    nombrecontacto: "", 
    emailcontacto: "",
    celularcontacto: "",
    estado: "Trial",
    nombreempresa: "",
    cmf: "No", 
    mailserver: process.env.MAILSERVER, 
    mailport: "465", 
    mailuser: process.env.MAILUSER, 
    passmail: process.env.PASSWORD,
    cipherstype: process.env.cipherS,
    inicioplan: null, // or an empty string, depending on your preference
    mailcom: "",
    mailfactura: "",
    oc: "",
    numAprobadores: "1",

  };

  const handleFormSubmit = async (values, onSubmitProps) => {

    try {
      const { REACT_APP_API_ENDPOINT } = process.env;
    
      const response = await fetch(`${REACT_APP_API_ENDPOINT}clients`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
    
      // Continue with the navigation logic
      navigate("/clients");
            setIsFormSubmitted(true);

    } catch (error) {
      console.log(error);
      // Handle error
    }

    
  };
  
   if (isFormSubmitted) {
    // Return null or any other component you want to show after successful submission
    // You may also navigate to another page if needed.
    return null;
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesEditCampaign}
      validationSchema={campaignSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
      <Box
  display="grid"
  gap="30px"
  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
  style={{ backgroundColor: "white", borderRadius: "20px" }}
>
            <p></p>
            <TextField
              label="Rut cliente"
              onBlur={handleBlur}
              onChange={(event) => {
                // Use a regular expression to allow only numbers and no dots or characters
                const newValue = event.target.value.replace(/[^0-9K]/gi, '');
                handleChange({ target: { name: 'rutemp', value: newValue } });
              }}
              value={values.rutemp}
              name="rutemp"
              error={Boolean(touched.rutemp) && Boolean(errors.rutemp)}
              helperText={touched.rutemp && errors.rutemp}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
            />

            <TextField
              label="Organización"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.nombreempresa}
              name="nombreempresa"
              error={Boolean(touched.nombreempresa) && Boolean(errors.nombreempresa)}
              helperText={touched.nombreempresa && errors.nombreempresa}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<TextField
              label="Producto"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.product}
              name="product"
              error={Boolean(touched.product) && Boolean(errors.product)}
              helperText={touched.product && errors.product}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
             <TextField
              label="Nombre Sponsor"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.nombrecontacto}
              name="nombrecontacto"
              error={Boolean(touched.nombrecontacto) && Boolean(errors.nombrecontacto)}
              helperText={touched.nombrecontacto && errors.nombrecontacto}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
            <TextField
              label="Mail Sponsor"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.emailcontacto}
              name="emailcontacto"
              error={Boolean(touched.emailContacto) && Boolean(errors.emailContacto)}
              helperText={touched.emailContacto && errors.emailContacto}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
            <TextField
              label="Numero aprobadores Transferencia de valor"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.numAprobadores}
              name="numAprobadores"
              error={Boolean(touched.numAprobadores) && Boolean(errors.numAprobadores)}
              helperText={touched.numAprobadores && errors.numAprobadores}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<TextField
              label="ciphers TLS(SSLv3?)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cipherstype}
              name="cipherstype"
              error={Boolean(touched.cipherstype) && Boolean(errors.cipherstype)}
              helperText={touched.cipherstype && errors.cipherstype}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<TextField
              label="Celular Sponsor"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.celularcontacto}
              name="celularcontacto"
              error={Boolean(touched.celularcontacto) && Boolean(errors.celularcontacto)}
              helperText={touched.celularcontacto && errors.celularcontacto}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<TextField
              label="mail factura"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.c}
              name="mailcom"
              error={Boolean(touched.mailfactura) && Boolean(errors.mailfactura)}
              helperText={touched.mailfactura && errors.mailfactura}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<TextField
              label="mail Comunicaciones"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mailcom}
              name="mailcom"
              error={Boolean(touched.mailcom) && Boolean(errors.mailcom)}
              helperText={touched.mailcom && errors.mailcom}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />



<TextField
              label="# Orden Compra"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.oc}
              name="oc"
              error={Boolean(touched.oc) && Boolean(errors.oc)}
              helperText={touched.oc && errors.oc}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />

<TextField
              label="Servidor SMTP"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mailserver}
              name="mailserver"
              error={Boolean(touched.mailserver) && Boolean(errors.mailserver)}
              helperText={touched.mailserver && errors.mailserver}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<TextField
              label="Puerto(465 ?..)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mailport}
              name="mailport"
              error={Boolean(touched.mailport) && Boolean(errors.mailport)}
              helperText={touched.mailport && errors.mailport}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<TextField
              label="Usuario Mail smtp"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mailuser}
              name="mailuser"
              error={Boolean(touched.mailuser) && Boolean(errors.mailuser)}
              helperText={touched.mailuser && errors.mailuser}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<TextField
              label="Contraseña Mail smtp"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.passmail}
              name="passmail"
              error={Boolean(touched.passmail) && Boolean(errors.passmail)}
              helperText={touched.passmail && errors.passmail}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

<TextField
              label="ciphers TLS(SSLv3?)"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cipherstype}
              name="cipherstype"
              error={Boolean(touched.cipherstype) && Boolean(errors.cipherstype)}
              helperText={touched.cipherstype && errors.cipherstype}
              multiline
              fullWidth
              sx={{ borderRadius: "20px", gridColumn: "span 1" }}
            />

         
  {/* Radio Group for CMF */}
  <FormControl component="fieldset">
            <Typography variant="subtitle1" sx={{ marginY: 2 }}>
              ¿CMF? {/* Asking the user for confirmation */}
            </Typography>
            <RadioGroup
              row
              name="cmf"
              value={values.cmf}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Si"
                control={<Radio />}
                label="Sí"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

     

          <p></p>

<div>
<div style={{ marginBottom: '10px' }}>Inicio del plan</div>
<DatePicker
selected={values.inicioplan}
onChange={(date) => handleChange({ target: { name: 'inicioplan', value: date } })}
onBlur={handleBlur}
name="inicioplan"
className="form-control" // Add your custom classes here
placeholderText="Seleccione Fecha"
style={{ fontSize: '16px', width: '100%', borderRadius: '20px' }}
/>
</div>
<p></p>
          
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              Guardar
            </Button>
            <Box>
              

              <Typography
                onClick={resetForm}
                sx={{ borderRadius: "20px",
                  textDecoration: "underline",
                  color: theme.palette.primary.red,
                  "&:hover": {
                    cursor: "pointer",
                    color: theme.palette.primary.light,
                  },
                }}
              >
                Borrar
              </Typography>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateClient;
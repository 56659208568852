// OfficeViewer.jsx
import React from 'react';
import PropTypes from 'prop-types';

const OfficeViewer = ({ fileUrl }) => {
  if (!fileUrl) {
    return <div>No file URL provided.</div>;
  }

  const fileExtension = fileUrl.split('.').pop().toLowerCase();
  const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;

  return (
    <iframe
      src={viewerUrl}
      width="100%"
      height="600px"
      frameBorder="0"
      title="Office Document Viewer"
    ></iframe>
  );
};

OfficeViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default OfficeViewer;

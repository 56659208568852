import { createSlice, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";

const initialState = {
  mode: "light",
  user: null,
  rangoAprobacion: null, // Add this line

  cmf: null,
  token: null,
  posts: [],
  forms: [],
  estado: "",
  clients: [],
  client: "", 
  searchInput: "",
  idcontrato: null,
  notification: "",
  sessionStart: null, // Use "sessionStart" consistently
  sessionExpiration: null,
  messages: [],
  rango: "",
  flag: "",
};



export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },

  
    
    setLogin: (state, action) => {
      state.user = {
        ...action.payload.user,
        rangoAprobacion: action.payload.user?.rangoAprobacion, // Make sure it's included here
      };
      state.token = action.payload.token;
      state.client = action.payload.client;
    },
    
    setComplaint: (state, action) => {
      state.complaint = {
        ...action.payload.complaint,
        flag: action.payload.complaint?.flag, // Make sure it's included here
      };
   
    },

    setSessionStart: (state, action) => {
      state.sessionStart = action.payload; // Store as a timestamp (ensure payload is a timestamp)
      // Calculate session expiration 60 minutes from now
      const expirationTime = new Date().getTime() + 8 * 60 * 60 * 1000; // 60 minutes in milliseconds
      state.sessionExpiration = expirationTime;
    },

    setSessionExpiration: (state, action) => {
      state.sessionExpiration = action.payload;
    },
    
    
    setSuccess: (state, action) => {
      state.user = action.payload.user;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.client = null;

    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("User friends do not exist.");
      }
    },
    setPosts: (state, action) => {
      const { posts } = action.payload;
      if (Array.isArray(posts)) {
        state.posts = posts;
      } else {
        console.error("Posts are not provided as an array.");
      }
    },
    setPost: (state, action) => {
      const { post } = action.payload;
      const updatedPosts = state.posts.map((p) => {
        if (p._id === post._id) {
          return post;
        }
        return p;
      });
      state.posts = updatedPosts;
    },
    setForms: (state, action) => {
      state.forms = action.payload.forms;
    },
    setForm: (state, action) => {
      const { form } = action.payload;
      const index = state.forms.findIndex((f) => f._id === form._id);
      if (index !== -1) {
        state.forms[index] = form;
      }
    
    },

    setClient: (state, action) => {
      state.client = action.payload.client;
    },
    
    
  
    setFormData: (state, action) => {
      const { form } = action.payload;
      const index = state.forms.findIndex((f) => f._id === form._id);
      if (index !== -1) {
        state.forms[index] = form;
      }
    
    },
    updateForm: (state, action) => {
      const { form } = action.payload;
      const updatedForms = state.forms.map((f) => {
        if (f._id === form._id) {
          return form;
        }
        return f;
      });
      state.forms = updatedForms;s
    },
    clearFormsCache: (state) => {
      state.forms = [];
    },
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    setIdcontrato: (state, action) => {
      
      state.idcontrato = action.payload;
    },

    setCmf: (state, action) => {
      
      state.cmf = action.payload;
    },
    setNotifications: (state, action) => {
      state.notification = action.payload;
    },
 
    setMessageToRead: (state, action) => {
      const { messageId, isRead } = action.payload;
      const messageIndex = state.messages.findIndex((message) => message._id === messageId);

      if (messageIndex !== -1) {
        // Update the 'isRead' status of the message
        state.messages[messageIndex].isRead = isRead;
      }
    },
 
    setMessages: (state, action) => {
      state.messages = action.payload;
    },

   


    updateMessages: (state, action) => {
      const updatedMessages = action.payload;
      state.messages = updatedMessages;
    },

    setUserEmail: (state, action) => {
      if (state.user) {
        state.user.email = action.payload;
      } else {
        console.error("User does not exist.");
      }
    },

    
   setUserRango: (state, action) => {
      if (state.user) {
        state.user.rangoAprobacion = action.payload;
      } else {
        console.error("User does not exist.");
      }
    },

    setUser: (state, action) => {
      if (state.user) {
        state.user = action.payload;
      } else {
        console.error("User does not exist.");
      }
    },
  },
});


export const {
  setComplaint,
  setCmf,
  setMode,
  setSessionStart,
  setLogin,
  setLogout,
  setFriends,
  setPosts,
  setPost,
  setSuccess,
  setForm,
  setForms,
  updateForm,
  clearFormsCache,
  setSearchInput,
  setIdcontrato,
  setUserEmail,
  setUserRango,
  setUser,
  setNotifications,
  setClient,
  setClients,
  setFormData,
  setSessionExpiration,
  setMessageToRead,
  setMessages, // Added this line to include setMessages in exports
  updateMessages, // Added this line to include updateMessages in exports
} = authSlice.actions;



const store = configureStore({
  reducer: authSlice.reducer,
  middleware: [...getDefaultMiddleware(), thunkMiddleware],
});

export default store;
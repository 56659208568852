import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { format } from "date-fns";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../assets/eticprologo8.png';

const editformSchema = yup.object().shape({
  approvals: yup.array().of(
    yup.object().shape({
      opauth: yup.string().notRequired(),
      authtxt: yup.string().notRequired(),
    })
  ).min(1, "Debe haber al menos un aprobador")
});

const initialValuesEditForm = {
  approvals: [{
    opauth: "",
    authtxt: "",
    opauth: "Por Evaluar",
    estado: "Por Evaluar",
  }],
};



const EditForm = ({ _id }) => {
  const [form, setForm] = useState(null);
  const idcontrato = useSelector((state) => state.user.idcontrato);

  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUserMail = useSelector((state) => state.user.email);
  const userApprovalRank1 = useSelector((state) => state.user.rangoAprobacion); // Update to match the slice name
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [formDataRango, setFormDataRango] = useState();
  const loggedclient = useSelector((state) => state.client);
    const [formData, setFormData] = useState({
    approvals: [{ opauth: "", authtxt: "", dateeval: "", approver: "" }],
  });

  const [currentApprovalStep, setCurrentApprovalStep] = useState(); // Track current step
  const [nextApprover, setNextApprover] = useState(); // Track current step
  const [nextAppEmail, setNextApproverEmail] = useState(); // Track current step

  useEffect(() => {
    const getForm = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const formData = await response.json();
          setForm(formData);
  
          // Find the index of the logged-in user's email in the 'aprobadores' array
          const userIndex = formData.aprobadores.findIndex(
            (email) => email === loggedInUserMail
          );
  
          if (userIndex !== -1) {
            setFormDataRango(userIndex + 1); // Add 1 to match your approval step logic
            const nextApproverIndex = userIndex + 1; // This is formDataRango
            setNextApprover(nextApproverIndex); // Set next approver as formDataRango + 1
            
            // Set the next approver's email if it exists
            if (nextApproverIndex < formData.aprobadores.length) {
              setNextApproverEmail(formData.aprobadores[nextApproverIndex]); // Set email of next approver
            } else {
              setNextApproverEmail(null); // No next approver if out of bounds
            }
          } else {
            console.log("User email not found in aprobadores array");
          }
  
          // Set the current approval step based on the completed approvals
          const completedApprovalsCount = formData.approvals.length;
          setCurrentApprovalStep(completedApprovalsCount + 1);
        } else {
          console.log("Failed to get form");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    getForm();
  }, [_id, token, loggedInUserMail]);
  
  

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    try {
      const currentTimestamp = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  
      // Map through the existing approvals and update them
      const updatedApprovals = formData.approvals.map((approval) => {
        if (approval.approver === loggedInUserMail) {
          const estado = values.approvals[0].opauth === "No" ? "Rechazada" : "Evaluada"; // Set individual estado based on opauth
  
          return {
            ...approval,
            opauth: values.approvals[0].opauth || "",
            authtxt: values.approvals[0].authtxt || "",
            dateeval: currentTimestamp,
            estado: estado, // Update individual estado here
          };
        }
        return approval;
      });
  
      // Check if the approver already exists, if not, add a new entry
      const approverExists = updatedApprovals.some(approval => approval.approver === loggedInUserMail);
      if (!approverExists) {
        const estado = values.approvals[0].opauth === "No" ? "Rechazada" : "Evaluada"; // Set estado for new approver
  
        updatedApprovals.push({
          approver: loggedInUserMail,
          opauth: values.approvals[0].opauth || "",
          authtxt: values.approvals[0].authtxt || "",
          dateeval: currentTimestamp,
          estado: estado, // Update estado here for new entry
        });
      }
  
     // Determine the overall approval status
  const anyApprovalNo = updatedApprovals.some(approval => approval.opauth === "No");
  const uniqueApproversCount = new Set(updatedApprovals.map(approval => approval.approver)).size;

let cierre;
let estado;
let opauth;

// Ensure both values are treated as numbers for comparison
const uniqueCount = Number(uniqueApproversCount);
const requiredCount = Number(loggedclient.numAprobadores);



// Adjust the required count based on conditions
let adjustedRequiredCount = requiredCount;

// Subtract 1 if the emailSolicitante is "gbravo@bago.cl" or autoEvaluacion is "Si"
if (form.autoEvaluacion === "Si") {
  adjustedRequiredCount -= 1;
}

// Subtract an additional 1 if loggedInUserMail is "gbravo@bago.cl"  asi se necesitan -1 wagon
if (loggedInUserMail === "gbravo@bago.cl" ) {
  adjustedRequiredCount -= 1;
}
// Subtract an additional 1 if loggedInUserMail is "gbravo@bago.cl" asi se necesitan -1 wagon
if (loggedInUserMail === "ssanzur@bago.cl" ) {
  adjustedRequiredCount -= 1;
}

  if (anyApprovalNo) {
    estado = "Rechazada";
    opauth =   "No";
    cierre = "NA"
    await sendEmailToSolicitante(form.emailSolicitante); // Call the email sending function

  } 
  
  // Check if the number of unique approvers is greater than or equal to the required number
  if (uniqueCount >= adjustedRequiredCount) {
      // If all approvers have evaluated, set estado to "Evaluado"
     opauth =  "Si" 
     estado = "Evaluada"; // Replace "someValue" with the appropriate value

  } 
         

    // Send an email to the next approver if there are more approvals needed
    
    if (nextApprover && uniqueApproversCount < loggedclient.numAprobadores && estado !== "Rechazada") {
      // Send an email to the next approver
  setNextApproverEmail(formData.aprobadores[nextApprover]); // Set email of the next approver from the list

      await sendEmailToNextApprover(nextAppEmail); // Call the email sending function
    }


      // Prepare the updated form data
      const updatedFormData = {
        ...formData,
        approvals: updatedApprovals,
        opauth: opauth,
        estado: estado, // Include general estado in the updated form data
        estadoCierre: cierre,
      };
  
      // Send the updated data to the API
      const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedFormData),
      });
  
      if (response.ok) {
        navigate("/successPage");
      } else {
        console.log("Failed to update form data");
      }
  
      // Reset the form after submission
      onSubmitProps.resetForm();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const sendEmailToNextApprover = async (nextAppEmail) => {
    const emailTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
      .frame {
        border: 2px solid #ccc;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        position: relative;
      }
      .logo-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .logo-box {
        text-align: center;
        margin-bottom: 20px;
      }
      .box {
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 5px;
      }
    </style>
    </head>
    <body>
    <div class="frame">
      <div class="logo-container">
        <img src="${LogoImage}" alt="Logo" style="width: 10%;" />
      </div>
      <img src="${loggedclient.logo}" alt="Logo" style="width: 30px; height: 15px;">

      <p>Estimado(a) Colaborador(a)</p>
      <p>Por requerimiento de ${loggedclient.nombreempresa}, tenemos el agrado de invitarlos a completar el Checklist de Diligencia Debida para cumplir con los Procedimientos e Información del Modelo de Prevención de Delitos de Eticpro.</p>

      <p>Paso 1: Para comenzar vaya al siguiente link seguro y digite sus credenciales:</p>
      <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
      <p>Usuario: ${nextAppEmail}</p>
      <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>

      <p>Paso 2: En el menú de la izquierda vaya a Diligencia Debida y haga click en Contraparte para revisar el listado de Diligencias Debidas que aguardan su Checklist según el estado "En Evaluación".</p>

      <p>Gracias por su atención y cooperación.</p>
      <p>Equipo Encargado de Prevención de Delitos.</p>
      <p>Eticpro.</p>
      <p><a href="www.eticpro.com">www.eticpro.com</a></p>
      <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
      <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
      <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
      <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es ${nextAppEmail}</p>
      <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
    </div>
    </body>
    </html>
  `;
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            emails: nextAppEmail,
            emailTemplate: emailTemplate,
            subject: "Acción Requerida: Aprobación Transferencia de Valor en Eticpro",
          }),
        });
    
        if (!response.ok) {
          throw new Error(`Failed to send email with status ${response.status}`);
        }
    
        console.log("Email sent successfully");
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };
    const sendEmailToSolicitante = async (nextAppEmail) => {
      const emailTemplate = `
      <!DOCTYPE html>
      <html>
      <head>
      <style>
        .frame {
          border: 2px solid #ccc;
          padding: 20px;
          border-radius: 10px;
          width: 80%;
          margin: 0 auto;
          text-align: left;
          position: relative;
        }
        .logo-container {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .logo-box {
          text-align: center;
          margin-bottom: 20px;
        }
        .box {
          background-color: #f2f2f2;
          padding: 10px;
          border-radius: 5px;
        }
      </style>
      </head>
      <body>
      <div class="frame">
        <div class="logo-container">
          <img src="${LogoImage}" alt="Logo" style="width: 10%;" />
        </div>
        <img src="${loggedclient.logo}" alt="Logo" style="width: 30px; height: 15px;">
  
        <p>Estimado(a) Colaborador(a)</p>
        <p>Por requerimiento de ${loggedclient.nombreempresa}, le informamos que su solicitud de Aprobacion de Transferencia de Valor ha sido rechazada.</p>
  
        <p>Paso 1: Para revisar los detalles o elevar una nueva solicitud vaya al siguiente link seguro y digite sus credenciales:</p>
        <p><a href="https://compliax.onrender.com/">https://compliax.onrender.com/</a></p>
        <p>Usuario: ${nextAppEmail}</p>
        <p>Contraseña: (Vaya a Recuperar Contraseña sino la recuerda)</p>
  
        <p>Paso 2: En el menú de la izquierda vaya a Diligencia Debida y haga click en Contraparte para revisar el listado de Diligencias Debidas que aguardan su Checklist según el estado "En Evaluación".</p>
  
        <p>Gracias por su atención y cooperación.</p>
        <p>Equipo Encargado de Prevención de Delitos.</p>
        <p>Eticpro.</p>
        <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, botón "Entrar" y su usuario es ${nextAppEmail}</p>
        <p>• Eticpro es una plataforma privada que permite a los usuarios participar de las actividades de controles y evidencia del Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
      </div>
      </body>
      </html>
    `;
        try {
          const response = await fetch(`${REACT_APP_API_ENDPOINT}email/send`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              emails: nextAppEmail,
              emailTemplate: emailTemplate,
              subject: "Solicitud de Aprobacion de Transferencia de Valor: Rechazada",
            }),
          });
      
          if (!response.ok) {
            throw new Error(`Failed to send email with status ${response.status}`);
          }
      
          console.log("Email sent successfully");
        } catch (error) {
          console.error("Error sending email:", error);
        }
      };
  useEffect(() => {
    if (form) {
      setFormData(form);
    }
  }, [form]);

 // Ensure both variables are treated as numbers for comparison
 const numericDataRango = Number(formDataRango);
 const numericCurrentApprovalStep = Number(currentApprovalStep);
console.log("Evaluador: ",numericDataRango);
console.log("Paso: ",numericCurrentApprovalStep);

 // Only render approval form if user's rank matches the current step
 if (numericDataRango !== numericCurrentApprovalStep) {
   return (
     <Typography variant="h4" color="primary">
       Evaluacion pendiente de  aprobador {nextAppEmail}
     </Typography>
   );
 }

  return (
    <Formik
      initialValues={initialValuesEditForm}
      validationSchema={editformSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, handleBlur, handleChange, touched, errors }) => (
        <Form>
          <Typography variant="h4" gutterBottom>
            Aprobación:
          </Typography>
          <FieldArray name="approvals">
            {({ push }) => (
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend"></FormLabel>
                  <Field
                    as={RadioGroup}
                    name="approvals[0].opauth"
                    value={values.approvals[0].opauth}
                  >
                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </Field>
                  <ErrorMessage name="approvals[0].opauth" component="div" />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Comentario"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.approvals[0].authtxt || ""}
                  name="approvals[0].authtxt"
                  error={touched.approvals?.[0]?.authtxt && Boolean(errors.approvals?.[0]?.authtxt)}
                  helperText={touched.approvals?.[0]?.authtxt}
                />
              </div>
            )}
          </FieldArray>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px" }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="Enviar" />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditForm;


import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper'; // Import the Paper component
import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  CircularProgress,
} from "@mui/material";import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ChatWidget from '../../components/chatWidget';

import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Formik } from "formik";
import * as yup from "yup";

import DialogWidget from '../../components/DialogWidget'; // Adjust the path based on your file structure

import DdDetailFerCheck from '../../components/DdDetailFerCheck';
import DdDetailDd from '../../components/DdDetailDdPostulante';
import DdVeredicto from '../../components/DdVeredictoPostulante';
import DropfilesDd from '../../components/DropfilesDd';
import DdChecklist from '../../components/DdChecklistPostulante';
import DdDetailDdPostulante from '../../components/DdDetailDdPostulante';
import DdDetailPostulante from '../../components/DdDetailPostulante';


import Dropzone from "react-dropzone";
import { FormattedMessage } from 'react-intl';




const DdevPostulantePage = () => {
  const [form, setForm] = useState(null);
  const [user, setUser] = useState([]); // Initialize user as an empty array
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const theme = useTheme();
  const nombrecliente = useSelector((state) => state.client.nombreempresa); 
  const loggedclient = useSelector((state) => state.client);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstUser, setFirstUser] = useState(null); // Define firstUser with an initial value of null
  const { _id } = useParams();

  const dispatch = useDispatch();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery('(min-width:1000px)');
  const loggedInUserId = useSelector((state) => state.user._id);
  const email = useSelector((state) => state.user.email);
  const userprofile = useSelector((state) => state.user.userprofile);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);

  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array
  const getForm = async () => {
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}ddPostulante/${_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  
      const data = await response.json();
      
      setForm(data);
      setLoading(false);

      // Set the dialogues state
      const fetchedDialogues = data.dialogues || [];
      setDialogues(fetchedDialogues);

    } catch (error) {
      setError('An error occurred while fetching form data.');
      setLoading(false);
    }
  };


  useEffect(() => {
    getForm();
  }, [token]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!form || Object.keys(form).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>No form data found.</Typography>
      </Box>
    );
  }

  const { estado } = form;

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };


  const handleResendmail = async (emailcontacto) => {

    try {
     
     
    const emailTemplateRec = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        .frame {
          border: 2px solid #ccc;
          padding: 20px;
          border-radius: 10px;
          width: 80%;
          margin: 0 auto;
          text-align: left;
          position: relative;
        }
        .logo-container {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .logo-box {
          text-align: center;
          margin-bottom: 20px;
        }
        .box {
          background-color: #f2f2f2;
          padding: 10px;
          border-radius: 5px;
        }
      </style>
    </head>
    <body>
      <div class="frame">
        <div class="logo-container">
        </div>
        <img src="https://repoeticpro.s3.sa-east-1.amazonaws.com/eticprologo8.png" alt="Logoemp" style="width: 20%"> 

        <p>Estimada(o) Postulante de ${nombrecliente}:</p>
        <p>Por requerimiento de ${nombrecliente}, le invitamos a completar el siguiente formulario de diligencia debida en el link de abajo para dar cumplimiento al Modelo de Prevención de Delitos (MPD) de acuerdo a lo establecido en la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas.</p>
        <p>La información recopilada es esencial para que tu organización pueda cumplir con la Ley #21.595. Su participación garantiza que estemos en conformidad con las normativas y que nuestros procesos estén alineados con los estándares legales.</p>
        <p><a href="${process.env.BASE_URL}/ddext/campaign/${form._id},${form.idcontrato},${form.idcompany},${encodeURIComponent(form.emailcontacto)}">Link al formulario</a></p>

        <p>Gracias por su atención y cooperación.</p>
        <p>Equipo Encargado de Prevención de Delitos.</p>
        <p>Eticpro.</p>
        <p><a href="www.eticpro.com">www.eticpro.com</a></p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>Para soporte, escríbenos a hello@eticpro.com | Wsup: +569 77657352.</p>
        <hr style="border-top: 1px solid #ccc; margin: 1rem 0;" />
        <p>• No olvide que para ingresar a nuestra plataforma en cualquier momento, puede ingresar a www.eticpro.com, boton "Entrar" y su usuario es ${form.emailcontacto}</p>
      </div>
    </body>
    </html>
  `;
console.log("emailcontacto",emailcontacto);
  const emailResponse = await fetch(`${REACT_APP_API_ENDPOINT}email/send/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      emails: [emailcontacto, "hadesdes@gmail.com"], // Properly format the emails as an array

      emailTemplateRec,
      subject: 'Diligencia Debida a Completar',
      mailserver: loggedclient.mailserver,
      mailport: loggedclient.mailport,
      mailuser: loggedclient.mailuser,
      passmail: loggedclient.passmail,
      cipherstype: loggedclient.cipherstype,
    }),
  });
            

      if (emailResponse.ok) {
     // Email sent successfully
     console.log(`Email sent successfully to ${emailcontacto}`);
    } else {
      // Error sending email
      console.log(`Failed to send email to ${emailcontacto}`);
    }
         
    

      if (emailResponse.ok) {
        const { emailSent } = responseData;
        setDialogMessage(emailSent
          ? "El formulario de Diligencia Debida (FDD) a completar fue enviado por mail al postulante. Mientras tanto  Ud. puede seleccionar su diligencia Debida en la grilla y continuar con  su proceso de Checklist."
          : "No ha sido posible enviar cuestionario, porfavor  intente mas tarde.");

        setShowSuccessPopup(true);
        
        RegSession({ token, userprofile, loggedInUserId, idcontrato, type: "EnvioQuizDD" });
       
      } else {
        console.error("Error storing user data:", userResponse.status, userResponse.statusText);
      }
    
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  return (
    <Box display="flex">
      <Box width="50%" padding="2rem 6%" display="flex" flexDirection="column" justifyContent="space-between">
        <Paper elevation={3} style={widgetStyle}>
        <div>
          
        <DdDetailPostulante _id={_id} />

           <DropfilesDd _id={_id} />


          </div>
        </Paper>
      </Box>
      
      <Box width="50%" display="flex" flexDirection="column">
        <Paper elevation={3} style={widgetStyle}>
        <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleResendmail(form.emailcontacto)} 
            style={{ marginTop: '1rem' }}
            sx={{ borderRadius: "20px",
              m: "2rem 0",
              p: "1rem",
              backgroundColor: palette.primary.red,
              color: palette.background.light,
              "&:hover": { color: palette.primary.light },
            }}
          >
            Reenviar Cuestonario a Postulante
          </Button>
          {estado === "En Evaluacion" && (userprofile == "declare" || userprofile === "adminetica" ||  userprofile === "admin" || userprofile === "superadmin" || userprofile === "aprobador") ? (
            <div>
           <DdChecklist _id={_id} />
           <DdDetailDdPostulante _id={_id} />
            </div>


          ) : estado === "En Aprobacion" && (userprofile === "admin" || userprofile === "adminetica" ||  userprofile === "aprobador" || userprofile === "superadmin") ? (
            <div>
               <DdDetailDdPostulante _id={_id} />
            <DdVeredicto _id={_id} />

            </div>

          ) 
          : estado === "Resuelta" && (userprofile === "admin" || userprofile === "adminetica" || userprofile === "superadmin" || userprofile === "aprobador") ? (
            <div>

        <DdDetailDdPostulante _id={_id} />
            </div>
          ) : null}
        </Paper>

     
       
      </Box>
    </Box>
  );
  

    
};

export default DdevPostulantePage;






import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider, tableBodyClasses } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateComplaint from "../../components/CreateComplaint";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import CreateTtvalue from "../../components/CreateTtvalue";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";
   import Grid from "@mui/material/Grid";
import StatBoxnumber from "../../components/statboxnumber";

import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import ProgressCircle from "../../components/ProgressCircle";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import EditModal from '../../components//EditModal';

const { REACT_APP_API_ENDPOINT } = process.env;


const ItemTypes = {
  RISK: "risk",
};

const RiskItem = ({ risk, onRiskDrop }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.RISK,
      item: { type: ItemTypes.RISK, risk },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    return (
      <div
        ref={drag}
        onClick={() => onRiskDrop(risk)}
        style={{
          opacity: isDragging ? 0.5 : 1,
          padding: "8px",
          border: "1px solid #ddd",
          marginBottom: "8px",
          cursor: "move",
        }}
      >
        {risk.name}
      </div>
    );
  };

  
const RiskMatrixCell = ({ onRiskDrop, risks, rowIndex, colIndex, resetMatrix }) => {
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.RISK,
      drop: (item) => onRiskDrop(item.risk, rowIndex, colIndex),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });
  
    const [, drag] = useDrag({
      type: ItemTypes.RISK,
      item: { type: ItemTypes.RISK, risk: risks },
    });
  
    return (
      <div
        ref={(node) => drop(drag(node))}
        style={{
          border: "2px solid #000",
          height: "130px",
          width: "180px",
          textAlign: "center",
          backgroundColor: isOver ? "orange" : "white",
        }}
      >
        {risks.map((risk, index) => (
        <div key={index}>
          {risk.name}
        </div>
      ))}
       
      </div>
    );
  };
  
const RiskMatrix = () => {
  const theme = useTheme();
  const { _idrisk } = useParams(); // Extract riskId from URL
  const colors = tokens(theme.palette.mode);
  const [riskData, setRiskData] = useState(); // State to hold the fetched risk data
  const [newRisk, setNewRisk] = useState("");
  const token = useSelector((state) => state.token);
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userprofile);
  const navigate = useNavigate();
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedColIndex, setSelectedColIndex] = useState(null);
  const [calculatedScore, setCalculatedScore] = useState(0); // New state for score
  const [availableRisks, setAvailableRisks] = useState([]);
    const [risks, setRisks] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data

    const [resetMatrix, setResetMatrix] = useState(false); // New state for reset

    const userId = useSelector((state) => state.user._id);

    const initialValuesEditCampaign = {

      userId: userId,
      idcontrato: idcontrato,
      risk: "",
      severity: "",
      freq: "",
      control: "",
      estadomatriz: "",
      comment: "",
      score: "",
    
    };

    const [fetchedRisks, setFetchedRisks] = useState([]);

const parseAndSetRisks = (risks) => {
  console.log('Parsing risks:', risks); // Debug: Log the risks to see the structure

  if (!Array.isArray(risks)) {
    console.error('parseAndSetRisks expects an array. Received:', risks);
    return; // Exit the function if risks is not an array
  }

  let matrix = {}; // Object to hold matrix cells with risks

  risks.forEach((risk) => {
    const key = `${risk.rowIndex}-${risk.colIndex}`;

    if (!matrix[key]) {
      matrix[key] = [];
    }

    risk.items.forEach(item => {
      matrix[key].push(item);
    });
  });

  setRisks(matrix); // Assuming setRisks is a setState call to update your component's state
};
    
    
  // Assuming this is within your RiskMatrix component

// Encapsulate fetching logic for available risks
const fetchAvailableRisks = async () => {
  try {
    const endpoint = `${REACT_APP_API_ENDPOINT}risklist/contract/${idcontrato}`;
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const risksData = await response.json();
      setAvailableRisks(risksData);
    } else {
      console.error('Failed to fetch available risks:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching available risks:', error.message);
  }
};

// Encapsulate fetching logic for risk by ID
const fetchRiskById = async (_idrisk) => {
  if (!_idrisk) return;
  try {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}risks/${_idrisk}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Failed to fetch the risk');
    const data = await response.json();

    setRiskData(data);
    parseAndSetRisks(data.risks);
  } catch (error) {
    console.error(error.message);
  }
};

// useEffect to fetch data on mount
useEffect(() => {
  
  fetchRiskById(_idrisk);
  fetchAvailableRisks();
  const initializeMatrixWithFetchedRisks = () => {
    const initialRisks = fetchedRisks.map(risk => ({
      ...risk,
      rowIndex: risk.severity, // assuming 'severity' maps directly to a row
      colIndex: risk.likelihood, // assuming 'likelihood' maps directly to a column
      items: [risk] // wrap the risk in an array to match the expected structure
    }));

    setRisks(initialRisks);
  };

  if (fetchedRisks.length > 0) {
    initializeMatrixWithFetchedRisks();
  }
}, [_idrisk, token, idcontrato,fetchedRisks]); // Dependency on 'token' and 'idcontrato'


const resetMatrixHandler = async () => {
  // Reset state to initial values
  setRiskData(null);
  setNewRisk("");
  setSelectedRisk(null);
  setSelectedRowIndex(null);
  setSelectedColIndex(null);
  setCalculatedScore(0);
  setRisks([]);

  // Fetch data again
  await fetchRiskById(_idrisk);
  await fetchAvailableRisks();
  setResetMatrix(false); // Optionally reset this flag if you're using it to trigger UI changes
};
  

const saveRisk = async () => {
  try {
    const endpoint = `${REACT_APP_API_ENDPOINT}risks/`;

    if (risks.length === 0) {
      console.error('No risks to save');
      return;
    }


    const requestBody = {
      userId: userId,
      idcontrato: idcontrato,
      estadomatriz: "Pendiente",
      riskData: risks, // Array of risks
      score: calculatedScore, // Include the calculated score

    };

      
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const responseBody = await response.json();

    if (response.ok) {
      console.log('Risks saved successfully!');
      // You can update the UI or perform other actions if needed
      navigate("/successPage");
    } else {
      console.error('Failed to save risks:', response.statusText);
      // Handle the error appropriately
    }
  } catch (error) {
    console.error('Error saving risks:', error.message);
    // Handle the error appropriately
  }
};

const addNewRisk = async () => {
  try {
    if (newRisk.trim() !== "") {
      // Make a POST request to add the new risk to the risk list
      const endpoint = `${REACT_APP_API_ENDPOINT}risklist`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newRisk, idcontrato: idcontrato, userId: userId, estado: "Por Evaluar"}),
      });

      if (response.ok) {
        // If the request is successful, update the availableRisks state
        setAvailableRisks((prevRisks) => [...prevRisks, { name: newRisk }]);
        setNewRisk("");
      } else {
        console.error('Failed to add new risk:', response.statusText);
        // Handle the error appropriately
      }
    }
  } catch (error) {
    console.error('Error adding new risk:', error.message);
    // Handle the error appropriately
  }
};

const moveItem = (risk, rowIndex, colIndex) => {
  setRisks((prevRisks) => {
    const updatedRisks = [...prevRisks];
    const existingRiskIndex = updatedRisks.findIndex(
      (r) => r.rowIndex === rowIndex && r.colIndex === colIndex
    );

    if (existingRiskIndex !== -1) {
      updatedRisks[existingRiskIndex].items.push(JSON.stringify({ ...risk }));
    } else {
      updatedRisks.push({ rowIndex, colIndex, items: [JSON.stringify({ ...risk })] });
s    }

    // Update the calculated score based on the new item
    setCalculatedScore((prevScore) => prevScore + updatedRisks.length * (rowIndex + colIndex));

    return updatedRisks.flat();
  });

  setAvailableRisks((prevRisks) =>
    prevRisks.filter((item) => item.name !== risk.name)
  );
};

const handleRowClick2 = (params, event) => {
  setSelectedRow(params.row); // Set the selected row data
};

const handleSaveRow = (editedData) => {
  // Implement save logic here, e.g., update the row data in the state or make API calls
  console.log('Edited Row Data:', editedData);
};

const handleCloseModal = () => {
  setSelectedRow(null); // Close the modal by resetting the selected row data
};

const handleRowClick = (params, event) => {
  // Check if the click event occurred on the delete button
  const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

  const rowId = params.row._id; // Assuming _id is the unique identifier for the row



  // Navigate to the formev page only if the click event didn't occur on the delete button
  if (!isDeleteButton) {
    navigate(`/riskMitPage/${rowId}`); 
  }
};

const renderMatrix = () => {
  const consequences = ["Menor", "Moderado", "Mayor"]; // Updated to Spanish
  const likelihoods = ["Frecuencia Baja", "Frecuencia Media", "Frecuencia Alta"]; // Updated to Spanish

  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "4px" }}>
      {/* Empty cell for the top-left corner */}
      <div style={{ border: "0px solid #000", height: "30px", width: "12px" }}></div>

      {/* Consequence labels */}
      {consequences.map((consequence, rowIndex) => (
        <div
          key={`consequence-label-${rowIndex}`}
          style={{
            border: "2px solid #000",
            height: "30px",
            width: "180px",
            textAlign: "center",
          }}
        >
          {consequence}
        </div>
      ))}

      {likelihoods.map((likelihood, colIndex) => (
        <React.Fragment key={`likelihood-label-${colIndex}`}>
          {/* Likelihood labels */}
          <div style={{
            border: "2px solid #000",
            height: "30px",
            width: "180px",
            textAlign: "center",
          }}>
            {likelihood}
          </div>

          {/* RiskMatrixCell components */}
          {consequences.map((_, rowIndex) => {
            const cellKey = `${rowIndex}-${colIndex}`;
            return (
              <RiskMatrixCell
                key={cellKey}
                onRiskDrop={(risk) => moveItem(risk, rowIndex, colIndex)}
                risks={risks[cellKey] || []}
                rowIndex={rowIndex}
                colIndex={colIndex}
              />
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString(); // Example formatting, adjust as needed
};
      
 const processedRisks = availableRisks.map((risk, index) => ({
    ...risk,
    id: risk._id || index + 1, // Use _id if available, otherwise generate a unique ID
  }));
  
const columns = [


    
   { field: "name", headerName: <FormattedMessage id="Nombre"/>, flex: 1 , 
   headerClassName: "bold-header",},
   { 
    field: "updatedAt", 
    headerName: <FormattedMessage id="Creado"/>, 
    flex: 1, 
    headerClassName: "bold-header",
    valueGetter: (params) => formatDate(params.value) // Use formatDate to format the updatedAt field
  },
  { field: "tipo", headerName: <FormattedMessage id="Tipo"/>, flex: 1 , 
  headerClassName: "bold-header",},
  
   { field: "description", headerName: <FormattedMessage id="Detalle"/>, flex: 1 , 
   headerClassName: "bold-header",},

   { field: "responsible", headerName: <FormattedMessage id="Responsable"/>, flex: 1 , 
   headerClassName: "bold-header",},
   
   { field: "dueDate", headerName: <FormattedMessage id="Fecha Entrega"/>, flex: 1 , 
   headerClassName: "bold-header",},

   { field: "mitigation", headerName: <FormattedMessage id="Mitigación"/>, flex: 1 , 
   headerClassName: "bold-header",},

   { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1 , 
   headerClassName: "bold-header",},
 
 ];
      return (

        <div style={{ background: "white", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
   <Typography variant="h2">{<FormattedMessage id="Matriz de Riesgo"/>}</Typography>

    <div>


<Typography variant="h6"></Typography>

</div>
<div style={{ background: "white", minHeight: "100vh", display: "flex", flexDirection: "row" }}>

        <DndProvider backend={HTML5Backend}>
     {/* 
    
          <div style={{ border: '2px solid #000', padding: '10px', margin: '20px', textAlign: 'center' }}>
            
            <div style={{ flex: 1 }}>
      
       <h2>Riesgos Disponibles</h2>
            {availableRisks.map((risk, index) => (
              <RiskItem key={index} risk={risk} onRiskDrop={moveItem} />
            ))}
            <TextField
              label="Nuevo Riesgo"
              variant="outlined"
              value={newRisk}
              onChange={(e) => setNewRisk(e.target.value)}
              style={{ marginTop: '10px' }}
            />
            <p></p>
            <Button variant="contained" color="primary" onClick={addNewRisk} style={{ marginTop: '10px' }}>
              Agregar Riesgo
            </Button>
            
            </div>
          </div>

        */}


          <Box p={3} border="1px solid" borderRadius={8} flex={1}>


          <div style={{ textAlign: 'center' }}>
             <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
              Score: {calculatedScore}
            </Typography>
            <h2>Gravedad</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {renderMatrix()}
          </div>
          
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {/*}  <button onClick={resetMatrixHandler}>Reset</button>
            <button onClick={() => saveRisk(selectedRisk, selectedRowIndex, selectedColIndex)}>Enviar</button>
          */}
           </div>
          </Box>


         
        </DndProvider>
        </div>
      <div>
      <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="120px"
      gap="30px"
    >
      <Box gridColumn={{ xs: 'span 12', md: 'span 12' }} gridRow="span 6">
        <Box
        m="20px 0 0 0"
        height="90%" // Set the height to 50% of the original height
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          

          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={processedRisks}

       
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          //onCellClick={handleCellClick} // Use onCellClick instead of onRowClick
          onRowClick={handleRowClick}
          
          rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
          headerClassName="bold-header"
          />

{/*<EditModal rowData={selectedRow} columns={columns} onSave={handleSaveRow} onClose={handleCloseModal} />  */}

      </Box>
      </Box>





</Box>
      </div>
        </div>

      );
    };
    

    export default RiskMatrix;
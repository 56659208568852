import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "./WidgetWrapper";
import FlexBetween from "./FlexBetween";
import { setNotifications } from "../state";
import { FormattedMessage } from 'react-intl';


const TtvaluependWidget = ({ _id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedincontract = useSelector((state) => state.user.idcontrato);
  const userProfile = useSelector((state) => state.user.userprofile);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [notifications, setNotifications] = useState(0);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchData();

  }, []);


  


  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}meetings/user/${loggedInUserId}`;
      // Check the user profile and update the endpoint accordingly
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/`;
      }

      if (userProfile === "admin"|| userProfile === "adminetica"|| userProfile === "coadmin" || userProfile === "aprobador"  || userProfile === "sales" || userProfile === "broker" ) {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/contract/${loggedincontract}`;
      }
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
      setData(formData);

      // Filter data by estado "pendiente"
      const filteredData = formData.filter((row) => row.estado === "Por Evaluar");
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';

    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row
      navigate(`/meetingsEvPage/${rowId}`, {
        state: {
          formData: params.row, // Pass the row data
          formTotalDays: params.row.formTotalDays // Pass the formTotalDays value
      
        }
      });   
    }
  };


  const columns = [
    {
      field: "fullName",
      headerName: "Nombre",headerClassName: "bold-header",
      flex: 1,
      valueGetter: (params) => {
        const firstName = params.row.user?.firstName || '';
        const lastName = params.row.user?.lastName || '';
        return `${firstName} ${lastName}`;
      },
    },

    {
      field: "createdAt",headerClassName: "bold-header",
      headerName: "Declarado",headerClassName: "bold-header",
      flex: 1,
      valueFormatter: (params) => {
        // Assuming params.value is a valid date string, you can parse it and format it
        const date = new Date(params.value);
        return date.toLocaleDateString("es-ES");
      },
    },

  ];
  
  

  return (
    <Box m="05px">
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(row) => row._id} // Specify the unique id property

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick}
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default TtvaluependWidget;

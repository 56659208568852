import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography, Snackbar, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library to generate random names
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import LogoImage from '../../assets/eticprologo1.png';
import { tokens } from "../../theme";

const PasswordResetForm = () => {
  const newPasswordInputName = uuidv4();
  const confirmPasswordInputName = uuidv4();
  const { token } = useParams(); // Obtener el token desde la URL
  const authToken = useSelector((state) => state.token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [passwordError, setPasswordError] = useState(''); // Nuevo estado para el mensaje de error
  const [showSnackbar, setShowSnackbar] = useState(false); // Estado para controlar la aparición del Snackbar
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { palette } = useTheme();

 

  useEffect(() => {
    // If an authenticated user has a token, use it to reset the password
    if (authToken) {
      // Reset the password using the authenticated user's token
      handleResetWithToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    // If the user is authenticated, we don't need to check token expiration
    if (!authToken) {
      const isTokenExpired = checkTokenExpiration();
      if (isTokenExpired) {
       
      setResetMessage(
        'El enlace (token) ha caducado. Por favor, solicite un nuevo enlace para restablecer la contraseña.'
        );
      }
    }
  }, [authToken]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const checkTokenExpiration = () => {
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000; // Convertir milisegundos a segundos
      return decodedToken.exp < currentTime; // Verificar si el token ha expirado
    } catch (error) {
      // Manejar cualquier error al decodificar el token
      console.error('Error al decodificar el enlace (token):', error);
      return true; // Tratar como caducado si hay un error
    }
  };

  const savePasswordReset = async (token) => {
    try {
      const updatedUser = {
        token: token, // {<FormattedMessage id="Enviar"/>} el token al servidor junto con la nueva contraseña
        password: password,
      };

      // Usar comillas invertidas y eliminar la barra diagonal adicional
      const response = await fetch(`${REACT_APP_API_ENDPOINT}reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
      });
      if (response.ok) {
        setResetMessage('¡Contraseña restablecida exitosamente!');
        setPassword('');
        setConfirmPassword('');
        navigate('/loginPage');
      } else {
        setResetMessage(
          'Error al restablecer la contraseña. Por favor, inténtelo nuevamente más tarde.'
        );
      }
    } catch (error) {
      console.error('Error al restablecer la contraseña:', error);
      setResetMessage(
        'Error al restablecer la contraseña. Por favor, inténtelo nuevamente más tarde.'
      );
    }
  };

  const handleResetWithToken = async (token) => {
    if (password !== confirmPassword) {
      setResetMessage('Las contraseñas no coinciden. Por favor, inténtelo nuevamente.');
    } else if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres.');
    } else {
      await savePasswordReset(token);
      setResetMessage('¡Contraseña restablecida exitosamente!');
      setPassword('');
      setConfirmPassword('');
      setShowSnackbar(true); // Show the success Snackbar
      // Reset fields and navigate after 2 seconds
      setTimeout(() => {
        setShowSnackbar(false);
        navigate('/loginPage');
      }, 2000);
    }
  };

  const handleReset = () => {
    // If an authenticated user has a token, use it to reset the password
    if (authToken) {
      handleResetWithToken(authToken);
    } else {
      // If the user is not authenticated, handle the reset as before
      if (password !== confirmPassword) {
        setResetMessage('Las contraseñas no coinciden. Por favor, inténtelo nuevamente.');
      } else if (password.length < 6) {
        setPasswordError('La contraseña debe tener al menos 6 caracteres.');
      } else {
        savePasswordReset(token);
        setResetMessage('¡Contraseña restablecida exitosamente!');
        setPassword('');
        setConfirmPassword('');
        setShowSnackbar(true); // Show the success Snackbar
        // Reset fields and navigate after 2 seconds
        setTimeout(() => {
          setShowSnackbar(false);
          navigate('/loginPage');
        }, 2000);
      }
    }
  };

  return (
    <Box component="form">
      <div style={{ position: "center", textAlign: "center" }}>
        <img
          src={LogoImage}
          alt='Eticpro'
          className='logo-image'
          style={{ maxWidth: "200px" }}
        />
        <p></p>
             <Typography variant="h2">Restablecer Contraseña</Typography>

      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        message="Éxito al cambiar la contraseña"
      />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{ borderRadius: "20px",
          '& > div': { gridColumn: 'span 4' },
        }}
      >
        <TextField
          label="Nueva contraseña"
          type="password"
          name={newPasswordInputName} // Add the random name attribute
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(''); // Clear the error message while typing
          }}
          error={Boolean(passwordError)}
          helperText={passwordError}
          sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
            background: 'white', // Set the background color to white
          },
        }}
        />
        <TextField
          label="Confirme nueva contraseña"
          type="password"
          name={confirmPasswordInputName} // Add the random name attribute
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setPasswordError(''); // Clear the error message while typing
          }}
          error={Boolean(passwordError)}
          helperText={passwordError}
          sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
            background: 'white', // Set the background color to white
          },
        }}
        />
      </Box>

      {/* Add new Box component for the button */}
      <Box mt={2} sx={{ textAlign: "center" }}>
  <Button 
    variant="contained"
    onClick={handleReset}
    sx={{
      width: "70%", // Widen the button to 30% of its container's width
      borderRadius: "20px",
      margin: "2rem auto", // Center the button horizontally
      padding: "1rem",
      backgroundColor: "green",
      color: "white",
      "&:hover": {
        backgroundColor: "lightgreen", // Changed to a lighter shade of green
        color: "darkgreen", // Changed to a darker shade of green
      },
    }}
  >
    Enviar 
  </Button>
</Box>


      <Typography>{resetMessage}</Typography>
    </Box>
  );
};

export default PasswordResetForm;

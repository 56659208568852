import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Print } from '@mui/icons-material';
import DialogWidget from '../components/DialogWidget'; // Adjust the path based on your file structure
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormattedMessage } from 'react-intl';

const campaignSchema = yup.object().shape({
  // Define your validation schema here

});

const initialValuesEditCampaign = {
  picturePath:"",
};



const greenText = { color: 'green' }; // Define the CSS style for green text

const ChatWidget = ({_id}) => {
  const [form, setForm] = useState({ picturePath: [] }); // Initialize as an empty object with pictures array
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const dispatch = useDispatch();
  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state.user._id);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setUploadedFiles(selectedFiles);
  };

    const getForm = async () => {

      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

      
        const data = await response.json();
        setForm(data);
        setLoading(false);

        // Set the dialogues state
        const fetchedDialogues = data.dialogues || [];
        setDialogues(fetchedDialogues);

      } catch (error) {
        setError('An error occurred while fetching form data.');
        setLoading(false);
      }
    };

      const handleSendMessage = async (messageDetail, uploadedFiles) => {

        try {
          const formData = new FormData();
      
      
      
          // Add other message details to the FormData object
        formData.append('sender', loggedInUserId);
        formData.append('detail', messageDetail);
        formData.append('dateOccurred', new Date());

        const response = await fetch(`${REACT_APP_API_ENDPOINT}complaints/${_id}/add-dialogue`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });


      
          if (!response.ok) {
            throw new Error('Failed to send message');
          }
      
          // Refresh the form data to update the dialogues
          getForm();
        } catch (error) {
          console.error('Error sending message:', error);
        }
      };
  

  useEffect(() => {
    getForm();
  }, []); // Empty dependency array

      if (loading) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        );
      }

      if (error) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Typography color="error">{error}</Typography>
          </Box>
        );
      }

        if (!form || Object.keys(form).length === 0) {
          return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <Typography>No form data found.</Typography>
            </Box>
          );
        }

    const { estado } = form;

  
  return (

    <Formik
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
 <p></p>


    <Box>
    <Box display="flex">
      <Box width="100%" >
        <Typography variant="h4">
        {<FormattedMessage id="Chat Denunciante"/>}

</Typography>

      <Box
        marginTop="1rem"
        border="1px solid #ccc" // Add a border for the frame
        borderRadius="4px"     // Add rounded corners to the frame
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)" // Add a shadow for depth
        maxHeight="200px" // Limit the height of the scrollable box
        overflow="auto"   // Enable scrolling if content overflows
      >
        <Typography variant="h6"></Typography>

        {dialogues.map((dialogue, index) => {
          const messageDate = new Date(dialogue.dateOccurred);
          const formattedDate = `(${
            ('0' + messageDate.getHours()).slice(-2)}:${
            ('0' + messageDate.getMinutes()).slice(-2)} ${('0' + messageDate.getDate()).slice(-2)}/${
            ('0' + (messageDate.getMonth() + 1)).slice(-2)}/${
            messageDate.getFullYear().toString().slice(-2)})`;
          return (            
            <Box key={index} marginTop="0.5rem">
              <Typography variant="subtitle1">
                [{dialogue.sender.slice(0, 4)}]
              </Typography>
              <Typography variant="subtitle1">
                {dialogue.detail} {formattedDate}
              </Typography>
            </Box>
            );
          })}
        </Box>
        <DialogWidget onSendMessage={(messageDetail, uploadedFiles) => handleSendMessage(messageDetail, uploadedFiles)} />
        </Box>
      </Box>
    </Box>

  </form>
      )}
    </Formik>
);
};

export default ChatWidget;






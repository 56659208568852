import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    InputLabel, // Import the InputLabel component
    Select,    // Import the Select component
    MenuItem,  // Import the MenuItem component
    useMediaQuery,
    Typography,
    useTheme,
    FormControl,
    
  } from "@mui/material";import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { ManageAccountsOutlined, EditOutlined, LocationOnOutlined, WorkOutlineOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Print } from '@mui/icons-material';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es'; // Import the Spanish locale
import { useReactToPrint } from "react-to-print";
import { FormattedMessage } from 'react-intl';

const greenText = { color: 'green' }; // Define the CSS style for green text

const MeetingsDetailWidget = () => {
  const [form, setForm] = useState(null);
  const dispatch = useDispatch();
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinUserprofile = useSelector((state) => state.user.userprofile);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useParams(); // Access the id1 parameter from the URL
  const { REACT_APP_API_ENDPOINT } = process.env;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  

  const fetchFiles = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}meetings/${_id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setForm(data); // Update setForm to setFormState
console.log("data", data);
  };
 
  useEffect(() => {
    fetchFiles()
      .then(fileData => {
        setFiles(fileData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setFiles([]);
        setLoading(false);
      });
  }, [token, _id]);
  
  if (!form) {
    return null;
  }


  const {

   
    userId,
    idcontrato,
    title,
    start,
    willPayReceiveValue,
    participantes,
    empresa,
    area,
    direccion,
    rutfunc,
    cargo,
    otrosasistentes,
    numeroacta,
    namepub,
    createdAt,
    authtxt,
    aprobador,
    opauth,
    dateval,
    emailaviso,
    estado,
    
  } = form;
  
  
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(start);
    return date.toLocaleDateString('es-ES', options);
  };

  const formattedDate = formatDate('2023-09-05T15:30:00'); // Example date string
  


  return (

      <Box m="2rem 0">
            <div ref={componentRef} className="card">

        <Box>
        <Typography variant="h2" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Reunión Persona de Interés o Comunidad"/>}<span style={greenText}></span></Typography>        
        <Typography variant="h4">{<FormattedMessage id="Estado:"/>}<span style={greenText}>{estado}</span></Typography>

<div>{<FormattedMessage id="Recibida:"/>} <span style={greenText}>{createdAt ? new Date(createdAt).toLocaleDateString('es-ES') : 'Unknown Date'}</span></div>
<div>{<FormattedMessage id="ID:"/>} <span style={greenText}>{_id ? _id.slice(-0) : 'Unknown ID'}</span></div>

  <div>{<FormattedMessage id="Creada por:"/>} <span style={greenText}>
  {`${form.user.firstName} ${form.user.lastName}`}
</span>
</div>
  
      
    
<p></p>
        
<Box>

<TextField
            label={<FormattedMessage id="Area funcional"/>}
            value={area}
name="area"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>





<TextField
            label={<FormattedMessage id="Fecha de la Reunión"/>}
            value={formattedDate}
name="start"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<TextField
            label={<FormattedMessage id="Proposito de la Reunión"/>}
            value={title}
name="title"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<TextField
            label={<FormattedMessage id="Lugar, Dirección o medio"/>}
            value={direccion}
name="direccion"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<TextField
            label={<FormattedMessage id="Organismo Público, Organización o Comunidad"/>}
            value={empresa}
name="empresa"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<TextField
            label={<FormattedMessage id="Cargo de la Persona de interés"/>}
            value={cargo}
name="cargo"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<TextField
            label={<FormattedMessage id="Nombre de la Persona de interés"/>}
            value={namepub}
name="namepub"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>



<TextField
            label={<FormattedMessage id="Rut en caso de ser fiscalizador"/>}
            value={rutfunc}

name="rutfunc"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>


<TextField
            label={<FormattedMessage id="Número de participantes en la reunión"/>}

value={participantes}
name="participantes"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>
<TextField
            label={<FormattedMessage id="Nombres de otros participantes a la reunión"/>}
            value={otrosasistentes}
name="otrosasistentes"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>


<TextField
            label={<FormattedMessage id="Si hay invitación o regalo u otra transferencia de valor indicar valor"/>}
            value={willPayReceiveValue}
name="willPayReceiveValue"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>


<TextField
            label={<FormattedMessage id="Acuerdos realizados o N° Acta Reunión"/>}
            value={numeroacta}
name="numeroacta"
multiline
fullWidth
 sx={{ borderRadius: "20px", gridColumn: "span 4", mb: 2 }} // Add margin-bottom here
/>

<Box width="100%" >

        <Box flexBasis="50%" marginRight="1rem">
         
         {/* Check if the user is "comitetica" or "superadmin" */}
          <Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
            <Typography variant="h4">{<FormattedMessage id="Archivos Relacionados(Acta Reunión, otros)"/>}</Typography>
            {form.picturePath && form.picturePath.map((filePath, index) => {
   if (filePath) {
    const fileName = filePath.split('/').pop();
    return (
      <div key={index}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleDownload(filePath, fileName);
          }}
        >
          {fileName}
        </a>
      </div>
    );
  } else {
    return null; // or handle the case when filePath is null
  }
            })}
          </Box>
     
          </Box>
          </Box>

</Box>

<Box sx={{ borderRadius: "20px", border: '1px solid', p: '1rem', mt: '1rem' }}>
<Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Solicitante:"/>} <span style={greenText}>{form.user.firstName} {form.user.lastName} </span></Typography>        

<Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>
{<FormattedMessage id="Fecha Solicitado:"/>}{" "}
  <span style={greenText}>
    {format(new Date(createdAt), "dd MMMM yyyy HH:mm", { locale: esLocale })}
  </span>
  <p></p>
</Typography>
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Estado:"/>}  <span style={greenText}>{estado}</span></Typography>        
        <Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Autorizada:"/>} <span style={greenText}>{opauth}</span></Typography>        
<p></p>
<hr></hr>

<Typography variant="h4" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Aprobador:"/>} <span style={greenText}>{form.aprobador.firstName} {form.aprobador.lastName}  </span></Typography>        
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Autorizada:"/>}  <span style={greenText}>{opauth}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}><span style={greenText}>{formatDate(dateval)}</span></Typography>  
          <Typography variant="h6" sx={{ borderRadius: "20px", wordWrap: 'break-word' }}>{<FormattedMessage id="Detalle:"/>}  <span style={greenText}>{authtxt}</span></Typography>  
         <hr></hr>
        </Box>

        <Box sx={{ borderRadius: "20px", mt: '1rem', display: 'flex', justifyContent: 'center' }}>
    <Button
      variant="contained"
      startIcon={<Print />}
      onClick={handlePrint}
    >
    {<FormattedMessage id="Imprimir en PDF"/>}
    </Button>
  </Box>

 


</Box>
</div>

</Box>

);
};

export default MeetingsDetailWidget;






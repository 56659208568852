import React, { useState } from "react";
import { Divider } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { Dialog, CircularProgress } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormattedMessage } from 'react-intl';
import RegSession from '../../components/RegSession'; // Adjust the path based on your file structure

import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

const customModalStyles = {
  content: {
    width: '400px',
    height: '280px',
    margin: 'auto',
    background: '#ffffff', // Set the background color to white
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 1, 0.5)', // Set the background color of the overlay to semi-transparent black
  },

};
const { REACT_APP_API_ENDPOINT } = process.env;

const createformSchema = yup.object().shape({
  rut: yup.string().required("Requerido"),
  telefono: yup.string().notRequired("notRequired"),
  firstName: yup.string().notRequired("notRequired"),
  lastName: yup.string().notRequired("notRequired"),
  email: yup.string().email("invalid email").required("required"),
  location: yup.string().notRequired("notRequired"),
  occupation: yup.string().notRequired("notRequired"), // Updated validation for occupation field
  area: yup.string().notRequired("notRequired"),

  

  opSansionado: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtSansionado: yup.string().notRequired("notRequired"),
  opParentesco: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtParentesco: yup.string().notRequired("notRequired"),
  opRelacion: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtRelacion: yup.string().notRequired("notRequired"),
  opOtroPos: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtOtroPos: yup.string().notRequired("notRequired"),
  opParaiso: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtParaiso: yup.string().notRequired("notRequired"),
  opRecomendado: yup.string().oneOf(["Si", "No"]).required("Requerido"),
  txtRecomendado: yup.string().notRequired("notRequired"),

  idcontrato: yup.string().notRequired("notRequired"),
  
  emailaviso: yup.string().notRequired("notRequired"),
  idevaluacion: yup.string().notRequired("notRequired"),
  confirmRead: yup.boolean().oneOf([true], "Debes confirmar que has leído la información."),
  

});

const initialValuescreateform = {
 //gral
 userId:  "",
 estado:  "",
 idcontrato:  "",
   //dd ext contacto
   rut:  "",
   telefono:  "",
   firstName:  "",
   lastName:  "",
   email:  "",
   location:  "",
   occupation:  "",
   area:  "",

 

//cdd
opSansionado:  "",
txtSansionado:  "",
opParentesco:  "",
txtParentesco:  "",
opRelacion:  "",
txtRelacion:  "",
opOtroPos:  "",
txtOtroPos:  "",
opParaiso:  "",
txtParaiso:  "",
opRecomendado:  "",
txtRecomendado:  "",
confirmRead: false,

};



const Form = () => {
  const [isFormepdSavedModalOpen, setIsFormepdSavedModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Step 1: Initialize the loading state
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [pageType, setPageType] = useState("createform");
  const iscreateform = pageType === "createform";
  const [isConflictxtClicked, setIsConflictxtClicked] = useState(false);

  const location = useLocation();
  const pathnameParts = location.pathname.split("/");

  const [ddpostulanteId, idcontrato, postulanteId, email ] = location.pathname
    .split("/")
    .pop()
    .split(",")
    .map((value) => decodeURIComponent(value.replace("%40", "@")));

  console.log("url", ddpostulanteId, idcontrato, postulanteId, email );
  let errorMessageElement; // Define the errorMessageElement variable
  let ddId = ddpostulanteId;

  const createform = async (values, onSubmitProps) => {
    try {
      setLoading(true); // Step 3: Set loading state to true before form submission

       // Make a request to obtain a temporary token
    const tokenResponse = await fetch(`${REACT_APP_API_ENDPOINT}generate-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ }),
    });

    if (!tokenResponse.ok) {
      throw new Error('Failed to generate temporary token');
    }

    const { token } = await tokenResponse.json();


      const savedUserResponse = await fetch(`${REACT_APP_API_ENDPOINT}ddPostulante/${ddpostulanteId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
           Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...values, estado: "En Evaluacion" }),


      });

      if (savedUserResponse.ok) {
        // Form successfully created
        onSubmitProps.resetForm();

        navigate("/successPage", {
          state: { message: "Diligencia Debida evaluada con éxito" }
      });           


      } else if (savedUserResponse.status === 409) {
        // Email conflict, display an error message to the user
        toast.error("Formulario ya habia sido guardado antes");
        setIsFormepdSavedModalOpen(true);
        onSubmitProps.resetForm();

        throw new Error("Email ya existia para esta campaña");
      } else {
        // Other error occurred, handle as desired
        throw new Error("Ocurrió un error");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false); // Make sure to reset the loading state when done
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    if (!checkboxChecked) {
      // Display the popup message here
      toast.error("Haga click en el checkbox antes de enviar");
      return;
    }
  
    if (iscreateform) {
      await createform(values, onSubmitProps);
    }
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <div style={{ display: "grid", placeItems: "center", height: "300", backgroundColor: "white" }}>

<Box p={3} border="1px solid" borderRadius={8} style={{ backgroundColor: "white" }}>

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={{
      ...initialValuescreateform,
      idcontrato: idcontrato,
      email: email.replace("%40", "@"),
    }}   

  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
      {/* Loading Popup */}
      <Dialog open={loading} onClose={() => {}}>
        <Box sx={{ borderRadius: "20px", p: 2, textAlign: "center" }}>
          <CircularProgress color="primary" />
          <Typography variant="body1" mt={2}>
            Procesando datos...
          </Typography>
        </Box>
      </Dialog>

<div>

<Modal
        isOpen={isFormepdSavedModalOpen}
        onRequestClose={() => setIsFormepdSavedModalOpen(false)}
        contentLabel="Formepd Saved Modal"
        style={customModalStyles} // Apply custom styles to the modal
      >
        <h2>Lo sentimos, su declaración ya fue enviada anteriormente.</h2>
        Si tiene alguna consulta, envienos un mail a hello@eticpro.com indicando su nombre y rut para revisar su caso. Gracias
        <button
          onClick={() => setIsFormepdSavedModalOpen(false)}
          style={{ fontSize: '18px', padding: '10px 20px' }} // Add custom styles to the button
        >
          Cerrar
        </button>  
         </Modal>
      <Typography variant="h4">ETICPRO -  Formulario de Declaración Postulante para Diligencia Debida (6 Preguntas/ 10 Min) </Typography>
     
    </div>

   
        <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{ borderRadius: "20px",
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          {iscreateform && (
            <>


<TextField
  label="Rut (Sin Guión)"
  onBlur={handleBlur}
  onChange={(event) => {
    // Use a regular expression to remove any non-numeric characters
    const cleanedValue = event.target.value.replace(/[^0-9K]/gi, "");
    // Update the field value with the cleaned value
    handleChange({ target: { name: "rut", value: cleanedValue } });
  }}
  value={values.rut}
  name="rut"
  error={Boolean(touched.rut) && Boolean(errors.rut)}
  helperText={touched.rut && errors.rut}
  multiline
  fullWidth
  sx={{ borderRadius: "20px", gridColumn: "span 4" }}
  inputProps={{
    inputMode: "numeric", // Show numeric keyboard on mobile devices
  }}
/>


              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Nombres"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              name="firstName"
              error={!!touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />

    
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Sus Apellidos"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              name="lastName"
              error={!!touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Organización a la que postula"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Área a la que postula"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.area}
              name="area"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
              <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Cargo al que postula"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.occupation}
              name="occupation"
              error={!!touched.occupation && !!errors.occupation}
              helperText={touched.occupation && errors.occupation}
              sx={{ borderRadius: "20px", gridColumn: "span 3" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Su Telefono Celular  ej.56xxxxx"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.telefono}
              name="telefono"
              error={!!touched.telefono && !!errors.telefono}
              helperText={touched.telefono && errors.telefono}
              sx={{ borderRadius: "20px", gridColumn: "span 2" }}
              />
 
             

              
<FormControl component="fieldset" sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}>
<Typography variant="h6" gutterBottom>
<Box
    border="2px solid"
    p="1rem"
    mt="2rem"
    mb="2rem"
    bgcolor="white"
  >
    <Typography>
    Nuestra organización esta comprometida con la integridad y el cumplimiento de la ley N°20.393 de Responsabilidad Penal de las Personas Jurídicas, UK Bribery Act del Reino Unido y Foreign Corrupt Practices Act (FCPA) de EE. UU. Para el logro total de dicho compromiso, la organización ha adoptado una política de revisión previa a todas los postulantes con los que se relaciona. El programa de revisión de postulantes requiere que estos provean la información necesaria para que la organización pueda revisar y evaluar los antecedentes necesarios con el fin de cumplir con la ley. Esta revisión consiste en un cuestionario de diligencia debida que deberá ser contestado por el postulante. 
      </Typography>
 


  </Box>

</Typography>


{<FormattedMessage id="1.	En relación con la Ley N°20.393 ¿Alguna vez has sido sancionado por uno de los delitos detallados en la Ley 20.393? (En el caso de ser positiva la respuesta, por favor detallar)"/>}
<RadioGroup
  aria-label="Opción"
  name="opSansionado"
  value={values.opSansionado}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtSansionado}
                name="txtSansionado"
                error={Boolean(touched.txtSansionado) && Boolean(errors.txtSansionado)}
                helperText={touched.txtSansionado && errors.txtSansionado} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="2.	¿Tienes relación de parentesco por consanguinidad o afinidad hasta el tercer grado con un funcionario público o PEP (persona políticamente expuesta)? (Favor suministrar detalles)"/>}
<RadioGroup
  aria-label="Opción"
  name="opParentesco"
  value={values.opParentesco}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtParentesco}
                name="txtParentesco"
                error={Boolean(touched.txtParentesco) && Boolean(errors.txtParentesco)}
                helperText={touched.txtParentesco && errors.txtParentesco} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="3.	¿Tienes relación con algún colaborador de la Organización, o existe algún conflicto de interés con algún colaborador?"/>}
<RadioGroup
  aria-label="Opción"
  name="opRelacion"
  value={values.opRelacion}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtRelacion}
                name="txtRelacion"
                error={Boolean(touched.txtRelacion) && Boolean(errors.txtRelacion)}
                helperText={touched.txtRelacion && errors.txtRelacion} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="4.	En el caso de tener conocimiento de otros postulantes. ¿Existe algún conflicto de interés con alguno?"/>}
<RadioGroup
  aria-label="Opción"
  name="opOtroPos"
  value={values.opOtroPos}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtOtroPos}
                name="txtOtroPos"
                error={Boolean(touched.txtOtroPos) && Boolean(errors.txtOtroPos)}
                helperText={touched.txtOtroPos && errors.txtOtroPos} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>


   {<FormattedMessage id="5.	¿Cuentas con algún domicilio o cuentas bancarias en alguno de los países considerados paraísos fiscales*?"/>}

<RadioGroup
  aria-label="Opción"
  name="opParaiso"
  value={values.opParaiso}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtParaiso}
                name="txtParaiso"
                error={Boolean(touched.txtParaiso) && Boolean(errors.txtParaiso)}
                helperText={touched.txtParaiso && errors.txtParaiso} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>



   {<FormattedMessage id="6.	¿Según tu conocimiento, fuiste recomendado por algún funcionario público, como por ejemplo, empleados de Codelco, Ministerios, Servicios públicos, Intendencias u otros? "/>}
<RadioGroup
  aria-label="Opción"
  name="opRecomendado"
  value={values.opRecomendado}
  onChange={handleChange}
  sx={{ borderRadius: "20px",
    "& .MuiSvgIcon-root": {
      color: palette.primary.light, // Selected radio color (red)
    },
    "& .Mui-checked": {
      color: "#FFFFFF", // Not selected radio color (white)
    },
  }}
>
  <FormControlLabel value="Si" control={<Radio/>} label="Sí" />
  <FormControlLabel value="No" control={<Radio/>} label="No" />
</RadioGroup>


<div>
              <TextField
                label={<FormattedMessage id="Comentarios"/>}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="filled"
                value={values.txtRecomendado}
                name="txtRecomendado"
                error={Boolean(touched.txtRecomendado) && Boolean(errors.txtRecomendado)}
                helperText={touched.txtRecomendado && errors.txtRecomendado} // Corrected line
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                maxLength={1000}
                sx={{ borderRadius: "20px", gridColumn: "span 5"
              }}
              
              />
   </div>






<FormControlLabel
  control={
    <Checkbox
      checked={values.confirmRead}
      onChange={(e) => {
        setFieldValue("confirmRead", e.target.checked);
        setCheckboxChecked(e.target.checked);
      }}
      name="confirmRead"
      color="primary"
    />
  }
  label={<FormattedMessage id="He leído y comprendido la información anterior."/>}
  sx={{ borderRadius: "20px", gridColumn: "span 5" }}
/>


</FormControl>
            </>
          )}

        </Box>
      
        {/* BUTTONS */}
        <Box>
        {values.confirmRead && (
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              {<FormattedMessage id="Confirmo que la información anterior es verdadera y completa."/>} {<FormattedMessage id="Enviar"/>}
              </Button>
            </Box>
          )}


          <Typography
            onClick={() => {
    
              resetForm();
            }}
            sx={{ borderRadius: "20px",
              textDecoration: "underline",
              color: palette.primary.light,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
          </Typography>
        </Box>
        
       
      </form>
    )}
  </Formik>
   </Box>
   </div>
  );
};





export default Form; 
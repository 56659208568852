import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { FormattedMessage } from 'react-intl';
import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = ({ progress, size}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress*100;
  return (
    <Box>
    <CircularProgressBar
      colorCircle="#e6e6e6"
      colorSlice="#0d5cbf"
      percent={angle}
      round
      speed={950}
      size="100" // String: Defines the size of the circle.
      bgColor="#ecedf0" // String: Color of "empty" portion of circle.
      textColor="#6b778c" // String: Color of percentage text color.
      responsive={true} // Boolean: Make SVG adapt to parent size
      textStyle={{
        font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
      }}
    />
    </Box>
  );
};

export default ProgressCircle;

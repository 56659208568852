import React, { useEffect, useState } from 'react';
import WidgetWrapper from './WidgetWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setForm } from '../state';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import * as yup from "yup";
import FlexBetween from "../components/FlexBetween";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Divider } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import AWS from 'aws-sdk'; // Import the AWS SDK
import { FormattedMessage } from 'react-intl';


import {
  Box,
  Button,
  TextField,
  InputLabel, // Import the InputLabel component
  Select,    // Import the Select component
  MenuItem,  // Import the MenuItem component
  useMediaQuery,
  Typography,
  useTheme,
  FormControl,
  CircularProgress,
  
} from "@mui/material";


const campaignSchema = yup.object().shape({
  // Define your validation schema here

});





const greenText = { color: 'green' }; // Define the CSS style for green text

const Dropfiles = ({_id}) => {
  const userprofile = useSelector((state) => state.user.userprofile);
  const idcontrato = useSelector((state) => state.user.idcontrato);

  const [form, setForm] = useState({ pictures: [] }); // Initialize as an empty object with pictures array
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const theme = useTheme();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useDispatch();
  const [dialogues, setDialogues] = useState([]); // Initialize dialogues as an empty array
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state.user._id);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [error, setError] = useState(null);

  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
  const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  

  
  const initialValuesEditCampaign = {
    idcontrato: idcontrato, // Update the initial value
    picturePath: "",
    pictures: "",
    commentcierre: "",
    estadoCierre: "",
    estado: "",
  };

 
AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: "sa-east-1", // Set your AWS region
  });
  
  // Create an S3 object
  const s3 = new AWS.S3();


    const getForm = async () => {

      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/${_id}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }
        const data = await response.json();
        setForm(data);
        setLoading(false);
        // Set the dialogues state
        const fetchedDialogues = data.dialogues || [];
        setDialogues(fetchedDialogues);
      } catch (error) {
        setError('An error occurred while fetching form data.');
        setLoading(false);
      }
    };

    useEffect(() => {
        getForm();
      }, []); // Empty dependency array


    const handleFormSubmit = async (values, onSubmitProps) => {
      try {
        await getForm();
      } catch (error) {
        console.error('Error fetching form data:', error);
        return;
      }
  
      try {
  
        const formData = new FormData();
        if (uploadedFiles.length > 0) {
          uploadedFiles.forEach((file) => {
            formData.append('pictures', file); // Append the actual file
          });
          // Define the uploadFileToS3 function
          const uploadFileToS3 = async (file) => {
            const uniqueSuffix = Date.now() + "-";
            const key = uniqueSuffix + file.name; // Add the unique suffix to the filename
            formData.append('picturePath', key); // Append the actual file
            formData.append('idcontrato', idcontrato);
            formData.append('userId', loggedInUserId);
          //  formData.append('estado', "Cerrado");

            AWS.config.update({
            
              accessKeyId: REACT_APP_ACCESS_KEY_ID,
              secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
              region: "sa-east-1", // Set your AWS region
              });
                const s3 = new AWS.S3();
    
                // Configure the S3 bucket and key where you want to upload the file
                const bucketName = 'repoeticpro';
          
  
                // Configure S3 upload parameters
                const params = {
                  Bucket: bucketName,
                  Key: key,
                  Body: file, // The actual file object to upload
                };
    
      
      // Use the AWS S3 client to upload the file to S3
      try {
        await s3.upload(params).promise();
        console.log(`File uploaded to S3: ${key}`);
        return key; // Return the key for reference
      } catch (error) {
        console.error(`Error uploading file to S3: ${error.message}`);
        throw error;
      }
    };
  
          const s3Urls = await Promise.all(uploadedFiles.map(uploadFileToS3));
      }
      formData.append('commentcierre', values.commentcierre);
      formData.append('estadoCierre', "Cierre Completado");


        const userResponse = await fetch(`${REACT_APP_API_ENDPOINT}ttvalue/files/${_id}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

    
        getForm();
        setUploadedFiles([]);
        onSubmitProps.resetForm();
        navigate("/successPage");
      } catch (err) {
        console.log("Error creating complaint:", err);
    
        if (err.response) {
          console.log("Response status:", err.response.status);
          err.response.text().then(responseText => {
            console.log("Response data:", responseText);
          });
        }
      }
    };
    
    const handleDownload = (filePath, fileName) => {
        

      const s3BucketURL = 'https://repoeticpro.s3.amazonaws.com'; // Use the correct endpoint
      const s3URL = `${s3BucketURL}/${encodeURIComponent(fileName)}`;
    
      // Initialize the AWS S3 client for downloading
      AWS.config.update({
        accessKeyId: REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
        region: "sa-east-1", // Set your AWS region
      });
      const s3 = new AWS.S3();

      const downloadLink = document.createElement('a');
      downloadLink.href = s3URL;
      downloadLink.target = '_blank'; // Open in a new tab
      downloadLink.download = fileName; // Specify the filename for the downloaded file
      downloadLink.click();
  
    };


  return (

    <Formik
    onSubmit={handleFormSubmit}
    initialValues={initialValuesEditCampaign}
    validationSchema={campaignSchema}
  >
    {({
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      resetForm,
    }) => (
      <form onSubmit={handleSubmit}>
 <p></p>


    <Box>
    <Typography>{<FormattedMessage id="Cierre del Solicitante"/>}
    </Typography>

    <TextField
            label={<FormattedMessage id="Comentario de Cierre"/>}
            onBlur={handleBlur}
            onChange={handleChange} 
            value={values.commentcierre}
            name="commentcierre"
            error={Boolean(touched.commentcierre) && Boolean(errors.commentcierre)}
            helperText={touched.commentcierre && errors.commentcierre}
            multiline
            fullWidth
            sx={{ borderRadius: "20px", gridColumn: "span 4", '& input': {
              background: 'white', // Set the background color to white
            },
          }}
          />

    <Box display="flex">    

      <Box width="100%" >
        <Box flexBasis="50%" marginRight="1rem">
         
          <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >

                <Dropzone

                onDrop={(acceptedFiles) => {
                  setUploadedFiles(acceptedFiles); // Update the uploadedFiles state here
                  setFieldValue("pictures", acceptedFiles); // Update Formik's field value as well, if needed
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ borderRadius: "20px", "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.pictures || values.pictures.length === 0 ? ( // Check if "pictures" is empty or not
                      <p> {<FormattedMessage id="Adjunte Archivos de Cierre Aquí"/>}</p>
                    ) : (
                      <div>
                        {values.pictures.map((file, index) => (
                          <FlexBetween key={index}>
                            <Typography>{file.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        ))}
                      </div>
                    )}
                  </Box>
                )}
              </Dropzone>

                </Box>
 {/* BUTTONS */}
 <Box>
        <Button
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ borderRadius: "20px",
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.primary.red,
                color: theme.palette.background.light,
                "&:hover": { color: theme.palette.primary.light },
              }}
            >
              {<FormattedMessage id="Guardar Archivos"/>}
            </Button>
            {/* Step 4: Conditionally render the success popup */}
            {showSuccessPopup && (
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                p={2}
                bg="green" // You can replace with appropriate color
                color="black"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                borderRadius="4px"
                zIndex={1000}
              >
                Envio exitoso
              </Box>
            )}
    </Box>
          
    </Box>
    </Box>
    </Box>
    </Box>
  </form>
      )}
    </Formik>
);
};

export default Dropfiles;






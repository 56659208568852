import { Box, Typography, useTheme, IconButton, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";

import { tokens } from "../../theme";
import CreateCampaign from "../../components/CreateCampaign";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";
import CreateDd from "../../components/CreateDd";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';


const Companies = ({ }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});
const [showCreateClient, setShowCreateClient] = useState(false);
const { _id, picturePath, email } = useSelector((state) => state.user);
const userProfile = useSelector((state) => state.user.userprofile);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedinuserprofile = useSelector((state) => state.user.userprofile); 

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [totalDescriptionsCount, setTotalDescriptionsCount] = useState(0);

  let filteredData = data;

const handleDateFilterChange = (event, newValue) => {
  setDateRange(newValue);
};

filteredData = data
.filter((row) => {
  // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
  const createdAt = parseInt(row.createdAt);
  return createdAt >= dateRange[0] && createdAt <= dateRange[1];
})
.filter((row) => {
  // Combine all column values into a single string
  const rowDataAsString = Object.values(row)
    .filter((value) => typeof value === 'string')
    .join(' ');

  // Apply the search filter to the concatenated string
  const searchInputLower = searchInput.toLowerCase();
  return rowDataAsString.toLowerCase().includes(searchInputLower);
});


useEffect(() => {
  setShowSuccessPopup(false);
  setSnackbarOpen(false);
  fetchData();
}, [isFormSubmitted, dateRange]);

  const fetchData = async () => {
    try {


    let endpoint = `${REACT_APP_API_ENDPOINT}company/contract/${idcontrato}`;

    if (userProfile === "superadmin"  ) {
      endpoint = `${REACT_APP_API_ENDPOINT}company`;
    }
    // Check the user profile and update the endpoint accordingly
    if (userProfile === "admin" || userProfile === "adminetica" || userProfile === "coadmin"  ) {
      endpoint = `${REACT_APP_API_ENDPOINT}company/contract/${idcontrato}`;
    }
        const response = await fetch(endpoint, {
          
          headers: {
            Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      setData(dataWithFormattedDate); // Set the fetched data to the state here
      console.log(dataWithFormattedDate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  


      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}company/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}company/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
      setFilteredData(updatedData);
    }
  };
  


  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const columns = [

 {
      field: "_id",headerClassName: "bold-header",
       headerName: <FormattedMessage id="ID"/>,
      flex: 1,
      hide: true,

    },

  

    {
      field: "rutempresa",headerClassName: "bold-header",
       headerName: <FormattedMessage id="Rut"/>,
      flex: 1,
      editable: true, // Make the column editable

    },
    {
      field: "nombreempresa",headerClassName: "bold-header",
       headerName: <FormattedMessage id="Organización"/>,
      flex: 2,
      editable: true, // Make the column editable

    },
    
    {
      field: "industria",
       headerName: <FormattedMessage id="Rubro"/>, headerClassName: "bold-header",
      flex: 1,
      editable: true, // Make the column editable

    },
    {
      field: "numeroSAP",headerClassName: "bold-header",
       headerName: <FormattedMessage id="#SAP"/>,
      flex: 1,
      editable: true, // Make the column editable

    },

    {
      field: "firstNameContacto",
       headerName: <FormattedMessage id="Nombres Contacto"/>,headerClassName: "bold-header",
      flex: 1,
      editable: true, // Make the column editable

    },

    {
      field: "lastNameContacto",headerClassName: "bold-header",
       headerName: <FormattedMessage id="Apellidos Contacto"/>,
      flex: 1,
      editable: true, // Make the column editable

    },

    {
      field: "emailcontacto",headerClassName: "bold-header",
       headerName: <FormattedMessage id="Email"/>,
      flex: 1,
      editable: true, // Make the column editable

    },



    {
        field: "createdAt",headerClassName: "bold-header",
         headerName: <FormattedMessage id="Creado"/>,
        flex: 1,      

  
        
    },

    {
      field: "estado",
       headerName: <FormattedMessage id="Estado"/>, headerClassName: "bold-header",
      flex: 1,
      editable: true, // Make the column editable

    },
    {
      field: "idcontrato",
      headerName: <FormattedMessage id="Contrato"/>,headerClassName: "bold-header",
      flex: 1,
      editable: userProfile === "superadmin",
    },

  
    {
      field: "actions",headerClassName: "bold-header",
       headerName: <FormattedMessage id="Actions"/>,
      width: 120,
      renderCell: (params) => (
        <>
          {/* Check if the user is a superadmin */}
          {(loggedinuserprofile === 'superadmin' || loggedinuserprofile === 'admin') && (
            <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
          </>
      )}
    </>
  ),
}

  ];

  const columnVisibilityModel = {
    idcontrat: false,
    _id: false,
    estado: false,
    createdAt: false,
    numeroSAP: false,
    firstNameContacto: false,
    lastNameContacto: false,
    industria: false,
   
  };

  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Contrapartes');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'Contrapartes.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };
  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "60%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Box m="20px">
<Grid container spacing={2}>


    <Grid item xs={12} sm={6} md={4}>
    
    <Box display="flex" justifyContent="space-between" alignItems="center">
    
      <Header title={<FormattedMessage id="Contrapartes" />} subtitle={<FormattedMessage id="Administrar mi lista Maestra" />} />

      {/* Search - 3 columns */}
      </Box>
     </Grid>
     <Grid item xs={12} sm={8} md={1}>
</Grid>

 
  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
    {/* HEADER */}


      <div>
      <Box>
          <Button
          onClick={() => setShowCreateClient(prevState => !prevState)}

            sx={{ borderRadius: "20px",
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[900],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Upload sx={{ borderRadius: "20px", mr: "10px" }} />
            {<FormattedMessage id="Nueva Contraparte"/>}
          </Button>
          {showCreateClient && <CreateDd />}

        </Box>
    </div>
    
      <Box
        m="40px 0 0 0"
        height="40vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
     <DataGrid
  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
  components={{ Toolbar: GridToolbar }}
  rows={filteredData.map((item) => ({
    ...item,
    id: item._id,
    nombremcompleto: `${item.firstNameContacto} ${item.lastNameContacto}`,
    
  }))}
  columns={columns}
  onRowClick={handleRowClick}
  rowsPerPageOptions={[10, 20]}
  headerClassName="bold-header"
  columnVisibilityModel={columnVisibilityModel}

/>


      </Box>

      <Box>
              <Button
                  onClick={handleDownloadXLSX}
                  sx={{ borderRadius: "20px",
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[900],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                  {<FormattedMessage id="Descargar Reporte"/>}
                  
            </Button>
            </Box>
            <p></p>

    </Box>
  );
};

export default Companies;

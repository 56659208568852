// translations.js

const translations = {
    en: {
      "Evaluada": "Evaluated",
      "Campañas enviadas por el team de Compliance Management":"Campaigns sent by the Compliance Management team",
      "¿Cómo se dio cuenta de cuenta de esta situación?": "How did you become aware of this situation?",
      "¿Cuándo ocurrió?": "When did it happen?",
      "¿Desde cuándo cree usted que ha estado ocurriendo?": "Since when do you believe it has been happening?",
      "¿Dónde ocurrió este incidente o infracción específicamente?": "Where did this incident or infraction occur specifically?",
      "¿Es Ud. Director, Gerente, Subgerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?": "Are you a Director, Manager, Administrator, Principal Executive, or Liquidator of the entity requiring the declaration?",
      "¿Está seguro de salir de Eticpro?": "Are you sure you want to exit Eticpro?",
      "¿Hay pagos a terceros como consultores u organizaciones para ejecutar la inversión social?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y la diligencia debida de este consultor u organización": "Are there payments to third parties such as consultants or organizations to carry out the social investment? If the answer is affirmative, consider the fair market price and due diligence of this consultant or organization.",
      "¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?": "Can you identify who committed these acts? Provide names if possible.",
      "¿Qué ocurrió?": "What happened?",
      "¿Realizo el procedimiento de Diligencia Debida?": "Did you perform the Due Diligence procedure?",
      "(i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal?": "(i) Is the organization organizing the event owned or controlled by a governmental entity or other state entity?",
      "(i) ¿La Contraparte efectúa alguno de los siguientes giros": "fundación, corporación sin fines de lucro, transporte, turismo, catering, taller de mantención?",
      "(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?": "(ii) Will the Counterparty sign a contract and/or is the consideration demonstrable, such as fixing a pipe, buying nails, conducting advertising, etc.?",
      "(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio": "(ii) Provide the name(s), position(s), and affiliation(s) of the individual(s) who suggested the sponsorship",
      "(iii) ¿Habrá otras empresas auspiciando?": "(iii) Will other companies be sponsoring?",
      "(iii) Estimación del Pago Anual superior a 100.000 USD anual?": "(iii) Estimated Annual Payment exceeding 100,000 USD annually?",
      "(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?": "(iv) Will your organization be mentioned as a sponsor in the event materials, publications, and other distributed materials related to the event?",
      "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?": "(iv) Will the Counterparty represent or interact on behalf of the Company or its subsidiaries before a third party or a public authority, such as customs agents, lawyers, spokesperson to a community, company processing permits before the Municipality, etc.?",
      "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?": "(iv) Will the Counterparty represent or interact on behalf of the Company or its subsidiaries before a third party or a public authority, such as customs agents, lawyers, spokesperson to a community, company processing permits before the Municipality, etc.?",
      "(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?": "(iv)(v) Will your organization be allowed to engage in any form of promotion or marketing activities at the event facilities?",
      "(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros": "(ix) Has the Counterparty been suggested by any public official? For example, employees of Codelco, Ministries, Public Services, Intendencies, Governorships, among others",
      "(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?": "(v) Is the Counterparty part of the group that represents an accumulated 80% of Annual Revenue?",
      "(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.": "(vi) How will the sponsorship be provided? For example, check to the organizing entity, in-kind contribution, direct payment to the provider.",
      "(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Empresa, sus fililales, o algún colaborador de esta?": "(vi) Is there any conflict of interest between the Counterparty and the Company, its subsidiaries, or any collaborator of the Company?",
      "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal ()?": "(vii) Does the Counterparty have a residence in any country considered a tax haven ()?",
      "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?": "(vii) Does the Counterparty have a residence in any country considered a tax haven ()?",
      "(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?": "(viii) Is the payment country for the Counterparty different from the location of its domicile, for example, the company is in Argentina and requests payments to be deposited into an account at a bank in the Bahamas?",
      "(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?": "(x) Any findings when checking the tax situation of the Counterparty on the SII website?",
      "(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?": "(xi) Did you find any arrears in the Pension Directorate regarding the workers of the Counterparty?",
      "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde.": "(xii) Did you find any 100% match in the Compliance Tracker or negative information on Google, in relation to the Counterparty, its legal representative, or its partners? Please save any records here below if applicable.",
      "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde": "(xii) Did you find any 100% match in the Compliance Tracker or negative information on Google, in relation to the Counterparty, its legal representative, or its partners? Please save any records here below if applicable.",
      "1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?": "1. Did you verify any alarming results or negative information (fines, lawsuits, scandal) about the Counterparty (individual or legal entity) in the conducted search?",
      "1. ¿Tiene conflicto de interés?": "1. Do you have a conflict of interest?",
      "1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?": "1. In relation to Law No. 20.393, UK Bribery Act, or FCPA, does your company have implemented a Crime Prevention Model or another system as a Compliance program on crime prevention such as money laundering, corruption, etc., to comply with national or foreign regulatory bodies?",
      "1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;": "1. Alone or with others with whom they have a joint action agreement, has sufficient voting power to influence the management of the company;",
      "10. ¿Algún Gerente, ejecutivo de la alta administración o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles": "10. Is any Manager, executive of senior management, or Director of your company currently a Politically Exposed Person  or a public official? Has this situation occurred in the past (up to 1 year ago)? Provide details.",
      "11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?": "11. Has your company or any related entity (parent company, or subsidiary) been subject to any investigation initiated based on an accusation of committing illicit acts related to the criminal liability of legal entities (local and/or international) within the last 5 years or been on international sanctions lists?",
      "12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?": "12. Is your company or any worker, manager, executive of senior management or director, currently or in the last 5 years, subject to an investigation for any illicit acts related to offenses such as money laundering, terrorism financing, bribery of national or foreign public officials, receiving stolen goods, corruption between private individuals, unfair administration, embezzlement, incompatible negotiation, water pollution, offenses related to the prohibition of products, illegal fishing of marine resources, and processing and storage of scarce products (collapsed or overexploited) without proving a legal origin, Law No. 21,240 on Non-Compliance with Isolation and Other Health Measures, and violation of Law No. 21,227 that enables access to Unemployment Insurance benefits in the context of the health crisis caused by the Covid-19 disease, also known as the Employment Protection Law?",
      "13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?": "13. Have the administrators, directors, and/or shareholders of your company clearly communicated to employees and third parties with whom they have contractual relationships the commitment of your company to the legal and ethical development of the business, zero tolerance for corruption, and have they transmitted the content of compliance programs and policies that the company has?",
      "2. ¿Cuál es el tipo de Conflicto?": "2. What type of conflict is it?",
      "2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)": "2. In the case that your company has a Crime Prevention Model, is it certified? (Please provide details)",
      "2.- Sus negocios con la sociedad originan conflictos de interés;": "2. Their businesses with the company give rise to conflicts of interest;",
      "2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?": "2. Did you find any discrepancies between the answers given by the Counterparty in the Counterparty DD Questionnaire and the Due Diligence conducted by you?",
      "3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos": "3. Does your company have a Compliance Officer or a Crime Prevention Officer who manages or oversees the proper functioning of the Crime Prevention Model?",
      "3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o": "3. Their management is influenced by the company, if it is a legal person, or",
      "3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?": "3. Did you find any background indicating that a special analysis is required for any other reason related to a crime?",
      "4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación.": "4. Does your company provide training on offenses included in Law No. 20,393, UK Bribery Act, FCPA, or other similar laws? Provide details regarding the frequency of training and the levels and areas of your company that receive this training.",
      "4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.": "4. Detail here the person(s) applicable to the declared conflict of interest.",
      "4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.": "4. If, due to their position or role, they are in a position to have information about the company and its businesses that has not been publicly disclosed to the market, and that is capable of influencing the quotation of the company's securities.",
      "5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?": "5. Are there training sessions related to offenses included in Law No. 20,393, UK Bribery Act, FCPA, or other similar laws for contractors or suppliers providing services/goods to your company?",
      "6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación": "6. Does your company have a Code of Ethics and/or Compliance Manual? Please attach documentation.",
      "7. ¿Su organización tiene un canal de denuncias?": "7. Does your company have a reporting channel?",
      "8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?": "8. Will you or your company represent your company to third parties or to a public authority?",
      "9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles": "9. To establish contractual relationships with third parties, is there a process for checking legal backgrounds in relation to Law No. 20,393, UK Bribery Act, FCPA, or whether the legal entity or its controllers are on international sanctions lists? Provide details.",
      "Abuso, Acoso o Discriminación": "Abuse, Harassment, or Discrimination",
      "Acta": "Minutes",
      "Actions": "Actions",
      "Actividad": "Activity",
      "Acuerdos realizados o N° Acta Reunión": "Agreements Made or Meeting/Inspection Minutes Number",
      "Adjunte Archivos de Cierre Aquí": "Attach Closing Files Here",
      "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo.": "Attach Draft Agreement or Formal Agreement, Maximum 5MB.",
      "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo": "Attach Draft Agreement or Formal Agreement, Maximum 5MB",
      "Adjunte Carta de solicitud, 5Mb Máximo": "Attach Request Letter, Maximum 5MB",
      "Adjunte Respaldo de Diligencia Debida del Beneficiario, 5Mb Máximo": "Attach Backing of Due Diligence from the Beneficiary, Maximum 5MB.",
      "Administrar mi equipo": "Manage my Team",
      "Administrar mi lista Maestra": "Manage my Counterparties List",
      "Administrar mi usuarios": "Manage my Users",
      "Administrar mis Clientes": "Manage my Clients",
      "Administrar todas las Denuncias": "Manage all Reports",
      "Administrar todas las Reuniones": "Manage all Meetings",
      "aereo": "air",
      "Agregar Archivos": "Add Files",
      "Agregar": "Add",
      "Agregue los Archivos aquí, máximo 5Mb": "Add Files here, maximum 5Mb",
      "All Campaigns": "All Campaigns",
      "All Forms": "All Forms",
      "All Meetings": "All Meetings",
      "All transferencias": "Transfers of Value",
      "All WhistleBlower": "All WhistleBlower",
      "Apellidos Contacto": "Contact Last name",
      "Apellidos de Contacto": "Contact Last Names",
      "Apellidos del relacionado": "Last name of the related party",
      "Apellidos": "Last Name",
      "Aprobada": "Approved",
      "Aprobador 1": "Approver 1",
      "Aprobador 2": "Approver 2",
      "Aprobador": "Approver",
      "APRUEBA DILIGENCIA DEBIDA?": "APPROVE DUE DILIGENCE?",
      "Archivos para Descargar": "Downloadable Files",
      "Archivos Relacionados": "Related Files",
      "Area a denunciar": "Area to Report",
      "Area Contraparte": "Counterparty Area",
      "Area Denunciada": "Reported Area",
      "Area funcional": "Functional Area",
      "Area": "Area",
      "ART. 100 - Son relacionadas con una sociedad las siguientes personas": "ART. 100 - The following persons are related to a company",
      "Asistentes": "Attendees",
      "Asunto": "Subject",
      "Auspicio": "Sponsorship",
      "AUTORIZA?": "APPROVE?",
      "Autorización": "Authorized",
      "Autorizada": "Authorized",
      "Autorizadas": "Authorized",
      "Autorizado": "Authorized",
      "Beneficiario Potencial": "Potential Beneficiary",
      "Bienvenido a Eticpro!": "Welcome to Eticpro",
      "Bisabuelo(a)": "Great-grandfather/Great-grandmother",
      "Bisnieto(a)": "Great-grandson/Great-granddaughter",
      "busauto": "bus/auto",
      "Cambiar Contraseña": "Change Password",
      "Campaigns": "Campaigns",
      "Campaña de Invitaciones": "Invitation Campaign",
      "Campaña": "Campaign",
      "CAMPAÑA": "CAMPAIGN",
      "Campañas enviadas por el team de Compliance Management": "Campaigns sent by the Compliance Management team",
      "Campañas": "Campaigns",
      "Cancelar": "Cancel",
      "Cantidad": "Quantity",
      "Carga Individual": "Unique Upload" ,
      "Carga Masiva": "Massive Upload",
      "Cargando...": "Loading...",
      "Cargo a denunciar": "Position to Report",
      "Cargo Beneficiario 1 del Regalo": "Position of Beneficiary 1 of the Gift",
      "Cargo Beneficiario 1 del viaje": "Position of Beneficiary 1 of the trip",
      "Cargo Beneficiario 1": "Position of Beneficiary 1",
      "Cargo Beneficiario 2 del Regalo": "Position of Beneficiary 2 of the Gift",
      "Cargo Beneficiario 2": "Position of Beneficiary 2",
      "Cargo de Contacto": "Contact Position",
      "Cargo de la Persona de interés": "Position of Public Official/Leader/Person of Interest",
      "Cargo Fun.": "Functional Position",
      "Cargo Representante que da la comida": "Position of the representative providing the meal",
      "Cargo Representante que ofrece la comida": "Position Representative offering the dinner",
      "Cargo Representante que va en el viaje": "Position of the representative going on the trip",
      "Cargo": "Occupation",
      "Celular": "Mobile",
      "Centro de Mensajes": "Message Center",
      "Charter": "Charter",
      "Chat Denunciante": "Chat",
      "Checklist": "Checklist",
      "Cierre de Investigación": "Investigation Closure",
      "Cierre de Solicitante": "Applicant's Closing",
      "Cierre": "Closure",
      "Clase de Viaje": "Travel Class",
      "Clases de Viaje": "Travel Classes",
      "Clientes": "Clients",
      "Clients": "Clients",
      "combinación": "combination",
      "Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)": "Additional comment to be added to the invitation email that will be sent to users. (optional)",
      "Comentario de Cierre": "Closing Comment",
      "Comentario": "Comment",
      "Comentarios Adicionales": "Additional Comments",
      "Comentarios": "Comments",
      "Comida": "Food",
      "Compartida": "Shared",
      "Compartir resultado a email, separado por comas": "Share result to email, separated by commas",
      "Con Mitigación": "With Mitigation",
      "Configuración": "Configuration",
      "Confirmo que la información anterior es verdadera y completa.": "I confirm that the above information is true and complete.",
      "Conflicto de Interés Recibidos": "Received Conflict of Interest",
      "Conflicto de Interés": "Conflict of Interest",
      "Conflicto": "Conflict",
      "Consulta": "Inquiry",
      "Contacto": "Contact",
      "Contraparte que se hará la inteligencia debida": "Counterparty to undergo due diligence",
      "Contrapartes": "Counterparties",
      "Contraté a un empleado que es pariente mío hasta el tercer grado.": "I hired an employee who is a relative up to the third degree.",
      "Contrato": "Contract",
      "Costo del auspicio propuesto": "Cost of the proposed sponsorship",
      "Costo por persona de cualquier otro gasto previsible de este viaje": "Cost per person for any other foreseeable expense of this trip",
      "Costo por persona de invitación a evento": "Cost per person for event invitation",
      "Costo por persona de la comida en UF": "Cost per person for food in UF",
      "Costo por persona de la comida": "Cost per person for the meal",
      "Costo por persona del (los) regalo(s)": "Cost per person for the gift(s)",
      "Costo por persona del costo de comida más alto esperado": "Cost per person for the highest expected food cost",
      "Costo por persona del viaje propuesto": "Cost per person for the proposed trip",
      "Costo por persona": "Cost per person",
      "Costo total del evento": "Total cost of the event",
      "Creada por": "Created by",
      "Creada": "Created",
      "Creado por": "Created by",
      "Creado": "Created",
      "Nueva Cliente": "Create Client",
      "Nueva Contraparte": "Create Counterpart",
      "Nueva Declaración": "Create Form",
      "Nueva Denuncia": "Create Report",
      "Nueva Diligencia Debida": "Create Due Diligence",
      "Nueva Evaluacion de Riesgo": "Create Risk Assessment",
      "Nueva Invitación": "Create Invitation",
      "Nuevo Mensaje": "Create Message",
      "Nueva Nueva Contraparte": "Create New Counterparty",
      "Nueva Reunión": "Create Meeting",
      "Nueva Transferencia Minería": "Create Mining Transfer",
      "Nueva Transferencia Salud": "Create Health Transfer",
      "Nueva Transferencia": "Create Transfer",
      "Nuevo Usuario": "Create User",
      "Cualquier otra cosa de valor a un Funcionario Público": "Any other thing of value to a Public Official",
      "Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*": "When my client/supplier works with partners who are close relatives up to the third degree.*",
      "Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.": "When my client/supplier works directly with organizations in which I or a close family member has a stake.",
      "Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a).": "When you hire an employee who is related to me up to the third degree. Relative up to the third degree by consanguinity and affinity are: Father, mother, spouse, son, father-in-law, son-in-law, daughter-in-law, grandfather ), grandson, brother, brother-in-law, great-grandfather, great-grandson, uncle, nephew.",
      "Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.": "When I am related by affinity or consanguinity to someone classified as a PEP (Politically Exposed Person). Politically Exposed Persons may include, for example, the President of the Republic, Ministers and undersecretaries, Intendants, Governors, Mayors, Regional Secretaries, Ambassadors, Heads of the Armed Forces, Carabineros and PDI (Investigative Police), members of the Board or Chief Executive of State-owned Enterprises, Court Ministers, Competition Tribunal Ministers, Public or Constitutional Procurement, National or Regional Prosecutor, members of the Directive of Political Parties, candidates for President, Senator, Deputy, or Mayor.",
      "Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.": "When a personal advantage could influence my judgment and objective decision-making for the Organization.",
      "Cuestionario de Debida Diligencia": "Due Diligence Questionnaire",
      "Cuñado(a)": "Brother-in-law/Sister-in-law",
      "Datos Cargados, verifique aquí si estan correctos, (todos con su email) y luego haga click en botón enviar": "Data loaded, verify here if they are correct (all with their email) and then click on the send button.",
      "Datos Principales": "Main Data",
      "Decisión Comité Ético": "Ethics Committee Decision",
      "Decisión Compliance Manager": "Decision Compliance Manager",
      "Decisión": "Decision",
      "Declaración de Conflicto de Interés": "Conflict of Interest Declaration",
      "Declaraciones Pendientes de Aprobación": "Pending Conflict of Interest",
      "Declaraciones por Area": "Declarations by Area",
      "Declaraciones": "Declarations",
      "Declaradas": "Declared",
      "Declarado": "Declared",
      "Declarados": "Declared",
      "Declarantes": "Declarants",
      "Declarar Conflicto de Interés": "Declare Conflict of Interest",
      "declared": "Declared",
      "delayDays": "Delay Days",
      "Delitos de responsabilidad penal de persona jurídica": "Crimes of criminal liability of a legal entity",
      "Demora (Días)": "Delay (Days)",
      "Demora Promedio": "Average Delay",
      "Demora": "Delay",
      "Demora(Días)": "Delay(Days)",
      "Denuncia": "WhistleBlower",
      "DENUNCIAS": "WHISTLEBLOWER",

      "Denunciado": "Accused",
      "Denuncias Anónimas Pendientes de Investigación": "Pending Investigation Anonymous WhistleBlower",
      "Denuncias": "WhistleBlower",
      "Descargar Reporte": "Download Report",
      "Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos.": "Briefly describe what is being requested for approval and the purpose of the Value Transfer for the proposed expenses.",
      "Describa otros gastos previsibles": "Describe other foreseeable expenses",
      "Destino": "Destination",
      "Detalle las acciones": "Action Detail",
      "Detalle las razones de Investigar o desistir de la investigación": "Provide reasons for investigating or abandoning the investigation",
      "Detalle las razones de la Autorizacion/Rechazo": "Detail the reasons for Authorization/Rejection",
      "Detalle": "Detail",
      "Días": "Días",
      "Diligencia Debida": "Due Diligence",
      "Diligencias Debidas": "Due Diligences",
      "Direccion": "Address",
      "Domicilio Empresa Contraparte": "Counterparty Company Address",
      "Domicilio Legal": "Legal Address",
      "Donación": "Donation",
      "Donde Ocurrio": "Where It Happened",
      "Duración de la transferencia de valor": "Duration of the value transfer",
      "Duracion": "Duration",
      "Ejecutiva": "Business Class",
      "El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación": "Article 147 No. 1 establishes that an open anonymous society may only enter into transactions with related parties when they are intended to contribute to the social interest, comply with the price, terms, and conditions prevailing in the market at the time of their approval, and meet the requirements and procedures outlined below",
      "Eliminar de Campaña": "Remove from Campaign",
      "Email Aviso": "Email Notice",
      "Email Contacto": "Email Contact",
      "Email de Contacto": "Contact Email",
      "Email del Representante Legal": "Legal Representative's Email",
      "Email Representante Legal": "Legal Representative's Email",
      "Email": "Email",
      "emailcontacto": "contact email",
      "Empleador u organización": "Employer or Organization",
      "Empleados Presentes": "Participants",
      "Empresa Denunciada": "Reported Company",
      "Empresa": "Company",
      "Empresas Denunciadas": "Companies Reported",
      "Empresas": "Companies",
      "En Evaluacion": "Under Evaluation",
      "Enviada": "Sent",
      "Enviada a": "Sent to",
      "Enviadas para evaluación": "Sent for evaluation",
      "Enviar": "Send",
      "Envio exitoso": "Successful Submission",
      "Envío exitoso": "Successful Sending",
      "Estado Solicitud": "Status",
      "estado": "Status",
      "Estado": "Status",
      "Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ": "Dear Declarant, To comply with the obligation established in article 147 No. 1 of Law 18.046, in relation to article 100 of Law 18.045, it is your duty to understand the following",
      "Evaluadas": "Evaluated",
      "Evento": "Event",
      "Explique el(los) conflicto(s) de interés aquí": "Explain the conflict(s) of interest here",
      "FAQ": "FAQ",
      "Fecha de entrega de la Inversión Social": "Date of delivery of the Social Investment",
      "Fecha de Inicio": "Start Date",
      "Fecha de la Reunión": "Meeting Date",
      "Fecha de Viaje": "Travel Date",
      "Fecha en que se efectuará o se realizó la comida": "Date on which the meal will be held or was held",
      "Fecha Solicitado": "Date Requested",
      "Forms": "Forms",
      "Formulario de Declaración": "Declaration Form",
      "Formulario de Evaluacion de Riesgo": "Risk Evaluation Form",
      "Formulario de Evaluacion": "Evaluation Form",
      "Funcionario del Gobierno?": "Government Official?",
      "Giro": "Industry",
      "Guardar Archivos": "Save Files",
      "Guardar Cambios": "Save Changes",
      "He leído y comprendido la información anterior.": "I have read and understood the above information.",
      "Hermano(a)": "Brother/Sister",
      "Hijo(a)": "Son/Daughter",
      "Horas": "Hours",
      "ID": "ID",
      "Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte": "Inability to send, receive, or complete the DD Questionnaire sent to the Counterparty",
      "Imprimir en PDF": "Print PDF",
      "Indique si ha hablado con alguien de esto o denunciado el hecho": "Indicate if you have talked to someone about this or reported the incident.",
      "Indique si la organización es sin fin de lucro": "Indicate if the organization is non-profit",
      "Industria": "Industry",
      "Ingrese los mails a excluir de la campaña(Separados por comas)": "Set all emails to delete (separated by comma)",
      "Ingrese sus Datos": "Enter Your Information",
      "Inicio": "Start",
      "Iniciodeclare": "Declare Start",
      "Inversión Social": "Social Investment",
      "Investigador": "Investigator",
      "Investigar": "Investigate?",
      "Invitacion a Viaje": "Travel Invitation",
      "Invitaciones enviadas por el team": "Invitations sent by the team",
      "Invitaciones": "Invitations",
      "La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que": "The Commission may establish, by means of a general rule, that any natural or legal person who, due to patrimonial, administrative, kinship, responsibility, or subordinate relationships, gives rise to the presumption that",
      "La institución a la que pertenece el Beneficiario, ¿Lo permite?": "Does the institution to which the Beneficiary belongs allow it?",
      "Las entidades del grupo empresarial al que pertenece la sociedad;": "Entities of the business group to which the company belongs;",
      "Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;": "Legal entities that have, regarding the company, the status of parent, affiliate, subsidiary, or affiliated, in accordance with the definitions contained in Law No. 18.046;",
      "lastName": "Last Name",
      "Libre competencia": "Fair competition",
      "Lista Maestra": "Master List",
      "Log de Usuarios": "User Log",
      "Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.": "Directors, managers, administrators, principal executives, or liquidators who have an interest or participate in negotiations leading to the completion of a transaction with related parties of the anonymous society must immediately inform the board of directors or whoever it designates. Those who fail to comply with this obligation will be jointly responsible for the damages that the transaction causes to the society and its shareholders.",
      "Lugar de Trabajo": "Working Place",
      "Lugar, Dirección o medio": "Location, Address, or Medium",
      "Lugar": "Location",
      "Madre": "Mother",
      "Mail Contacto principal en la organización destinataria": "Email of the Main Contact at the Receiving Organization",
      "Meetings": "Meetings",
      "Mensaje Enviado": "Message Sent",
      "Mensaje": "Message",
      "MI PERFIL": "MY PROFILE",
      "Minutos": "Minutes",
      "Mis Declaraciones": "My Forms",
      "Mis Mensajes": "My Messages",
      "Mis reuniones con personas de influencia o funcionarios públicos": "My meetings with influential people or public officials",
      "MIS REUNIONES": "MY MEETINGS",
      "MITIGACION": "MITIGATION ACTIONS?",
      "Mitigación": "Mitigation",
      "Modos de viaje para el evento": "Modes of travel for the event",
      "Nacionalidad Contacto": "Contact Nationality",
      "Nacionalidad Representante Legal": "Nationality of Legal Representative",
      "Nacionalidad": "Nationality",
      "name": "First Name",
      "Nieto(a)": "Grandson/Granddaughter",
      "No Aplica": "Not Applicable",
      "No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.": "A person will not be considered related to the company solely by the fact of participating up to 5% of the capital or 5% of the capital with voting rights if it is a stock company, or if they are only a non-executive employee of that company.",
      "No": "No",
      "Nombre Beneficiario 1 del Regalo": "Name of Beneficiary 1 of the Gift",
      "Nombre Beneficiario 1 del viaje": "Name of Beneficiary 1 of the trip",
      "Nombre Beneficiario 1": "Name of Beneficiary 1",
      "Nombre Beneficiario 2 del Regalo": "Name of Beneficiary 2 of the Gift",
      "Nombre Beneficiario 2": "Name of Beneficiary 2",
      "Nombre Completo Representante Legal de la contraparte": "Full Name of Legal Representative of the Counterparty",
      "Nombre Contacto principal en la organización destinataria": "Name of the Main Contact at the Receiving Organization",
      "Nombre de Fantasía de la organización": "Business Name",
      "Nombre de la Campaña": "Campaign Name",
      "Nombre de la comunidad apoyada": "Supported Community Name",
      "Nombre de la Donación": "Name of the Donation",
      "Nombre de la entidad que organiza el evento": "Name of the organization organizing the event",
      "Nombre de la Inversión Social (Proyecto de desarrollo comunitario)": "Name of the Social Investment (Community Development Project)",
      "Nombre de la organización destinataria (con/sin fin de lucro)": "Name of the Receiving Organization (for-profit/non-profit)",
      "Nombre de la organización destinataria (si fuese aplicable)": "Name of the Receiving Organization (if applicable)",
      "Nombre de la Persona de interés": "Name of Public Official/Leader/Person of Interest",
      "Nombre del relacionado o Razon Social": "Name of the related party or Legal Entity",
      "Nombre del Representante Legal": "Name of the Legal Representative",
      "Nombre Representante que ofrece la comida": "Name of the representative providing the meal",
      "Nombre Representante que va en el viaje": "Name of the representative going on the trip",
      "Nombre Representante(s) que va en el viaje": "Name of Representative(s) going on the trip",
      "Nombre": "Name",
      "Nombres Contacto": "Contact First Name",
      "Nombres de Contacto": "Contact Names",
      "Nombres de otros participantes a la reunión": "Names of other Participants in the Meeting/Inspection",
      "Nombres": "First Name",
      "Número de participantes en la reunión": "Number of Participants in the Meeting",
      "Número total de asistentes propuestos": "Total number of proposed attendees",
      "Número total de viajeros propuestos": "Total number of proposed travelers",
      "Ocurrió": "Occurred",
      "Operacion en la que tengo interés o participo": "Operation in which I have an interest or participate",
      "Organismo Público o Comunidad": "Public Body or Community",
      "Organización Beneficiario 1 del Regalo": "Organization Beneficiary 1 of the Gift",
      "Organización Beneficiario 1 del viaje": "Organization Beneficiary 1 of the trip",
      "Organización Beneficiario 1": "Organization Beneficiary 1",
      "Organización Beneficiario 2 del Regalo": "Organization Beneficiary 2 of the Gift",
      "Organización Beneficiario 2": "Organization Beneficiary 2",
      "Organización Contraparte": "Counterparty Organization",
      "Organización donde está el conflicto de interés": "Organization where the conflict of interest exists",
      "Organización en la que trabaja": "Organization where you work",
      "Organización que requiere la declaración de conflicto de interés": "Organization requiring Conflict of Interest declaration",
      "Organizacion": "Organization",
      "Organización": "Organization",
      "organization": "Organization",
      "Otra información que complemente lo anterior": "Any other information that complements the above",
      "Otro": "Other",
      "Otros": "Other",
      "Padre": "Father",
      "Pago/Recepción": "Payment/Receipt",
      "Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045": "To comply with the obligation established in article 147 No. 1 of Law 18.046, in relation to article 100 of Law 18.045",
      "Para su Acción": "For Your Action",
      "Participantes": "Participants",
      "password": "Password",
      "Pendiente": "Pending",
      "Perfil": "Profile",
      "Personas Enviadas": "Users Invited",
      "Por Fecha": "By Date",
      "Por lo tanto, declaro el posible conflicto de interés aquí.": "Therefore, I declare the possible conflict of interest here.",
      "Porfavor deje su comentario aquí, guarde su reporte en Agregar Archivos más abajo, y haga click en enviar para finalizar la investigación y enviarla a Comité Ético para que resuelva": "Please leave your comment here, save your report in 'Add Files' below, and click 'Send' to conclude the investigation and submit it to the Ethics Committee for resolution.",
      "Presupuesto general del viaje propuesto": "Overall budget for the proposed trip",
      "Primera": "First Class",
      "Procesando datos...": "Loading...",
      "Propósito de la Reunión": "Meeting/Inspection Purpose",
      "Propósito del evento": "Purpose of the event",
      "Propósito del viaje propuesto": "Purpose of the proposed trip",
      "Proposito": "Purpose",
      "Protección de confidencialidad de información": "Protection of Confidential Information",
      "Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias.": "You can choose another option in the menu or close this window if there is no option. Thank you.",
      "Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y": "Those who are directors, managers, administrators, principal executives, or liquidators of the company, and their spouses or relatives up to the second degree of consanguinity, as well as any entity controlled, directly or through others, by any of them, and",
      "Razón de Contratación de la Contraparte": "Reason for Hiring the Counterparty",
      "Razón de negocio para el auspicio": "Business reason for sponsorship",
      "Razón del (los) regalo(s)": "Reason for the gift(s)",
      "Realizó el procedimiento de Diligencia Debida?": "Did you perform the Due Diligence procedure?",
      "Recibida": "Received",
      "Recibidos": "Received",
      "Recordé la contraseña": "I remembered the password",
      "Recuperar Contraseña": "Recover Password",
      "Regalo": "Gift",
      "Registrese Aquí": "Register Here",
      "Relación con la organización que denuncia": "Your Relationship with the Company Being Reported",
      "Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva": "A personal advantage could influence my judgment and objective decision-making",
      "Relaciones con stakeholders": "Relationships with stakeholders",
      "Repositorio": "Repository",
      "Respuesta de Campañas": "Campaigns Response",
      "Resultado Evaluación": "Assessment Result",
      "Resultado Investigación": "Investigation Result",
      "Reunión Persona de Interés o Comunidad": "Meeting with Person of Interest or Community",
      "Reuniones con Personas de Interés": "Meetings with Persons of Interest",
      "Reuniones por Area": "Reuniones por Área",
      "Reuniones por Organización": "Reuniones por Organización",
      "Reuniones": "Meetings",
      "Riesgo Alto": "High Risk",
      "Risk Assessment": "Risk Assessment",
      "Risk": "Risk",
      "Robo o fraude o mal uso recursos": "Theft, Fraud, or Misuse of Resources",
      "Rol": "Role",
      "Rut Contacto": "Personal ID number Contact",
      "Rut de Contacto": "Contact ID",
      "Rut Empresa": "Company RUT",
      "Rut Empresa":"Company Tax ID Number",
      "Rut en caso de ser fiscalizador": "Personal ID Number in case of Inspector",
      "Rut Fun.": "Personal ID Number Fun",
      "Rut": "Personal ID Number",
      "rutcontacto": "contact ID",
      "Salir": "Exit",
      "Score": "Score",
      "Se proveedrá alojamiento?": "Will accommodation be provided?",
      "Seleccionar Clases de Viaje": "Select Travel Class",
      "Seleccionar Contraparte": "Select Counterparty",
      "Seleccionar Modo de Viaje": "Select Travel Mode",
      "Seleccionar Organización a denunciar": "Select Organization to Report to",
      "Seleccionar Organización que requiere la declaración": "Select Organization requiring the declaration",
      "Seleccionar Transferencia de Valor": "Select Value Transfer",
      "Seleccione Investigador": "Select Investigator",
      "Señale cual es la inversión y su valor. Incluya Presupuesto o cotización.": "Indicate what the investment is and its value. Include budget or quotation.",
      "Sesiones": "Sessions",
      "Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?": "If the sponsorship was provided through direct payment to the provider, was it at the fair market price?",
      "Si es combinación, favor describir": "If it's a combination, please describe",
      "Si hay invitación o regalo u otra transferencia de valor indicar valor": "If there is an invitation, gift, or other transfer of value, indicate the value",
      "Si igual o más de 100.000 USD año": "If equal to or more than 100,000 USD per year",
      "Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche": "If the answer is Yes, please provide details regarding where, how many nights, and cost per night",
      "Si la respuesta es Sí, porfavor explique": "If the answer is Yes, please explain",
      "Si menos de 100.000 USD año": "If less than 100,000 USD per year",
      "Si": "Yes",
      "Sí": "Yes",
      "Sin Respuesta": "No Response",
      "Sitio web": "Website",
      "Sito Web": "Website",
      "Sobrino(a)": "Nephew/Niece",
      "Solicitada por": "Requested by",
      "Solicitado por": "Requested by",
      "Solicitante": "Requester",
      "Soy pariente por afinidad o consanguinidad de alguien catalogado PEP.": "I am a relative by affinity or consanguinity of someone classified as PEP (Politically Exposed Person).",
      "Su información fue enviada con éxito!": "Your information was sent successfully!",
      "Su mensaje aquí...": "Your message here..." ,
      "Suba Aquí el Archivo": "Upload your files Here",
      "Suegro(a)": "Father-in-law/Mother-in-law",
      "Tamaño de Campañas": "Campaign Size",
      "Target": "Target",
      "Telefono Contacto principal en la organización destinataria": "Phone of the Main Contact at the Receiving Organization",
      "Telefono de Contacto": "Contact Phone",
      "Termino de Sesión en": "Login expires in",
      "Tío(a)": "Uncle/Aunt",
      "Tipo Conflicto": "Conflict Type",
      "Tipo de Auspicio": "Type of Sponsorship",
      "Tipo de Carga": "Upload Type",
      "Tipo de Denuncia": "Type of Report",
      "Tipo de Invitación": "Invitation Type",
      "Tipo de relación conmigo": "Type of relationship with me",
      "tipo": "Type",
      "Tipo": "Type",
      "Tipos de Conflicto": "Conflict Types",
      "Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.": "Any person who, alone or with others with whom they have a joint action agreement, can appoint at least one member of the company's administration or control 10% or more of the capital or voting capital if it is a stock company.",
      "Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*": "I work with partners who are close relatives up to the third degree.*",
      "Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación.": "I work directly with companies or organizations in which I or a close family member has a stake.",
      "Transferencia de Valor": "Transfer of Value",
      "Transferencias de Valor Pendientes de Aprobación": "Pending Approval Value Transfers",
      "Transferencias de Valor": "Value Transfers",
      "Transferencias": "Transfers",
      "tren": "train",
      "Turista": "Tourist",
      "Ubicación de la comunidad apoyada": "Location of the Supported Community",
      "Ubicación del Beneficiario Potencial": "Location of the Potential Beneficiary",
      "Ultimas Reuniones Pendientes de Aprobación": "Latest Meetings Pending Approval",
      "Un posible conflicto de interés puede existir": "A possible conflict of interest may exist",
      "Usar Eticpro": "Use Eticpro",
      "Usuarios por Area": "By Area",
      "Usuarios por Contrato": "By Contract",
      "Usuarios por Perfil": "By Profile",
      "Usuarios por Rut": "By Rut",
      "Usuarios": "Users",
      "Valor anticipado de la contribución de La Empresa al desarrollo comunitario (en dinero y en especie)": "Anticipated value of the Company's contribution to community development (in cash and in kind)",
      "Value Transfer": "Value Transfer",
      "Viaje": "Travel",
      "WhistleBlower": "Whistleblower",
      "Ya tienes una cuenta?, Ingrese aquí.": "Already have an account?",
      "Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Empresa.": "I understand that a conflict of interest can occur when my private (personal, social, or financial) interests interfere in any way with my responsibilities or my ability to act in the best interest of the Company.",
      "Sesión": "Log Out",


    },


    sp: {
      "Evaluada": "Evaluada",
      "Cerrar Sesión": "Cerrar Sesión",
      "Campañas enviadas por el team de Compliance Management":"Campañas enviadas por el team de Compliance Management",
      "¿Cómo se dio cuenta de cuenta de esta situación?":"¿Cómo se dio cuenta de cuenta de esta situación?",
      "¿Cuándo ocurrió?":"¿Cuándo ocurrió?",
      "¿Desde cuándo cree usted que ha estado ocurriendo?":"¿Desde cuándo cree usted que ha estado ocurriendo?",
      "¿Dónde ocurrió este incidente o infracción específicamente?":"¿Dónde ocurrió este incidente o infracción específicamente?",
      "¿Es Ud. Director, Gerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?":"¿Es Ud. Director, Gerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?",
      "¿Está seguro de salir de Eticpro?":"¿Está seguro de salir de Eticpro?",
      "¿Hay pagos a terceros como consultores u organizaciones para ejecutar la inversión social?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y la diligencia debida de este consultor u organización":"¿Hay pagos a terceros como consultores u organizaciones para ejecutar la inversión social?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y la diligencia debida de este consultor u organización",
      "¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?":"¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?",
      "¿Qué ocurrió?":"¿Qué ocurrió?",
      "¿Realizo el procedimiento de Diligencia Debida?":"¿Realizo el procedimiento de Diligencia Debida?",
      "(i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal?":"(i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal?",
      "(i) ¿La Contraparte efectúa alguno de los siguientes giros":"(i) ¿La Contraparte efectúa alguno de los siguientes giros",
      "(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?":"(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?",
      "(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio":"(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio",
      "(iii) ¿Habrá otras empresas auspiciando?":"(iii) ¿Habrá otras empresas auspiciando?",
      "(iii) Estimación del Pago Anual superior a 100.000 USD anual?":"(iii) Estimación del Pago Anual superior a 100.000 USD anual?",
      "(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?":"(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?",
      "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?":"(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?",
      "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?":"(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?",
      "(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?":"(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?",
      "(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros":"(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros",
      "(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?":"(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?",
      "(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.":"(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.",
      "(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Empresa, sus fililales, o algún colaborador de esta?":"(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Empresa, sus fililales, o algún colaborador de esta?",
      "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal ()?":"(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal ()?",
      "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?":"(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?",
      "(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?":"(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?",
      "(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?":"(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?",
      "(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?":"(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?",
      "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde.":"(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde.",
      "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde":"(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde",
      "1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?":"1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?",
      "1. ¿Tiene conflicto de interés?":"1. ¿Tiene conflicto de interés?",
      "1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?":"1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?",
      "1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;":"1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;",
      "10. ¿Algún Gerente, ejecutivo de la alta administración o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles":"10. ¿Algún Gerente, ejecutivo de la alta administración o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles",
      "11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?":"11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?",
      "12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?":"12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?",
      "13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?":"13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?",
      "2. ¿Cuál es el tipo de Conflicto?":"2. ¿Cuál es el tipo de Conflicto?",
      "2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)":"2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)",
      "2.- Sus negocios con la sociedad originan conflictos de interés;":"2.- Sus negocios con la sociedad originan conflictos de interés;",
      "2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?":"2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?",
      "3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos":"3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos",
      "3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o":"3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o",
      "3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?":"3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?",
      "4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación.":"4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación.",
      "4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.":"4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.",
      "4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.":"4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.",
      "5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?":"5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?",
      "6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación":"6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación",
      "7. ¿Su organización tiene un canal de denuncias?":"7. ¿Su organización tiene un canal de denuncias?",
      "8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?":"8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?",
      "9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles":"9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles",
      "Abuso, Acoso o Discriminación":"Abuso, Acoso o Discriminación",
      "Acta":"Acta",
      "Actions":"Actions",
      "Actividad":"Actividad",
      "Acuerdos realizados o N° Acta Reunión":"Acuerdos realizados o N° Acta Reunión",
      "Adjunte Archivos de Cierre Aquí":"Adjunte Archivos de Cierre Aquí",
      "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo.":"Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo.",
      "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo":"Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo",
      "Adjunte Carta de solicitud, 5Mb Máximo":"Adjunte Carta de solicitud, 5Mb Máximo",
      "Adjunte Respaldo de Diligencia Debida del Beneficiario, 5Mb Máximo":"Adjunte Respaldo de Diligencia Debida del Beneficiario, 5Mb Máximo",
      "Administrar mi equipo":"Administrar mi equipo",
      "Administrar mi lista Maestra":"Administrar mi lista Maestra",
      "Administrar mi usuarios":"Administrar mi usuarios",
      "Administrar mis Clientes":"Administrar mis Clientes",
      "Administrar todas las Denuncias":"Administrar todas las Denuncias",
      "Administrar todas las Reuniones":"Administrar todas las Reuniones",
      "aereo":"aereo",
      "Agregar Archivos":"Agregar Archivos",
      "Agregar":"Agregar",
      "Agregue los Archivos aquí, máximo 5Mb":"Agregue los Archivos aquí, máximo 5Mb",
      "All Campaigns":"All Campaigns",
      "All Forms":"All Forms",
      "All Meetings":"All Meetings",
      "All transferencias":"All transferencias",
      "All WhistleBlower":"All WhistleBlower",
      "Apellidos Contacto":"Apellidos Contacto",
      "Apellidos de Contacto":"Apellidos de Contacto",
      "Apellidos del relacionado":"Apellidos del relacionado",
      "Apellidos":"Apellidos",
      "Aprobada":"Aprobada",
      "Aprobador 1":"Aprobador 1",
      "Aprobador 2":"Aprobador 2",
      "Aprobador":"Aprobador",
      "APRUEBA DILIGENCIA DEBIDA?":"APRUEBA DILIGENCIA DEBIDA?",
      "Archivos para Descargar":"Archivos para Descargar",
      "Archivos Relacionados":"Archivos Relacionados",
      "Area a denunciar":"Area a denunciar",
      "Area Contraparte":"Area Contraparte",
      "Area Denunciada":"Area Denunciada",
      "Area funcional":"Area funcional",
      "Area":"Area",
      "ART. 100 - Son relacionadas con una sociedad las siguientes personas":"ART. 100 - Son relacionadas con una sociedad las siguientes personas",
      "Asistentes":"Asistentes",
      "Asunto":"Asunto",
      "Auspicio":"Auspicio",
      "AUTORIZA?":"AUTORIZA?",
      "Autorización":"Autorizacion",
      "Autorizada":"Autorizada",
      "Autorizadas":"Autorizadas",
      "Autorizado":"Autorizado",
      "Beneficiario Potencial":"Beneficiario Potencial",
      "Bienvenido a Eticpro!":"Bienvenido a Eticpro!",
      "Bisabuelo(a)":"Bisabuelo(a)",
      "Bisnieto(a)":"Bisnieto(a)",
      "busauto":"busauto",
      "Cambiar Contraseña":"Cambiar Contraseña",
      "Campaigns":"Campaigns",
      "Campaña de Invitaciones":"Campaña de Invitaciones",
      "Campaña":"Campaña",
      "CAMPAÑA":"CAMPAÑA",
      "Campañas enviadas por el team de Compliance Management":"Campañas enviadas por el team de Compliance Management",
      "Campañas":"Campañas",
      "Cancelar":"Cancelar",
      "Cantidad":"Cantidad",
      "Carga Individual":"Carga Individual",
      "Carga Masiva":"Carga Masiva",
      "Cargando...":"Cargando...",
      "Cargo a denunciar":"Cargo a denunciar",
      "Cargo Beneficiario 1 del Regalo":"Cargo Beneficiario 1 del Regalo",
      "Cargo Beneficiario 1 del viaje":"Cargo Beneficiario 1 del viaje",
      "Cargo Beneficiario 1":"Cargo Beneficiario 1",
      "Cargo Beneficiario 2 del Regalo":"Cargo Beneficiario 2 del Regalo",
      "Cargo Beneficiario 2":"Cargo Beneficiario 2",
      "Cargo de Contacto":"Cargo de Contacto",
      "Cargo de la Persona de interés":"Cargo de la Persona de interés",
      "Cargo Fun.":"Cargo Fun.",
      "Cargo Representante que da la comida":"Cargo Representante que da la comida",
      "Cargo Representante que ofrece la comida":"Cargo Representante que ofrece la comida",
      "Cargo Representante que va en el viaje":"Cargo Representante que va en el viaje",
      "Cargo":"Cargo",
      "Celular":"Celular",
      "Centro de Mensajes":"Centro de Mensajes",
      "Charter":"Charter",
      "Chat Denunciante":"Chat Denunciante",
      "Checklist":"Checklist",
      "Cierre de Investigación":"Cierre de Investigación",
      "Cierre de Solicitante":"Cierre de Solicitante",
      "Cierre":"Cierre",
      "Clase de Viaje":"Clase de Viaje",
      "Clases de Viaje":"Clases de Viaje",
      "Clientes":"Clientes",
      "Clients":"Clients",
      "combinación":"combinación",
      "Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)":"Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)",
      "Comentario de Cierre":"Comentario de Cierre",
      "Comentario":"Comentario",
      "Comentarios Adicionales":"Comentarios Adicionales",
      "Comentarios":"Comentarios",
      "Comida":"Comida",
      "Compartida":"Compartida",
      "Compartir resultado a email, separado por comas":"Compartir resultado a email, separado por comas",
      "Con Mitigación":"Con Mitigación",
      "Configuración":"Configuración",
      "Confirmo que la información anterior es verdadera y completa.":"Confirmo que la información anterior es verdadera y completa.",
      "Conflicto de Interés Recibidos":"Conflicto de Interés Recibidos",
      "Conflicto de Interés":"Conflicto de Interés",
      "Conflicto":"Conflicto",
      "Consulta":"Consulta",
      "Contacto":"Contacto",
      "Contraparte que se hará la inteligencia debida":"Contraparte que se hará la inteligencia debida",
      "Contrapartes":"Contrapartes",
      "Contraté a un empleado que es pariente mío hasta el tercer grado.":"Contraté a un empleado que es pariente mío hasta el tercer grado.",
      "Contrato":"Contrato",
      "Costo del auspicio propuesto":"Costo del auspicio propuesto",
      "Costo por persona de cualquier otro gasto previsible de este viaje":"Costo por persona de cualquier otro gasto previsible de este viaje",
      "Costo por persona de invitación a evento":"Costo por persona de invitación a evento",
      "Costo por persona de la comida en UF":"Costo por persona de la comida en UF",
      "Costo por persona de la comida":"Costo por persona de la comida",
      "Costo por persona del (los) regalo(s)":"Costo por persona del (los) regalo(s)",
      "Costo por persona del costo de comida más alto esperado":"Costo por persona del costo de comida más alto esperado",
      "Costo por persona del viaje propuesto":"Costo por persona del viaje propuesto",
      "Costo por persona":"Costo por persona",
      "Costo total del evento":"Costo total del evento",
      "Creada por":"Creada por",
      "Creada":"Creada",
      "Creado por":"Creado por",
      "Creado":"Creado",
      "Nueva Cliente":"Nueva Cliente",
      "Nueva Contraparte":"Nueva Contraparte",
      "Nueva Declaración":"Nueva Declaración",
      "Nueva Denuncia":"Nueva Denuncia",
      "Nueva Diligencia Debida":"Nueva Diligencia Debida",
      "Nueva Evaluacion de Riesgo":"Nueva Evaluacion de Riesgo",
      "Nueva Invitación":"Nueva Invitación",
      "Nuevo Mensaje":"Nuevo Mensaje",
      "Nueva Nueva Contraparte":"Nueva Nueva Contraparte",
      "Nueva Reunión":"Nueva Reunión",
      "Nueva Transferencia Minería":"Nueva Transferencia Minería",
      "Nueva Transferencia Salud":"Nueva Transferencia Salud",
      "Nueva Transferencia":"Nueva Transferencia",
      "Nuevo Usuario":"Nuevo Usuario",
      "Cualquier otra cosa de valor a un Funcionario Público":"Cualquier otra cosa de valor a un Funcionario Público",
      "Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*":"Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*",
      "Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.":"Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.",
      "Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a).": "Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a).",
      "Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.":"Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.",
      "Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.":"Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.",
      "Cuestionario de Debida Diligencia":"Cuestionario de Debida Diligencia",
      "Cuñado(a)":"Cuñado(a)",
      "Datos Cargados, verifique aquí si estan correctos, (todos con su email) y luego haga click en botón enviar":"Datos Cargados, verifique aquí si estan correctos, (todos con su email) y luego haga click en botón enviar",
      "Datos Principales":"Datos Principales",
      "Decisión Comité Ético":"Decisión Comité Ético",
      "Decisión Compliance Manager":"Decisión Compliance Manager",
      "Decisión":"Decisión",
      "Declaración de Conflicto de Interés":"Declaración de Conflicto de Interés",
      "Declaraciones Pendientes de Aprobación":"Declaraciones Pendientes de Aprobación",
      "Declaraciones por Area":"Declaraciones por Area",
      "Declaraciones":"Declaraciones",
      "Declaradas":"Declaradas",
      "Declarado":"Declarado",
      "Declarados":"Declarados",
      "Declarantes":"Declarantes",
      "Declarar Conflicto de Interés":"Declarar Conflicto de Interés",
      "declared":"declared",
      "delayDays":"delayDays",
      "Delitos de responsabilidad penal de persona jurídica":"Delitos de responsabilidad penal de persona jurídica",
      "Demora (Días)":"Demora (Días)",
      "Demora Promedio":"Demora Promedio",
      "Demora":"Demora",
      "Demora(Días)":"Demora(Días)",
      "Denuncia":"Denuncia",
      "Denunciado":"Denunciado",
      "Denuncias Anónimas Pendientes de Investigación":"Denuncias Anónimas Pendientes de Investigación",
      "Denuncias":"Denuncias",
      "Descargar Reporte":"Descargar Reporte",
      "Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos.":"Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos.",
      "Describa otros gastos previsibles":"Describa otros gastos previsibles",
      "Destino":"Destino",
      "Detalle las acciones":"Detalle las acciones",
      "Detalle las razones de Investigar o desistir de la investigación":"Detalle las razones de Investigar o desistir de la investigación",
      "Detalle las razones de la Autorizacion/Rechazo":"Detalle las razones de la Autorizacion/Rechazo",
      "Detalle":"Detalle",
      "Días":"Días",
      "Diligencia Debida":"Diligencia Debida",
      "Diligencias Debidas":"Diligencias Debidas",
      "Direccion":"Direccion",
      "Domicilio Empresa Contraparte":"Domicilio Empresa Contraparte",
      "Domicilio Legal":"Domicilio Legal",
      "Donación":"Donación",
      "Donde Ocurrio":"Donde Ocurrio",
      "Duración de la transferencia de valor":"Duración de la transferencia de valor",
      "Duracion":"Duracion",
      "Ejecutiva":"Ejecutiva",
      "El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación":"El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación",
      "Eliminar de Campaña":"Eliminar de Campaña",
      "Email Aviso":"Email Aviso",
      "Email Contacto":"Email Contacto",
      "Email de Contacto":"Email de Contacto",
      "Email del Representante Legal":"Email del Representante Legal",
      "Email Representante Legal":"Email Representante Legal",
      "Email":"Email",
      "emailcontacto":"emailcontacto",
      "Empleador u organización":"Empleador u organización",
      "Empleados Presentes":"Empleados Presentes",
      "Empresa Denunciada":"Empresa Denunciada",
      "Empresa":"Empresa",
      "Empresas Denunciadas":"Empresas Denunciadas",
      "Empresas":"Empresas",
      "En Evaluacion":"En Evaluacion",
      "Enviada":"Enviada",
      "Enviada a":"Enviada a",
      "Enviadas para evaluación":"Enviadas para evaluación",
      "Enviar":"Enviar",
      "Envio exitoso":"Envio exitoso",
      "Envío exitoso":"Envío exitoso",
      "Estado Solicitud":"Estado Solicitud",
      "estado":"estado",
      "Estado":"Estado",
      "Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ":"Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ",
      "Evaluadas":"Evaluadas",
      "Evento":"Evento",
      "Explique el(los) conflicto(s) de interés aquí":"Explique el(los) conflicto(s) de interés aquí",
      "FAQ":"FAQ",
      "Fecha de entrega de la Inversión Social":"Fecha de entrega de la Inversión Social",
      "Fecha de Inicio":"Fecha de Inicio",
      "Fecha de la Reunión":"Fecha de la Reunión",
      "Fecha de Viaje":"Fecha de Viaje",
      "Fecha en que se efectuará o se realizó la comida":"Fecha en que se efectuará o se realizó la comida",
      "Fecha Solicitado":"Fecha Solicitado",
      "Forms":"Forms",
      "Formulario de Declaración":"Formulario de Declaración",
      "Formulario de Evaluacion de Riesgo":"Formulario de Evaluacion de Riesgo",
      "Formulario de Evaluacion":"Formulario de Evaluacion",
      "Funcionario del Gobierno?":"Funcionario del Gobierno?",
      "Giro":"Giro",
      "Guardar Archivos":"Guardar Archivos",
      "Guardar Cambios":"Guardar Cambios",
      "He leído y comprendido la información anterior.":"He leído y comprendido la información anterior.",
      "Hermano(a)":"Hermano(a)",
      "Hijo(a)":"Hijo(a)",
      "Horas":"Horas",
      "ID":"ID",
      "Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte":"Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte",
      "Imprimir en PDF":"Imprimir en PDF",
      "Indique si ha hablado con alguien de esto o denunciado el hecho":"Indique si ha hablado con alguien de esto o denunciado el hecho",
      "Indique si la organización es sin fin de lucro":"Indique si la organización es sin fin de lucro",
      "Industria":"Industria",
      "Ingrese los mails a excluir de la campaña(Separados por comas)":"Ingrese los mails a excluir de la campaña(Separados por comas)",
      "Ingrese sus Datos":"Ingrese sus Datos",
      "Inicio":"Inicio",
      "Iniciodeclare":"Iniciodeclare",
      "Inversión Social":"Inversión Social",
      "Investigador":"Investigador",
      "Investigar":"Investigar",
      "Invitacion a Viaje":"Invitacion a Viaje",
      "Invitaciones enviadas por el team":"Invitaciones enviadas por el team",
      "Invitaciones":"Invitaciones",
      "La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que":"La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que",
      "La institución a la que pertenece el Beneficiario, ¿Lo permite?":"La institución a la que pertenece el Beneficiario, ¿Lo permite?",
      "Las entidades del grupo empresarial al que pertenece la sociedad;":"Las entidades del grupo empresarial al que pertenece la sociedad;",
      "Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;":"Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;",
      "lastName":"lastName",
      "Libre competencia":"Libre competencia",
      "Lista Maestra":"Lista Maestra",
      "Log de Usuarios":"Log de Usuarios",
      "Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.":"Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.",
      "Lugar de Trabajo":"Lugar de Trabajo",
      "Lugar, Dirección o medio":"Lugar, Dirección o medio",
      "Lugar":"Lugar",
      "Madre":"Madre",
      "Mail Contacto principal en la organización destinataria":"Mail Contacto principal en la organización destinataria",
      "Meetings":"Meetings",
      "Mensaje Enviado":"Mensaje Enviado",
      "Mensaje":"Mensaje",
      "MI PERFIL":"MI PERFIL",
      "Minutos":"Minutos",
      "Mis Declaraciones":"Mis Declaraciones",
      "Mis Mensajes":"Mis Mensajes",
      "Mis reuniones con personas de influencia o funcionarios públicos":"Mis reuniones con personas de influencia o funcionarios públicos",
      "MIS REUNIONES":"MIS REUNIONES",
      "MITIGACION":"MITIGACION",
      "Mitigación":"Mitigación",
      "Modos de viaje para el evento":"Modos de viaje para el evento",
      "Nacionalidad Contacto":"Nacionalidad Contacto",
      "Nacionalidad Representante Legal":"Nacionalidad Representante Legal",
      "Nacionalidad":"Nacionalidad",
      "name":"name",
      "Nieto(a)":"Nieto(a)",
      "No Aplica":"No Aplica",
      "No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.":"No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.",
      "No":"No",
      "Nombre Beneficiario 1 del Regalo":"Nombre Beneficiario 1 del Regalo",
      "Nombre Beneficiario 1 del viaje":"Nombre Beneficiario 1 del viaje",
      "Nombre Beneficiario 1":"Nombre Beneficiario 1",
      "Nombre Beneficiario 2 del Regalo":"Nombre Beneficiario 2 del Regalo",
      "Nombre Beneficiario 2":"Nombre Beneficiario 2",
      "Nombre Completo Representante Legal de la contraparte":"Nombre Completo Representante Legal de la contraparte",
      "Nombre Contacto principal en la organización destinataria":"Nombre Contacto principal en la organización destinataria",
      "Nombre de Fantasía de la organización":"Nombre de Fantasía de la organización",
      "Nombre de la Campaña":"Nombre de la Campaña",
      "Nombre de la comunidad apoyada":"Nombre de la comunidad apoyada",
      "Nombre de la Donación":"Nombre de la Donación",
      "Nombre de la entidad que organiza el evento":"Nombre de la entidad que organiza el evento",
      "Nombre de la Inversión Social (Proyecto de desarrollo comunitario)":"Nombre de la Inversión Social (Proyecto de desarrollo comunitario)",
      "Nombre de la organización destinataria (con/sin fin de lucro)":"Nombre de la organización destinataria (con/sin fin de lucro)",
      "Nombre de la organización destinataria (si fuese aplicable)":"Nombre de la organización destinataria (si fuese aplicable)",
      "Nombre de la Persona de interés":"Nombre de la Persona de interés",
      "Nombre del relacionado o Razon Social":"Nombre del relacionado o Razon Social",
      "Nombre del Representante Legal":"Nombre del Representante Legal",
      "Nombre Representante que ofrece la comida":"Nombre Representante que ofrece la comida",
      "Nombre Representante que va en el viaje":"Nombre Representante que va en el viaje",
      "Nombre Representante(s) que va en el viaje":"Nombre Representante(s) que va en el viaje",
      "Nombre":"Nombre",
      "Nombres Contacto":"Nombres Contacto",
      "Nombres de Contacto":"Nombres de Contacto",
      "Nombres de otros participantes a la reunión":"Nombres de otros participantes a la reunión",
      "Nombres":"Nombres",
      "Número de participantes en la reunión":"Número de participantes en la reunión",
      "Número total de asistentes propuestos":"Número total de asistentes propuestos",
      "Número total de viajeros propuestos":"Número total de viajeros propuestos",
      "Ocurrió":"Ocurrió",
      "Operacion en la que tengo interés o participo":"Operacion en la que tengo interés o participo",
      "Organismo Público o Comunidad":"Organismo Público o Comunidad",
      "Organización Beneficiario 1 del Regalo":"Organización Beneficiario 1 del Regalo",
      "Organización Beneficiario 1 del viaje":"Organización Beneficiario 1 del viaje",
      "Organización Beneficiario 1":"Organización Beneficiario 1",
      "Organización Beneficiario 2 del Regalo":"Organización Beneficiario 2 del Regalo",
      "Organización Beneficiario 2":"Organización Beneficiario 2",
      "Organización Contraparte":"Organización Contraparte",
      "Organización donde está el conflicto de interés":"Organización donde está el conflicto de interés",
      "Organización en la que trabaja":"Organización en la que trabaja",
      "Organización que requiere la declaración de conflicto de interés":"Organización que requiere la declaración de conflicto de interés",
      "Organizacion":"Organizacion",
      "Organización":"Organización",
      "organization":"organization",
      "Otra información que complemente lo anterior":"Otra información que complemente lo anterior",
      "Otro":"Otro",
      "Otros":"Otros",
      "Padre":"Padre",
      "Pago/Recepción":"Pago/Recepción",
      "Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045":"Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045",
      "Para su Acción":"Para su Acción",
      "Participantes":"Participantes",
      "password":"password",
      "Pendiente":"Pendiente",
      "Perfil":"Perfil",
      "Personas Enviadas":"Personas Enviadas",
      "Por Fecha":"Por Fecha",
      "Por lo tanto, declaro el posible conflicto de interés aquí.":"Por lo tanto, declaro el posible conflicto de interés aquí.",
      "Porfavor deje su comentario aquí, guarde su reporte en Agregar Archivos más abajo, y haga click en enviar para finalizar la investigación y enviarla a Comité Ético para que resuelva":"Porfavor deje su comentario aquí, guarde su reporte en Agregar Archivos más abajo, y haga click en enviar para finalizar la investigación y enviarla a Comité Ético para que resuelva",
      "Presupuesto general del viaje propuesto":"Presupuesto general del viaje propuesto",
      "Primera":"Primera",
      "Procesando datos...":"Procesando datos...",
      "Propósito de la Reunión":"Propósito de la Reunión",
      "Propósito del evento":"Propósito del evento",
      "Propósito del viaje propuesto":"Propósito del viaje propuesto",
      "Proposito":"Proposito",
      "Protección de confidencialidad de información":"Protección de confidencialidad de información",
      "Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias.":"Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias.",
      "Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y":"Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y",
      "Razón de Contratación de la Contraparte":"Razón de Contratación de la Contraparte",
      "Razón de negocio para el auspicio":"Razón de negocio para el auspicio",
      "Razón del (los) regalo(s)":"Razón del (los) regalo(s)",
      "Realizó el procedimiento de Diligencia Debida?":"Realizó el procedimiento de Diligencia Debida?",
      "Recibida":"Recibida",
      "Recibidos":"Recibidos",
      "Recordé la contraseña":"Recordé la contraseña",
      "Recuperar Contraseña":"Recuperar Contraseña",
      "Regalo":"Regalo",
      "Registrese Aquí":"Registrese Aquí",
      "Relación con la organización que denuncia":"Relación con la organización que denuncia",
      "Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva":"Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva",
      "Relaciones con stakeholders":"Relaciones con stakeholders",
      "Repositorio":"Repositorio",
      "Respuesta de Campañas":"Respuesta de Campañas",
      "Resultado Evaluación":"Resultado Evaluación",
      "Resultado Investigación":"Resultado Investigación",
      "Reunión Persona de Interés o Comunidad":"Reunión Persona de Interés o Comunidad",
      "Reuniones con Personas de Interés":"Reuniones con Personas de Interés",
      "Reuniones por Area":"Reuniones por Area",
      "Reuniones por Organización":"Reuniones por Organización",
      "Reuniones":"Reuniones",
      "Riesgo Alto":"Riesgo Alto",
      "Risk Assessment":"Risk Assessment",
      "Risk":"Risk",
      "Robo o fraude o mal uso recursos":"Robo o fraude o mal uso recursos",
      "Rol":"Rol",
      "Rut Contacto":"Rut Contacto",
      "Rut de Contacto":"Rut de Contacto",
      "Rut Empresa":"Rut Empresa",
      "Rut Empresa":"Rut Empresa",
      "Rut en caso de ser fiscalizador":"Rut en caso de ser fiscalizador",
      "Rut Fun.":"Rut Fun.",
      "Rut":"Rut",
      "rutcontacto":"rutcontacto",
      "Salir":"Salir",
      "Score":"Score",
      "Se proveedrá alojamiento?":"Se proveedrá alojamiento?",
      "Seleccionar Clases de Viaje":"Seleccionar Clases de Viaje",
      "Seleccionar Contraparte":"Seleccionar Contraparte",
      "Seleccionar Modo de Viaje":"Seleccionar Modo de Viaje",
      "Seleccionar Organización a denunciar":"Seleccionar Organización a denunciar",
      "Seleccionar Organización que requiere la declaración":"Seleccionar Organización que requiere la declaración",
      "Seleccionar Transferencia de Valor":"Seleccionar Transferencia de Valor",
      "Seleccione Investigador":"Seleccione Investigador",
      "Señale cual es la inversión y su valor. Incluya Presupuesto o cotización.":"Señale cual es la inversión y su valor. Incluya Presupuesto o cotización.",
      "Sesiones":"Sesiones",
      "Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?":"Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?",
      "Si es combinación, favor describir":"Si es combinación, favor describir",
      "Si hay invitación o regalo u otra transferencia de valor indicar valor":"Si hay invitación o regalo u otra transferencia de valor indicar valor",
      "Si igual o más de 100.000 USD año":"Si igual o más de 100.000 USD año",
      "Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche":"Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche",
      "Si la respuesta es Sí, porfavor explique":"Si la respuesta es Sí, porfavor explique",
      "Si menos de 100.000 USD año":"Si menos de 100.000 USD año",
      "Si":"Si",
      "Sí":"Sí",
      "Sin Respuesta":"Sin Respuesta",
      "Sitio web":"Sitio web",
      "Sito Web":"Sito Web",
      "Sobrino(a)":"Sobrino(a)",
      "Solicitada por":"Solicitada por",
      "Solicitado por":"Solicitado por",
      "Solicitante":"Solicitante",
      "Soy pariente por afinidad o consanguinidad de alguien catalogado PEP.":"Soy pariente por afinidad o consanguinidad de alguien catalogado PEP.",
      "Su información fue enviada con éxito!":"Su información fue enviada con éxito!",
      "Su mensaje aquí...":"Su mensaje aquí...",
      "Suba Aquí el Archivo":"Suba Aquí el Archivo",
      "Suegro(a)":"Suegro(a)",
      "Tamaño de Campañas":"Tamaño de Campañas",
      "Target":"Target",
      "Telefono Contacto principal en la organización destinataria":"Telefono Contacto principal en la organización destinataria",
      "Telefono de Contacto":"Telefono de Contacto",
      "Termino de Sesión en":"Termino de Sesión en",
      "Tío(a)":"Tío(a)",
      "Tipo Conflicto":"Tipo Conflicto",
      "Tipo de Auspicio":"Tipo de Auspicio",
      "Tipo de Carga":"Tipo de Carga",
      "Tipo de Denuncia":"Tipo de Denuncia",
      "Tipo de Invitación":"Tipo de Invitación",
      "Tipo de relación conmigo":"Tipo de relación conmigo",
      "tipo":"tipo",
      "Tipo":"Tipo",
      "Tipos de Conflicto":"Tipos de Conflicto",
      "Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.":"Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.",
      "Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*":"Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*",
      "Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación.":"Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación.",
      "Transferencia de Valor":"Transferencia de Valor",
      "Transferencias de Valor Pendientes de Aprobación":"Transferencias de Valor Pendientes de Aprobación",
      "Transferencias de Valor":"Transferencias de Valor",
      "Transferencias":"Transferencias",
      "tren":"tren",
      "Turista":"Turista",
      "Ubicación de la comunidad apoyada":"Ubicación de la comunidad apoyada",
      "Ubicación del Beneficiario Potencial":"Ubicación del Beneficiario Potencial",
      "Ultimas Reuniones Pendientes de Aprobación":"Ultimas Reuniones Pendientes de Aprobación",
      "Un posible conflicto de interés puede existir":"Un posible conflicto de interés puede existir",
      "Usar Eticpro":"Usar Eticpro",
      "Usuarios por Area":"Usuarios por Area",
      "Usuarios por Contrato":"Usuarios por Contrato",
      "Usuarios por Perfil":"Usuarios por Perfil",
      "Usuarios por Rut":"Usuarios por Rut",
      "Usuarios":"Usuarios",
      "Valor anticipado de la contribución de La Empresa al desarrollo comunitario (en dinero y en especie)":"Valor anticipado de la contribución de La Empresa al desarrollo comunitario (en dinero y en especie)",
      "Value Transfer":"Value Transfer",
      "Viaje":"Viaje",
      "WhistleBlower":"WhistleBlower",
      "Ya tienes una cuenta?, Ingrese aquí.":"Ya tienes una cuenta?, Ingrese aquí.",
      "Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Empresa.":"Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Empresa.",


    },

br: {

  "¿Cómo se dio cuenta de cuenta de esta situación?":"Como você tomou conhecimento dessa situação?",
  "¿Cuándo ocurrió?":"Quando isso aconteceu?",
  "¿Desde cuándo cree usted que ha estado ocurriendo?":"Há quanto tempo você acha que isso está acontecendo?",
  "¿Dónde ocurrió este incidente o infracción específicamente?":"Onde esse incidente ou violação ocorreu especificamente?",
  "¿Es Ud. Director, Gerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?":"Você é o Diretor, Gerente, Administrador, Diretor Presidente ou Síndico daquele que exige a declaração?",
  "¿Está seguro de salir de Eticpro?":"Você tem certeza sobre deixar a Eticpro?",
  "¿Hay pagos a terceros como consultores u organizaciones para ejecutar la inversión social?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y la diligencia debida de este consultor u organización":"Há pagamentos a terceiros, como consultorias ou organizações, para executar o investimento social? Se a resposta for sim, tenha em mente o preço justo de mercado e a due diligence desse consultor ou organização",
  "¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?":"Você consegue identificar quem (s) cometeu esses atos, detalhar nomes se sim?",
  "¿Qué ocurrió?":"O que aconteceu?",
  "¿Realizo el procedimiento de Diligencia Debida?":"Realizo o procedimento de Due Diligence?",
  "(i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal?":"(i) A entidade anfitriã do evento é de propriedade ou controlada por uma entidade governamental ou outra entidade estatal?",
  "(i) ¿La Contraparte efectúa alguno de los siguientes giros":"(i) A Contraparte faz algum dos seguintes rascunhos?",
  "(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?":"(ii) A Contraparte assinará um contrato e/ou a contraprestação deve ser demonstrada, como consertar um cano, comprar pregos, publicidade, etc.?",
  "(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio":"(ii) Fornecer o(s) nome(s), título(s) e afiliação(ões) do(s) indivíduo(s) que sugeriram o patrocínio",
  "(iii) ¿Habrá otras empresas auspiciando?":"(iii) Haverá outras empresas patrocinando?",
  "(iii) Estimación del Pago Anual superior a 100.000 USD anual?":"(iii) Pagamento anual estimado superior a US$ 100.000 por ano?",
  "(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?":"(iv) Sua organização será mencionada como patrocinadora em materiais do evento, publicações e outros materiais distribuídos relacionados ao evento?",
  "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?":"(iv) A Contraparte representará perante um terceiro ou interagirá em nome da Companhia ou de suas subsidiárias perante qualquer autoridade pública, tais como despachantes aduaneiros, advogados, interlocutor com uma comunidade, organización processadora de licenças perante o Município, etc.?",
  "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?":"(iv) A Contraparte representará perante um terceiro ou interagirá em nome da Companhia ou de suas subsidiárias perante qualquer autoridade pública, tais como despachantes aduaneiros, advogados, interlocutor com uma comunidade, organización processadora de licenças perante o Município, etc.?",
  "(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?":"(iv)(v) Sua organização terá permissão para realizar qualquer forma de promoção ou atividades de marketing nas instalações do evento?",
  "(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros":"(ix) A Contraparte foi sugerida por algum funcionário público? Por exemplo, funcionários da Codelco, Ministérios, Serviços Públicos, Prefeitos, Governadores, entre outros",
  "(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?":"(v) A Contraparte está no grupo que representa 80% do Volume de Negócios anual?",
  "(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.":"(vi) Como será fornecido o patrocínio? Por exemplo, cheque à entidade organizadora, contribuição em espécie, pagamento direto ao fornecedor.",
  "(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Empresa, sus fililales, o algún colaborador de esta?":"(vi) Existe algum conflito de interesses entre a Contraparte e a Empresa, suas afiliadas ou qualquer colaborador da Empresa?",
  "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal ()?":"(vii) A Contraparte está domiciliada em algum país considerado paraíso fiscal ()?",
  "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?":"(vii) A Contraparte está domiciliada em algum país considerado paraíso fiscal?",
  "(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?":"(viii) O país de pagamento da Contraparte é diferente do local do seu domicílio, por exemplo, a organización é ",
  "(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?":"(x) Alguma constatação ao consultar a situação fiscal da contraparte no site do SII?",
  "(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?":"(xi) Constatou na Diretoria do Trabalho a existência de aposentadoria em atraso em relação aos trabalhadores da contraparte?",
  "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde.":"(xii) Você encontrou alguma correspondência de 100% no Compliance Tracker ou informações negativas no Google, em relação à contraparte, seu representante legal ou seus parceiros? Por favor, mantenha algum registro disso abaixo, se aplicável.",
  "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde":"(xii) Você encontrou alguma correspondência de 100% no Compliance Tracker ou informações negativas no Google, em relação à contraparte, seu representante legal ou seus parceiros? Por favor, mantenha algum registro disso abaixo, se aplicável",
  "1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?":"1. Houve algum resultado alarmante ou informação negativa (multas, sentenças, escândalo) da Contraparte (pessoa física ou jurídica) nas buscas realizadas?",
  "1. ¿Tiene conflicto de interés?":"1. Você tem conflito de interesses?",
  "1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?":"1. Em relação à Lei nº 20.393, UK Bribery Act ou FCPA, sua organización implementa um Modelo de Prevenção ao Crime ou outro sistema, como um programa de Compliance na prevenção de crimes como lavagem de dinheiro, anticorrupção, etc., para atender a órgãos reguladores nacionais ou estrangeiros?",
  "1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;":"1.- Isoladamente, ou com outras pessoas com as quais tenha um acordo de ação conjunta, tem poder de voto suficiente para influenciar a gestão da organización;",
  "10. ¿Algún Gerente, ejecutivo de la alta administración o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles":"10. Algum gerente, executivo de alta gerência ou diretor de sua organización atualmente também é uma Pessoa Politicamente Exposta  ou um funcionário público? Entregar detalhes",
  "11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?":"11. A sua organización, ou qualquer organización relacionada (a organización-mãe da sua organización, ou uma subsidiária) foi sujeita a qualquer investigação iniciada com base numa acusação de prática de atos ilícitos relacionados com a responsabilidade penal de entidades jurídicas (locais e/ou internacionais) nos últimos 5 anos ou esteve em listas de sanções internacionais?",
  "12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?":"12. Sua organización ou qualquer funcionário, gerente, dirigente ou diretor, atualmente ou nos últimos 5 anos, está sujeito a uma investigação por qualquer ato ilícito relacionado aos crimes de lavagem de dinheiro, financiamento do terrorismo, suborno de funcionário público nacional ou estrangeiro, recepção, corrupção entre indivíduos, administração injusta, apropriação indébita,  negociação incompatível, poluição das águas, crimes relacionados à proibição de produtos, pesca ilegal de recursos do fundo do mar e processamento e armazenamento de produtos escassos (colapsados ou superexplorados) sem comprovação de origem legal, Lei nº 21.240 sobre Desobservância de Isolamento e Outras Medidas Sanitárias e violação da Lei nº 21.227 que autoriza o acesso ao benefício do Seguro-Desemprego no contexto da crise sanitária causada pela doença Covid-19,  também chamada de Lei de Proteção ao Emprego?",
  "13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?":"13. Os diretores, conselheiros e/ou acionistas de sua organización transmitiram claramente aos funcionários e terceiros com os quais mantêm relações contratuais o compromisso de sua organización com o desenvolvimento legal e ético dos negócios, tolerância zero à corrupção e você transmitiu o conteúdo dos programas e políticas de compliance da organización?",
  "2. ¿Cuál es el tipo de Conflicto?":"2. Qual é o tipo de conflito?",
  "2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)":"2. Se a sua organización possui um Modelo de Prevenção ao Crime, esse certificado está disponível? (Por favor, forneça detalhes)",
  "2.- Sus negocios con la sociedad originan conflictos de interés;":"2.- Suas relações comerciais com a sociedade geram conflitos de interesse;",
  "2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?":"2.Foram encontradas discrepâncias entre as respostas fornecidas pela Contraparte ao abrigo do Questionário DD da Contraparte e a Due Diligence conduzida por si?",
  "3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos":"3. Sua organización possui um Compliance Officer ou um Crime Prevention Officer que gerencia ou garante o bom funcionamento do Modelo de Prevenção ao Crime?",
  "3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o":"3.- Sua administração é influenciada pela organización, se for pessoa jurídica, ou",
  "3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?":"3. Houve algum registro que indique que é necessária uma análise especial por qualquer outro motivo relacionado a algum crime?",
  "4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación.":"4. Sua organización fornece treinamento com relação às infrações incluídas na Lei nº 20.393, UK Bribery Act, FCPA ou outras infrações semelhantes? Forneça detalhes, em termos da frequência dos treinamentos e dos níveis e áreas da sua organización para os quais esse treinamento é feito.",
  "4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.":"4. Detalhe Aqui estão as pessoas que se candidatam ao conflito de interesses declarado.",
  "4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.":"4.- Se, em razão de seu cargo ou posição, estiver em condições de dispor de informações sobre a companhia e seus negócios, que não tenham sido divulgadas publicamente ao mercado, e que sejam capazes de influenciar o preço dos valores mobiliários da companhia.",
  "5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?":"5. Existe treinamento relacionado às infrações incluídas na Lei nº 20.393, UK Bribery Act, FCPA ou outro similar para empreiteiros ou fornecedores que prestam serviços à sua organización?",
  "6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación":"6. Existe um Código de Ética e/ou Manual de Compliance na sua organización? Anexar documentação",
  "7. ¿Su organización tiene un canal de denuncias?":"7. Sua organización tem um canal de denúncias?",
  "8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?":"8. Você ou sua organización representarão sua organización perante terceiros ou perante uma autoridade pública?",
  "9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles":"9. Para estabelecer relações contratuais com terceiros, existe um processo de verificação de antecedentes legais em relação à Lei nº 20.393, UK Bribery Act, FCPA ou se a pessoa jurídica ou seus controladores estão em listas de sanções internacionais? Entregar detalhes",
  "Abuso, Acoso o Discriminación":"Abuso, Assédio ou Discriminação",
  "Acta":"Minutos",
  "Actions":"Ações",
  "Actividad":"Atividade",
  "Acuerdos realizados o N° Acta Reunión":"Acordos feitos ou Atas de Reunião nº",
  "Adjunte Archivos de Cierre Aquí":"Anexar arquivos de fechamento aqui",
  "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo.":"Anexar Minuta de Acordo ou Acordo Formal, 5Mb no máximo.",
  "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo":"Anexar Minuta de Acordo ou Acordo Formal, 5Mb no Máximo",
  "Adjunte Carta de solicitud, 5Mb Máximo":"Anexar Carta de Solicitação, 5Mb no máximo",
  "Adjunte Respaldo de Diligencia Debida del Beneficiario, 5Mb Máximo":"Anexar suporte de diligência do beneficiário, máximo de 5Mb",
  "Administrar mi equipo":"Gerenciar minha equipe",
  "Administrar mi lista Maestra":"Gerenciar minha lista mestra",
  "Administrar mi usuarios":"Gerenciar meus usuários",
  "Administrar mis Clientes":"Gerenciar Meus Clientes",
  "Administrar todas las Denuncias":"Gerenciar todas as reclamações",
  "Administrar todas las Reuniones":"Gerenciar todas as reuniões",
  "aereo":"Aéreo",
  "Agregar Archivos":"Adicionar arquivos",
  "Agregar":"Adicionar",
  "Agregue los Archivos aquí, máximo 5Mb":"Adicionar arquivos aqui, max 5Mb",
  "Campañas enviadas por el team de Compliance Management":"Campanhas enviadas pela equipe de Gestão de Compliance",
  "All Forms":"Todas as formas",
  "All Meetings":"Todas as reuniões",
  "All transferencias":"Todas as transferências",
  "All WhistleBlower":"Todos os denunciantes",
  "Apellidos Contacto":"Sobrenome Contato",
  "Apellidos de Contacto":"Sobrenomes de contato",
  "Apellidos del relacionado":"Sobrenome do parente",
  "Apellidos":"Sobrenome",
  "Aprobada":"Aprovado",
  "Aprobador 1":"Aprovador 1",
  "Aprobador 2":"Aprovador 2",
  "Aprobador":"Aprovador",
  "APRUEBA DILIGENCIA DEBIDA?":"VOCÊ PASSA NA DEVIDA DILIGÊNCIA?",
  "Archivos para Descargar":"Arquivos para download",
  "Archivos Relacionados":"Arquivos relacionados",
  "Area a denunciar":"Área a reportar",
  "Area Contraparte":"Área de Contraparte",
  "Area Denunciada":"Área reportada",
  "Area funcional":"Área Funcional",
  "Area":"Área",
  "ART. 100 - Son relacionadas con una sociedad las siguientes personas":"ART. 100 - São pessoas ligadas a uma organización",
  "Asistentes":"Assistentes",
  "Asunto":"Assunto",
  "Auspicio":"Patrocínio",
  "AUTORIZA?":"AUTORIZAR?",
  "Autorización":"Autorização",
  "Autorizada":"Autorizado",
  "Autorizadas":"Autorizado",
  "Autorizado":"Autorizado",
  "Beneficiario Potencial":"Beneficiário potencial",
  "Bienvenido a Eticpro!":"Bem-vindo à Eticpro!",
  "Bisabuelo(a)":"Bisavôs",
  "Bisnieto(a)":"Bisneto",
  "busauto":"ônibus",
  "Cambiar Contraseña":"Alterar senha",
  "Campaigns":"Campanhas",
  "Campaña de Invitaciones":"Campanha de Convite",
  "Campaña":"Campanha",
  "CAMPAÑA":"CAMPANHA",
  "Campañas enviadas por el team de Compliance Management":"Campanhas enviadas pela equipe de Gestão de Compliance",
  "Campañas":"Campanhas",
  "Cancelar":"Cancelar",
  "Cantidad":"Quantidade",
  "Carga Individual":"Carga Individual",
  "Carga Masiva":"Upload em massa",
  "Cargando...":"Carregando...",
  "Cargo a denunciar":"Cobrar para denunciar",
  "Cargo Beneficiario 1 del Regalo":"Beneficiário Cobrar 1 do Presente",
  "Cargo Beneficiario 1 del viaje":"Beneficiário Cobrar 1 da viagem",
  "Cargo Beneficiario 1":"Taxa do beneficiário 1",
  "Cargo Beneficiario 2 del Regalo":"Cobrança do beneficiário do presente 2",
  "Cargo Beneficiario 2":"Taxa do beneficiário 2",
  "Cargo de Contacto":"Título do contato",
  "Cargo de la Persona de interés":"Posição da Pessoa de Interesse",
  "Cargo Fun.":"Carga divertida.",
  "Cargo Representante que da la comida":"Representante de posição que dá a comida",
  "Cargo Representante que ofrece la comida":"Cargo",
  "Cargo Representante que va en el viaje":"Representante de posição indo na viagem",
  "Cargo":"Carga",
  "Celular":"Celular",
  "Centro de Mensajes":"Central de Mensagens",
  "Charter":"Carta",
  "Chat Denunciante":"Bate-papo de denunciantes",
  "Checklist":"Lista de verificação",
  "Cierre de Investigación":"Encerramento do inquérito",
  "Cierre de Solicitante":"Encerramento do Requerente",
  "Cierre":"Fechamento",
  "Clase de Viaje":"Classe de Viagem",
  "Clases de Viaje":"Lições de Viagem",
  "Clientes":"Clientes",
  "Clients":"Clientes",
  "combinación":"Combinação",
  "Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)":"Comentário adicional a ser adicionado ao e-mail de convite que será enviado aos usuários. (opcional)",
  "Comentario de Cierre":"Considerações finais",
  "Comentario":"Comentário",
  "Comentarios Adicionales":"Comentários adicionais",
  "Comentarios":"Comentários",
  "Comida":"Comida",
  "Compartida":"Compartilhado",
  "Compartir resultado a email, separado por comas":"Compartilhar resultado por e-mail, separado por vírgulas",
  "Con Mitigación":"Com mitigação",
  "Configuración":"Configurações",
  "Confirmo que la información anterior es verdadera y completa.":"Confirmo que as informações acima são verdadeiras e completas.",
  "Conflicto de Interés Recibidos":"Conflito de Interesses Recebido",
  "Conflicto de Interés":"Conflito de interesses",
  "Conflicto":"Conflito",
  "Consulta":"Consulta",
  "Contacto":"Contato",
  "Contraparte que se hará la inteligencia debida":"Contrapartida de que será feita a inteligência necessária",
  "Contrapartes":"Contrapartidas",
  "Contraté a un empleado que es pariente mío hasta el tercer grado.":"Contratei um funcionário que tem parentesco comigo até o terceiro grau.",
  "Contrato":"Contrato",
  "Costo del auspicio propuesto":"Custo do Patrocínio Proposto",
  "Costo por persona de cualquier otro gasto previsible de este viaje":"Custo por pessoa de quaisquer outras despesas previsíveis desta viagem",
  "Costo por persona de invitación a evento":"Custo por pessoa de convite para evento",
  "Costo por persona de la comida en UF":"Custo por pessoa de alimentação na UF",
  "Costo por persona de la comida":"Custo por pessoa de alimentação",
  "Costo por persona del (los) regalo(s)":"Custo por pessoa do(s) presente(s)",
  "Costo por persona del costo de comida más alto esperado":"Custo por pessoa do maior custo esperado de alimentação",
  "Costo por persona del viaje propuesto":"Custo por pessoa da viagem proposta",
  "Costo por persona":"Custo por pessoa",
  "Costo total del evento":"Custo Total do Evento",
  "Creada por":"Criado por",
  "Creada":"Criado",
  "Creado por":"Criado por",
  "Creado":"Criado",
  "Nueva Cliente":"Criar Cliente",
  "Nueva Contraparte":"Criar contraparte",
  "Nueva Declaración":"Criar declaração",
  "Nueva Denuncia":"Criar relatório",
  "Nueva Diligencia Debida":"Criar Due Diligence",
  "Nueva Evaluacion de Riesgo":"Criar avaliação de risco",
  "Nueva Invitación":"Criar convite",
  "Nuevo Mensaje":"Criar mensagem",
  "Nueva Nueva Contraparte":"Criar nova contraparte",
  "Nueva Reunión":"Criar reunião",
  "Nueva Transferencia Minería":"Criar transferência de mineração",
  "Nueva Transferencia Salud":"Criar transferência de saúde",
  "Nueva Transferencia":"Criar transferência",
  "Nuevo Usuario":"Criar usuário",
  "Cualquier otra cosa de valor a un Funcionario Público":"Qualquer outra coisa de valor para um funcionário público",
  "Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*":"Quando meu cliente/provedor trabalha com sócios que são parentes próximos meus até o terceiro grau.*",
  "Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.":"Quando meu cliente/fornecedor trabalha diretamente com organizações nas quais eu ou um familiar próximo tem participação.",
  "Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son: Padre, madre, cónyuge, hijo(a), suegro(a), yerno, nuera, abuelo(a), nieto(a), hermano(a), cuñado(a), bisabuelo(a), bisnieto(a), tío(a), sobrino(a).":"Quando você contrata um funcionário que tem parentesco comigo até o terceiro grau. Os parentes até o terceiro grau por consanguinidade e afinidade são: Quando é contratado empregado que seja meu parente até o terceiro grau. São parentes até o terceiro grau por sangue e afinidade: Pai, mãe, cônjuge, filho, sogro, genro, nora, avô), neto, irmão, cunhado, bisavô, bisneto, tio, sobrinho.",
  "Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.":"Quando me relaciono por afinidade ou consanguinidade com alguém classificado como PEP. Pessoas Politicamente Expostas, como, por exemplo, o Presidente da República, Ministros e Subsecretários, Prefeitos, Governadores, Prefeitos, Seremis, Embaixadores, Chefes das Forças Armadas, Carabineros e PDI, membros do Conselho de Administração ou Chefe do Executivo de Empresas Estatais, Ministros do Tribunal, Ministros de Tribunais de Livre Concorrência, Compras Públicas ou Constitucionais, Procuradoria Nacional ou Regional, membros do Conselho de Administração de Partidos Políticos,  candidatos a presidente, senador, deputado ou prefeito",
  "Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.":"Quando uma vantagem pessoal pode influenciar meu julgamento e tomada de decisão objetiva para a Organização.",
  "Cuestionario de Debida Diligencia":"Questionário de Due Diligence",
  "Cuñado(a)":"Cunhado",
  "Datos Cargados, verifique aquí si estan correctos, (todos con su email) y luego haga click en botón enviar":"Dados enviados, verifique aqui se estão corretos, (todos com seu e-mail) e depois clique no botão enviar",
  "Datos Principales":"Principais fatos",
  "Decisión Comité Ético":"Decisão do Comitê de Ética",
  "Decisión Compliance Manager":"Gerente de Conformidade de Decisões",
  "Decisión":"Decisão",
  "Declaración de Conflicto de Interés":"Declaração de Conflito de Interesses",
  "Declaraciones Pendientes de Aprobación":"Declarações pendentes de aprovação",
  "Declaraciones por Area":"Declarações por Área",
  "Declaraciones":"Declarações",
  "Declaradas":"Declarado",
  "Declarado":"Declarado",
  "Declarados":"Declarado",
  "Declarantes":"Declarantes",
  "Declarar Conflicto de Interés":"Declarar Conflito de Interesses",
  "declared":"declarado",
  "delayDays":"Dias-atraso",
  "Delitos de responsabilidad penal de persona jurídica":"Infracções de responsabilidade penal das pessoas colectivas",
  "Demora (Días)":"Atraso (Dias)",
  "Demora Promedio":"Atraso médio",
  "Demora":"Atraso",
  "Demora(Días)":"Atraso (Dias)",
  "Denuncia":"Denúncia",
  "Denunciado":"Denunciado",
  "Denuncias Anónimas Pendientes de Investigación":"Denúncias anônimas pendentes de investigação",
  "Denuncias":"Reclamações",
  "Descargar Reporte":"Baixar relatório",
  "Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos.":"Descreva brevemente o que está sendo solicitado para aprovação e a finalidade da Transferência de Valor das despesas propostas.",
  "Describa otros gastos previsibles":"Descreva outras despesas previsíveis",
  "Destino":"Destino",
  "Detalle las acciones":"Detalhar as ações",
  "Detalle las razones de Investigar o desistir de la investigación":"Detalhar as razões para investigar ou retirar a investigação",
  "Detalle las razones de la Autorizacion/Rechazo":"Detalhar os motivos da Autorização/Recusa",
  "Detalle":"Detalhe",
  "Días":"Dias",
  "Diligencia Debida":"Due Diligence",
  "Diligencias Debidas":"Due Diligence",
  "Direccion":"Endereço",
  "Domicilio Empresa Contraparte":"Endereço da Empresa da Contraparte",
  "Domicilio Legal":"Endereço Legal",
  "Donación":"Doação",
  "Donde Ocurrio":"Onde aconteceu",
  "Duración de la transferencia de valor":"Duração da transferência de valor",
  "Duracion":"Duração",
  "Ejecutiva":"Executivo",
  "El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación":"El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación",
  "Eliminar de Campaña":"Eliminar de Campaña",
  "Email Aviso":"Email Aviso",
  "Email Contacto":"Email Contacto",
  "Email de Contacto":"Email de Contacto",
  "Email del Representante Legal":"Email del Representante Legal",
  "Email Representante Legal":"Email Representante Legal",
  "Email":"Email",
  "emailcontacto":"emailcontacto",
  "Empleador u organización":"Empleador u organización",
  "Empleados Presentes":"Empleados Presentes",
  "Empresa Denunciada":"Empresa Denunciada",
  "Empresa":"Empresa",
  "Empresas Denunciadas":"Empresas Denunciadas",
  "Empresas":"Empresas",
  "En Evaluacion":"En Evaluacion",
  "Enviada":"Enviada",
  "Enviada a":"Enviada a",
  "Enviadas para evaluación":"Enviadas para evaluación",
  "Enviar":"Enviar",
  "Envio exitoso":"Envio exitoso",
  "Envío exitoso":"Envío exitoso",
  "Estado Solicitud":"Estado Solicitud",
  "estado":"estado",
  "Estado":"Estado",
  "Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ":"Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ",
  "Evaluadas":"Evaluadas",
  "Evento":"Evento",
  "Explique el(los) conflicto(s) de interés aquí": "Explique o(s) conflito(s) de interesse aqui",
  "FAQ": "FAQ",
  "Fecha de entrega de la Inversión Social": "Data de entrega do Investimento Social",
  "Fecha de Inicio": "Data de início",
  "Fecha de la Reunión": "Data da reunião",
  "Fecha de Viaje": "Data da viagem",
  "Fecha en que se efectuará o se realizó la comida": "Data em que a refeição será realizada ou foi realizada",
  "Fecha Solicitado": "Data solicitada",
  "Forms": "Formulários",
  "Formulario de Declaración": "Formulário de Declaração",
  "Formulario de Evaluacion de Riesgo": "Formulário de Avaliação de Risco",
  "Formulario de Evaluacion": "Ficha de Avaliação",
  "Funcionario del Gobierno?": "Funcionário do governo?",
  "Giro": "Indústria",
  "Guardar Archivos": "Salvar arquivos",
  "Guardar Cambios": "Salvar alterações",
  "He leído y comprendido la información anterior.": "Li e entendi as informações acima.",
  "Hermano(a)": "Irmão/Irmã",
  "Hijo(a)": "Filho/Filha",
  "Horas": "Horas",
  "ID": "ID",
  "Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte": "Incapacidade de enviar, receber ou completar o Questionário DD enviado à Contraparte",
  "Imprimir en PDF": "Imprimir PDF",
  "Indique si ha hablado con alguien de esto o denunciado el hecho": "Indique se você conversou com alguém sobre isso ou relatou o incidente.",
  "Indique si la organización es sin fin de lucro": "Indique se a organização não tem fins lucrativos",
  "Industria": "Indústria",
  "Ingrese los mails a excluir de la campaña(Separados por comas)": "Definir todos os e-mails para excluir (separados por vírgula)",
  "Ingrese sus Datos": "Insira suas informações",
  "Inicio": "Início",
  "Iniciodeclare": "Declarar início",
  "Inversión Social": "Investimento Social",
  "Investigador": "Investigador",
  "Investigar":"Investigar",
  "Invitacion a Viaje":"Convite de Viagem",
  "Invitaciones enviadas por el team":"Convites enviados pela equipe",
  "Invitaciones":"Convites",
  "La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que":"A Comissão pode estabelecer, por meio de regra geral, que qualquer pessoa singular ou jurídica que, em razão de relações de propriedade, administração, parentesco, responsabilidade ou subordinação, dê origem à presunção de que",
  "La institución a la que pertenece el Beneficiario, ¿Lo permite?":"A instituição a que pertence o Beneficiário permite?",
  "Las entidades del grupo empresarial al que pertenece la sociedad;":"As entidades do grupo empresarial a que a organización pertence",
  "Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;":"Pessoas jurídicas que possuam, em relação à organización, a condição de controladora, co-ligada, subsidiária ou colega, de acordo com as definições contidas na Lei nº 18.046;",
  "lastName":"sobrenome",
  "Libre competencia":"Livre concorrência",
  "Lista Maestra":"Lista Mestra",
  "Log de Usuarios":"Registro do usuário",
  "Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.":"Conselheiros, gerentes, administradores, principais executivos ou liquidatários que tenham interesse ou participem de negociações que levem à celebração de transação com partes relacionadas da companhia, devem informar imediatamente o conselho de administração ou quem ele designar. Aqueles que descumprirem essa obrigação responderão solidariamente pelos danos causados pela transação à organización e a seus acionistas.",
  "Lugar de Trabajo":"Local de trabalho",
  "Lugar, Dirección o medio":"Local, Endereço ou Meio",
  "Lugar":"Lugar",
  "Madre":"Mãe",
  "Mail Contacto principal en la organización destinataria":"Contato principal de email na organização de destinatários",
  "Meetings":"Reuniões",
  "Mensaje Enviado":"Mensagem enviada",
  "Mensaje":"Mensagem",
  "MI PERFIL":"MEU PERFIL",
  "Minutos":"Minutos",
  "Mis Declaraciones":"Minhas declarações",
  "Mis Mensajes":"Minhas Mensagens",
  "Mis reuniones con personas de influencia o funcionarios públicos":"Meus encontros com pessoas influentes ou funcionários públicos",
  "MIS REUNIONES":"MEUS ENCONTROS",
  "MITIGACION":"MITIGAÇÃO",
  "Mitigación":"Mitigação",
  "Modos de viaje para el evento":"Modos de viagem para o evento",
  "Nacionalidad Contacto":"Contato de nacionalidade",
  "Nacionalidad Representante Legal":"Representante Legal da Nacionalidade",
  "Nacionalidad":"Nacionalidade",
  "name":"nome",
  "Nieto(a)":"Neto",
  "No Aplica":"Não aplicável",
  "No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.":"Uma pessoa não será considerada relacionada à sociedade pelo simples fato de participar de até 5% do capital ou 5% do capital com direito a voto, no caso de sociedade anônima por ações, ou se for apenas um empregado não gerencial dessa organización.",
  "No":"Não",
  "Nombre Beneficiario 1 del Regalo":"Nome do Beneficiário 1 do Presente",
  "Nombre Beneficiario 1 del viaje":"Nome do beneficiário 1 da viagem",
  "Nombre Beneficiario 1":"Nome do beneficiário 1",
  "Nombre Beneficiario 2 del Regalo":"Nome do beneficiário 2 do presente",
  "Nombre Beneficiario 2":"Nome do beneficiário 2",
  "Nombre Completo Representante Legal de la contraparte":"Nome Completo",
  "Nombre Contacto principal en la organización destinataria":"Nome",
  "Nombre de Fantasía de la organización":"Nome da Empresa Fantasy",
  "Nombre de la Campaña":"Nome da campanha",
  "Nombre de la comunidad apoyada":"Nome da Comunidade Suportada",
  "Nombre de la Donación":"Nome da doação",
  "Nombre de la entidad que organiza el evento":"Nome da entidade organizadora do evento",
  "Nombre de la Inversión Social (Proyecto de desarrollo comunitario)":"Nome do Investimento Social (Projeto de Desenvolvimento Comunitário)",
  "Nombre de la organización destinataria (con/sin fin de lucro)":"Nome da organização beneficiária (com fins lucrativos)",
  "Nombre de la organización destinataria (si fuese aplicable)":"Nome da organização beneficiária (se aplicável)",
  "Nombre de la Persona de interés":"Nome da Pessoa de Interesse",
  "Nombre del relacionado o Razon Social":"Nome da pessoa relacionada ou nome da organización",
  "Nombre del Representante Legal":"Nome do Representante Legal",
  "Nombre Representante que ofrece la comida":"Nome",
  "Nombre Representante que va en el viaje":"Nome do representante indo na viagem",
  "Nombre Representante(s) que va en el viaje":"Nome do(s) representante(s) que viajam",
  "Nombre":"Nome",
  "Nombres Contacto":"Nomes de contato",
  "Nombres de Contacto":"Nomes de contato",
  "Nombres de otros participantes a la reunión":"Nomes de outros participantes da reunião",
  "Nombres":"Nomes",
  "Número de participantes en la reunión":"Número de participantes na reunião",
  "Número total de asistentes propuestos":"Número total de participantes propostos",
  "Número total de viajeros propuestos":"Número total de viajantes propostos",
  "Ocurrió":"Aconteceu",
  "Operacion en la que tengo interés o participo":"Transação na qual estou interessado ou envolvido",
  "Organismo Público o Comunidad":"Órgão Público ou Comunidade",
  "Organización Beneficiario 1 del Regalo":"Organização de Destinatários de Presentes 1",
  "Organización Beneficiario 1 del viaje":"Organização Beneficiária de Viagens 1",
  "Organización Beneficiario 1":"Organização Beneficiária 1",
  "Organización Beneficiario 2 del Regalo":"Organização de Destinatários de Presentes 2",
  "Organización Beneficiario 2":"Organização Beneficiária 2",
  "Organización Contraparte":"Organização de Contrapartida",
  "Organización donde está el conflicto de interés":"Organização onde está o conflito de interesses",
  "Organización en la que trabaja":"Organização para a qual você trabalha",
  "Organización que requiere la declaración de conflicto de interés":"Organização que exige declaração de conflito de interesses",
  "Organizacion":"Organização",
  "Organización":"Organização",
  "organization":"organização",
  "Otra información que complemente lo anterior":"Outras informações que complementam o acima",
  "Otro":"Outros",
  "Otros":"Outros",
  "Padre":"Pai",
  "Pago/Recepción":"Pagamento/Recebimento",
  "Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045":"Cumprir a obrigação estabelecida no art. 147, § 1º, da Lei 18.046, em relação ao art. 100 da Lei 18.045",
  "Para su Acción":"Para sua ação",
  "Participantes":"Participantes",
  "password":"senha",
  "Pendiente":"Pendente",
  "Perfil":"Perfil",
  "Personas Enviadas":"Pessoas enviadas",
  "Por Fecha":"Por data",
  "Por lo tanto, declaro el posible conflicto de interés aquí.":"Portanto, declaro o potencial conflito de interesses aqui.",
  "Porfavor deje su comentario aquí, guarde su reporte en Agregar Archivos más abajo, y haga click en enviar para finalizar la investigación y enviarla a Comité Ético para que resuelva":"Por favor, deixe seu comentário aqui, salve seu relatório em Adicionar arquivos abaixo, e clique em enviar para finalizar a pesquisa e enviá-la ao Comitê de Ética para resolução.",
  "Presupuesto general del viaje propuesto":"Orçamento Geral da Viagem Proposta",
  "Primera":"Primeiro",
  "Procesando datos...":"Processamento de dados...",
  "Propósito de la Reunión":"Objetivo do Encontro",
  "Propósito del evento":"Objetivo do evento",
  "Propósito del viaje propuesto":"Objetivo da Viagem Proposta",
  "Proposito":"Propósito",
  "Protección de confidencialidad de información":"Proteção da confidencialidade das informações",
  "Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias.":"Você pode escolher outra opção no menu ou fechar esta janela se não tiver escolha, obrigado.",
  "Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y":"Aqueles que sejam diretores, gerentes, administradores, principais executivos ou liquidatários da companhia, e seus cônjuges ou parentes até o segundo grau de consanguinidade, bem como qualquer entidade controlada, diretamente ou por intermédio de outras pessoas, por qualquer deles, e",
  "Razón de Contratación de la Contraparte":"Motivo da Contraparte para a Contratação",
  "Razón de negocio para el auspicio":"Motivo do Patrocínio",
  "Razón del (los) regalo(s)":"Razão do(s) presente(s)",
  "Realizó el procedimiento de Diligencia Debida?":"Você realizou o procedimento de Due Diligence?",
  "Recibida":"Recebido",
  "Recibidos":"Recebido",
  "Recordé la contraseña":"Lembrei-me da senha",
  "Recuperar Contraseña":"Recuperar senha",
  "Regalo":"Presente",
  "Registrese Aquí":"Inscreva-se aqui",
  "Relación con la organización que denuncia":"Relacionamento com a organización denunciante",
  "Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva":"Índice de vantagem pessoal pode influenciar meu julgamento e tomada de decisão objetiva",
  "Relaciones con stakeholders":"Relacionamento com Partes Interessadas",
  "Repositorio":"Repositório",
  "Respuesta de Campañas":"Resposta da campanha",
  "Resultado Evaluación":"Resultado da avaliação",
  "Resultado Investigación":"Resultado da pesquisa",
  "Reunión Persona de Interés o Comunidad":"Pessoa de Interesse ou Reunião da Comunidade",
  "Reuniones con Personas de Interés":"Reuniões com Pessoas de Interesse",
  "Reuniones por Area":"Reuniões por Área",
  "Reuniones por Organización":"Reuniões por Organização",
  "Reuniones":"Reuniões",
  "Riesgo Alto":"Alto risco",
  "Risk Assessment":"Avaliação de Risco",
  "Risk":"Risco",
  "Robo o fraude o mal uso recursos":"Roubo, fraude ou uso indevido de recursos",
  "Rol":"Papel",
  "Rut Contacto":"Ruth Contato",
  "Rut de Contacto":"Fale com a Ruth",
  "Rut Empresa":"Companhia Ruth",
  "Rut Empresa":"Número de identificação fiscal da organización",
  "Rut en caso de ser fiscalizador":"Rute no caso de ser inspetora",
  "Rut Fun.":"Rute Divertida.",
  "Rut":"Rute",
  "rutcontacto":"Rutcontacto",
  "Salir":"Saia",
  "Score":"Pontuação",
  "Se proveedrá alojamiento?":"Será providenciado alojamento?",
  "Seleccionar Clases de Viaje":"Selecione Classes de Viagem",
  "Seleccionar Contraparte":"Selecionar contraparte",
  "Seleccionar Modo de Viaje":"Selecionar Modo de Viagem",
  "Seleccionar Organización a denunciar":"Selecione a organização a ser denunciada",
  "Seleccionar Organización que requiere la declaración":"Selecione a organização que requer declaração",
  "Seleccionar Transferencia de Valor":"Selecione Transferência de Valor",
  "Seleccione Investigador":"Selecionar Pesquisador",
  "Señale cual es la inversión y su valor. Incluya Presupuesto o cotización.":"Aponte qual é o investimento e seu valor. Inclua orçamento ou cotação.",
  "Sesiones":"Sessões",
  "Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?":"Se o patrocínio foi feito por meio de pagamento direto ao fornecedor, ele o fez pelo preço justo de mercado?",
  "Si es combinación, favor describir":"Se for uma combinação, por favor, descreva",
  "Si hay invitación o regalo u otra transferencia de valor indicar valor":"Se houver convite ou presente ou outra transferência de valor, indique valor",
  "Si igual o más de 100.000 USD año":"Se igual ou superior a 100.000 USD por ano",
  "Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche":"Se a resposta for sim, forneça detalhes sobre onde, quantas noites e custo por noite.",
  "Si la respuesta es Sí, porfavor explique":"Se a resposta for sim, por favor, explique",
  "Si menos de 100.000 USD año":"Se menos de 100.000 USD por ano",
  "Si":"Sim",
  "Sí":"Sim",
  "Sin Respuesta":"Sem resposta",
  "Sitio web":"Site",
  "Sito Web":"Site",
  "Sobrino(a)":"Sobrinho(s)",
  "Solicitada por":"Solicitado por",
  "Solicitado por":"Solicitado por",
  "Solicitante":"Requerente",
  "Soy pariente por afinidad o consanguinidad de alguien catalogado PEP.":"Sou parente por afinidade ou consanguinidade com alguém classificado como PEP.",
  "Su información fue enviada con éxito!":"Suas informações foram enviadas com sucesso!",
  "Su mensaje aquí...":"Sua mensagem aqui...",
  "Suba Aquí el Archivo":"Carregue o arquivo aqui",
  "Suegro(a)":"Sogro",
  "Tamaño de Campañas":"Tamanho da campanha",
  "Target":"Alvo",
  "Telefono Contacto principal en la organización destinataria":"Contato telefônico principal na organização destinatária",
  "Telefono de Contacto":"Telefone de contato",
  "Termino de Sesión en":"Fim da sessão em",
  "Tío(a)":"Tio",
  "Tipo Conflicto":"Tipo de conflito",
  "Tipo de Auspicio":"Tipo de Patrocínio",
  "Tipo de Carga":"Tipo de Carga",
  "Tipo de Denuncia":"Tipo de Reclamação",
  "Tipo de Invitación":"Tipo de Convite",
  "Tipo de relación conmigo":"Tipo de relacionamento comigo",
  "tipo":"Tipo",
  "Tipo":"Tipo",
  "Tipos de Conflicto":"Tipos de conflito",
  "Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.":"Qualquer pessoa que, sozinha ou com outras com quem tenha um acordo de ação conjunta, pode nomear pelo menos um membro da administração da organización ou controlar 10% ou mais do capital ou do capital votante no caso de uma sociedade anônima.",
  "Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*":"Trabalho com parceiros que são familiares próximos meus até a terceira série.*",
  "Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación.":"Trabalho diretamente com empresas ou organizações nas quais eu ou um familiar próximo tem participação.",
  "Transferencia de Valor":"Transferência de Valor",
  "Transferencias de Valor Pendientes de Aprobación":"Transferências de Valor Pendentes de Aprovação",
  "Transferencias de Valor":"Transferências de Valor",
  "Transferencias":"Traslados",
  "tren":"trem",
  "Turista":"Turista",
  "Ubicación de la comunidad apoyada":"Localização da comunidade apoiada",
  "Ubicación del Beneficiario Potencial":"Localização do Beneficiário Potencial",
  "Ultimas Reuniones Pendientes de Aprobación":"Últimas reuniões pendentes de aprovação",
  "Un posible conflicto de interés puede existir":"Pode existir um potencial conflito de interesses",
  "Usar Eticpro":"Usando o Eticpro",
  "Usuarios por Area":"Usuários por Área",
  "Usuarios por Contrato":"Usuários contratados",
  "Usuarios por Perfil":"Usuários por Perfil",
  "Usuarios por Rut":"Usuários por Ruth",
  "Usuarios":"Usuários",
  "Valor anticipado de la contribución de La Empresa al desarrollo comunitario (en dinero y en especie)":"Valor Antecipado da Contribuição da Empresa para o Desenvolvimento Comunitário (em Dinheiro e em Espécie)",
  "Value Transfer":"Transferência de Valor",
  "Viaje":"Viagem",
  "Cerrar Sesión":"Sair",
  "Ya tienes una cuenta?, Ingrese aquí.":"Já tem uma conta, faça login aqui.",
  "Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Empresa.":"Entendo que um conflito de interesses pode ocorrer quando meus interesses privados (pessoais, sociais ou financeiros) interferem de alguma forma em minhas responsabilidades, ou em minha capacidade de agir no melhor interesse da Empresa.",
  "Evaluada":"Avaliado",


},

ch: {

  "¿Cómo se dio cuenta de cuenta de esta situación?":"你是怎么知道这种情况的？",
  "¿Cuándo ocurrió?": "什么时候发生的？",
  "¿Desde cuándo cree usted que ha estado ocurriendo?": "你认为它从什么时候开始发生？",
  "¿Dónde ocurrió este incidente o infracción específicamente?": "这起事件或违规行为具体发生在哪里？",
  "¿Es Ud. Director, Gerente, Administrador, Ejecutivo Principal o Liquidador de quien requiere la declaración?": "您是需要声明的实体的董事、经理、管理人、主要执行官还是清算人？",
  "¿Está seguro de salir de Eticpro?": "你确定要退出Eticpro吗？",
  "¿Hay pagos a terceros como consultores u organizaciones para ejecutar la inversión social?. De ser una respuesta Afirmativa, tener presente el justo precio de mercado, y la diligencia debida de este consultor u organización": "是否向第三方（如顾问或组织）支付费用以进行社会投资？如果答案是肯定的，请考虑该顾问或组织的公平市场价格和尽职调查。",
  "¿Puede identificar quien(es) han cometido estos hechos, detalle nombres si es así?": "你能确定是谁犯下了这些行为吗？如果可能，请提供姓名。",
  "¿Qué ocurrió?": "发生什么事了？",
  "¿Realizo el procedimiento de Diligencia Debida?": "你执行了尽职调查程序吗？",
  "(i) ¿Es la entidad que organiza el evento de propiedad de, o controlada por, una entidad gubernamental u otra entidad estatal?": "（一） 组织活动的组织是否由政府实体或其他国家实体拥有或控制？",
  "(i) ¿La Contraparte efectúa alguno de los siguientes giros":"（i） 交易对手是否出具下列任何汇票？",
  "(ii) ¿La Contraparte firmará un contrato y/o la contraprestación que realizará es demostrable, como por ejemplo arreglar una cañería, comprar clavos, realizó publicidad etc?": "（ii） 交易对手是否会签订合同和/或对价是否可证明，例如修理管道、购买钉子、进行广告宣传等？",
  "(ii) Entregue el nombre(s), cargo(s) y vinculación(es) del (los) individuo(s) que sugirieron entregue el auspicio": "（二） 提供建议赞助的个人的姓名、职位和隶属关系",
  "(iii) ¿Habrá otras empresas auspiciando?": "（iii） 其他公司会赞助吗？",
  "(iii) Estimación del Pago Anual superior a 100.000 USD anual?": "（iii） 估计每年支付超过100,000美元？",
  "(iv) ¿Será mencionado su organización como auspiciador en los materiales del evento, publicaciones y otros materiales distribuidos relativos al evento?": "（四） 在与活动有关的活动材料、出版物和其他分发材料中，是否会提及贵组织为赞助商？",
  "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc.?": "（iv） 交易对手是否会在第三方或公共机构（例如海关代理人、律师、社区发言人、公司向市政当局办理许可证等）面前代表公司或其子公司进行互动？",
  "(iv)¿La Contraparte representará ante un tercero o interactuará en representación de La Empresa o sus filiales ante alguna autoridad pública, como sería el caso de agentes de aduanas, abogados, interlocutor frente a una comunidad, organización tramitadora de permisos ante la Municipalidad etc?": "（iv） 交易对手是否会在第三方或公共机构（例如海关代理人、律师、社区发言人、公司向市政当局办理许可证等）面前代表公司或其子公司进行互动？",
  "(iv)(v) ¿Se permitirá su organización que realice cualquier forma de promoción o actividades de marketing en las instalaciones del evento?": "（iv）（v） 贵组织是否被允许在活动设施内从事任何形式的促销或营销活动？",
  "(ix) ¿La Contraparte ha sido sugerida por algún funcionario público? Por ejemplo empleados de Codelco, Ministerios, Servicios Públicos, Intendencias, Gobernaciones, entre otros": "（九） 是否有任何公职人员建议对方？例如，Codelco、各部委、公共服务、意图、州长等的雇员",
  "(v) ¿La Contraparte se encuentra en el grupo que representa acumulado el 80% de Facturación anual?": "（v） 交易对手是否属于累计占年收入80%的集团？",
  "(vi) ¿Cómo se proveerá el auspicio? Por ejemplo, cheque a la entidad organizadora, contribución en especies, pago directo al proveedor.": "（六） 如何提供赞助？例如，向组织实体核对、实物捐助、直接向提供者付款。",
  "(vi) ¿Existe algún conflicto de interés entre la Contraparte y La Empresa, sus fililales, o algún colaborador de esta?": "（vi） 交易对手与本公司、其子公司或本公司的任何合作者之间是否存在任何利益冲突？",
  "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal ()?": "（七） 交易对手方是否在任何被视为避税天堂的国家有住所（）？",
  "(vii) ¿Tiene la Contraparte domicilio en algún país considerado paraíso fiscal?": "（七） 交易对手方是否在任何被视为避税天堂的国家有住所（）？",
  "(viii) ¿Es el país de pago de la Contraparte distinto al de la ubicación de su domicilio, por ejemplo la organización se encuentra en Argentina y pide los depositos de sus pagos en una cuenta de un banco en Bahamas?":"（viii） 交易对手的付款国与其住所地不同，例如公司是",
  "(x) ¿Algún hallazgo al consultar la situación tributaria de la contraparte en el sitio del SII?":"（x） 在SII网站上咨询交易对手的税务状况时，有何发现？",
  "(xi) ¿Encontró en la Dirección del Trabajo la existencia de Mora Previsional respecto de los trabajadores de la contraparte?":"（习）你有没有在劳工委员会发现对方工人存在逾期退休？",
  "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde.":"（xii） 您是否在合规跟踪器上发现任何与交易对手、其法定代表人或合作伙伴有关的 100% 匹配或在 Google 上发现任何负面信息？如果适用，请在下面保留一些记录。",
  "(xii) ¿Encontró alguna coincidencia del 100% en el Compliance Tracker o información negativa en google, en relación con la contraparte, su representante legal o sus socios? por favor guarde algun registro de esta aqui abajo si corresponde":"（xii） 您是否在合规跟踪器上发现任何与交易对手、其法定代表人或合作伙伴有关的 100% 匹配或在 Google 上发现任何负面信息？如果适用，请在下面保留一些记录",
  "1. ¿Se verificó algún resultado alarmante o información negativa (multas, juicios, escándalo) de la Contraparte (persona natural o jurídica) en la búsqueda realizada?":"1. 在进行的搜查中，交易对手方（个人或法人实体）是否有任何令人震惊的结果或负面信息（罚款、判刑、丑闻）？",
  "1. ¿Tiene conflicto de interés?":"1. 你有利益冲突吗？",
  "1. En relación con la Ley N°20.393, UK Bribery Act o FCPA ¿En su organización existe implementado un Modelo de Prevención de Delitos u otro sistema como programa de Compliance sobre prevención de delitos como lavado de activos, anticorrupción etc., para dar cumplimiento a cuerpos normativos nacionales o extranjeros?":"1. 关于第20,393号法律、英国《反贿赂法》或《反海外腐败法》，贵公司是否实施了预防犯罪模式或其他系统，例如预防洗钱、反腐败等犯罪的合规计划，为国家或外国监管机构服务？",
  "1.- Por sí sola, o con otras con quienes tenga acuerdo de actuación conjunta, tiene poder de voto suficiente para influir en la gestión de la sociedad;":"1.- 单独或与有联合行动协议的其他人一起，它有足够的投票权来影响公司的管理;",
  "10. ¿Algún Gerente, ejecutivo de la alta administración o Director de su organización es en la actualidad también Persona Expuesta Políticamente  o un funcionario público?, ¿Se ha presentado esta situación en el pasado (hasta 1 año atrás)? Entregar detalles":"10. 贵公司的任何经理、高级管理人员或董事目前是否也是政治公众人物（PEP）或公职人员？提供详细信息",
  "11. ¿Su organización, o alguna sociedad relacionada (la matriz de su organización, o una filial) se ha(n) visto sujeta(s) a alguna investigación iniciada en base a una acusación por comisión de actos ilícitos relacionados a la responsabilidad penal de las personas jurídicas (local y/o internacional) dentro de los últimos 5 años o ha estado en listas de sancionados internacionales?":"11. 贵公司或任何相关公司（贵公司的母公司或子公司）在过去5年中是否因与法人实体（当地和/或国际）的刑事责任有关的不法行为指控而受到任何调查，或者是否被列入国际制裁名单？",
  "12. ¿Se encuentra su organización o algún trabajador, gerente, ejecutivo de la alta administración o director, actualmente o en los últimos 5 años sujeto a una investigación por cualquier acto ilícito relacionado con los delitos de lavado de activos, financiamiento del terrorismo, cohecho de funcionario público nacional o extranjero, receptación, corrupción entre particulares, administración desleal, apropiación indebida, negociación incompatible, contaminación de aguas, delitos relacionados con la veda de productos, pesca ilegal de recursos del fondo marino, y procesamiento y almacenamiento de productos escasos (colapsados o sobreexplotados) sin acreditar un origen legal, la ley N°21.240 de Inobservancia de Aislamiento y Otras Medidas Sanitarias e infracción a la ley N° 21.227 que faculta el acceso a prestaciones del Seguro de Cesantía en el contexto de la crisis sanitaria producida por la enfermedad Covid-19, llamada también ley de Protección al Empleo?":"12. 贵公司或任何雇员、经理、高级职员或董事，目前或在过去 5 年内，因与洗钱、资助恐怖主义、贿赂国内外公职人员、收受、个人之间的腐败、管理不公平、挪用公款、交易不相容、水污染、与禁止产品有关的犯罪等犯罪有关的任何非法行为而受到调查， 在没有合法来源证明的情况下非法捕捞海底资源和加工和储存稀缺（坍塌或过度开发）产品，关于不遵守隔离和其他卫生措施的第21,240号法律，以及违反第21,227号法律，该法律授权在Covid-19疾病引起的健康危机的情况下获得失业保险金，  也叫《就业保护法》？",
  "13. ¿En su organización los administradores, directores y/o accionistas han transmitido de manera clara a los empleados y a terceros con los que tengan relaciones contractuales el compromiso de su organización con el desarrollo legal y ético del negocio, de cero tolerancia ante la corrupción y ha transmitido el contenido de los programas y políticas de cumplimiento que tenga la organización?":"13. 贵公司的管理人员、董事和/或股东是否向与其有合同关系的员工和第三方明确传达了贵公司对商业法律和道德发展的承诺，对腐败的零容忍，以及贵公司合规计划和政策的内容？",
  "2. ¿Cuál es el tipo de Conflicto?":"2. 冲突的类型是什么？",
  "2. En el caso de contar su organización con un Modelo de Prevención de Delitos, ¿Se encuentra este certificado? (Favor suministrar detalles)":"2. 如果贵公司有预防犯罪模式，是否可以获得此证书？（请提供详细资料）",
  "2.- Sus negocios con la sociedad originan conflictos de interés;":"2.-他们与社会的商业关系产生利益冲突;",
  "2.¿Se encontró alguna discrepancia entre las respuestas entregadas por la Contraparte según el Cuestionario DD de la Contraparte y la Debida Diligencia realizada por usted?":"2.在交易对手的DD问卷中提供的回答与你进行的尽职调查之间是否有任何差异？",
  "3. ¿En su organización existe un Oficial de Cumplimiento o un Encargado de Prevención de Delitos que gestione o vele por el correcto funcionamiento del Modelo de Prevención de Delitos":"3. 贵公司是否有合规干事或预防犯罪干事来管理或确保预防犯罪模式的正常运作？",
  "3.- Su gestión es influenciada por la sociedad, si se trata de una persona jurídica, o":"3.- 其管理受公司影响，如果它是一个法人实体，或者",
  "3.¿Se encontró algún antecedente que indique que se requiere de un análisis especial por cualquier otra razón relacionada a algún delito?":"3. 是否有任何记录表明，由于与犯罪有关的任何其他原因需要进行特别审查？",
  "4. ¿En su organización realizan capacitaciones con respecto a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares? Entregar detalles, en términos de la frecuencia de las capacitaciones y los niveles y áreas de su organización a las que se les hace esta capacitación.":"4. 贵公司是否就第20.393号法案、英国《反贿赂法》、《反海外腐败法》或其他类似违规行为提供培训？提供详细信息，包括培训的频率以及贵公司进行培训的级别和领域。",
  "4. Detalle Aquí la(s) personas que aplican al conflicto de interés declarado.":"4. 细节：以下是申请申报利益冲突的人。",
  "4.- Si por su cargo o posición está en situación de disponer de información de la sociedad y de sus negocios, que no haya sido divulgada públicamente al mercado, y que sea capaz de influir en la cotización de los valores de la sociedad.":"4.- 如果由于他的职位或职位，他能够获得有关公司及其业务的信息，这些信息尚未向市场公开披露，并且能够影响公司证券的价格。",
  "5. ¿Existen capacitaciones relacionados a los delitos incluidos en la Ley N°20.393, UK Bribery Act, FCPA u otras similares para los contratistas o proveedores que les prestan servicios / proveen un bien a su organización?":"5. 是否为贵公司提供服务的承包商或供应商提供与第20,393号法案、英国《反贿赂法》、《反海外腐败法》或类似法案中所列违规行为有关的培训？",
  "6. ¿Existe un Código de Ética y/o Manual de Cumplimiento en su compañía? Adjunte documentación":"6. 贵公司是否有《道德守则和/或合规手册》？附上文档",
  "7. ¿Su organización tiene un canal de denuncias?":"7. 贵公司有举报渠道吗？",
  "8. ¿Usted o su organización representará a su organización ante terceros o ante una autoridad pública?":"8. 你或你的公司是否会在第三方或公共当局面前代表你的公司？",
  "9. Para establecer relaciones contractuales con terceros, ¿Existe un proceso de chequeo de antecedentes legales en relación a la ley N°20.393, UK Bribery Act, FCPA o si está la persona jurídica o sus controladores en listas de sancionados internacionales? Entregar detalles":"9. 为了与第三方建立合同关系，是否有与联合王国《反贿赂法》第20393号法律、《反海外腐败法》有关的法律背景调查程序，或者法律实体或其控制人是否在国际制裁名单上？提供详细信息",
  "Abuso, Acoso o Discriminación":"虐待、骚扰或歧视",
  "Acta":"分钟",
  "Actions":"行动",
  "Actividad":"活动",
  "Acuerdos realizados o N° Acta Reunión":"达成的协议或会议记录没有。",
  "Adjunte Archivos de Cierre Aquí":"在此处附加关闭文件",
  "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo.":"附上协议草案或正式协议，最大 5Mb。",
  "Adjunte Borrador de Convenio o Acuerdo Formal, 5Mb Máximo":"附上协议草案或正式协议，最大 5Mb",
  "Adjunte Carta de solicitud, 5Mb Máximo":"附加请求信，最大 5Mb",
  "Adjunte Respaldo de Diligencia Debida del Beneficiario, 5Mb Máximo":"附上收款人尽职调查支持，最大5Mb",
  "Administrar mi equipo":"管理我的团队",
  "Administrar mi lista Maestra":"管理我的主列表",
  "Administrar mi usuarios":"管理我的用户",
  "Administrar mis Clientes":"管理我的客户",
  "Administrar todas las Denuncias":"管理所有投诉",
  "Administrar todas las Reuniones":"管理所有会议",
  "aereo":"航拍",
  "Agregar Archivos":"添加文件",
  "Agregar":"添加",
  "Agregue los Archivos aquí, máximo 5Mb":"在此处添加文件，最大 5Mb",
  "Campañas enviadas por el team de Compliance Management":"合规管理团队发送的营销活动",
  "All Forms":"所有形式",
  "All Meetings":"所有会议",
  "All transferencias":"所有下载",
  "All WhistleBlower":"所有举报人",
  "Apellidos Contacto":"姓氏联系人",
  "Apellidos de Contacto":"联系人姓氏",
  "Apellidos del relacionado":"亲戚的姓氏",
  "Apellidos":"姓氏",
  "Aprobada":"已批准",
  "Aprobador 1":"审批者 1",
  "Aprobador 2":"审批者 2",
  "Aprobador":"审批者",
  "APRUEBA DILIGENCIA DEBIDA?":"你通过尽职调查了吗？",
  "Archivos para Descargar":"可下载文件",
  "Archivos Relacionados":"相关文件",
  "Area a denunciar":"报告区域",
  "Area Contraparte":"交易对手专区",
  "Area Denunciada":"报告区域",
  "Area funcional":"功能区",
  "Area":"区域",
  "ART. 100 - Son relacionadas con una sociedad las siguientes personas":"第100条——与公司有联系的人",
  "Asistentes":"助手",
  "Asunto":"主题",
  "Auspicio":"赞助",
  "AUTORIZA?":"授权？",
  "Autorización":"授权",
  "Autorizada":"已授权",
  "Autorizadas":"已授权",
  "Autorizado":"已授权",
  "Beneficiario Potencial":"潜在受益人",
  "Bienvenido a Eticpro!":"欢迎来到Eticpro！",
  "Bisabuelo(a)":"曾祖父母",
  "Bisnieto(a)":"曾孙",
  "busauto":"巴士",
  "Cambiar Contraseña":"更改密码",
  "Campaigns":"广告系列",
  "Campaña de Invitaciones":"邀请活动",
  "Campaña":"活动",
  "CAMPAÑA":"活动",
  "Campañas enviadas por el team de Compliance Management":"合规管理团队发送的营销活动",
  "Campañas":"广告系列",
  "Cancelar":"取消",
  "Cantidad":"数量",
  "Carga Individual":"个人负载",
  "Carga Masiva":"批量上传",
  "Cargando...":"加载中...",
  "Cargo a denunciar":"收费举报",
  "Cargo Beneficiario 1 del Regalo":"收件人领取 1 份礼物",
  "Cargo Beneficiario 1 del viaje":"旅行的受益人费用 1",
  "Cargo Beneficiario 1":"收款人费用 1",
  "Cargo Beneficiario 2 del Regalo":"这 2 的受益人的集合",
  "Cargo Beneficiario 2":"收款人费用 2",
  "Cargo de Contacto":"联系人职务",
  "Cargo de la Persona de interés":"利害关系人的立场",
  "Cargo Fun.":"好玩。",
  "Cargo Representante que da la comida":"提供食物的职位代表",
  "Cargo Representante que ofrece la comida":"职称",
  "Cargo Representante que va en el viaje":"出差的职位代表",
  "Cargo":"货物",
  "Celular":"移动",
  "Centro de Mensajes":"消息中心",
  "Charter":"信",
  "Chat Denunciante":"举报人聊天",
  "Checklist":"清单",
  "Cierre de Investigación":"调查结束",
  "Cierre de Solicitante":"申请人的终止",
  "Cierre":"结束语",
  "Clase de Viaje":"舱位等级",
  "Clases de Viaje":"旅行课程",
  "Clientes":"客户",
  "Clients":"客户",
  "combinación":"组合",
  "Comentario adicional a agregar al Email de invitación que se enviará a los usuarios.(opcional)":"要添加到将发送给用户的邀请电子邮件中的其他评论。（可选）",
  "Comentario de Cierre":"最后的想法",
  "Comentario":"评论",
  "Comentarios Adicionales":"补充意见",
  "Comentarios":"评论",
  "Comida":"食物",
  "Compartida":"共享",
  "Compartir resultado a email, separado por comas":"通过电子邮件共享结果，以逗号分隔",
  "Con Mitigación":"有缓解措施",
  "Configuración":"设置",
  "Confirmo que la información anterior es verdadera y completa.":"我确认上述信息是真实和完整的。",
  "Conflicto de Interés Recibidos":"收到的利益冲突",
  "Conflicto de Interés":"利益冲突",
  "Conflicto":"冲突",
  "Consulta":"查询",
  "Contacto":"联系方式",
  "Contraparte que se hará la inteligencia debida":"对应物，将进行必要的情报",
  "Contrapartes":"权衡",
  "Contraté a un empleado que es pariente mío hasta el tercer grado.":"我雇用了一名与我有三级关系的员工。",
  "Contrato":"合同",
  "Costo del auspicio propuesto":"拟议赞助费用",
  "Costo por persona de cualquier otro gasto previsible de este viaje":"此行程的任何其他可预见费用的人均费用",
  "Costo por persona de invitación a evento":"活动邀请的每人费用",
  "Costo por persona de la comida en UF":"用友中的人均食物成本",
  "Costo por persona de la comida":"人均食物成本",
  "Costo por persona del (los) regalo(s)":"每人赠送的费用",
  "Costo por persona del costo de comida más alto esperado":"最高预期食品成本的人均成本",
  "Costo por persona del viaje propuesto":"拟议旅行的每人费用",
  "Costo por persona":"人均费用",
  "Costo total del evento":"活动总成本",
  "Creada por":"创建者",
  "Creada":"创建",
  "Creado por":"创建者",
  "Creado":"创建",
  "Nueva Cliente":"创造客户",
  "Nueva Contraparte":"创建交易对手",
  "Nueva Declaración":"创建声明",
  "Nueva Denuncia":"创建报告",
  "Nueva Diligencia Debida":"创建尽职调查",
  "Nueva Evaluacion de Riesgo":"创建风险评估",
  "Nueva Invitación":"创建邀请",
  "Nuevo Mensaje":"创建消息",
  "Nueva Nueva Contraparte":"创建新的交易对手",
  "Nueva Reunión":"创建会议",
  "Nueva Transferencia Minería":"创建挖矿转账",
  "Nueva Transferencia Salud":"创建健康转移",
  "Nueva Transferencia":"创建转账",
  "Nuevo Usuario":"创建用户",
  "Cualquier otra cosa de valor a un Funcionario Público":"对公职人员有其他价值的东西",
  "Cuando mi cliente/proveedor trabaja con socios que son familiares cercanos míos hasta el tercer grado.*":"当我的客户/提供者与我的近亲合作时，他们的近亲最高可达三级。",
  "Cuando mi cliente/proveedor trabaja directamente con Organizaciónes en las que yo o un miembro cercano de mi familia tiene una participación.":"当我的客户/供应商直接与我或亲密家庭成员有利益关系的组织合作时。",
  "Cuando se contrata a un empleado que es pariente mío hasta el tercer grado. Pariente hasta el tercer grado por consanguinidad y afinidad son":"当你雇用一个与我有三级关系的员工时。血缘关系和亲缘关系最高三等的亲戚是",
  "Cuando soy pariente por afinidad o consanguinidad de alguien catalogado PEP. Personas Políticamente Expuestas, como serían por ejemplo, Presidente de la República, Ministros y subsecretarios, Intendentes, Gobernadores, Alcaldes, Seremis, Embajadores, Jefes de las Fuerzas Armadas, Carabineros y PDI, miembro del Directorio o Ejecutivo Principal de Empresas del Estado, Ministros de Corte, Ministros de Tribunales de Libre Competencia, Contratación Pública o Constitucional, Fiscal Nacional o Regional, miembros de la Directiva de partidos Políticos, candidatos a Presidente, Senador, Diputado o Alcalde.":"当我与被归类为 PEP 的人有亲缘关系或血缘关系时。政治公众人物，例如共和国总统、部长和副部长、市长、州长、市长、塞雷米斯、大使、武装部队首长、宪兵和警察处、国有企业董事会成员或首席执行官、法院部长、自由竞争部长、公共或宪法采购法院、国家或地区检察官办公室、政党董事会成员， 总统、参议员、国会议员或市长候选人",
  "Cuando una ventaja personal podría influir en mi juicio y en la toma de decisión objetiva para la Organización.":"当个人优势可以影响我对组织的判断和客观决策时。",
  "Cuestionario de Debida Diligencia":"尽职调查问卷",
  "Cuñado(a)":"姐夫",
  "Datos Cargados, verifique aquí si estan correctos, (todos con su email) y luego haga click en botón enviar":"数据已发送，请在此处检查是否正确，（全部使用您的电子邮件），然后单击提交按钮",
  "Datos Principales":"关键事实",
  "Decisión Comité Ético":"伦理委员会的决定",
  "Decisión Compliance Manager":"决策合规经理",
  "Decisión":"决定",
  "Declaración de Conflicto de Interés":"利益冲突声明",
  "Declaraciones Pendientes de Aprobación":"待批准的声明",
  "Declaraciones por Area":"按地区分列的申报",
  "Declaraciones":"声明",
  "Declaradas":"宣布",
  "Declarado":"宣布",
  "Declarados":"宣布",
  "Declarantes":"申报人",
  "Declarar Conflicto de Interés":"声明利益冲突",
  "declared":"宣布",
  "delayDays":"延迟天数",
  "Delitos de responsabilidad penal de persona jurídica":"法人的刑事责任罪",
  "Demora (Días)":"延迟（天）",
  "Demora Promedio":"中等延迟",
  "Demora":"延迟",
  "Demora(Días)":"延迟（天）",
  "Denuncia":"谴责",
  "Denunciado":"谴责",
  "Denuncias Anónimas Pendientes de Investigación":"有待调查的匿名举报",
  "Denuncias":"投诉",
  "Descargar Reporte":"下载报告",
  "Describa brevemente qué está siendo solicitado para aprobación y el propósito de la Transferencia de Valor de los gastos propuestos.":"简要说明要求批准的内容以及拟议支出的价值转移的目的。",
  "Describa otros gastos previsibles":"描述其他可预见的费用",
  "Destino":"《缘分》",
  "Detalle las acciones":"深入到操作",
  "Detalle las razones de Investigar o desistir de la investigación":"详细说明调查或撤回调查的原因",
  "Detalle las razones de la Autorización/Rechazo":"详细说明授权/拒绝的原因",
  "Detalle":"详细",
  "Días":"天",
  "Diligencia Debida":"尽职",
  "Diligencias Debidas":"尽职",
  "Direccion":"地址",
  "Domicilio Empresa Contraparte":"交易对手公司地址",
  "Domicilio Legal":"法定地址",
  "Donación":"捐赠",
  "Donde Ocurrio":"发生的地方",
  "Duración de la transferencia de valor":"价值转移的持续时间",
  "Duracion":"持续时间",
  "Ejecutiva":"执行官",
  "El ART. 147 N°1 establece que una sociedad anónima abierta sólo podrá celebrar operaciones con partes relacionadas cuando tengan por objeto contribuir al interés social, se ajusten en precio, términos y condiciones a aquellas que prevalezcan en el mercado al tiempo de su aprobación, y cumplan con los requisitos y procedimientos que se señalan a continuación":"创建尽职调查",
  "Eliminar de Campaña":"创建风险评估",
  "Email Aviso":"创建邀请",
  "Email Contacto":"创建消息",
  "Email de Contacto":"创建新的交易对手",
  "Email del Representante Legal":"创建会议",
  "Email Representante Legal":"创建挖矿转账",
  "Email":"创建健康转移",
  "emailcontacto":"创建转账",
  "Empleador u organización":"创建用户",
  "Empleados Presentes":"对公职人员有其他价值的东西",
  "Empresa Denunciada":"当我的客户/提供者与我的近亲合作时，他们的近亲最高可达三级。",
  "Empresa":"当我的客户/供应商直接与我或亲密家庭成员有利益关系的组织合作时。",
  "Empresas Denunciadas":"当你雇用一个与我有三级关系的员工时。血缘关系和亲缘关系最高三等的亲戚是",
  "Empresas":"当我与被归类为 PEP 的人有亲缘关系或血缘关系时。政治公众人物，例如共和国总统、部长和副部长、市长、州长、市长、塞雷米斯、大使、武装部队首长、宪兵和警察处、国有企业董事会成员或首席执行官、法院部长、自由竞争部长、公共或宪法采购法院、国家或地区检察官办公室、政党董事会成员， 总统、参议员、国会议员或市长候选人",
  "En Evaluacion":"当个人优势可以影响我对组织的判断和客观决策时。",
  "Enviada":"尽职调查问卷",
  "Enviada a":"姐夫",
  "Enviadas para evaluación":"数据已发送，请在此处检查是否正确，（全部使用您的电子邮件），然后单击提交按钮",
  "Enviar":"关键事实",
  "Envio exitoso":"伦理委员会的决定",
  "Envío exitoso":"决策合规经理",
  "Estado Solicitud":"决定",
  "estado":"利益冲突声明",
  "Estado":"待批准的声明",
  "Estimado Declarante, Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045, es su deber comprender lo siguiente ":"按地区分列的申报",
  "Evaluadas":"声明",
  "Evento":"事件",
  "Explique el(los) conflicto(s) de interés aquí": "请在这里解释利益冲突",
  "FAQ": "常见问题",
  "Fecha de entrega de la Inversión Social":" 《社会投资交割日》",
  "Fecha de Inicio": "开始日期",
  "Fecha de la Reunión": "会议日期",
  "Fecha de Viaje": "旅行日期",
  "Fecha en que se efectuará o se realizó la comida": "用餐或用餐的日期",
  "Fecha Solicitado": "请求日期",
  "Forms": "表格",
  "Formulario de Declaración":" 《申报表》",
  "Formulario de Evaluacion de Riesgo": "《风险评估表》",
  "Formulario de Evaluacion":" 《评估表》",
  "Funcionario del Gobierno?": "政府官员？",
  "Giro": "行业",
  "Guardar Archivos": "保存文件",
  "Guardar Cambios": "保存更改",
  "He leído y comprendido la información anterior.": "我已阅读并理解上述信息。",
  "Hermano(a)": "兄弟/姐妹",
  "Hijo(a)": "儿子/女儿",
  "Horas": "小时",
  "ID": "ID",
  "Imposibilidad de enviar, recibir o completar el Cuestionario DD enviado a la Contraparte":"无法发送、接收或完成发送给交易对手的DD问卷",
  "Imprimir en PDF": "打印 PDF",
  "Indique si ha hablado con alguien de esto o denunciado el hecho": "表明你是否与任何人谈过这件事或报告了这一事件。",
  "Indique si la organización es sin fin de lucro": "指明组织是否为非营利性组织",
  "Industria": "行业",
  "Ingrese los mails a excluir de la campaña(Separados por comas)": "设置要删除的所有电子邮件（以逗号分隔）",
  "Ingrese sus Datos": "输入您的信息",
  "Inicio": "家",
  "Iniciodeclare": "声明开始",
  "Inversión Social": "社会投资",
  "Investigador": "调查员",
  "Investigar":"调查",
  "Invitacion a Viaje":"《旅行邀请函》",
  "Invitaciones enviadas por el team":"团队发出的邀请",
  "Invitaciones":"邀请函",
  "La Comisión podrá establecer mediante norma de carácter general, que es relacionada a una sociedad toda persona natural o jurídica que por relaciones patrimoniales, de administración, de parentesco, de responsabilidad o de subordinación, haga presumir que":"委员会可以通过一般规则确定，任何自然人或法人，如果由于所有权、管理、亲属关系、责任关系或从属关系而推定",
  "La institución a la que pertenece el Beneficiario, ¿Lo permite?":"受益人所属的机构是否允许？",
  "Las entidades del grupo empresarial al que pertenece la sociedad;":"公司所属业务集团的实体",
  "Las personas jurídicas que tengan, respecto de la sociedad, la calidad de matriz, coligante, filial o coligada, en conformidad a las definiciones contenidas en la ley N° 18.046;":"根据第18,046号法律的定义，与公司有关的具有控制人、共同关联公司、子公司或同事条件的法人实体;",
  "lastName":"姓氏",
  "Libre competencia":"自由竞争",
  "Lista Maestra":"主列表",
  "Log de Usuarios":"用户注册",
  "Los directores, gerentes, administradores, ejecutivos principales o liquidadores que tengan interés o participen en negociaciones conducentes a la realización de una operación con partes relacionadas de la sociedad anónima, deberán informar inmediatamente de ello al directorio o a quien éste designe. Quienes incumplan esta obligación serán solidariamente responsables de los perjuicios que la operación ocasionare a la sociedad y sus accionistas.":"董事、经理、行政人员、首席执行官或清算人，如与公司关联方有利害关系或参与导致执行交易的谈判，必须立即通知董事会或其指定的任何人。不履行这一义务的，将对交易给公司及其股东造成的损害承担连带责任。",
  "Lugar de Trabajo":"工作场所",
  "Lugar, Dirección o medio":"地点、地址或媒介",
  "Lugar":"地点",
  "Madre":"母亲",
  "Mail Contacto principal en la organización destinataria":"收件人组织中的主要电子邮件联系人",
  "Meetings":"会议",
  "Mensaje Enviado":"消息已发送",
  "Mensaje":"留言",
  "MI PERFIL":"我的个人资料",
  "Minutos":"分钟",
  "Mis Declaraciones":"我的陈述",
  "Mis Mensajes":"我的消息",
  "Mis reuniones con personas de influencia o funcionarios públicos":"我与有影响力的人或公职人员的会面",
  "MIS REUNIONES":"《我的邂逅》",
  "MITIGACION":"缓解",
  "Mitigación":"缓解",
  "Modos de viaje para el evento":"活动的出行模式",
  "Nacionalidad Contacto":"国籍联系人",
  "Nacionalidad Representante Legal":"国籍法定代表人",
  "Nacionalidad":"国籍",
  "name":"名称",
  "Nieto(a)":"孙子",
  "No Aplica":"不适用",
  "No se considerará relacionada a la sociedad una persona por el sólo hecho de participar hasta en un 5% del capital o 5% del capital con derecho a voto si se tratare de una sociedad por acciones, o si sólo es empleado no directivo de esa sociedad.":"在股份公司的情况下，任何人不得仅仅因为参与最多 5% 的资本或 5% 的有表决权的资本这一事实而被视为与公司有关系，或者如果他只是该公司的非管理人员。",
  "No":"否",
  "Nombre Beneficiario 1 del Regalo":"礼物接收者姓名 1",
  "Nombre Beneficiario 1 del viaje":"旅行受益人姓名 1",
  "Nombre Beneficiario 1":"收款人姓名 1",
  "Nombre Beneficiario 2 del Regalo":"礼物接收者姓名 2",
  "Nombre Beneficiario 2":"收款人姓名 2",
  "Nombre Completo Representante Legal de la contraparte":"全名",
  "Nombre Contacto principal en la organización destinataria":"名称",
  "Nombre de Fantasía de la organización":"幻想公司名称",
  "Nombre de la Campaña":"广告系列名称",
  "Nombre de la comunidad apoyada":"支持的社区名称",
  "Nombre de la Donación":"「捐款名称」",
  "Nombre de la entidad que organiza el evento":"活动组织者名称",
  "Nombre de la Inversión Social (Proyecto de desarrollo comunitario)":"社会投资（社区发展项目）名称",
  "Nombre de la organización destinataria (con/sin fin de lucro)":"（营利性）受益组织的名称",
  "Nombre de la organización destinataria (si fuese aplicable)":"受惠机构名称（如适用）",
  "Nombre de la Persona de interés":"相关人员姓名",
  "Nombre del relacionado o Razon Social":"相关人员的姓名或公司名称",
  "Nombre del Representante Legal":"法定代表人姓名",
  "Nombre Representante que ofrece la comida":"名称",
  "Nombre Representante que va en el viaje":"出差代表的姓名",
  "Nombre Representante(s) que va en el viaje":"旅行代表姓名",
  "Nombre":"名称",
  "Nombres Contacto":"联系人姓名",
  "Nombres de Contacto":"联系人姓名",
  "Nombres de otros participantes a la reunión":"其他会议参与者的姓名",
  "Nombres":"名称",
  "Número de participantes en la reunión":"会议参与者人数",
  "Número total de asistentes propuestos":"拟议参与者总数",
  "Número total de viajeros propuestos":"拟议旅行者总数",
  "Ocurrió":"它发生了",
  "Operacion en la que tengo interés o participo":"我感兴趣或参与的交易",
  "Organismo Público o Comunidad":"公共机构或社区",
  "Organización Beneficiario 1 del Regalo":"礼品接收者组织 1",
  "Organización Beneficiario 1 del viaje":"受益人旅行组织1",
  "Organización Beneficiario 1":"受益机构1",
  "Organización Beneficiario 2 del Regalo":"礼物接收者组织 2",
  "Organización Beneficiario 2":"受益机构2",
  "Organización Contraparte":"对应组织",
  "Organización donde está el conflicto de interés":"存在利益冲突的组织",
  "Organización en la que trabaja":"您为之工作的组织",
  "Organización que requiere la declaración de conflicto de interés":"要求申报利益冲突的组织",
  "Organizacion":"组织",
  "Organización":"组织",
  "organization":"组织",
  "Otra información que complemente lo anterior":"补充上述内容的其他信息",
  "Otro":"其他",
  "Otros":"其他",
  "Padre":"父亲",
  "Pago/Recepción":"付款/收据",
  "Para dar cumplimiento a la obligación establecida en el art. 147 N° 1 de la ley 18.046, en relación con el art. 100 de la LEY 18.045":"遵守第18,046号法律第147条第1款与第18,045号法律第100条有关的义务",
  "Para su Acción":"为了你的行动",
  "Participantes":"参与者",
  "password":"密码",
  "Pendiente":"待定",
  "Perfil":"个人资料",
  "Personas Enviadas":"派人",
  "Por Fecha":"按日期",
  "Por lo tanto, declaro el posible conflicto de interés aquí.":"因此，我在这里宣布潜在的利益冲突。",
  "Porfavor deje su comentario aquí, guarde su reporte en Agregar Archivos más abajo, y haga click en enviar para finalizar la investigación y enviarla a Comité Ético para que resuelva":"请在这里留下您的评论，将您的报告保存在下面的”添加文件“中，然后单击提交以完成研究并将其发送给伦理委员会进行解决。",  "Presupuesto general del viaje propuesto":"拟议旅行的总预算",
  "Primera":"第一",
  "Procesando datos...":"数据处理...",
  "Propósito de la Reunión":"会议目的",
  "Propósito del evento":"活动目的",
  "Propósito del viaje propuesto":"拟议旅行的目的",
  "Proposito":"目的",
  "Protección de confidencialidad de información":"保护信息的机密性",
  "Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias.":"您可以从菜单中选择其他选项，或者如果您没有选择，请关闭此窗口，谢谢。",
  "Quienes sean directores, gerentes, administradores, ejecutivos principales o liquidadores de la sociedad, y sus cónyuges o sus parientes hasta el segundo grado de consanguinidad, así como toda entidad controlada, directamente o a través de otras personas, por cualquiera de ellos, y":"公司董事、经理、行政人员、首席官员或清盘人，及其配偶或亲属，直至二等血亲，以及由其中任何一方直接或通过其他人控制的任何实体，以及",
  "Razón de Contratación de la Contraparte":"交易对手的聘用理由",
  "Razón de negocio para el auspicio":"赞助理由",
  "Razón del (los) regalo(s)":"赠送理由",
  "Realizó el procedimiento de Diligencia Debida?":"你执行了尽职调查程序吗？",
  "Recibida":"收到",
  "Recibidos":"收到",
  "Recordé la contraseña":"我记得密码",
  "Recuperar Contraseña":"找回密码",
  "Regalo":"现在",
  "Registrese Aquí":"在这里注册",
  "Relación con la organización que denuncia":"与举报人公司的关系",
  "Relacion ventaja personal podría influir en mi juicio y en la toma de decisión objetiva":"个人优势指数可以影响我的判断和客观决策",
  "Relaciones con stakeholders":"利益相关者关系",
  "Repositorio":"存储库",
  "Respuesta de Campañas":"活动响应",
  "Resultado Evaluación":"评估结果",
  "Resultado Investigación":"搜索结果",
  "Reunión Persona de Interés o Comunidad":"相关人员或社区会议",
  "Reuniones con Personas de Interés":"与感兴趣的人会面",
  "Reuniones por Area":"按地区划分的会议",
  "Reuniones por Organización":"按组织分列的会议",
  "Reuniones":"会议",
  "Riesgo Alto":"高风险",
  "Risk Assessment":"风险评估",
  "Risk":"风险",
  "Robo o fraude o mal uso recursos":"盗窃、欺诈或滥用资源",
  "Rol":"纸",
  "Rut Contacto":"露丝接触",
  "Rut de Contacto":"和露丝谈谈",
  "Rut Empresa":"露丝公司",
  "Rut Empresa":"公司税务识别号",
  "Rut en caso de ser fiscalizador":"露丝在担任检查员的情况下",
  "Rut Fun.":"露丝·芬。",
  "Rut":"露丝",
  "rutcontacto":"Rutcontacto",
  "Salir":"滚出去",
  "Score":"标点符号",
  "Se proveedrá alojamiento?":"会提供住宿吗？",
  "Seleccionar Clases de Viaje":"选择舱位",
  "Seleccionar Contraparte":"选择交易对手",
  "Seleccionar Modo de Viaje":"选择旅行模式",
  "Seleccionar Organización a denunciar":"选择要举报的组织",
  "Seleccionar Organización que requiere la declaración":"选择需要申报的组织",
  "Seleccionar Transferencia de Valor":"「选择转账」",
  "Seleccione Investigador":"选择研究员",
  "Señale cual es la inversión y su valor. Incluya Presupuesto o cotización.":"指出投资是什么及其价值。包括引号或引号。",
  "Sesiones":"会话",
  "Si el auspicio fue proveido a través de pago directo al proveedor, ¿Lo efectuo al justo precio de mercado?":"如果赞助是通过直接支付给供应商完成的，他是否以公平的市场价格进行赞助？",
  "Si es combinación, favor describir":"如果是组合，请描述",
  "Si hay invitación o regalo u otra transferencia de valor indicar valor":"如果有邀请或礼物或其他价值转移，请注明价值",
  "Si igual o más de 100.000 USD año":"如果等于或大于每年 100,000 美元",
  "Si la respuesta es Sí, favor entregue detalles en relación a dónde, cuántas noches y costo por noche":"如果答案是肯定的，请提供地点、住宿晚数和每晚费用的详细信息。",
  "Si la respuesta es Sí, porfavor explique":"如果答案是肯定的，请解释",
  "Si menos de 100.000 USD año":"如果每年少于 100,000 美元",
  "Si":"是",
  "Sí":"是",
  "Sin Respuesta":"没有回应",
  "Sitio web":"网站",
  "Sito Web":"网站",
  "Sobrino(a)":"侄子（任）",
  "Solicitada por":"请求者",
  "Solicitado por":"请求者",
  "Solicitante":"申请人",
  "Soy pariente por afinidad o consanguinidad de alguien catalogado PEP.":"我与被归类为 PEP 的人有亲缘关系或血缘关系。",
  "Su información fue enviada con éxito!":"您的信息已成功提交！",
  "Su mensaje aquí...":"你的信息在这里......",
  "Suba Aquí el Archivo":"在此处上传文件",
  "Suegro(a)":"岳父",
  "Tamaño de Campañas":"广告系列尺寸",
  "Target":"目标",
  "Telefono Contacto principal en la organización destinataria":"收件人组织中的主要电话联系人",
  "Telefono de Contacto":"联系电话",
  "Termino de Sesión en":"会话结束",
  "Tío(a)":"舅舅",
  "Tipo Conflicto":"冲突类型",
  "Tipo de Auspicio":"赞助类型",
  "Tipo de Carga":"负载类型",
  "Tipo de Denuncia":"投诉类型",
  "Tipo de Invitación":"邀请类型",
  "Tipo de relación conmigo":"与我的关系类型",
  "tipo":"赞",
  "Tipo":"赞",
  "Tipos de Conflicto":"冲突类型",
  "Toda persona que, por sí sola o con otras con que tenga acuerdo de actuación conjunta, pueda designar al menos un miembro de la administración de la sociedad o controle un 10% o más del capital o del capital con derecho a voto si se tratare de una sociedad por acciones.":"任何人单独或与其他人有共同股份协议，可以任命至少一名公司管理层成员，或控制公司10%或以上的资本或表决权资本。",
  "Trabajo con socios que son familiares cercanos míos hasta el tercer grado.*":"我和伙伴一起工作，他们是我的亲密家庭成员，直到三年级。",
  "Trabajo directamente con Empresas u Organizaciones en las que yo o un miembro cercano de mi familia tiene una participación.":"我直接与我或近亲有利益关系的公司或组织合作。",
  "Transferencia de Valor":"价值转移",
  "Transferencias de Valor Pendientes de Aprobación":"待审批的价值转移",
  "Transferencias de Valor":"价值转移",
  "Transferencias":"转让",
  "tren":"火车",
  "Turista":"游客",
  "Ubicación de la comunidad apoyada":"支持的社区位置",
  "Ubicación del Beneficiario Potencial":"潜在受益人的位置",
  "Ultimas Reuniones Pendientes de Aprobación":"等待批准的最后一次会议",
  "Un posible conflicto de interés puede existir":"可能存在潜在的利益冲突",
  "Usar Eticpro":"使用Eticpro",
  "Usuarios por Area":"按地区划分的用户",
  "Usuarios por Contrato":"签约用户",
  "Usuarios por Perfil":"用户（按配置文件）",
  "Usuarios por Rut":"露丝的用户",
  "Usuarios":"用户",
  "Valor anticipado de la contribución de La Empresa al desarrollo comunitario (en dinero y en especie)":"公司对社区发展的贡献的预期价值（现金和实物）",
  "Value Transfer":"价值转移",
  "Viaje":"旅行",
  "WhistleBlower":"告密者",
  "Ya tienes una cuenta?, Ingrese aquí.":"您已经有一个帐户，请在此处登录。",
  "Yo entiendo que un conflicto de intereses puede ocurrir cuando mis intereses privados (personales, sociales o financieros) interfieren de alguna manera con mis responsabilidades, o mi capacidad de actuar en el mejor interés para la Empresa.":"我明白，当我的私人利益（个人、社会或财务）以任何方式干扰我的职责或我为公司最佳利益行事的能力时，可能会发生利益冲突。",

},

  };
  
  export default translations;
  
import { Box, IconButton, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';


const Alldecl = ({ }) => {
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  // Rename one of the variables to something different, e.g., filteredSearchData
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };

  let filteredData = data;

  const styles = {
    // Define the rounded corners style for the box
  
    roundedBox: {
      width: "60%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }; 

filteredData = data

.filter((row) => {
  // Apply the search filter based on all fields in the row, including user object properties
  const searchInputLower = searchInput.toLowerCase();
  for (const key in row) {
    if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
      if (row[key].toLowerCase().includes(searchInputLower)) {
        return true; // Return true if any field contains the search input
      }
    }
  }
  // Also check user object properties
  for (const key in row.user) {
    if (row.user.hasOwnProperty(key) && typeof row.user[key] === 'string') {
      if (row.user[key].toLowerCase().includes(searchInputLower)) {
        return true; // Return true if any user object property contains the search input
      }
    }
  }
  return false; // Return false if no field contains the search input
});

  useEffect(() => {
    fetchData(); // Remove idcontrato from the fetchData call
  }, [idcontrato]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}formepd`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      setData(dataWithFormattedDate); // Set the fetched data to the state here
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  


      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}formepd/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  

  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}formepd/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
    }
  };
  

  

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
  
    const filteredData = data.filter((row) => {
      const { firstName, lastName, email } = row;
      const fullName = `${firstName} ${lastName}`.toLowerCase();
  
      return (
        fullName.includes(inputValue.toLowerCase()) ||
        email.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  
  };

  const columns = [

    { field: "userId", headerName: "User Id", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },

    { field: "firstName", headerName: "Nombres", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
  { field: "lastName", headerName: "Apellidos", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "rut", headerName: "rut", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "location", headerName: "Organización", flex: 1,      editable: true,
  headerClassName: "bold-header",
  },
    { field: "email", headerName: "Email", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
   
  { field: "area", headerName: "area", flex: 1,       editable: true,
    
  headerClassName: "bold-header",
  },
   
    { field: "opconflicto", headerName: "Conflicto", flex: 1,       editable: true,
    
  headerClassName: "bold-header",
  },


    { field: "tipoconflicto", headerName: "Tipo Conflicto", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "estado", headerName: "Estado", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "opauth", headerName: "Autorizado", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "campaignid", headerName: "Id Campaña", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "opCampaign", headerName: "Tipo Campaña", flex: 1,       editable: true,
  headerClassName: "bold-header",
  },
    { field: "createdAt", headerName: "Declarado", flex: 1, headerClassName: "bold-header",
  },
    
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
  },

  ];

  return (
    <Box m="20px">
        {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "130%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

      <Header title="Declaraciones" subtitle="Administrar todas las Declaracioes" />
      <div>

      <p>ID Contrato: {idcontrato}</p>
   
  
    </div>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: GridToolbar }}

          rows={filteredData}
          columns={columns}
          onRowClick={handleRowClick} // Updated: Pass the click handler
          headerClassName="bold-header"

        />
      </Box>

      
    </Box>
  );
};

export default Alldecl;

import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";
import LogoImage from '../../assets/eticprologo1.png';
import { FormattedMessage } from 'react-intl';

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Box
      width={isNonMobileScreens ? "50%" : "93%"}
      p="2rem"
      m="1rem auto"
      borderRadius="1.5rem"
      backgroundColor={theme.palette.background.alt}
      mt={8} // Add margin top to create space for the header
      zIndex={1} // Set a lower z-index for the login form box
    >
    
      
      <Form />
    </Box>
  );
};

export default LoginPage;

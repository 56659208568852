// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, esES  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, Snackbar, Button, Typography, useTheme, CircularProgress, TextField , Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import StatBox from "../../components/StatBox";
import StatBoxnom from "../../components/StatBoxnom";   import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateMeeting from "../../components/CreateMeeting";



const Meetings = () => {
  
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // Set initial value to false
  const [userSol, setUserSol] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax1, setDatax1] = useState([]);
  const [dataxprog1, setDataxprog1] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [totalcam, setTotalcam] = useState(0);
  const [openForm, setOpenForm] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);
  const [showCreateCampaignEdit, setShowCreateCampaignEdit] = useState(false); // Set initial value to false

  let filteredData = data;


  
  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };

   filteredData = data
  .filter((row) => {
    // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
    const createdAt = parseInt(row.createdAt);
    return createdAt >= dateRange[0] && createdAt <= dateRange[1];
  })
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });

  // Calculate the count of each area type from filtered data
  const tipottCounts = filteredData.reduce((counts, item) => {
    const tipott = item.area || 'Unknown';
    counts[tipott] = (counts[tipott] || 0) + 1;
    return counts;
  }, {});

// Calculate the count of each conflict type from filtered data
const userEmailCounts = filteredData.reduce((counts, item) => {
  const userEmail = item.empresa || 'Unknown';
  counts[userEmail] = (counts[userEmail] || 0) + 1;
  return counts;
}, {});

 useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);
    fetchData();

  }, [isFormSubmitted, searchInput]);

  const handleOpenForm = () => {
    setOpenForm(true);
  };
  
  const handleCloseForm = () => {
    setOpenForm(false);
  };
  
  const handleOpenFormEdit = () => {
    setOpenFormEdit(true);
  };
  
  const handleCloseFormEdit = () => {
    setOpenFormEdit(false);
  };
  const fetchData = async () => {
    try {
      let endpoint = `${REACT_APP_API_ENDPOINT}meetings/user/${loggedInUserId}`;
      // Check the user profile and update the endpoint accordingly
      if (userProfile === "superadmin") {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/`;
      } else if (userProfile === "admin" || userProfile === "coadmin" || userProfile === "adminetica" || userProfile === "sales" || userProfile === "comitetica" || userProfile === "aprobador" ) {
        endpoint = `${REACT_APP_API_ENDPOINT}meetings/contract/${idcontrato}`;
      }
  
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const formData = await response.json();
      if (!Array.isArray(formData)) {
        throw new Error("Invalid data format");
      }
  
      const userIds = formData.map((row) => row.userId);
  
      if (!Array.isArray(userIds) || userIds.length === 0) {
        throw new Error("No user IDs found in formData");
      }
  
      const userDataPromises = userIds.map((userId) =>
        fetch(`${REACT_APP_API_ENDPOINT}users/${userId}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => response.json())
      );
  
      const userDataArray = await Promise.all(userDataPromises);
  
      const userIdToEmailMap = userDataArray.reduce((acc, userData) => {
        if (userData && userData._id && userData.email) {
          acc[userData._id] = userData.email;
        }
        return acc;
      }, {});
  
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        userEmail: userIdToEmailMap[row.userId] || '',
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("en-EN", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      const totalcam = dataWithFormattedDate.length;
      setTotalcam(totalcam);
  
      setIsLoading(false);
      setData(dataWithFormattedDate);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false);
    }
  };
  
  
 
 const datax = Object.entries(tipottCounts).map(([label, count]) => ({ label, count }));
  const dataxconflicto = Object.entries(userEmailCounts).map(([label, count]) => ({ label, count }));


  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';
  
    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      const rowId = params.row._id; // Assuming _id is the unique identifier for the row

      navigate(`/meetingsEvPage/${rowId}`); // Replace "/campaigndetail" with the actual URL of your CampaignDetailPage

    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };




  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };

 
  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Reuniones');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'reuniones.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };


  const columns = [
    {
      field: "start",
      headerName: <FormattedMessage id="Ocurrió"/>,
      flex: 1, headerClassName: "bold-header",
      valueGetter: (params) => {
        // Convert the date to the "es-ES" locale format
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString("es-ES");
      },    },
    { 
      field: "user.firstName", 
      headerName: <FormattedMessage id="Nombres" />,
      flex: 1, 
      valueGetter: (params) => params.row.user ? params.row.user.firstName : "" 
    ,headerClassName: "bold-header",
  },
        { field: "user.lastName",     headerName: <FormattedMessage id="Apellidos" />
        , flex: 1, valueGetter: (params) => params.row.user.lastName ,headerClassName: "bold-header", },
    { field: "user.rut",     headerName: <FormattedMessage id="Rut" />
    , flex: 1,  valueGetter: (params) => params.row.user.rut ,headerClassName: "bold-header", },
    { field: "user.location",     headerName: <FormattedMessage id="Organización" />, 
    flex: 1, valueGetter: (params) => params.row.user.location ,headerClassName: "bold-header", },
    { field: "user.occupation", headerName: <FormattedMessage id="Rol"/>,  flex: 1, headerClassName: "bold-header",valueGetter: (params) => params.row.user.occupation },
    { field: "user.area", headerName: <FormattedMessage id="Area"/>,  flex: 1, headerClassName: "bold-header", valueGetter: (params) => params.row.user.area },
    { field: "participantes", headerName: <FormattedMessage id="Participantes"/>, flex: 1, headerClassName: "bold-header", },
    { field: "title", headerName: <FormattedMessage id="Asunto"/>,  flex: 1, headerClassName: "bold-header", },
    
    { field: "willPayReceiveValue", headerName: <FormattedMessage id="Pago/Recepción"/> , flex: 1 ,headerClassName: "bold-header", },
    { field: "empresa", headerName: <FormattedMessage id="Contraparte"/>, flex: 1,headerClassName: "bold-header", },
    { field: "area", headerName: <FormattedMessage id="Area Contraparte"/>, flex: 1,headerClassName: "bold-header", },
    { field: "direccion", headerName: <FormattedMessage id="Direccion"/>,  flex: 1 ,headerClassName: "bold-header", },
    { field: "rutfunc", headerName: <FormattedMessage id="Rut Fun."/>,  flex: 1 , headerClassName: "bold-header", },
    { field: "cargo", headerName: <FormattedMessage id="Cargo Fun."/>, flex: 1,headerClassName: "bold-header", },
    { field: "otrosasistentes", headerName: <FormattedMessage id="Asistentes"/>, flex: 1, editable: true ,headerClassName: "bold-header", },
    { field: "numeroacta", headerName: <FormattedMessage id="Acta"/>, flex: 1,headerClassName: "bold-header", },
    { field: "estado", headerName: <FormattedMessage id="Estado"/>, flex: 1,headerClassName: "bold-header", },

    {
      field: "createdAt",
      headerName: <FormattedMessage id="Declarado"/>,
      flex: 1, headerClassName: "bold-header",
      valueGetter: (params) => {
        // Convert the date to the "es-ES" locale format
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString("es-ES");
      },    },
 
  ];


// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const formatDate = (dateString) => {
    if (!dateString) return "";
  
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Check if the date is valid
  
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
    return formattedDate;
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  let clientJSX;
  
      clientJSX = (
        <Box>
                    {!showCreateCampaignEdit && (

            <Button
              onClick={handleOpenForm}
              sx={{
                borderRadius: "20px",
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[900],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
              }}
            >
              <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
              <FormattedMessage id="Nueva Reunion" />
            </Button>
           )}
             {showCreateCampaign && (
          <>
            <CreateMeeting />    
            </>
        )}
      
        </Box>
      );
   

  return (
    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
    

    {/* HEADER */}

    <Box display="flex" justifyContent="space-between" alignItems="center">
    <Header title={<FormattedMessage id="Reuniones" />} subtitle={<FormattedMessage id="Reuniones con Personas de Interés" />} />

    </Box>
    </Grid>

      <Grid item xs={12} sm={8} md={1}>
</Grid>


  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>


    <Box>
      
   
      </Box>
         <p></p>


{/*datagrid que lo ve el denunciante*/}
<p></p>

{clientJSX}


  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
  {/* StatBox - 3 columns */}
  <Box gridColumn={{ xs: 'span 3', md: 'span 2' }} gridRow="span 2">

  </Box>
  </Box>
  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.length.toString()}
      subtitle={<FormattedMessage id="Reuniones" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
  
  <div style={{ marginRight: '25px' }}>
    <StatBoxnom
      title={filteredData.filter(row => row.estado === "Por Evaluar").length.toString()}
      subtitle={<FormattedMessage id="Por Evaluar" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>

  <div>
    <StatBoxnom
      title={filteredData.filter(row => row.estado === "Evaluada").length.toString()}
      subtitle={<FormattedMessage id="Evaluada" />}
      icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
    />
  </div>
</Box>

<p></p>



          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{ borderRadius: "20px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
            >
            {/* Display loading spinner if isLoading is true */}
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress />
              </div>
            ) : (
             
             
              <DataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    "user.area": false,
                    "area": false,
                    "start": false,

                    "participantes": false,
                    "willPayReceiveValue": false,
                    "direccion": false,
                    "rutfunc": false,
                    "cargo": false,
                    "user.rut": false,
                    "otrosasistentes": false,
                    "numeroacta": false,
                    
                    "user.location": true,


                    // Set other columns visibility here
                  },
                },
              }}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={filteredData.map((row) => ({
                ...row,
                id: row._id,
              }))}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                  toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
              }}}
              rowsPerPageOptions={[10, 20, 50]} // Include 25 in the options
              columns={columns}
              onRowClick={handleRowClick}
              headerClassName="bold-header"

            />

            
              )}

<Dialog 
  open={openForm} 
  onClose={handleCloseForm} 
  fullScreen={!isNonMobileScreens}
  sx={{ 
    "& .MuiDialog-paper": { // Targeting the dialog's paper element
      width: "900px", // Set width to 300px
      maxWidth: "900px" // Ensure it doesn't expand beyond 300px
    }
  }}
>
          <DialogTitle>
          <Typography variant="h2">Crear Reunion</Typography>
          </DialogTitle>
        <DialogContent>
         
      <CreateMeeting />

          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cerrar</Button>
        </DialogActions>
      </Dialog>


            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              message="Copiado al portapapeles"
            />
          </Box>
          <p></p>
          <Box>
                <Button
                    onClick={handleDownloadXLSX}
                    sx={{ borderRadius: "20px",
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[900],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <p></p>
                    <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
                    <FormattedMessage id="Descargar en Excel" />

              </Button>
              </Box>
              <p></p>

 {/*barras*/}
         <Box
              gridColumn={{ xs: 'span 12', md: 'span 6' }}
              gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      sx={{ borderRadius: "20px", padding: "30px 30px 0 30px" }}
                    >
            <FormattedMessage id="Reuniones por Area" />

                    </Typography>


                    <Box height="250px" mt="-20px">

                    <BarChart data={datax} />

                    </Box>


        </Box>
   <Box
                    gridColumn={{ xs: 'span 12', md: 'span 12' }}
                    gridRow="span 2"
                          backgroundColor={colors.primary[400]}
                        >
                          <Box
                            mt="25px"
                            p="0 30px"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight="600"
                                      color={colors.grey[100]}
                                    >
                                    <FormattedMessage id="Reuniones por Organización" />

                                    </Typography>
                                    <Typography
                                      variant="h3"
                                      fontWeight="bold"
                                      color={colors.greenAccent[500]}
                                    >
                                    </Typography>
                                  </Box>
                            <Box>
                              
                            </Box>
                          
                          
                          </Box>
                              <Box height="250px" m="-20px 0 0 0">
                              <BarChart data={dataxconflicto} />

                              </Box>

                  </Box></Box>
  );
};

export default Meetings;
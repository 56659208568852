// pagina oficial de campañas incluye el widget Nueva camapaña y el grid que muestas las campañas directamente

import { DataGrid, GridToolbar, GridColDef, esES, GridRenderCellParams } from "@mui/x-data-grid";
import Reunion from "@mui/icons-material/Group";
import Read from "@mui/icons-material/LocalLibrary";
import CI from "@mui/icons-material/ListAlt";

import Login from "@mui/icons-material/PlayArrow";
import TV from "@mui/icons-material/AttachMoney";
import Denuncia from "@mui/icons-material/Warning";
import DD from "@mui/icons-material/ZoomIn";
import Otro from "@mui/icons-material/DoubleArrow";
import { createTheme } from '@mui/material/styles';

import Chip, { ChipProps } from "@mui/material/Chip";
import { green, red, blue } from "@mui/material/colors";
import ProgressBarr from "../../components/ProgressBarr";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, IconButton, Snackbar, Button, Typography, useTheme, Slider, TextField, CircularProgress, LinearProgress  } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setIdcontrato } from "../../state";
import { useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import CreateCampaign from "../../components/CreateCampaign";
import { useLocation } from "react-router-dom";
import Upload from "@mui/icons-material/AddSharp";
import BarChart from "../../components/BarChart";
import BarChartor from "../../components/BarChart";
import Paper from '@mui/material/Paper'; // Import the Paper component
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';


const greenText = { color: 'green' }; // Define the CSS style for green text

const Campaigns = () => {
  const userEmail = useSelector((state) => state.user.email);
  const [campaignDelays, setCampaignDelays] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const location = useLocation();
  const idcontrato = useSelector((state) => state.user.idcontrato);
  const [selectedCellData, setSelectedCellData] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const userProfile = useSelector((state) => state.user.userprofile);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const [isLoading, setIsLoading] = useState(true);
  const [exportedData, setExportedData] = useState(null);
  const { _id, picturePath, email } = useSelector((state) => state.user);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [datax, setDatax] = useState([]);
  const [dataxprog, setDataxprog] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [data, setData] = useState([]);


  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
  
// Create a function to calculate datax and dataxprog based on filteredData
const calculateChartData = (filteredData) => {
  if (!filteredData) {
    // Return default values or handle the case when data is not available

    
    return {
      filteredDataxprog: [],
    };
  }


  const filteredDataxprog = filteredData
    .map((row) => ({
      label: row.comments, // Display the last 4 digits of the ID
      count: ((row.latestSessionsLength / row.descriptionLength) * 100).toFixed(2) , // Calculate and format the percentage
      progress: row.latestSessionsLength,

    }));

    filteredDataxprog.sort((a, b) => b.count - a.count);


  return { filteredDataxprog };
};




  
  useEffect(() => {

    setShowSuccessPopup(false);
    setSnackbarOpen(false);

    // Fetch data without applying filters when the component first loads
    fetchData();
  }, [isFormSubmitted, searchInput]);
  

useEffect(() => {
  // Filter data based on the search input
  const filtered = data.filter(row => {
    // Combine relevant fields for search
    const combinedFields = `${row.description}`.toLowerCase();
    return combinedFields.includes(searchInput);
  });
  setFilteredData(filtered);
}, [data, searchInput]);

  const fetchData = async () => {
    try {
      //      const response = await fetch(`${REACT_APP_API_ENDPOINT}posts/`, {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}posts/contract3/${idcontrato}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
    }
      const formData = await response.json();
      const dataWithFormattedDate = formData.map((post) => {
        const emptySessionsLength = post.descriptionLength - post.latestSessionsLength - post.mismatchedSessionsLength;

        return {
          ...post.postFields, // Extract post fields from the server response
          id: post.postId,
          createdAt: post.postFields.createdAt
            ? new Date(post.postFields.createdAt).toLocaleDateString("en-EN", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })
            : "",
          descriptionLength: post.descriptionLength,
          latestSessionsLength: post.latestSessionsLength,
          emptySessionsLength: emptySessionsLength,
          mismatchedSessionsLength: post.mismatchedSessionsLength,
          latestSessionsLastPeriodLength: post.latestSessionsLastPeriodLength, 

        };
      });
  
  // Sort the data chronologically based on 'createdAt'
  dataWithFormattedDate.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });

  setData(dataWithFormattedDate);

  const dataxprog = dataWithFormattedDate
  .filter((row) => row.opCampaign === "CI")
  .map((row) => ({
    label: row._id.slice(-4), // Display the last 4 digits of the ID
    count: row.descriptionLength,
    progress: row.matchingEmailsLength,
  }));

const datax = dataWithFormattedDate
  .filter((row) => row.opCampaign === "CI")
  .map((row) => ({
    label: row._id.slice(-4), // Display the last 4 digits of the ID
    count: row.descriptionLength,
  }));

      setIsLoading(false);
      const exportedData = {
        totalDescriptionLength: dataWithFormattedDate.reduce((total, row) => total + row.descriptionLength, 0),
        totalMatchingEmailCount: dataWithFormattedDate.reduce((total, row) => total + row.matchingEmailCount, 0),
      };

    
      setExportedData(exportedData);
      
      setDataxprog(dataxprog); // Set the datax state with the generated data
      setDatax(datax); // Set the datax state with the generated data

      const delayPromises = dataWithFormattedDate.map(async (row) => {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}formepd/campaign/${row._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const delayData = await response.json();
  

        // Calculate the sum of createdAt values
        const totalDelay = delayData.reduce((sum, item) => {
          if (item.createdAt) {
            const createdAtDate = new Date(item.createdAt);
            return sum + createdAtDate.getTime();
          }
          return sum;
        }, 0);

  
        return { campaignId: row._id, totalSum: totalDelay };
      });
  
      const campaignDelayResults = await Promise.all(delayPromises);
      setCampaignDelays(campaignDelayResults);

      return formData; // Return the fetched data

    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Set loading state to false to handle the error
      throw error; // Re-throw the error for higher-level handling, if needed
    }
  };

  // Calculate new chart data based on the filteredData
  const { filteredDataxprog } = calculateChartData(filteredData);


  // Calculate chart data based on filteredData
  const chartData = filteredData
    ? filteredData.map((row) => ({
        label: new Date(row.createdAt).toLocaleDateString("en-EN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }), // Format the date as needed
        count: Array.isArray(row.description) ? row.description.length : 0,
      }))
    : [];
 

  const handleCellClick = (params) => {
    const field = params.field;
    const cellData = params.row[field];
    setSelectedCellData(cellData);
    setSnackbarOpen(true);
  };

  const handleRowClick = (params, event) => {
    // Check if the click event occurred on the delete button
    const isDeleteButton = event.target.tagName.toLowerCase() === 'button';
    
    const rowId = params.row._id; // Assuming _id is the unique identifier for the row
    const campaignType = params.row.opCampaign; // Assuming opCampaign is the campaign type field
  
 
  
    // Navigate to the formev page only if the click event didn't occur on the delete button
    if (!isDeleteButton) {
      navigate(`/campaigndetailPage/${rowId}`); // Replace "/campaigndetail" with the actual URL of your CampaignDetailPage
    }
  };
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };



  const handleDelete = async (rowId) => {
    try {
      // Make API call to delete the row in the database
      const response = await fetch(`${REACT_APP_API_ENDPOINT}posts/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        // If the deletion is successful, update the data in state by removing the deleted row
        setData((prevData) => prevData.filter((row) => row.id !== rowId));
        // Optionally, display a success message or perform any other necessary actions
      } else {
        // If there is an error with the deletion, display an error message or perform error handling
        console.error("Error deleting row:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };


  const handleCreateCampaignClick = () => {
    setShowCreateCampaign((prevState) => !prevState);
  };




  if (isLoading) {
    return <div>Cargando...</div>;
  }

  const widgetStyle = {
    padding: '1rem',
    borderRadius: '15px', // Adjust the value for rounded corners
  };


  
  

function getChipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "login") {
    return {
      icon: <Login style={{ fill: green[500] }} />,
      label: params.value,
      style: {
        borderColor: red[500]
      }
    };
  }
    if (params.value === "Read") {
      return {
        icon: <Read style={{ fill: blue[500] }} />,
        label: params.value,
        style: {
          borderColor: green[500]
        }
      };    
  }
  if (params.value === "CI") {
    return {
      icon: <CI style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
}
  if (params.value === "TV") {
    return {
      icon: <TV style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };    
}

if (params.value === "Denuncia") {
  return {
    icon: <Denuncia style={{ fill: blue[500] }} />,
    label: params.value,
    style: {
      borderColor: green[500]
    }
  };    
}

if (params.value === "Repo") {
  return {
    icon: <Repo style={{ fill: green[500] }} />,
    label: params.value,
    style: {
      borderColor: green[500]
    }
  };    
}
if (params.value === "PEP") {
  return {
    icon: <Reunion style={{ fill: blue[500] }} />,
    label: params.value,
    style: {
      borderColor: green[500]
    }
  };    
}  if (params.value === "DD") {
  return {
    icon: <DD style={{ fill: blue[500] }} />,
    label: params.value,
    style: {
      borderColor: green[500]
    }
  };    
}

   else {
    return {
      icon: <Otro style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: blue[500]
      }
    };
  }
}




const columns = [

 // { field: "id", headerName: <FormattedMessage id="Id", width: 50, valueGetter: (params) => params.value },
 { field: "comments", headerName: <FormattedMessage id="Campaña" />, width: 250 , 
 headerClassName: "bold-header", }, 

 {
  field: "opCampaign",
  headerName: "Tipo",
  flex: 1,
  minWidth: 100,
  description: "opCampaign",
  headerAlign: "left",
  headerClassName: "bold-header",
  renderCell: (params) => {
    return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
  }
},



  { field: "description", headerName: <FormattedMessage id="Enviada a"/>, width: 150 , 
  headerClassName: "bold-header",},


  {
    field: "envioMail",
    headerName: <FormattedMessage id="Envio Mail" />,
    width: 100,
    headerClassName: "bold-header",
    valueGetter: (params) => params.row.envioMail === "true" ? "No" : "Si",

  },
  
 
  { field: "createdAt", headerName: <FormattedMessage id="Creada"/>, width: 70, valueGetter: (params) => formatDate(params.value), 
  headerClassName: "bold-header", },
  { field: "descriptionLength", headerName: <FormattedMessage id="Target"/>, width: 80, 
  headerClassName: "bold-header",},

  {
    field: 'progress',
    headerClassName: 'bold-header',
    headerName: <FormattedMessage id="Progreso" />,
    width: 200,
    renderCell: (params) => {
     
      const campaignType = params.row.opCampaign; // Assuming opCampaign is the campaign type field

     
      const latestSessionsLength = params.row.latestSessionsLength
      const descriptionLength = params.row.descriptionLength || 1; // To avoid division by zero
      const percentage = Math.round((latestSessionsLength / descriptionLength) * 100);
      const mismatchedSessionsLength = params.row.mismatchedSessionsLength || 1; // To avoid division by zero
      const emptySessionsLength = params.row.emptySessionsLength || 1; // To avoid division by zero

 

  return (

    <ProgressBarr bgcolor="#1976d2" completed={percentage} />
  );
    },
  },

  { field: "latestSessionsLength", headerName: <FormattedMessage id="Cumplió"/>, width: 80, 
  headerClassName: "bold-header",},
   
  { field: "latestSessionsLastPeriodLength", headerName: <FormattedMessage id="Últimos 15 dias"/>, width: 80, 
  headerClassName: "bold-header",},
  
/*
  {
    field: "completionRatio",
    headerClassName: "bold-header",
    headerName: <FormattedMessage id="15d Ratio"/>,
    width: 150, // Adjust width as needed
    renderCell: (params) => {
      const latestSessionsLength = params.row.latestSessionsLength || 1; // To avoid division by zero
      const latestSessionsLastPeriodLength = params.row.latestSessionsLastPeriodLength || 0; // Use 0 if latestSessionsLastPeriodLength is undefined
      const ratio = latestSessionsLastPeriodLength / latestSessionsLength;
  
      return (
        <ProgressBarr bgcolor="#FFA500" completed={ratio*100} />
      );
    },
  },
*/

  { field: "emptySessionsLength", headerName: <FormattedMessage id="Sin Entrar"/>, width: 80, 
  headerClassName: "bold-header",},
  { field: "mismatchedSessionsLength", headerName: <FormattedMessage id="Otra Actividad"/>, width: 80, 
  headerClassName: "bold-header",},
];

// Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Check if the date is valid

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(2);

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

const handleSearchInputChange = (event) => {
  const inputValue = event.target.value.toLowerCase();
  setSearchInput(inputValue);
  
  // Filter data based on the search input in the combined user fields
  const filteredData = data.filter(row => {
    // Combine all relevant user fields into a single string
    const combinedFields = `${row.description}`.toLowerCase();
    return combinedFields.includes(inputValue);
  });

  // Update the state with the filtered data
  setData(filteredData);
};







  const handleDownloadXLSX = async () => {
    if (data !== undefined && data !== null && data.length > 0) {
      try {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Campañas');
  
        // Define the headers for your data (assuming the first object in data has the keys)
        const headers = Object.keys(data[0]);
  
        // Add the headers to the worksheet
        worksheet.addRow(headers);
  
        // Add the data rows to the worksheet
        data.forEach((item) => {
          const row = [];
          headers.forEach((header) => {
            row.push(item[header]);
          });
          worksheet.addRow(row);
        });
  
        // Generate a blob from the workbook
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Convert the buffer to a blob
        const xlsxBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        // Download the XLSX file
        saveAs(xlsxBlob, 'campañas.xlsx');
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      // Handle the case when data is empty or undefined
    }
  };

 const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  return (
    <Box m="10px">

<Grid container spacing={2}>
<Grid item xs={12} sm={6} md={4}>

<Box display="flex" justifyContent="space-between" alignItems="center">
   
     <Header title={<FormattedMessage id="Campañas" />} subtitle={<FormattedMessage id="Campaña de invitaciones enviadas" />} />

     </Box>
     </Grid>


<Grid item xs={12} sm={8} md={1}>


</Grid>

  {/* Search - 3 columns */}
<Grid item xs={12} sm={6} md={2}>
  {/* Wrap the Search input in a Box */}
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "8px", // Adjust padding for spacing
      display: "flex",
      alignItems: "center", // Center the icon and input vertically
      height: "50%", // Set the height of the Box to 60%
      width: "100%", // Make the input element 100% wide within the box

    }}
  >
    <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
    <TextField
      label=""
      value={searchInput}
      onChange={handleSearchInputChange}
      InputProps={{
        style: {
          ...styles.roundedInput,
          width: "100%", // Make the input element 100% wide within the box
          height: "100%", // Set the height of the TextField to 100%
        },
      }}
      sx={{
        height: "100%", // Set the height of the TextField to 100%
      }}
    />
  </Box>
</Grid>

 </Grid>
    {/* HEADER */}


    <Box>
  {userProfile !== 'coadmin' && (
    <Button
      onClick={handleCreateCampaignClick}
      sx={{ borderRadius: "20px",
        backgroundColor: colors.blueAccent[700],
        color: colors.grey[900],
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 20px',
      }}
    >
      <Upload sx={{ borderRadius: "20px", mr: '10px' }} />
      <FormattedMessage id="Nueva Invitación" />

    </Button>
  )}
       {showCreateCampaign && <CreateCampaign _id={_id} />}
       </Box>
<p></p>

  




<Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="120px"
      gap="30px"
    >
      <Box gridColumn={{ xs: 'span 12', md: 'span 12' }} gridRow="span 6">
        <Box
        m="20px 0 0 0"
        height="90%" // Set the height to 50% of the original height
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
             initialState={{
              columns: {
                columnVisibilityModel: {
                  description: false,
                

                },
              },
            }}

          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={filteredData.map((row) => ({
            ...row,
            id: row._id, 
          }))}     
       
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          //onCellClick={handleCellClick} // Use onCellClick instead of onRowClick
          onRowClick={handleRowClick}
          
          rowsPerPageOptions={[10, 25, 50]} // Include 25 as an option
          headerClassName="bold-header"
          />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copiado al portapapeles"
        />
      </Box>
      </Box>


    {/* Last BarChart */}
    <Box gridColumn={{ xs: 'span 12', md: 'span 12' }} gridRow="span 2" backgroundColor={colors.primary[400]}>
      <Typography
        variant="h5"
        fontWeight="600"
        sx={{ borderRadius: "20px", padding: "20px 20px 0 20px" }}
      >
                 <FormattedMessage id="Respuesta de Campañas" />

      </Typography>
      <Box height="200px" mt="-20px">
      {chartData.length > 0 && (
  <BarChartor data={filteredDataxprog} />
)}

  </Box>
    </Box>

</Box>
<p></p>
 {/* Button for downloading report */}
 <Box>
      <Button
        onClick={handleDownloadXLSX}
        sx={{
          borderRadius: "20px",
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[900],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
      >
        <p></p>
        <DownloadOutlinedIcon sx={{ borderRadius: "20px", mr: "10px" }} />
        <FormattedMessage id="Descargar Reporte" />

      </Button>
    </Box>

    </Box>
   
    
  );
  
};

export default Campaigns;
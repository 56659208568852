import React from 'react';
import { Box, Typography, useMediaQuery } from "@mui/material";
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

const SuccessPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const location = useLocation();
  const message = location.state?.message || "Su información fue enviada con éxito!";

  return (
    <Box
      sx={{
        borderRadius: "20px",
        backgroundColor: "white",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={isNonMobileScreens ? "50%" : "100%"}
        p="1rem"
        textAlign="center"
      >
        <Typography
          fontWeight="bold"
          fontSize={isNonMobileScreens ? "56px" : "32px"}
          color="green" // or any other shade of green you prefer
        >
          {<FormattedMessage id={message} />}
        </Typography>
        <Typography
          fontWeight="bold"
          fontSize={isNonMobileScreens ? "12px" : "16px"}
          color="blue" // or any other shade of green you prefer
        >
          {<FormattedMessage id="Puede escoger otra opción en el menu o cerrar esta ventana si no tiene opción, Gracias." />}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessPage;

import { Box, IconButton, Snackbar, Button, Typography, useTheme, Slider, TextField  } from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/SaveSharp";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { idcontrato, setForm } from "../../state";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import FlexBetween from "../../components/FlexBetween";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StatBox from "../../components/StatBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StatBoxnom from "../../components/StatBoxnom";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage } from 'react-intl';

const Allcamp = ({ }) => {
const location = useLocation();
const idcontrato = useSelector((state) => state.user.idcontrato);
const [editedRows, setEditedRows] = useState({});

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchInput, setSearchInput] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [data, setData] = useState([]); // Updated: Initialize data state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  // Rename one of the variables to something different, e.g., filteredSearchData
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dateRange, setDateRange] = useState([0, 100]);

  const [chartData, setChartData] = useState([]);
  const [filteredDataxprog, setFilteredDataxprog] = useState([]);
  const [filteredDatax, setFilteredDatax] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [filteredata, setFilteredData] = useState([]);

  useEffect(() => {
    setShowSuccessPopup(false);
    setSnackbarOpen(false);
  
    // Fetch data without applying filters when the component first loads
    fetchData().then((data) => {
      // Calculate new chart data based on the filteredData
      const { filteredDataxprog, filteredDatax } = calculateChartData(filteredData);
      setFilteredDataxprog(filteredDataxprog);
      setFilteredDatax(filteredDatax);
  
      // Calculate chart data based on filteredData
      const chartData = filteredData
        ? filteredData.map((row) => ({
            label: new Date(row.createdAt).toLocaleDateString("en-EN", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }), // Format the date as needed
            count: Array.isArray(row.description) ? row.description.length : 0,
          }))
        : [];
  
      setChartData(chartData);
    });
  }, [isFormSubmitted, dateRange, searchInput]);
  
  
  
  let filteredData = data;



  const handleDateFilterChange = (event, newValue) => {
    setDateRange(newValue);
  };
// Create a function to calculate datax and dataxprog based on filteredData
const calculateChartData = (filteredData) => {
  if (!filteredData) {
    // Return default values or handle the case when data is not available
    return {
      filteredDataxprog: [],
      filteredDatax: [],
    };
  }

  const filteredDataxprog = filteredData
    .filter((row) => row.opCampaign === "CI")
    .map((row) => ({
      label: row._id,
      count: row.descriptionLength,
      progress: row.matchingEmailsLength,
    }));

  const filteredDatax = filteredData
    .filter((row) => row.opCampaign === "CI")
    .map((row) => ({
      label: row._id,
      count: row.descriptionLength,
    }));

  return { filteredDataxprog, filteredDatax };
};


  filteredData = data
  .filter((row) => {
    // Apply the date filter based on the "createdAt" field (assuming it is a numeric value)
    const createdAt = parseInt(row.createdAt);
    return createdAt >= dateRange[0] && createdAt <= dateRange[1];
  })
  .filter((row) => {
    // Apply the search filter based on all fields in the row
    const searchInputLower = searchInput.toLowerCase();
    for (const key in row) {
      if (row.hasOwnProperty(key) && typeof row[key] === 'string') {
        if (row[key].toLowerCase().includes(searchInputLower)) {
          return true; // Return true if any field contains the search input
        }
      }
    }
    return false; // Return false if no field contains the search input
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}posts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
        },
      });
      const formData = await response.json();
  
      // Add unique id and format createdAt
      const dataWithFormattedDate = formData.map((row) => ({
        ...row,
        id: row._id,
        createdAt: row.createdAt
          ? new Date(row.createdAt).toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
          : "",
      }));
  
      setData(dataWithFormattedDate); // Set the fetched data to the state here
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleRowClick = async (params, action) => {
    const rowId = params.row._id;
    
    if (action === "edit") {
      const { row, field } = params;
      const updatedRow = { ...row, [field]: params.value };
  


      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [row.id]: updatedRow,
      }));
  
      // Save the edited row
      await saveRowChanges(rowId, updatedRow);
    } else if (action === "delete") {
      // Add code to delete the row from the database using the rowId
      deleteRow(rowId);
    }
  };
  
  // Function to save the changes for a specific row
  const saveRowChanges = async (rowId, updatedRow) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}posts/${rowId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedRow),
      });
    } catch (error) {
      console.error("Error saving row changes:", error);
    }
  };
  
  const deleteRow = async (rowId) => {
    try {
      await fetch(`${REACT_APP_API_ENDPOINT}posts/${rowId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the data and filteredData states after deleting the row
      const updatedData = data.filter((row) => row.id !== rowId);
      setData(updatedData);
      setFilteredData(updatedData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const row = data.find((row) => row.id === id);
  
    if (row) {
      const updatedRow = { ...row, [field]: value };
  
  
  
      setEditedRows((prevEditedRows) => ({
        ...prevEditedRows,
        [id]: updatedRow,
      }));
  
      const updatedData = data.map((row) => (row.id === id ? updatedRow : row));
      setData(updatedData);
      setFilteredData(updatedData);
    }
  };
  


  const columns = [
    { field: "id", headerName: "ID Campaña", flex: 0.5, valueGetter: (params) => params.value.slice(0) , headerClassName: "bold-header",
  },
    { field: "email", headerName: "Creado por", flex: 1, editable: true , headerClassName: "bold-header",
  },
    { field: "comments", headerName: "Campaña", flex: 1, editable: true , headerClassName: "bold-header",
  },
    { field: "idcontrato", headerName: "contrato", flex: 1,       editable: true , headerClassName: "bold-header",
  },
    { field: "description", headerName: "Enviado a", flex: 1,       editable: true , headerClassName: "bold-header",
  },
  { field: "link", headerName: "mensaje", flex: 1, editable: true , headerClassName: "bold-header",
},
    { field: "opCampaign", headerName: "Tipo Formulario", flex: 1, editable: true , headerClassName: "bold-header",
  },
    { field: "createdAt", headerName: "Fecha", flex: 1, editable: true , headerClassName: "bold-header",
  },

    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleRowClick(params, "edit")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleRowClick(params, "delete")}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },

  ];


 // Sort the data array in ascending order based on createdAt field
data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Check if the date is valid

  const formattedDate = date.toLocaleDateString("en-EN", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit"
  });
  return formattedDate;
};

const handleSearchInputChange = (event) => {
  setSearchInput(event.target.value);
};

  const styles = {
    // Define the rounded corners style for the box
    roundedBox: {
      width: "30%", // Make the box 50% wide
      borderRadius: "10px", // Adjust the value to control the roundness
      padding: "10px", // Add some padding for spacing
      backgroundColor: "white", // Set the background color to white
    },
    roundedInput: {
      borderRadius: "10px", // Adjust the value to control the roundness
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };



  return (
    <Box m="10px">

    <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
    
    <Box display="flex" justifyContent="space-between" alignItems="center">
       
         <Header title={<FormattedMessage id="Campaña de Invitaciones" />} subtitle={<FormattedMessage id="Campañas  enviadas por el team de Compliance Management" />} />

         </Box>
         </Grid>
    
    
    <Grid item xs={12} sm={8} md={1}>
    
    
    </Grid>
    
    <Grid item xs={12} sm={6} md={4}>
         {/* Slider */}
         <Box
           sx={{
             ...styles.roundedBox,
             width: "100%", // Make the box 30% wide
             padding: "10px", // Add 20px of padding
             height: "50%", // Set the height of the Box to 60%
    
           }}
         >
           <Slider
             value={dateRange}
             onChange={handleDateFilterChange}
             valueLabelDisplay="auto"
             
             min={0}
             max={100}
             marks={[
               { value: 0, label: formatDate(data[0]?.createdAt) || "" },
               {
                 value: 100,
                 label: formatDate(data[data.length - 1]?.createdAt) || "",
               },
             ]}
             sx={{
              
               borderRadius: "20px",
               "& .MuiSlider-rail": {
                 backgroundColor: "green",
               },
               "& .MuiSlider-track": {
                 backgroundColor: "green",
               },
               "& .MuiSlider-thumb": {
                 backgroundColor: "green",
                 padding: "0 10px",
                 "&:hover, &.Mui-focusVisible": {
                   boxShadow: "0px 0px 0px 8px rgba(0, 255, 0, 0.16)",
                 },
               },
             }}
           />
         </Box>
       </Grid>
      {/* Search - 3 columns */}
    <Grid item xs={12} sm={6} md={2}>
      {/* Wrap the Search input in a Box */}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "8px", // Adjust padding for spacing
          display: "flex",
          alignItems: "center", // Center the icon and input vertically
          height: "50%", // Set the height of the Box to 60%
          width: "130%", // Make the input element 100% wide within the box
    
        }}
      >
        <SearchIcon sx={{ color: "gray", marginRight: "8px" }} />
        <TextField
          label=""
          value={searchInput}
          onChange={handleSearchInputChange}
          InputProps={{
            style: {
              ...styles.roundedInput,
              width: "100%", // Make the input element 100% wide within the box
              height: "100%", // Set the height of the TextField to 100%
            },
          }}
          sx={{
            height: "100%", // Set the height of the TextField to 100%
          }}
        />
      </Box>
    </Grid>
    
     </Grid>
        {/* HEADER */}
    
    
    <p></p>
    
      
    
    
    
    <Box gridColumn={{ xs: 'span 3', md: 'span 3' }} gridRow="span 1">
        <div style={{ marginRight: '20px' }}>
          <StatBoxnom
            title={filteredData.length.toString()}
            subtitle= {<FormattedMessage id="Invitaciones" />}

            icon={<PersonAddIcon sx={{ borderRadius: "20px", color: colors.greenAccent[600], fontSize: "35px" }} />}
          />
        </div>
      </Box>
    
      <Box
        m="40px 0 0 0"
        height="55vh"
        sx={{ borderRadius: "20px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white", // Set the font color to white

          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: GridToolbar }}
                    headerClassName="bold-header"

                    rows={filteredData}
                    columns={columns}
                    onRowClick={handleRowClick} // Updated: Pass the click handler
                    
        />
      </Box>

      
    </Box>
  );
};

export default Allcamp;
